import { useForm } from "react-hook-form";
import Footer from "./ui/footer/footer";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import React, { useEffect, useState } from "react";
import Searchfield from "./ui/searchfield/Searchfield";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isLoading, setisLoading] = useState(false);
  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;

    console.log("Name: ", name);
    console.log("Email: ", email);
    console.log("Subject: ", subject);
    console.log("Message: ", message);

    setisLoading(true);
    fetch(
      "https://public.herotofu.com/v1/ac7760d0-74c8-11ee-8bcd-4fcc9e7e7286",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Form response was not ok");
        } else {
          toast.success("Nachricht versendet!");
          reset();
        }

        setisLoading(false);
      })
      .catch((err) => {
        // Submit the form manually
        toast.error(err.message);
        setisLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <div
        className="ContactForm"
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
          fontFamily: "Poppins",
        }}
      >
        <div className="container">
          <div className="row">
            <h1 className="page-title mb-0">Kontakt</h1>
            <div className="col-12 text-center">
              <div className="contactForm">
                <form
                  id="contact-form"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  {/* Row 1 of form */}
                  <div className="row formRow">
                    <div className="col-6">
                      <input
                        type="text"
                        name="name"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Bitte einen Namen angeben",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "Name muss weniger als 30 Zeichen lang sein",
                          },
                        })}
                        className="form-control formInput"
                        placeholder="Name"
                      ></input>
                      {errors.name && (
                        <span className="errorMessage">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div className="col-6">
                      <input
                        type="email"
                        name="email"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                        className="form-control formInput"
                        placeholder="Email Adresse"
                      ></input>
                      {errors.email && (
                        <span className="errorMessage">
                          Bitte gebe eine valide Email Adresse an
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Row 2 of form */}
                  <div className="row formRow" style={{ marginTop: "8px" }}>
                    <div className="col">
                      <input
                        type="text"
                        name="Betreff"
                        {...register("subject", {
                          required: {
                            value: true,
                            message: "Bitte einen Betreff eingeben",
                          },
                          maxLength: {
                            value: 75,
                            message: "Subject cannot exceed 75 characters",
                          },
                        })}
                        className="form-control formInput"
                        placeholder="Anliegen"
                      ></input>
                      {errors.subject && (
                        <span className="errorMessage">
                          {errors.subject.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Row 3 of form */}
                  <div className="row formRow" style={{ marginTop: "8px" }}>
                    <div className="col">
                      <textarea
                        rows={3}
                        name="Betreff"
                        {...register("message", {
                          required: true,
                        })}
                        className="form-control formInput"
                        placeholder="Nachricht"
                      ></textarea>
                      {errors.message && (
                        <span className="errorMessage">Deine Nachricht</span>
                      )}
                    </div>
                  </div>
                  <p
                    className="mb-4"
                    style={{ textAlign: "left", marginTop: "4px" }}
                  >
                    Du hast eine Frage oder Anliegen? Wir freuen uns von dir zu
                    lesen!
                  </p>
                  {!isLoading ? (
                    <button
                      className="btn btn-primary btn-rounded"
                      type="submit"
                      style={{ marginTop: "8px" }}
                    >
                      Senden
                    </button>
                  ) : (
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#F4442C"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactForm;
