import React from "react";
import Countdown from "../countdown";

function TopNavbar() {
  return (
    <div className="header-top my-header-top">
      <div className="container">
        <div className="header-left">
          <Countdown />
        </div>
      </div>
    </div>
  );
}
export default TopNavbar;
