import React, { useState } from "react";
import { HiOutlineDuplicate } from "react-icons/hi";
import { IoMdCloseCircleOutline } from "react-icons/io";


const VCartSingleProduct = ({
  product,
  duplicateDbIds,
  capitalizeFirstLetter,
  handleRemoveProduct,
  similarReference,
  referencePrice,
}) => {
  return (
    <div
      style={{
        marginBottom: "10px",
        padding: "10px",
        borderBottom: "1px solid #eee",
        backgroundColor: similarReference ? "#F5F5F5" : "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <span style={{ flex: "2", display: "flex", alignItems: "center" }}>
          {product.product_name}{" "}
          {!product.product_name
            .toLowerCase()
            .includes(product.product_brand) &&
            `von ${capitalizeFirstLetter(product.product_brand)}`}
          {duplicateDbIds.includes(String(product.db_id)) && (
            <HiOutlineDuplicate
              size={16}
              color="#F4442C"
              style={{ marginLeft: "5px", cursor: "pointer" }}
              title="Dieses Produkt liegt in verschiedenen Varianten beim Händler vor"
            />
          )}
        </span>
        <span style={{ flex: "1", textAlign: "right" }}>
          {String(product.product_price.toFixed(2)).replace(".", ",")} €
        </span>
        <span
          title={product.product_availability || "Keine Angabe"}
          style={{
            flex: "1",
            textAlign: "right",
            display: "block",
            cursor: "pointer",
          }}
          className="hidden sm:block break-words"
        >
          {(product.product_availability?.length > 25
            ? product.product_availability.substring(0, 25) + "..."
            : product.product_availability) || "Keine Angabe"}
        </span>

        <span
          style={{
            flex: "1",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <a
            href={product.product_link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
            className="mr-2 text-[#F4442C] hidden sm:block"
          >
            Zum Shop
          </a>
          <IoMdCloseCircleOutline
            size={18}
            style={{ cursor: "pointer" }}
            title="Dieses Produkt vorübergehend aus der Ansicht entfernen..."
            onClick={(e) => {
              e.stopPropagation(); // Prevent toggleExpand when clicking the bin icon
              handleRemoveProduct(product.product_id);
            }}
          />
        </span>
      </div>
      <a
        href={product.product_link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
        className="block sm:hidden mr-2 text-[#F4442C] mt-2"
      >
        Zum Shop
      </a>
      {/* Show similarReference if the product is expanded */}
    </div>
  );
};

export default VCartSingleProduct;
