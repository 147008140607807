import React from "react";
import TopNavbar from "../ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "../ui/headers/middleHeader";
import Searchbar from "../includes/Searchbar";
import Footer from "../ui/footer/footer";
import { Link } from "react-router-dom";
import MainHeader from "../ui/headers/header-assets/main-header.png";
import Searchfield from "../ui/searchfield/Searchfield";

function Article1() {
  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield/>
      </header>

      <main className="main">
        <div className="page-header">
          <div className="container">
            <h1
               className="page-title mb-0 text-5xl font-bold"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              Die Geschichte, Mission und Personen hinter Best Preis Pyro{" "}
            </h1>
          </div>
        </div>

        <div className="page-content mb-12" style={{ marginTop: "2rem" }}>
          <div className="container pb-10 mb-2">
            <div className="main-content post-single-content">
              <div className="post post-grid post-single">
                <figure className="post-media br-sm">
                  <img src={MainHeader} alt="Blog" width="930" height="500" />
                </figure>
                <div className="post-details">
                  <div className="post-meta">
                    von <a className="post-author">Stefan Schiller</a>-{" "}
                    <a className="post-date">18.10.2023</a>
                  </div>

                  <div>
                    <p>
                      Mein Name ist Stefan Schiller, vielen auch bekannt unter
                      dem Pseudonym PyroExtrem. Seit 2011 auf{" "}
                      <a
                        href="https://www.youtube.com/user/pyroextrem"
                        target="_blank"
                      >
                        Youtube
                      </a>{" "}
                      und seit geraumer Zeit auf weiteren Social Media
                      Plattformen vertreten wie beispielsweise{" "}
                      <a
                        href="https://www.instagram.com/pyroextremyt/?hl=de"
                        target="_blank"
                      >
                        Instagram
                      </a>
                      ,{" "}
                      <a
                        href="https://www.tiktok.com/@pyroextremyt"
                        target="_blank"
                      >
                        TikTok
                      </a>{" "}
                      oder{" "}
                      <a
                        href="https://www.facebook.com/pyroextrem.de/"
                        target="_blank"
                      >
                        Facebook
                      </a>
                      . Feuerwerk ist und bleibt meine große Leidenschaft seit
                      Kindheitstagen. Die Faszination bunter Sterne, eines
                      druckvollen Knalls oder die Gruppendynamik von Feuerwerk
                      Großveranstaltungen weltweit lassen den Funken im Blut
                      immer wieder aufflammen.{" "}
                    </p>
                    <p>
                      Über die Jahre durfte ich sehr viele interessante,
                      wertvolle Menschen kennenlernen, unter anderem viele
                      Betreiber von Feuerwerk Onlineshops bzw. lokalen
                      Verkaufsflächen. Mein Ziel war es niemals, selbst mit
                      Feuerwerk zu handeln, deshalb konnte ich diesem Prinzip
                      bis heute treu bleiben. Warum? Ganz klar, ich wollte, dass
                      das Thema nicht zu meinem “täglichen Brot” wird, sondern
                      dass ich mir die Leidenschaft behalten kann.
                    </p>
                  </div>
                </div>
              </div>
              <h4 className="title title-md font-weight-bold">
                Wie aus einer ersten Vision, die Mission wurde!
              </h4>

              <p>
                Daher ist es nicht verwunderlich, dass mir die Idee zu Best
                Preis Pyro mitten im Sommer kam. Eine Schnapsidee, die ich in
                einem sehr kleinen ausgewählten Kreis guter Freunde
                vertrauensvoll besprechen konnte. Mit einem dieser Freunde wurde
                die erste Vision, dann zu einer Mission: Die Plattform MUSS noch
                zur Saison 2023 fertig werden! Sie soll möglichst alle
                Onlineshops und Händler berücksichtigen, die mit Feuerwerk
                handeln.
              </p>
              <h4 className="title title-md font-weight-bold">
                Mammutaufgabe: Programmierung der Plattform
              </h4>

              <p>
                Zwar betreibe ich mit meiner besseren Hälfte Sarah Onlineshop
                Projekte, die wir in Eigenregie von Null aufgebaut haben und bis
                heute betreuen, so kamen wir dennoch sehr schnell an
                programmiertechnische Probleme bei der angedachten Pyro
                Preisvergleich Plattform. Durch einen glücklichen Zufall durfte
                ich 2023 auf einer Marketing Messe in Düsseldorf einen
                wertvollen Menschen kennenlernen, der über genau die Strukturen
                verfügte, die uns fehlten: Programmierskills und ein dynamisches
                Team an Leuten, die sich dem Projekt annehmen können.
              </p>
              <p>
                In den darauffolgenden Wochen ging es dann Schlag auf Schlag.
                Ideen wurden gesammelt, es waren so viele, dass wir schnell an
                Budgetgrenzen stießen - kleiner Teaser, es folgen also noch
                Features in den nächsten Jahren, wenn das Projekt genug
                Unterstützer findet. Wie du das Projekt unterstützen kannst,
                erfährst du hier: BPP Support // Zurück zum Thema… Stundenlange
                Brainstorming Sessions festigten langsam den Rahmen des
                Projekts. Es konnte losgehen!
              </p>
              <p>
                Ich war selbst erstaunt, dass es in Deutschland über 100
                Feuerwerkshändler gibt. Wer kann sich noch an die Insta Story
                aus dem Sommer erinnern, als ich nach euren Lieblings Shops und
                Händlern fragte? Alleine die Auswertung dieser Umfrage dauerte
                STUNDEN! Dieser Zeitaufwand war aber nichts gegen die
                unendlichen Stunden, die Daten dieser Händler und deren Produkte
                auf die Plattform zu bekommen! Das war eine wahrliche
                Mammutaufgabe, die durch das Entwicklerteam aber sehr gut
                gestemmt werden konnte. Bis wir dann die
              </p>

              <p className="mb-2">
                Plattform in der veröffentlichten Form präsentieren konnten,
                vergingen Wochen an intensiven Tests, um sämtliche Bugs zu
                identifizieren. Solltest du trotzdem noch welche finden, melde
                dich gerne über unser <Link to="/contact">Kontaktformular</Link>
                .
              </p>

              <h4 className="title title-md font-weight-bold">
                Best Preis Pyro existiert FÜR DICH, kann aber gleichzeitig nicht
                OHNE DICH funktionieren!
              </h4>
              <p>
                Wir sind unfassbar stolz, nach diesem turbulenten Sommer und
                Herbst, die Best Preis Pyro Plattform so präsentieren zu können,
                wie sie nun besteht. Ein Projekt, von Pyros für Pyros, das klar
                in starkem Vertrauen an die Feuerwerk Fans und die Szene
                komplett in privater Vorleistung finanziert wurde. Die
                Entwicklungskosten selbst liegen weit im fünfstelligen Bereich,
                die laufenden Kosten der Plattform im vierstelligen Bereich -
                monatlich! Damit das Projekt erfolgreich und langfristig
                betrieben werden kann, muss es von der Pyro Community
                unterstützt werden.
              </p>
              <p>
                Preisvergleich Seiten existieren eigentlich nur aus einer
                Motivation heraus: Viel Geld verdienen! Wie machen das
                klassische Preisvergleich Seiten?
              </p>
              <ul className="list-style-none list-type-check">
                <li>
                  Über Provisionen bei Weiterleitung auf Händlerseiten und
                  erfolgreichen Bestellabschluss
                </li>
                <li>
                  Durch penetrante Werbung in Form von Popups oder Bannern{" "}
                </li>
              </ul>
              <p>
                Dadurch, dass kaum ein Händler der Feuerwerksbranche über ein
                eigenes Partnernetzwerk verfügt, bleibt uns diese Möglichkeit
                mit Ausnahme der "Big 3" der Branche verwehrt. Auch entschieden
                wir uns gegen nervige Bannerwerbung oder die Bevorzugung
                einzelner Partner, die bereit sind, für die Platzierung auf
                unserer Seite Geld zu bezahlen.
              </p>
              <p>
                Doch es soll ein komplett unverfälschter Pyro Preisvergleich im
                Fokus stehen, der jedem Anwender Mehrwert und Spaß bringt. Du
                feierst das Projekt und willst, dass es zu 100% transparent und
                werbefrei bleibt? Dann würden wir uns sehr über deine
                Unterstützung über Patreon freuen! Und selbst für deine
                Unterstützung bekommst du etwas zurück - mehr dazu, auf
                <a
                  href="https://www.patreon.com/user?u=100031016"
                  target="_blank"
                >
                  {" "}
                  Patreon
                </a>{" "}
                selbst oder in einem der nächsten Blogbeiträge.
              </p>
              <p>
                Jetzt erstmal ganz viel Spaß beim Preisvergleich auf Best Preis
                Pyro und auf eine maximal geile heiße Pyro Phase 2023 - euer,
                Stefan
              </p>
              <Link
                     to="/support"
                    >
                      <button
                        className="btn btn-primary btn-rounded"
                        style={{ marginTop: "2rem" }}
                      >
                        Wie du uns unterstützen kannst
                      </button>
                    </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Article1;
