import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context";
import { toast } from "react-toastify";


function MobileSidebar(props) {
  const [state, setState] = useContext(UserContext);

  const handleRedirect = () => {
    if (!state?.token) {
      toast.info("Bitte registriere Dich oder melde Dich an, um den virtuellen Warenkorb nutzen zu können!");
    }
  };
  return (
    <div className="mmenu-wrapper" style={{ width: "100vw" }}>
      <div className="mmenu-overlay" />
      <a
        className="test"
        onClick={() => {
          props.setShowMobileSidebar(false);
        }}
      >
        <i className="close-icon" />
      </a>
      <div
        className="mmenu-container scrollable"
        style={{
          maxWidth: "296px",
          padding: "2rem 1.5rem",
          overflowY: "auto",
          background: "#222",
          boxShadow: "1px 0 5px rgba(0,0,0,0.5)",
          transform: `${
            props.setShowMobileSidebar ? "translateX(0)" : "translateX(-296px)"
          }`,
          transition: "transform 0.4s",
        }}
      >
        <div className="tab">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active">Menü</a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div
            className="tab-pane active"
            id="main-menu"
            style={{ background: "rgb(34, 34, 34)" }}
          >
            <ul className="mmenu" style={{ listStyle: "none", color: "#fff" }}>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <a href={"/shop"}>Preisvergleich</a>
              </li>

              <li>
                <Link to={"/contact"}>Kontakt</Link>
              </li>
              <li>
                <Link to={"/blog"}>Blog</Link>
              </li>
              <li>
                <Link to={"/account"}>Account</Link>
              </li>
              <li>
                <Link to={"/about"}>Über Uns</Link>
              </li>
              <li>
                <a
                  href="https://www.patreon.com/user?u=100031016"
                  target="_blank"
                >
                  Patreon
                </a>
              </li>
              <li>
                {state?.token != "" && state?.token ? (
                  <Link to={"/virtualcartlist"}>Virtueller Warenkorb</Link>
                ) : (
                  <Link to={"/login"} onClick={handleRedirect}>Virtueller Warenkorb</Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSidebar;
