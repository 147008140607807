import React, { useState, useEffect } from "react";
import HeroHeader from "./ui/headers/heroHeader";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Footer from "./ui/footer/footer";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import Searchfield from "./ui/searchfield/Searchfield";

function NewsletterPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await axios.post(`/subscribeNewsletter`, {
        email: email,
      });

      toast.success(
        "Vielen Dank für die Anmeldung! Du hast eine Email zur Bestätigung erhalten."
      );
    } catch (error) {
      toast.error("Bei der Anmeldung ist etwas schief gelaufen!");
    }
    setIsLoading(false);
    setEmail("");
  };
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  return (
    <main className="main">
      <header className="header">
        <TopNavbar />

        <MiddleHeader />

        <Searchbar />
        <Searchfield />
      </header>
      <div className="container mb-2">
        <HeroHeader
          ctaText={"Zum Vergleich"}
          ctaLink={"/shop"}
          customHeadline="BPP-Newsletter"
          customSubline=" Du willst als aller erstes Zugriff bekommen zu neuen Features? Du willst von Rabattaktionen der gelisteten 
          Händler & Shop profitieren? Du bist generell interessiert an News zum Thema Feuerwerk & PYrotechnik? Dann trag dich jetzt zum BPP-Newsletter ein!"
        />
      </div>

      <div className="container" style={{ marginBottom: "5rem" }}>
        <div
          className="formContainer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              display: true ? "block" : "none",
            }}
          >
            <div
              className="form-group"
              style={{
                width: "30vw",
                minWidth: "300px",
                marginBottom: "2rem",
                fontFamily: "Poppins",
              }}
            >
              <label>Email Adresse</label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              className="btn btn-primary"
              style={{
                width: `100%`,
                backgroundColor: false && "grey",
                cursor: false && "not-allowed",
              }}
              disabled={isLoading}
            >
              {isLoading ? "Senden..." : "Senden"}
            </button>
          </form>
        </div>
      </div>
      <div className="container">
        <Footer />
      </div>
    </main>
  );
}

export default NewsletterPage;
