import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Space } from "antd";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import { isNotUndefined } from "../../../../utils/data";
const { Option } = Select;


const ShopFilter = (props) => {
  const { selectedShops, setSelectedShops } = props;

  const [allShops, setAllShops] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const fetchShopNames = async () => {
    try {
      const data = await axios.get("/shopnames");
      const tmp = data.data.data.sort();
      setAllShops([...tmp]);
      setisLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChange = (value) => {
    setSelectedShops(String(value).toLowerCase());
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    fetchShopNames();
    console.log(selectedShops);
  }, []);

  return (
    <Select
      mode="multiple"
      style={{
        width: "100%",
        color: "#999",
        fontFamily: "Poppins",
        zIndex: "1000",
      }}
      placeholder="Wähle deine Shops"
      defaultValue={isNotUndefined(selectedShops)?String(selectedShops)?.toLowerCase()?.split(","):[]}
      onChange={handleChange}
      optionLabelProp="label"
    >
      {!isLoading &&
        allShops.map((shopname, index) => {
          return (
            <Option value={shopname} label={capitalizeFirstLetter(shopname)} key={index}>
              <Space>{capitalizeFirstLetter(shopname)}</Space>
            </Option>
          );
        })}
    </Select>
  );
};

export default ShopFilter;
