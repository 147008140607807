import React, { useContext } from "react";
import { Container, Row, Col } from "react-grid-system";
import { removeCommaFromEnd } from "../../../utils";
import { Link, useNavigate } from "react-router-dom";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import imgh from "../../../images/placeholder.png";
import { UserContext } from "../../../context";
import { FaInfoCircle } from "react-icons/fa";
import { useVirtualCartList } from "../../../hooks/useVirtualCartList";
import { MdOutlineAddShoppingCart } from "react-icons/md"; // Importing cart icon

function ListView(props) {
  const { products } = props;
  const [state] = useContext(UserContext);
  const navigate = useNavigate();

  // Using the useVirtualCartList hook
  const { addItemToCart, removeItemFromCart, isProductInCart } =
    useVirtualCartList();

  const truncateString = (str) => {
    if (str?.length > 30) {
      return str?.slice(0, 30) + "...";
    } else {
      return str;
    }
  };

  // Functions to handle add and remove from cart
  const handleAddToCart = (productId) => {
    if (state && state.token) {
      addItemToCart(productId);
    } else {
      navigate("/login");
    }
  };

  const handleRemoveFromCart = (productId) => {
    if (state && state.token) {
      removeItemFromCart(productId);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {products && products.length > 0 && (
        <Container fluid>
          <Row style={{ borderBottom: "1px solid #EEEEEE" }}>
            <Col></Col>
            <Col md={2}>
              <h3 className="product-name">Shop</h3>
            </Col>
            <Col md={3}>
              <h3 className="product-name">Produkt Name</h3>
            </Col>
            <Col md={3}>
              <h3 className="product-name">Preis</h3>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      )}

      <Container>
        {products &&
          products.length > 0 &&
          products.map((product) => {
            if (
              (product.product_price > 0 || product.product_old_price > 0) &&
              product.product_category !== "PyroMerch" &&
              product?.product_shop_name !== "feuerwerk"
            ) {
              return (
                <div key={product.id}>
                  <Row>
                    <Col style={{ border: "1px solid #EEEEEE" }}>
                      <figure className="product-media">
                        <Link to={`/product?productId=${product.id}`}>
                          <img
                            src={
                              removeCommaFromEnd(
                                product.product_thumbnail,
                                product.product_image
                              ) === "invalid"
                                ? imgh
                                : removeCommaFromEnd(
                                    product.product_thumbnail,
                                    product.product_image
                                  )
                            }
                            alt={`${product.product_name} kaufen`}
                            width={150}
                            height={150}
                            style={{
                              objectFit: "contain",
                              height: "64px",
                            }}
                          />
                        </Link>
                      </figure>
                    </Col>

                    <Col md={2} style={{ border: "1px solid #EEEEEE" }}>
                      <a
                        href={product.shop_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="flex align-top">
                          <h3 className="product-name">
                            {capitalizeFirstLetter(product.product_shop_name)}
                          </h3>
                          {product?.shop?.deliver_info ? (
                            <span
                            title={`Versandinfo: ${product?.shop?.deliver_info}`}
                            // Ensure icon and text are aligned
                          >
                            <FaInfoCircle size={16} className="mx-2 cursor-pointer" />
                           
                          </span>
                          ) : (
                            <></>
                          )}
                        </span>
                      </a>
                    </Col>

                    <Col md={3} style={{ border: "1px solid #EEEEEE" }}>
                      <h3 className="product-name">
                        <Link to={`/product?productId=${product.id}`}>
                          {product.product_name}
                        </Link>
                      </h3>
                      <div style={{ color: "#999" }}>
                        {product?.product_availability
                          ? truncateString(product?.product_availability)
                          : truncateString(product?.product_delivery_time)}
                      </div>
                    </Col>

                    <Col md={2} style={{ border: "1px solid #EEEEEE" }}>
                      <Link to={`/product?productId=${product.id}`}>
                        <div className="product-pa-wrapper">
                          <div className="product-price">
                            {product.product_price <= 0 &&
                            product.product_old_price > 0 ? (
                              <ins className="new-price">
                                {product.product_old_price.toLocaleString(
                                  "de-AT",
                                  {
                                    style: "currency",
                                    currency: "EUR",
                                  }
                                )}
                              </ins>
                            ) : (
                              <>
                                <ins className="new-price">
                                  {product.product_price.toLocaleString(
                                    "de-AT",
                                    {
                                      style: "currency",
                                      currency: "EUR",
                                    }
                                  )}
                                </ins>
                                {product.product_old_price > 0 &&
                                  product.product_old_price !==
                                    product.product_price && (
                                    <del className="old-price">
                                      {product.product_old_price.toLocaleString(
                                        "de-AT",
                                        {
                                          style: "currency",
                                          currency: "EUR",
                                        }
                                      )}
                                    </del>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </Link>
                    </Col>

                    {/* Dummy Column to maintain alignment */}
                    <Col
                      md={3}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center", // Ensure vertical alignment
                      }}
                    >
                      {/* If the product has a db_id, show the cart icon, otherwise show an empty span */}

                      {/* Details Button */}
                      {product.product_link && (
                        <Link to={`/product?productId=${product.id}`}>
                          <button
                            className="btn btn-primary"
                            style={{
                              borderRadius: "4px",
                              backgroundColor:
                                product.db_id !== null ? "#F4442C" : "#183544",
                              borderColor:
                                product.db_id !== null ? "#F4442C" : "#183544",
                            }}
                          >
                            <span>Details</span>
                          </button>
                        </Link>
                      )}
                      {product.db_id ? (
                        <MdOutlineAddShoppingCart
                          size={28}
                          color={
                            isProductInCart(product.db_id)
                              ? "#F4442C"
                              : "#999999"
                          }
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={
                            isProductInCart(product.db_id)
                              ? () => handleRemoveFromCart(product.id)
                              : () => handleAddToCart(product.id)
                          }
                        />
                      ) : (
                        <span style={{ width: "28px", marginLeft: "10px" }} /> // Empty space for alignment
                      )}
                    </Col>
                  </Row>
                </div>
              );
            }
          })}
      </Container>
    </>
  );
}

export default ListView;
