import React from "react";
import { removeCommaFromEnd } from "../../../utils";
import { Link } from "react-router-dom";
import imgh from "../../../images/mateusz-roman-4wM9P7c8its-unsplash.jpg";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { MdOutlineAddShoppingCart } from "react-icons/md"; // Import cart icon
import { useVirtualCartList } from "../../../hooks/useVirtualCartList"; // Import your hook

function GridView(props) {
  const { products } = props;

  // Using the useVirtualCartList hook
  const { addItemToCart, removeItemFromCart, isProductInCart } = useVirtualCartList();

  const truncateString = (str) => {
    if (str?.length > 30) {
      return str?.slice(0, 30) + "...";
    } else {
      return str;
    }
  };

  const handleAddToCart = (productId) => {
    addItemToCart(productId);
  };

  const handleRemoveFromCart = (productId) => {
    removeItemFromCart(productId);
  };

  return (
    <>
      <div className="product-wrapper row cols-md-3 cols-sm-2 cols-2">
        {products &&
          products.length > 0 &&
          products.map(
            (product) =>
              (product.product_price > 0 || product.product_old_price > 0) && (
                <div key={product.id} className="product-wrap">
                  <div className="product text-center">
                    <figure className="product-media">
                      <Link to={`/product?productId=${product.id}`}>
                        <img
                          alt={`${product.product_name} kaufen`}
                          src={
                            removeCommaFromEnd(
                              product.product_thumbnail,
                              product.product_image
                            ) === "invalid"
                              ? imgh
                              : removeCommaFromEnd(
                                  product.product_thumbnail,
                                  product.product_image
                                )
                          }
                          width={300}
                          height={338}
                          style={{
                            objectFit: "contain",
                            height: "338px",
                          }}
                        />
                      </Link>
                    </figure>

                    <div className="product-details">
                      <h3 className="product-name">
                        <Link to={`/product?productId=${product.id}`}>
                          {product.product_name}
                        </Link>
                      </h3>
                      <div className="product-cat">
                        <Link to={`/product?productId=${product.id}`}>
                          {capitalizeFirstLetter(product.product_shop_name)}
                        </Link>
                      </div>

                      <div className="product-pa-wrapper">
                        <div className="product-price">
                          {product.product_price <= 0 &&
                          product.product_old_price > 0 ? (
                            <ins className="new-price">
                              {product.product_old_price.toLocaleString(
                                "de-AT",
                                {
                                  style: "currency",
                                  currency: "EUR",
                                }
                              )}
                            </ins>
                          ) : (
                            <>
                              <ins className="new-price">
                                {product.product_price.toLocaleString(
                                  "de-AT",
                                  {
                                    style: "currency",
                                    currency: "EUR",
                                  }
                                )}
                              </ins>
                              {product.product_old_price > 0 && (
                                <del className="old-price">
                                  {product.product_old_price.toLocaleString(
                                    "de-AT",
                                    {
                                      style: "currency",
                                      currency: "EUR",
                                    }
                                  )}
                                </del>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <div className="product-cat">
                        <Link to={`/product?productId=${product.id}`}>
                          {product?.product_availability
                            ? truncateString(product?.product_availability)
                            : truncateString(product?.product_delivery_time)}
                        </Link>
                      </div>

                      {/* Add to Cart Button */}
                      {product.db_id !== null && (<div className="add-to-cart-btn">
                        {isProductInCart(product.db_id) ? (
                          <button
                            className="btn btn-primary text-sm rounded-md"
                            onClick={() => handleRemoveFromCart(product.id)}
                          >
                            Entferne v. Warenkorb
                          </button>
                        ) : (
                          <button
                            className="btn btn-secondary text-sm rounded-md"
                            onClick={() => handleAddToCart(product.id)}
                          >
                            Hinzufügen v. Warenkorb
                          </button>
                        )}
                      </div>)}
                    </div>
                  </div>
                </div>
              )
          )}
      </div>
    </>
  );
}

export default GridView;
