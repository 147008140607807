import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import VCartSingleProduct from "./vCartSingleProduct";

const VCartMatchedProductList = ({
  shop,
  filteredProducts,
  expandedShops,
  toggleExpand,
  duplicateDbIds,
  handleRemoveProduct,
  capitalizeFirstLetter,
  getNumberUniqueProducts,
  getTotalShopPrice,
  similarProducts,
  showSimilarProducts,
}) => {
  // Filter out products with duplicate product names
  const uniqueFilteredProducts = filteredProducts.reduce((acc, product) => {
    console.log(duplicateDbIds);
    const isDuplicate = acc.some(
      (item) => item.product_name.toLowerCase() === product.product_name.toLowerCase()
    );
    if (!isDuplicate) {
      acc.push(product);
    }
    return acc;
  }, []);

  // Group similar products by product_reference
  const groupedSimilarProducts = similarProducts.reduce((groups, product) => {
    const reference = product?.product_reference;
    if (reference) {
      if (!groups[reference]) {
        groups[reference] = [];
      }
      groups[reference].push(product);
    } else {
      groups["no_reference"] = groups["no_reference"] || [];
      groups["no_reference"].push(product);
    }
    return groups;
  }, {});

  // Check if there are more than one group with valid references
  const validReferenceGroups = Object.keys(groupedSimilarProducts).filter(
    (reference) =>
      reference !== "no_reference" &&
      groupedSimilarProducts[reference].length > 1
  );

  const shouldShowRedBorder = validReferenceGroups.length > 1;

  return (
    <div
      style={{
        marginBottom: "20px",
        display: uniqueFilteredProducts.length > 0 ? "block" : "none",
      }}
    >
      <div
  style={{
    backgroundColor: "#183544",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  }}
  onClick={() => toggleExpand(shop.shopName)}
>
  <strong>{capitalizeFirstLetter(shop.shopName)}</strong> - Einzigartige Produkte: {getNumberUniqueProducts(uniqueFilteredProducts)}
  {showSimilarProducts && similarProducts.length > 0 && " - Ähnliche Produkte: " + similarProducts.length}
  {" - Gesamt: ~ "}
  <strong>
    {(
      getTotalShopPrice(uniqueFilteredProducts) +
      (showSimilarProducts && similarProducts.length > 0 && getTotalShopPrice(similarProducts))
    )
      .toFixed(2)
      .toString()
      .replace(".", ",")} €
  </strong>
  
  {shop.shopDeliveryInfo ? ` - Versandinfo: ${shop.shopDeliveryInfo}` : ""}
</div>

      {expandedShops.includes(shop.shopName) && (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          {/* Information Row */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#183544",
            }}
          >
            {shop.shopDeliveryInforUrl && (
              <span style={{ flex: "2", cursor: "pointer" }}>
              <a
                href={shop.shopDeliveryInforUrl}
                target="_blank"
                rel="noopener noreferrer"
                title="Klicke hier, um die Lieferinformationen zu sehen"
                style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}  // Ensure icon and text are aligned
              >
                <FaInfoCircle size={16} style={{ marginRight: "5px" }} />
                <span> Lieferinformationen</span>
              </a>
            </span>
            
            )}
            <span style={{ flex: "3", textAlign: "right" }}></span>{" "}
            {/* Empty space to align correctly */}
          </div>

          {/* Column Names Row */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            <span style={{ flex: "2" }}className="hidden sm:block">Produkt</span>
            <span style={{ flex: "1", textAlign: "right" }} className="hidden sm:block">Preis</span>
            <span style={{ flex: "1", textAlign: "right" }} className="hidden sm:block">
              Verfügbarkeit
            </span>
            <span style={{ flex: "1", textAlign: "right" }}className="hidden sm:block">Aktionen</span>
          </div>

          {uniqueFilteredProducts.map((product) => (
            <VCartSingleProduct
              key={product.product_id}
              product={product}
              duplicateDbIds={duplicateDbIds}
              capitalizeFirstLetter={capitalizeFirstLetter}
              handleRemoveProduct={handleRemoveProduct}
            />
          ))}

          {/* Similar Products Block */}
          {showSimilarProducts && similarProducts.length > 0 && (
            <div>
              <label
                style={{
                  fontWeight: "bold",
                  marginBottom: "4px",
                  display: "inline-block",
                }}
              >
                Ähnliche Produkte:
              </label>
              {Object.entries(groupedSimilarProducts).map(
                ([reference, products]) => (
                  <div
                    key={reference}
                    style={{
                      border:
                        shouldShowRedBorder &&
                        reference !== "no_reference" &&
                        products.length > 1
                          ? "2px solid #F4442C"
                          : "none",
                      padding: "10px",
                      marginBottom: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{ marginBottom: "10px", cursor: "pointer" }}
                      title="Medianpreis dieses Produkts in der BPP Datenbank"
                    >
                      Produkt aus virtuellem Warenkorb:{" "}
                      <span className="font-bold">{reference}</span> Preis:{" ⌀ "}
                      <span className="font-bold">
                        {products[0].product_data.product_reference_price
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")}
                         {" €"}
                      </span>
                    </div>
                    {products.map((product) => (
                      <VCartSingleProduct
                        key={product.product_data.product_id}
                        product={product.product_data}
                        duplicateDbIds={duplicateDbIds}
                        capitalizeFirstLetter={capitalizeFirstLetter}
                        handleRemoveProduct={handleRemoveProduct}
                        similarReference={product?.product_reference}
                        referencePrice={
                          product.product_data.product_reference_price
                        }
                      />
                    ))}
                  </div>
                )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VCartMatchedProductList;
