import React, { useContext } from "react";

import Searchbar from "../../includes/Searchbar";

import Sidebar from "../../ui/account/Sidebar";
import UserRoute from "../../routes/UserRoute";
import { UserContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import MiddleHeader from "../headers/middleHeader";
import TopNavbar from "../countdown/topNavbar/topNavbar";
import Footer from "../footer/footer";

function AccountLayout(props) {
  const { showSidebar, heading } = props;
  const [state, setState] = useContext(UserContext);
  const navigate = useNavigate();
  const logout = () => {
    setState({
      user: {},
      token: "",
    });

    // save in local storage
    window.localStorage.removeItem("auth");

    navigate("/");
  };
  return (
    <UserRoute>
      <div>
        <div className="page-wrapper">
          <header className="header">
            <TopNavbar />

            <MiddleHeader />

            <Searchbar />
          </header>
          <main className="main">
            {/* Start of Page Header */}
            <div className="page-header">
              <div className="container mt-4">
                <h1 className="page-title mb-0 text-5xl font-bold">{heading? heading: "Account"}</h1>
              </div>
            </div>
            <div className="page-content pt-2">
              <div className="container">
                {showSidebar ? (
                  <div className="tab tab-vertical row gutter-lg">
                    {<Sidebar logout={logout} />}
                    <div
                      className="tab-content mb-6"
                      style={{ borderTop: "none" }}
                    >
                      <>{props.children}</>
                    </div>
                  </div>
                ) : (
                  <>{props.children}</>
                )}
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </UserRoute>
  );
}

export default AccountLayout;
