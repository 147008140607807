import React, { useState, useEffect } from "react";
import { Select, Space } from "antd";
import { allFireworksCategories } from "../../../../utils/data";
import { isNotUndefined } from "../../../../utils/data";
const { Option } = Select;


const FireworksCategoryFilter = (props) => {
  const { selectedFireworksCategories,
    setSelectedFireworksCategories,} = props;



  let fireworksCategories = allFireworksCategories;

  const handleChange = (value) => {
    setSelectedFireworksCategories(String(value));
    console.log(`selected ${String(value)}`);
  };

 

  return (
    <Select
      mode="multiple"
      style={{
        width: "100%",
        color: "#999",
        fontFamily: "Poppins",
        zIndex: "1000",
      }}
      placeholder="Wähle deine Feuerwerkskategorien"
      defaultValue={isNotUndefined(selectedFireworksCategories)?String(selectedFireworksCategories)?.split(","):[]}
      onChange={handleChange}
      optionLabelProp="label"
    >
      {
        fireworksCategories.map((category, index) => {
          return (
            <Option value={category.name} label={category.name} key={index}>
              <Space>{category.name}</Space>
            </Option>
          );
        })}
    </Select>
  );
};

export default FireworksCategoryFilter;
