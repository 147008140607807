import React, { useContext } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Link, useNavigate } from "react-router-dom";
import { useVirtualCartList } from "../../../hooks/useVirtualCartList";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io"; // Import the new icon
import { UserContext } from "../../../context";

function ProductView(props) {
  const { products } = props;
  const [state, setState] = useContext(UserContext);
  const navigate = useNavigate();
  const { isProductInCart, addItemToCart, removeItemFromCart } =
    useVirtualCartList();

  const handleAddToCart = (productId) => {
    if (state && state.token) {
      addItemToCart(productId);
    } else {
      navigate("/login");
    }
  };

  const handleRemoveFromCart = (productId) => {
    if (state && state.token) {
      removeItemFromCart(productId);
    } else {
      navigate("/login");
    }
  };

  return (
    <Container fluid style={{ padding: "20px", fontFamily: "Poppins" }}>
      {products.length > 0 ? (
        <Row
          style={{
            borderBottom: "2px solid #ccc",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          <Col sm={2}></Col>
          <Col sm={6} className="hidden sm:block">
            <strong>Produkt Name</strong>
          </Col>
          <Col sm={2} className="hidden sm:block">
            <strong>Preis</strong>
          </Col>
          <Col sm={2} className="hidden sm:block">
            <strong>Aktion</strong>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <h2 className="text-3xl">Keine Produkte im virtuellen Warenkorb!</h2>
            <Link
              className=""
              to="/shop?selectedbrands=undefined&selectedcategories=undefined&mincaliber=undefined&maxcaliber=undefined&minprice=undefined&maxprice=undefined&pgnumber=1&pgsize=20&searchquery=undefined&selectedshops=undefined&sortingorder=asc&isdiscounted=false&isavailable=false&onlyallyear=false&selectedfireworkscategories=undefined&onlyvirtualcart=true"
            >
              <button className="btn btn-primary text-base rounded-md mt-4">
                Jetzt Produkte hinzufügen!
              </button>
            </Link>
          </Col>
        </Row>
      )}
      {products.map((item) => (
        <Row
          key={item.id}
          style={{
            borderBottom: "1px solid #eee",
            padding: "10px 0",
            alignItems: "center",
          }}
        >
          <Col sm={2}>
            <img
              src={item.product.product_image}
              alt={item.product.product_name}
              style={{
                width: "100%",
                maxWidth: "80px",
                height: "60px",
                borderRadius: "4px",
              }}
            />
          </Col>
          <Col sm={6}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                {item.product.product_name}
              </span>
            </div>
          </Col>
          <Col sm={1}>
            <span
              title="Medianpreis dieses Produkts in der BPP Datenbank"
              style={{
                fontSize: "1.1em",
                fontWeight: "bold",
                color: "#333",
                cursor: "pointer",
              }}
            >
              ⌀{" "}
              {String(item.product.product_price.toFixed(2)).replace(".", ",")}{" "}
              €
            </span>
          </Col>

          {/* Desktop/Tablet View: Show Cart Icon */}
          <Col sm={3}>
            <div
              className="hidden md:flex justify-center items-center" // Hide on mobile, show on tablet and larger
            >
              {item.product.db_id && (
                isProductInCart(item.product.db_id) ? (
                  <IoMdCloseCircleOutline // Use IoMdCloseCircleOutline when the item is in the cart
                    title="Produkt aus virtuellen Warenkorb entfernen, Seite neuladen um Matching zu aktualisieren"
                    size={28}
                    color="#F4442C"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveFromCart(item.product.id)}
                  />
                ) : (
                  <MdOutlineAddShoppingCart // Use MdOutlineAddShoppingCart when the item is not in the cart
                    title="Produkt virtuellem Warenkorb hinzufügen"
                    size={28}
                    color="#999999"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleAddToCart(item.product.id)}
                  />
                )
              )}
            </div>

            {/* Mobile View: Show Add to Cart Button */}
            <div className="flex md:hidden justify-start items-center mt-2">
              {item.product.db_id !== null && (
                <div className="add-to-cart-btn">
                  {isProductInCart(item.product.db_id) ? (
                    <button
                      className="btn btn-primary text-sm rounded-md"
                      onClick={() => handleRemoveFromCart(item.product.id)}
                    >
                      Entferne v. Warenkorb
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary text-sm rounded-md"
                      onClick={() => handleAddToCart(item.product.id)}
                    >
                      Hinzufügen v. Warenkorb
                    </button>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default ProductView;
