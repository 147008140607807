import React, { useEffect, useState, useCallback } from "react";

function PatreonPopup() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [counter, setCounter] = useState(0);

  const timeoutFunction = useCallback(() => {
    if (counter !== 2) {
      setCounter((prevCounter) => prevCounter + 1);
    } else if (counter === 2) {
      if (document.cookie.indexOf("patreonPopupShown=true") === -1) {
        setShowPopUp(true);
        document.cookie = "patreonPopupShown=true; max-age=86400"; // 86400: seconds in a day
      }
    }
  }, [counter]);

  useEffect(() => {
    const timeoutId = setTimeout(timeoutFunction, 9000);

    // Cleanup function to stop the timeout when the component is unmounted
    return () => clearTimeout(timeoutId);
  }, [timeoutFunction]);

  return (
    <>
      {showPopUp && (
        <div
          style={{
            background: "rgba(0,0,0,0.5)",
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: "100",
          }}
        >
          <div
            className="my-popup min-w-[100vw] md:min-w-[500px]"
            style={{
              backgroundColor: "#183544",
              position: "fixed",
              transform: "translate(-50%, -50%)",
              WebkitTransform: "translate(-50%, -50%)",
              zIndex: "999999999999999999",
              top: "50%",
              left: "50%",
              borderRadius: "8px",
            }}
          >
            <div
              className="newsletter-content py-16 pl-4"
              style={{
                minWidth: "355px",
                maxWidth: "500px",
                overflowWrap: "break-word", // Ensures long words are wrapped
                wordBreak: "break-word",
              }}
            >
              <div className="newsletter-heading-box">
                <h2
                  className="ls-25 text-primary"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                >
                  Wir brauchen deine Unterstützung!
                </h2>
              </div>
              <p
                className="newsletter-text"
                style={{
                  color: "#fff",
                  fontFamily: "Poppins",
                  letterSpacing: "-.01em",
                }}
              >
                Jetzt Bestpreis Pyro VIP werden
                für exklusive Vorteile! Sei
                immer up-to-date was Angebote, Neuheiten und News rund um
                Feuerwerk betrifft.
              </p>

              <span className="custom-checkbox"></span>
              <div className="newsletter-button-box">
                <div
                  onClick={() => setShowPopUp(false)}
                  style={{
                    backgroundColor: "#333333",
                    color: "#fff",
                    fontFamily: "Poppins",
                    borderRadius: "5px",
                    padding: "6px 10px 6px 10px",
                    boxShadow: "none",
                    display: "inline-block",
                    cursor: "pointer",
                    textAlign: "center",
                    boxShadow: "0 0 5px -1px rgba(0,0,0,0.2)",
                    marginTop: "2rem",
                  }}
                >
                  Schließen
                </div>
                <a
                  href="https://www.patreon.com/user?u=100031016"
                  target="_blank"
                >
                  <div
                    onClick={() => setShowPopUp(false)}
                    style={{
                      backgroundColor: "#F4442C",
                      marginLeft: "2rem",
                      color: "#fff",
                      fontFamily: "Poppins",
                      borderRadius: "5px",
                      padding: "6px 10px 6px 10px",
                      boxShadow: "none",
                      display: "inline-block",
                      cursor: "pointer",
                      textAlign: "center",
                      boxShadow: "0 0 5px -1px rgba(0,0,0,0.2)",
                      marginTop: "2rem",
                    }}
                  >
                    Zu Patreon
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PatreonPopup;
