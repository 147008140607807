function capitalizeFirstLetter(string) {
  if(string in shopTranslator){
    return  shopTranslator[string];
  }
  else return string?.charAt(0).toUpperCase() + string?.slice(1);

  
}
export default capitalizeFirstLetter;


const shopTranslator = {
  "feuerwerk": "Beisel",
  "pyroweb": "Pyroweb GmbH",
  "roeder": "Röder Feuerwerk",
  "xpyro": "xPyro",
  "colibri": "Colibri Feuerwerk",
  "pulver": "Pulver Pyrotechnik",
  "pyroteam": "Pyroteam Store",
  "welovepyo": "We Love Pyro",
  "weco": "WECO",
  "firestar": "Firestar Pyrotechnik",
  "kago": "KagoFeuerwerk",
  "nico": "NICO",
  "profipyro": "Profi Pyro",
  "raketenmodellbau": "Raketenmodellbau Klima",
  "pyroingermany": "Pyro In Germany",
  "raketenzauber": "Feuerbändiger",
  "pyrospecky": "Pyro Specky",
  "pyroshop24": "Pyro Shop 24",
  "krausesohn": "Krause & Sohn",
  "feuerwerkspreewald": "Feuerwerk Spreewald",
  "koller": "Koller Feuerwerk",
  "feuerwerkmitsinn": "Feuerwerk mit Sinn",
  "lagerfeuerwerksverkauf": "Lager Feuerwerks Verkauf",
  "dreamfireworks": "Dreamfireworks",
  "rakentendealer": "Raketendealer",
  "savpyrotechnik": "SAV Pyrotechnik",
  "feuerwerkpyrotechnik": "JK Pyrotechnik",
  "lichtenrader": "Lichtenrader Feuerwerkverkauf",
  "nordic": "Nordic Fireworks",
  "nordfeuerwerk": "Feuerwerk Depot Nord",
  "goetterfunke": "Götterfunken Feuerwerke",
  "ruhrfeuerwerk": "Ruhr Feuerwerk",
  "knallkoeppe": "Knallköppe",
  "ahlers": "Ahlers Pyrotechnik",
  "pyrostore24": "Pyrotec Cologne",
  "neumann": "Neumann Feuerwerk",
  "mfg": "Mfg Feuerwerk",
  "pyrowuerfel": "Pyrowürfel",
  "feuerwerkdiscounter": "Hoops Pyrotechnik",
  "roehr": "Röhr Feuerwerk",
  "darkstar": "Darkstar Fireworks",
  "zuendidee": "Zündidee",
  "auerbach": "Feuerwerke Scharff",
  "kuerbs": "Kürbs Feuerwerke",
  "pyrostube": "Pyrostube Goslar",
  "loewenschweif": "Löwenschweif Pyrotechnik",
  "firemaster": "Firemaster Feuerwerk",
"dragonfireworks": "Dragon Fireworks",
"moeller": "Pyrotechnik Mölller",
"lgfireworks": "LG Fireworks",
"meissen": "Light Dreams Pyrotechnik",
"silvesterwelt": "Silvester Welt Berlin",
"flyfire": "Fly Fire",
"feuerwerkstern": "Pyrostern",
"buettner": "Büttner Feuerwerke",
"pyroverkauf24": "Pyroverkauf 24",
"himmelszauber": "Himmels Zauber",
"jueterborg": "Pyrohandel Jüterbog"
};
