import React, { useEffect } from "react";
import Footer from "./ui/footer/footer";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Searchfield from "./ui/searchfield/Searchfield";

function DataProtection() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <div className="container">
        <h4
          className="title tab-pane-title font-weight-bold mb-2"
          style={{ fontSize: "3rem", marginTop: "2rem" }}
        >
          Datenschutzerklärung
        </h4>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          1. Information über die Erhebung personenbezogener Daten und
          Kontaktdaten des Verantwortlichen
        </h4>
        <p className="mb-4">
          1.1 Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns
          für Ihr Interesse. Im Folgenden informieren wir Sie über den Umgang
          mit Ihren personenbezogenen Daten bei der Nutzung unserer Website.
          Personenbezogene Daten sind hierbei alle Daten, mit denen Sie
          persönlich identifiziert werden können. 1.2 Verantwortlicher für die
          Datenverarbeitung auf dieser Website im Sinne der
          Datenschutz-Grundverordnung (DSGVO) ist helpaholiCom UG
          (haftungsbeschränkt), Gewerbepark 2, 92526 Oberviechtach, Deutschland
          Telefon: +49 9671 8474045 E-Mail: kontakt@pyroextrem.de. Der für die
          Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige
          natürliche oder juristische Person, die allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten entscheidet. 1.3 Diese Website nutzt aus
          Sicherheitsgründen und zum Schutz der Übertragung personenbezogener
          Daten und anderer vertraulicher Inhalte eine SSL-bzw.
          TLS-Verschlüsselung. Sie können eine verschlüsselte Verbindung an der
          Zeichenfolge „https://“ und dem Schloss-Symbol in Ihrer Browserzeile
          erkennen.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          2. Datenerfassung beim Besuch unserer Website
        </h4>
        <p className="mb-4">
          Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie
          sich nicht registrieren oder uns anderweitig Informationen
          übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren
          Server übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere Website
          aufrufen, erheben wir die folgenden Daten, die für uns technisch
          erforderlich sind, um Ihnen die Website anzuzeigen: - Unsere besuchte
          Website - Datum und Uhrzeit zum Zeitpunkt des Zugriffes - Menge der
          gesendeten Daten in Byte - Quelle/Verweis, von welchem Sie auf die
          Seite gelangten - Verwendeter Browser - Verwendetes Betriebssystem -
          Verwendete IP-Adresse (ggf.: in anonymisierter Form) Die Verarbeitung
          erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
          berechtigten Interesses an der Verbesserung der Stabilität und
          Funktionalität unserer Website. Wir behalten uns allerdings vor, die
          Server-Logfiles nachträglich zu überprüfen, sollten konkrete
          Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          3. Hosting
        </h4>
        <p className="mb-4">
          Wir nutzen das Shopsystem des Dienstleisters Shopify International
          Limited, Victoria Buildings, 2. Etage, 1-2 Haddington Road, Dublin 4,
          D04 XN32, Irland („Shopify“), zum Zwecke des Hostings und der
          Darstellung des Online-Shops auf Basis einer Verarbeitung in unserem
          Auftrag. Sämtliche auf unserer Website erhobenen Daten werden auf den
          Servern von Shopify verarbeitet. Im Rahmen der vorgenannten Leistungen
          von Shopify können Daten auch im Rahmen einer weiteren Verarbeitung im
          Auftrag an die Shopify Inc., 150 Elgin St, Ottawa, ON K2P 1L4, Kanada,
          Shopify Data Processing (USA) Inc., Shopify Payments (USA) Inc. oder
          Shopify (USA) Inc. übermittelt werden. Für den Fall der Übermittlung
          von Daten an die Shopify Inc. in Kanada ist durch
          Angemessenheitsbeschluss der Europäischen Kommission das angemessene
          Datenschutzniveau gewährleistet. Weitere Hinweise zum Datenschutz von
          Shopify erhalten Sie unter der nachstehenden Internetseite:
          https://www.shopify.de/legal/datenschutz Eine weitere Verarbeitung auf
          anderen Servern als den vorgenannten von Shopify findet nur im
          nachstehend mitgeteilten Rahmen statt.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          4. Cookies
        </h4>
        <p className="mb-4">
          Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
          bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen
          Seiten sogenannte Cookies. Hierbei handelt es sich um kleine
          Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige der von
          uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also
          nach Schließen Ihres Browsers, wieder gelöscht (sog.
          Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und
          ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen (sog.
          persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten
          diese im individuellen Umfang bestimmte Nutzerinformationen wie
          Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies
          werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich
          je nach Cookie unterscheiden kann. Die Dauer der jeweiligen
          Cookie-Speicherung können Sie der Übersicht zu den
          Cookie-Einstellungen Ihres Webbrowsers entnehmen. Teilweise dienen die
          Cookies dazu, durch Speicherung von Einstellungen den Bestellprozess
          zu vereinfachen (z.B. Merken des Inhalts eines virtuellen Warenkorbs
          für einen späteren Besuch auf der Website). Sofern durch einzelne von
          uns eingesetzte Cookies auch personenbezogene Daten verarbeitet
          werden, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 lit. b DSGVO
          entweder zur Durchführung des Vertrages, gemäß Art. 6 Abs. 1 lit. a
          DSGVO im Falle einer erteilten Einwilligung oder gemäß Art. 6 Abs. 1
          lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der
          bestmöglichen Funktionalität der Website sowie einer
          kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
          Bitte beachten Sie, dass Sie Ihren Browser so einstellen können, dass
          Sie über das Setzen von Cookies informiert werden und einzeln über
          deren Annahme entscheiden oder die Annahme von Cookies für bestimmte
          Fälle oder generell ausschließen können. Jeder Browser unterscheidet
          sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist
          in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert,
          wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für
          die jeweiligen Browser unter den folgenden Links: Internet Explorer:
          https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
          Firefox:
          https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
          Chrome: https://support.google.com/chrome/answer/95647?hl=de&hlrm=en
          Safari: https://support.apple.com/de-de/guide/safari/sfri11471/mac
          Opera: https://help.opera.com/de/latest/web-preferences/#cookies Bitte
          beachten Sie, dass bei Nichtannahme von Cookies die Funktionalität
          unserer Website eingeschränkt sein kann.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          5. Kontaktaufnahme
        </h4>
        <p className="mb-4">
          Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
          E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle
          der Nutzung eines Kontaktformulars erhoben werden, ist aus dem
          jeweiligen Kontaktformular ersichtlich. Diese Daten werden
          ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die
          Kontaktaufnahme und die damit verbundene technische Administration
          gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung dieser
          Daten ist unser berechtigtes Interesse an der Beantwortung Ihres
          Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung
          auf den Abschluss eines Vertrages ab, so ist zusätzliche
          Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre
          Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht.
          Dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass
          der betroffene Sachverhalt abschließend geklärt ist und sofern keine
          gesetzlichen Aufbewahrungspflichten entgegenstehen.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          6. Datenverarbeitung bei Eröffnung eines Kundenkontos und zur
          Vertragsabwicklung
        </h4>
        <p className="mb-4">
          Gemäß Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene Daten
          weiterhin erhoben und verarbeitet, wenn Sie uns diese zur Durchführung
          eines Vertrages oder bei der Eröffnung eines Kundenkontos mitteilen.
          Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen
          ersichtlich. Eine Löschung Ihres Kundenkontos ist jederzeit möglich
          und kann durch eine Nachricht an die o.g. Adresse des Verantwortlichen
          erfolgen. Wir speichern und verwenden die von Ihnen mitgeteilten Daten
          zur Vertragsabwicklung. Nach vollständiger Abwicklung des Vertrages
          oder Löschung Ihres Kundenkontos werden Ihre Daten mit Rücksicht auf
          steuer- und handelsrechtliche Aufbewahrungsfristen gesperrt und nach
          Ablauf dieser Fristen gelöscht, sofern Sie nicht ausdrücklich in eine
          weitere Nutzung Ihrer Daten eingewilligt haben oder eine gesetzlich
          erlaubte weitere Datenverwendung von unserer Seite vorbehalten wurde.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          7. Nutzung von Kundendaten zur Direktwerbung
        </h4>
        <p className="mb-4">
          7.1 Anmeldung zu unserem E-Mail-Newsletter Wenn Sie sich zu unserem
          E-Mail Newsletter anmelden, übersenden wir Ihnen Informationen zum
          Thema Feuerwerk und zur Betpreispyro Plattform. Pflichtangabe für die
          Übersendung des Newsletters ist allein Ihre E-Mailadresse. Die Angabe
          weiterer Daten ist freiwillig und wird verwendet, um Sie persönlich
          ansprechen zu können. Für den Versand des Newsletters verwenden wir
          das sog. Double Opt-in Verfahren. Dies bedeutet, dass wir Ihnen erst
          dann einen E-Mail Newsletter übermitteln werden, wenn Sie uns
          ausdrücklich bestätigt haben, dass Sie in den Empfang von Newsletter
          einwilligen. Wir schicken Ihnen dann eine Bestätigungs-E-Mail, mit der
          Sie gebeten werden durch Anklicken eines entsprechenden Links zu
          bestätigen, dass Sie künftig den Newsletter erhalten wollen. Mit der
          Aktivierung des Bestätigungslinks erteilen Sie uns Ihre Einwilligung
          für die Nutzung Ihrer personenbezogenen Daten gemäß Art. 6 Abs. 1 lit.
          a DSGVO. Bei der Anmeldung zum Newsletter speichern wir Ihre vom
          Internet Service-Provider (ISP) eingetragene IP-Adresse sowie das
          Datum und die Uhrzeit der Anmeldung, um einen möglichen Missbrauch
          Ihrer E-Mail- Adresse zu einem späteren Zeitpunkt nachvollziehen zu
          können. Die von uns bei der Anmeldung zum Newsletter erhobenen Daten
          werden ausschließlich für Zwecke der werblichen Ansprache im Wege des
          Newsletters benutzt. Sie können den Newsletter jederzeit über den
          dafür vorgesehenen Link im Newsletter oder durch entsprechende
          Nachricht an den eingangs genannten Verantwortlichen abbestellen. Nach
          erfolgter Abmeldung wird Ihre E-Mailadresse unverzüglich in unserem
          Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in eine
          weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine
          darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich
          erlaubt ist und über die wir Sie in dieser Erklärung informieren.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          7.2 Newsletterversand via Klaviyo
        </h4>
        <p className="mb-4">
          Wir verwenden für den Newsletterversand den Dienst der Klaviyo Inc.
          (125 Summer St Floor 7, Boston, MA 02111, USA; “Klaviyo”) im Rahmen
          einer Auftragsverarbeitung. Wir geben die von Ihnen während der
          Newsletteranmeldung zur Verfügung gestellten Informationen (E-Mail
          Adresse, ggf. Vor- und Nachname) an Klaviyo weiter. Die
          Datenverarbeitung dient dem Zweck des Newsletterversands und dessen
          statistischer Auswertung. Um Newsletter-Kampagnen auszuwerten,
          enthalten die versendeten Newsletter eine 1x1 Pixel Grafik (Tracking
          Pixel) oder einen Tracking-Link. So können wir feststellen, ob Sie den
          Newsletter geöffnet haben und ob Sie ggf. integrierte Links angeklickt
          haben. In dem Zusammenhang erheben wir Ihre personenbezogenen Daten
          wie bspw. IP-Adresse, Browsertyp- und device sowie den Zeitpunkt. Aus
          diesen Daten können unter einem Pseudonym Nutzungsprofile erstellt
          werden. Die erhobenen Daten werden nicht dazu benutzt, Sie persönlich
          zu identifizieren. Die erhobenen Daten werden lediglich zur
          statistischen Auswertung zur Verbesserung von Newsletter-Kampagnen
          genutzt. Ihre Daten werden in der Regel an Server von Klaviyo in den
          USA übermittelt und dort gespeichert. Für die USA ist ein
          Angemessenheitsbeschluss der EU-Kommission vorhanden, das
          Trans-Atlantic Data Privacy Framework (TADPF). Klaviyo hat sich nach
          dem TADPF zertifiziert und damit verpflichtet, europäische
          Datenschutzgrundsätze einzuhalten. Die Verarbeitung Ihrer
          personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f
          DSGVO aus unserem überwiegenden berechtigten Interesse an einem
          zielgerichteten, werbewirksamen und benutzerfreundlichen
          Newslettersystem. Sie haben das Recht aus Gründen, die sich aus Ihrer
          besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie
          betreffender personenbezogener Daten zu widersprechen. Nähere
          Informationen zum Datenschutz bei Klaviyo finden Sie unter
          https://www.klaviyo.com/legal/privacy-notice sowie unter
          https://www.klaviyo.com/legal/data-processing-agreement.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          8. Online-Marketing
        </h4>
        <p className="mb-4">
          Facebook Pixel für die Erstellung von Custom Audiences mit erweitertem
          Datenabgleich (mit Cookie-Consent-Tool) Innerhalb unseres
          Onlineangebotes wird das sog. "Facebook-Pixel" des sozialen Netzwerkes
          Facebook im Modus des erweiterten Datenabgleichs eingesetzt, welches
          von der Facebook Ireland Limited, 4 Grand Canal Quare, Dublin 2,
          Irland ("Facebook“) betrieben wird. Auf Basis seiner ausdrücklichen
          Einwilligung wird, wenn ein Nutzer auf eine bei Facebook ausgespielte,
          von uns geschaltete Werbeanzeige klickt, der URL unserer verknüpften
          Seite durch Facebook Pixel ein Zusatz angefügt. Sodann wird dieser
          URL-Parameter nach Weiterleitung in den Browser des Nutzers per Cookie
          eingeschrieben, welches unsere verknüpfte Seite selbst setzt.
          Zusätzlich werden von diesem Cookie spezifische Kundendaten wie
          beispielsweise die Mailadresse, die wir auf unserer mit der
          Facebook-Anzeige verknüpften Webseite bei Vorgängen wie
          Kaufabschlüssen, Kontoanmeldungen oder Registrierungen sammeln,
          erfasst (erweiterter Datenabgleich). Das Cookie wird von Facebook
          Pixel sodann ausgelesen und ermöglicht eine Weiterleitung der Daten,
          inklusive der spezifischen Kundendaten, an Facebook. Mit Hilfe des
          Facebook-Pixels mit erweitertem Datenabgleich ist es Facebook
          einerseits möglich, die Besucher unseres Onlineangebotes als
          Zielgruppe für die Darstellung von Anzeigen (sog. "Facebook-Ads")
          genau zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel mit
          erweitertem Datenabgleich ein, um die durch uns geschalteten
          Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein
          Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte
          Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die
          anhand der besuchten Webseiten bestimmt werden) aufweisen, welche wir
          an Facebook übermitteln (sog. „Custom Audiences“). Mit Hilfe des
          Facebook-Pixels mit erweiterten Datenabgleich möchten wir ebenfalls
          sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der
          Nutzer entsprechen und nicht belästigend wirken. So können wir weiter
          die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
          Marktforschungszwecke auswerten, indem wir nachvollziehen, ob Nutzer
          nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Website
          weitergeleitet wurden (sog. „Conversion“). Gegenüber der
          Standard-Variante von Facebook Pixel hilft die Funktion des
          erweiterten Datenabgleichs uns, die Effektivität unserer
          Werbekampagnen besser zu messen, indem sie mehr zugeordnete
          Conversions erfasst. Alle übermittelten Daten werden von Facebook
          gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen
          Nutzerprofil möglich ist und Facebook die Daten für eigene
          Werbezwecke, entsprechend der Facebook- Datenverwendungsrichtlinie
          (https://www.facebook.com/about/privacy/) verwenden kann. Die Daten
          können Facebook sowie dessen Partnern das Schalten von Werbeanzeigen
          auf und außerhalb von Facebook ermöglichen. Diese
          Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der
          ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Eine
          Einwilligung in den Einsatz des Facebook-Pixels darf nur von Nutzern,
          die älter als 16 Jahre alt sind, erklärt werden. Falls Sie jünger
          sind, bitten wir Sie, Ihre Erziehungsberechtigten um Erlaubnis zu
          fragen. Die durch Facebook erzeugten Informationen werden in der Regel
          an einen Server von Facebook übertragen und dort gespeichert, hierbei
          kann es auch zu einer Übermittlung an die Server der Facebook Inc. in
          den USA kommen. Sie können Ihre erteilte Einwilligung jederzeit mit
          Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben,
          entfernen Sie das im auf der Website eingebundenen
          „Cookie-Consent-Tool“ gesetzte Häkchen neben der Einstellung für den
          „Facebook Pixel“.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          9. Tools und Sonstiges
        </h4>
        <p className="mb-4">
          9.1 - DATEV Für die Erledigung der Buchhaltung nutzen wir die
          cloudbasierten Buchhaltungssoftware der DATEV eG, Paumgartnerstr.
          6-14, 90429 Nürnberg („DATEV“). DATEV verarbeitet Eingangs- und
          Ausgangsrechnungen sowie ggf. auch die Bankbewegungen unseres
          Unternehmens, um Rechnungen automatisch zu erfassen, zu den
          Transaktionen zu matchen und hieraus in einem teilautomatisierten
          Prozess die Finanzbuchhaltung zu erstellen. Sofern hierbei auch
          personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung
          gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten
          Interesses an einer effizienten Organisation und Dokumentation unserer
          Geschäftsvorgänge. Nähere Informationen zu DATEV, der automatisierten
          Verarbeitung von Daten und zu den Datenschutzbestimmungen finden Sie
          unter https://www.datev.de/web/de/m/ueber-datev/datenschutz/ -
          Lexoffice Für die Erledigung der Buchhaltung nutzen wir den Service
          der cloudbasierten Buchhaltungssoftware „lexoffice“ der Haufe-Lexware
          GmbH & Co. KG, Munzinger Straße 9, 79111 Freiburg. Lexoffice
          verarbeitet Eingangs- und Ausgangsrechnungen sowie ggf. auch die
          Bankbewegungen unseres Unternehmens, um Rechnungen automatisch zu
          erfassen, zu den Transaktionen zu matchen und hieraus in einem
          teilautomatisierten Prozess die Finanzbuchhaltung zu erstellen. Sofern
          hierbei auch personenbezogene Daten verarbeitet werden, erfolgt die
          Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
          berechtigten Interesses an einer effizienten Organisation und
          Dokumentation unserer Geschäftsvorgänge. Nähere Informationen zu
          lexoffice, der automatisierten Verarbeitung von Daten und zu den
          Datenschutzbestimmungen finden Sie unter
          https://www.lexoffice.de/datenschutz/
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          10. Rechte des Betroffenen
        </h4>
        <p className="mb-4">
          10.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem
          Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen
          Daten umfassende Betroffenenrechte (Auskunfts- und
          Interventionsrechte), über die wir Sie nachstehend informieren: -
          Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht
          auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten,
          die Verarbeitungszwecke, die Kategorien der verarbeiteten
          personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern,
          gegenüber denen Ihre Daten offengelegt wurden oder werden, die
          geplante Speicherdauer bzw. die Kriterien für die Festlegung der
          Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
          Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung,
          Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn
          diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer
          automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
          aussagekräftige Informationen über die involvierte Logik und die Sie
          betreffende Tragweite und die angestrebten Auswirkungen einer solchen
          Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien
          gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer
          bestehen; - Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein
          Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger
          Daten und/oder Vervollständigung Ihrer bei uns gespeicherten
          unvollständigen Daten; - Recht auf Löschung gemäß Art. 17 DSGVO: Sie
          haben das Recht, die Löschung Ihrer personenbezogenen Daten bei
          Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen.
          Dieses Recht besteht jedoch insbesondere dann nicht, wenn die
          Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
          Information, zur Erfüllung einer rechtlichen Verpflichtung, aus
          Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen erforderlich ist; - Recht auf
          Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie haben das
          Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
          Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit
          Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen
          unzulässiger Datenverarbeitung ablehnen und stattdessen die
          Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre
          Daten zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen, nachdem wir diese Daten nach
          Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus
          Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht
          feststeht, ob unsere berechtigten Gründe überwiegen; - Recht auf
          Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf
          Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber
          dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
          Empfängern, denen die Sie betreffenden personenbezogenen Daten
          offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
          Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist
          sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
          verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet
          zu werden. - Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie
          haben das Recht, Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen, soweit dies technisch machbar
          ist; - Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3
          DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die
          Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu
          widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten
          unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine
          Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden
          kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
          aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          nicht berührt; - Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie
          der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die DSGVO verstößt, haben Sie -
          unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
          Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen
          Verstoßes. 10.2 WIDERSPRUCHSRECHT WENN WIR IM RAHMEN EINER
          INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES
          ÜBERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS
          JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
          SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG
          FÜR DIE ZUKUNFT EINZULEGEN. MACHEN SIE VON IHREM WIDERSPRUCHSRECHT
          GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE
          WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE
          SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE
          INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE
          VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
          RECHTSANSPRÜCHEN DIENT. WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS
          VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT,
          JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
          PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE
          KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN. MACHEN SIE VON
          IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER
          BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          11. Dauer der Speicherung personenbezogener Daten
        </h4>
        <p className="mb-4">
          Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
          anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und –
          sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen
          Aufbewahrungsfrist (z.B. handels- und steuerrechtliche
          Aufbewahrungsfristen). Bei der Verarbeitung von personenbezogenen
          Daten auf Grundlage einer ausdrücklichen Einwilligung gemäß Art. 6
          Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der
          Betroffene seine Einwilligung widerruft. Existieren gesetzliche
          Aufbewahrungsfristen für Daten, die im Rahmen rechtsgeschäftlicher
          bzw. rechtsgeschäftsähnlicher Verpflichtungen auf der Grundlage von
          Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach
          Ablauf der Aufbewahrungsfristen routinemäßig gelöscht, sofern sie
          nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich
          sind und/oder unsererseits kein berechtigtes Interesse an der
          Weiterspeicherung fortbesteht. Bei der Verarbeitung von
          personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
          werden diese Daten so lange gespeichert, bis der Betroffene sein
          Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausübt, es sei denn, wir
          können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
          die die Interessen, Rechte und Freiheiten der betroffenen Person
          überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen. Bei der Verarbeitung von
          personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
          bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO
          ausübt. Sofern sich aus den sonstigen Informationen dieser Erklärung
          über spezifische Verarbeitungssituationen nichts anderes ergibt,
          werden gespeicherte personenbezogene Daten im Übrigen dann gelöscht,
          wenn sie für die Zwecke, für die sie erhoben oder auf sonstige Weise
          verarbeitet wurden, nicht mehr notwendig sind.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default DataProtection;
