import pickupLocationData from "./locations/pickupLocations";
import extractFloats from "./extractFloats";
import { mainLocationIcon, sideLocationIcon, storeIcon } from "./marker-icons/markerIcons";

function getMarkers() {
    let markers = [];
    
    pickupLocationData.forEach((location) => {
      let furtherLocationsAdresses = location?.shop_further_locations.split("\n");
      markers.push({
        icon: storeIcon,
        mainLocation:location?.main_locations,
        coordinates: extractFloats(location.main_coordinates),
        color: "red",
        isMain: true,
        shopLink: location.shop_url,
        shopName: location.shop_name,
      });
      location.shop_further_location_coordinates.forEach((furtherLocation, index) => {

        markers.push({
          icon: sideLocationIcon,
          mainLocation:furtherLocationsAdresses[index],
          coordinates: furtherLocation,
          color: "blue",
          isMain: false,
          shopLink: location.shop_url,
          shopName: location.shop_name,
        });
      });
    });
    return markers;
  };

export default getMarkers;