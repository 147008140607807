import React from "react";
import {GiFireworkRocket} from "react-icons/gi";

function Advantages() {
  return (
    <div style={{display:"flex", justifyContent: "space-around", alignItems: "center", border: "1px solid #eee", borderRadius: "8px", padding: "0 8px 0 8px", marginBottom: "5rem"  }}>
      <div className="icon-box icon-box-side text-dark my-icon-first" style={{padding: "2.5rem 0 2.4rem"}}>
        <span className="icon-box-icon icon-shipping">
        <i className="w-icon-sale" />
        </span>
        <div className="icon-box-content">
          <h4 className="icon-box-title font-weight-bolder">Kostenlos</h4>
          <p className="text-default"><a href="https://www.patreon.com/user?u=100031016" target="_blank">Jetzt unterstützen!</a></p>
        </div>
      </div>
      <div className="icon-box icon-box-side text-dark" style={{padding: "2.5rem 0 2.4rem"}}>
        <span className="icon-box-icon icon-payment">
          <i className="w-icon-store" />
        </span>
        <div className="icon-box-content">
          <h4 className="icon-box-title font-weight-bolder">
            Über 100 Händler
          </h4>
          <p className="text-default">Unabhängiger Preisvergleich</p>
        </div>
      </div>
      <div className="icon-box icon-box-side text-dark icon-box-money my-icon-last" style={{padding: "2.5rem 0 2.4rem"}}>
        <span className="icon-box-icon icon-payment" style={{marginBottom: "4px"}}>
          
          <GiFireworkRocket size={29}/>
        </span>
        <div className="icon-box-content">
          <h4 className="icon-box-title font-weight-bolder">
            Tausende Produkte
          </h4>
          <p className="text-default">Täglich aktualisiert</p>
        </div>
      </div>
      {/*<div className="icon-box icon-box-side text-dark icon-box-chat" style={{padding: "2.5rem 0 2.4rem"}}>
        <span className="icon-box-icon icon-chat">
          <i className="w-icon-chat" />
        </span>
        <div className="icon-box-content">
          <h4 className="icon-box-title font-weight-bolder">
            Community Driven
          </h4>
          <p className="text-default">Entscheide wie es weitergeht!</p>
        </div>
      </div>*/}
    </div>
  );
}

export default Advantages;
