import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "../context";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const useVirtualCartList = () => {
  const [state, setState] = useContext(UserContext);
  const userId = state?.user?.id;
  const [virtualCartList, setVirtualCartList] = useState([]);
  const [matchedShopData, setMatchedShopData] = useState(null);
  const [loadingVirtualCartList, setLoadingVirtualCartList] = useState(true);
  const [loadingMatchingData, setLoadingMatchingData] = useState(false);
  const [error, setError] = useState(null);

  // Handle case when user is not logged in
  const handleNotLoggedIn = () => {
    toast.error(
      <>
        Bitte melde dich an, um fortzufahren.{" "}
        <a
          href="/login"
          style={{ color: "#F4442C", textDecoration: "underline" }}
        >
          Login
        </a>
      </>,
       {
        autoClose: 10000,  // 10 seconds
      }
    );
  };

  useEffect(() => {
    console.log("loadingMatchingData has changed:", loadingMatchingData);
  }, [loadingMatchingData]);

  useEffect(() => {
    if (!userId) {
      console.log("No userId provided, skipping fetch");
      setLoadingVirtualCartList(false); // Ensure loading stops when there's no user
      return;
    }

    const fetchVirtualCartList = async () => {
      try {
        const response = await axios.get(`/virtualcartlist/${userId}`);

        if (response.data.virtualCartList) {
          console.log(response.data.virtualCartList);
          setVirtualCartList(response.data.virtualCartList);
        }
      } catch (err) {
        console.error("Error fetching virtual cart list:", err);
        setError(err);
      } finally {
        setLoadingVirtualCartList(false);
        console.log("Finished fetching virtual cart list");
      }
    };

    fetchVirtualCartList();
  }, [userId]);

  const addItemToCart = async (productId) => {
    if (!userId) {
      handleNotLoggedIn();
      return;
    }

    try {
      const response = await axios.post("/virtualcartlist/add", {
        user_id: userId,
        product_id: productId,
      });

      setVirtualCartList([...response.data.virtualCartList]);
      toast.success(
        <div>
          Produkt wurde deinem virtuellen Warenkorbliste hinzugefügt!{" "}
          <Link to="/virtualcartlist" style={{ textDecoration: 'underline', color: '#F4442C' }}>
            Zum Warenkorb
          </Link>
        </div>, {
        autoClose: 10000,  // 10 seconds
      }
      );
    } catch (err) {
      setError(err);
    }
  };

  const removeItemFromCart = async (productId) => {
    if (!userId) {
      handleNotLoggedIn();
      return;
    }

    try {
      await axios.post("/virtualcartlist/remove", {
        user_id: userId,
        product_id: productId,
      });

      // Update the virtual cart list
      setVirtualCartList((prev) =>
        prev.filter((item) => item.product.id !== productId)
      );

      // Fetch updated matched shop data
      await getMatchedShopData(); // Ensure that matchedShopData is updated after removal

      toast.success(
        <div>
          Produkt wurde aus deiner virtuellen Warenkorbsliste entfernt!{" "}
          <Link to="/virtualcartlist" style={{ textDecoration: 'underline', color: '#F4442C' }}>
            Zum Warenkorb
          </Link>
        </div>, {
        autoClose: 10000,  // 10 seconds
      }
      );
    } catch (err) {
      setError(err);
    }
  };

  const isProductInCart = (db_id) => {
    return virtualCartList?.some((item) => item.product.db_id === db_id);
  };

  // New method to fetch matched shop data
  const getMatchedShopData = async () => {
    
    if (!userId) return;
    try {
      setLoadingMatchingData(true);
      const response = await axios.get(`/virtualcartlist/match/${userId}`);
      setMatchedShopData(response.data);
    } catch (err) {
      console.error("Error fetching matched shop data:", err);
      setError(err);
    } finally {
      setLoadingMatchingData(false);
      console.log("Finished fetching matched shop data");
    }
  };

  return {
    virtualCartList,
    matchedShopData, // Expose the matched shop data
    loadingMatchingData,
    loadingVirtualCartList,
    error,
    addItemToCart,
    removeItemFromCart,
    isProductInCart,
    getMatchedShopData, // Expose the new method
  };
};
