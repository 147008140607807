import React from "react";
import { Link, useLocation } from "react-router-dom";

function Sidebar({ logout }) {
  const innerLogout = () => {
    logout();
  };

  const location = useLocation();

  return (
    <>
      <ul className="nav nav-tabs mb-10" role="tablist" >
        <li className="nav-item">
          <Link
            to="/account"
            className={`nav-link ${
              location.pathname === "/account" ? "active" : ""
            }`}
          >
            Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/wishlists"
            className={`nav-link ${
              location.pathname === "/wishlists" ? "active" : ""
            }`}
          >
            Wishlist
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/pricealerts"
            className={`nav-link ${
              location.pathname === "/pricealerts" ? "active" : ""
            }`}
          >
            Mein Preisalarm
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/virtualcartlist"
            className={`nav-link ${
              location.pathname === "/virtualcartlist" ? "active" : ""
            }`}
          >
            Liste Virtueller Warenkorb
          </Link>
        </li>
      </ul>
    </>
  );
}

export default Sidebar;
