import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import Footer from "./ui/footer/footer";
import { UserContext } from "../context";
import Searchfield from "./ui/searchfield/Searchfield";

function Login() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [state, setState] = useContext(UserContext);

  const [firstname, setFirstname] = React.useState("");

  const [lastname, setLastname] = React.useState("");

  const [email, setEmail] = React.useState("");

  const [otp, setOTP] = React.useState("");

  const [password, setPassword] = React.useState("");

  const [emailLogin, setEmailLogin] = React.useState("");

  const [passwordLogin, setPasswordLogin] = React.useState("");

  const [loading, setLoading] = useState(false);

  const [isRegistrationHidden, setIsRegistrationHidden] = useState(false);

  const navigate = useNavigate();

  const handleOTPSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(`/send-otp`, {
        email,
      });

      setLoading(false);

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        setIsRegistrationHidden(true);

        toast.success("Erfolgreich angemeldet!");
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
      setLoading(false);
    }
  };

  const handleOTPConfirmSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(`/verify-otp`, {
        email,
        otp,
      });

      setLoading(false);

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        toast.success(data.description);

        handleRegistrationSubmit();
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
      setLoading(false);
    }
  };

  const handleRegistrationSubmit = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(`/user`, {
        first_name: firstname,
        last_name: lastname,
        email: email,
        password: password,
      });

      setLoading(false);

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        setState({
          user: data.data.user,
          token: data.data.token,
        });

        //toast.success("erfolgreich angemeldet");

        // save in local storage
        window.localStorage.setItem("auth", JSON.stringify(data.data));

        navigate("/account");
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
      setLoading(false);
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(`/login`, {
        email: emailLogin,
        password: passwordLogin,
      });

      console.log("The dataa ===> ", data);

      setLoading(false);

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        setState({
          user: data.data.user,
          token: data.data.token,
        });

        toast.success(data.description);

        // save in local storage
        window.localStorage.setItem("auth", JSON.stringify(data.data));

        navigate("/account");
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
      setLoading(false);
    }
  };

  if (state && state.token) navigate("/account");

  return (
    <>
      <div>
        <div className="page-wrapper">
          <header className="header">
            <TopNavbar />
            <MiddleHeader />
            <Searchbar />
            <Searchfield />
          </header>

          {/* Start of Main */}
          <main className="main login-page" style={{ margin: "40px" }}>
            {/* Start of Page Header */}
            {
              <div className="page-header">
                <div className="container">
                  <h1  className="page-title mb-0 text-5xl font-bold">Login | Registrieren</h1>
                </div>
              </div>
            }
            {/* End of Page Header */}

            <div className="page-content">
              <div className="container">
                <div className="login-popup" style={{ padding: "0px" }}>
                  <div className="tab tab-nav-boxed tab-nav-center tab-nav-underline">
                    <ul className="nav nav-tabs text-uppercase" role="tablist">
                      <li className="nav-item">
                        <a href="#sign-in" className="nav-link active">
                          Anmelden
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#sign-up" className="nav-link">
                          Registrieren
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="sign-in">
                        <form onSubmit={handleLoginSubmit}>
                          <div className="form-group">
                            <label>Email Adresse *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              required
                              value={emailLogin}
                              onChange={(e) => setEmailLogin(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-0">
                            <label>Passwort *</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              required
                              value={passwordLogin}
                              onChange={(e) => setPasswordLogin(e.target.value)}
                            />
                          </div>
                          <div className="form-checkbox d-flex align-items-center justify-content-between">
                            <Link to={"/forgotpassword"}>
                              Passwort vergessen
                            </Link>
                          </div>
                          <button
                            className="btn btn-primary"
                            style={{
                              width: `100%`,
                              backgroundColor: loading && "grey",
                              cursor: loading && "not-allowed",
                            }}
                            disabled={loading}
                          >
                            {loading ? "Senden..." : "Anmelden"}
                          </button>
                        </form>
                      </div>
                      <div className="tab-pane" id="sign-up">
                        <form
                          onSubmit={handleOTPSubmit}
                          style={{
                            display: isRegistrationHidden ? "none" : "block",
                          }}
                        >
                          <div className="form-group">
                            <label>Vorname *</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label>Nachname *</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label>Deine Email Adresse *</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email_1"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-5">
                            <label>Passwort *</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password_1"
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>

                          <p>
                            Ihre persönlichen Daten werden verwendet, um Ihr
                            Erlebnis auf dieser Website zu unterstützen, den
                            Zugriff auf Ihr Konto zu verwalten und für andere in
                            unserem beschriebene Zwecke{" "}
                            <Link to={"/datenschutz"}>
                              <a className="text-primary">
                                Datenschutzerklärung
                              </a>
                            </Link>
                          </p>

                          <div className="form-checkbox d-flex align-items-center justify-content-between mb-5">
                            <input
                              type="checkbox"
                              className="custom-checkbox"
                              id="remember"
                              required
                            />
                            <label htmlFor="remember" className="font-size-md">
                              Ich stimme der{" "}
                              <a href="#" className="text-primary font-size-md">
                                Datenschutzerklärung zu
                              </a>
                            </label>
                          </div>
                          <button
                            className="btn btn-primary"
                            style={{
                              width: `100%`,
                              backgroundColor: loading && "grey",
                              cursor: loading && "not-allowed",
                            }}
                            disabled={loading}
                          >
                            {loading ? "Laden..." : "Registrieren"}
                          </button>
                        </form>
                        <form
                          onSubmit={handleOTPConfirmSubmit}
                          style={{
                            display: isRegistrationHidden ? "block" : "none",
                          }}
                        >
                          <div className="form-group">
                            <label>
                              <h4>
                              E-Mail Postfach jetzt öffnen um Bestätigungscode zu kopieren um hier einzufügen!
                              </h4>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={otp}
                              onChange={(e) => setOTP(e.target.value)}
                            />
                          </div>

                          <button
                            className="btn btn-primary"
                            style={{
                              width: `100%`,
                              backgroundColor: loading && "grey",
                              cursor: loading && "not-allowed",
                            }}
                            disabled={loading}
                          >
                            {loading ? "Laden..." : "Registrieren"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* End of Main */}
          <Footer />
        </div>
        {/* End of Page Wrapper */}
        {/* Start of Sticky Footer */}

        {/* End of Sticky Footer */}
        {/* Start of Scroll Top */}
        <a
          id="scroll-top"
          className="scroll-top"
          href="#top"
          title="Top"
          role="button"
        >
          {" "}
          <i className="w-icon-angle-up" />{" "}
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 70 70"
          >
            {" "}
            <circle
              id="progress-indicator"
              fill="transparent"
              stroke="#000000"
              strokeMiterlimit={10}
              cx={35}
              cy={35}
              r={34}
              style={{ strokeDasharray: "16.4198, 400" }}
            />{" "}
          </svg>{" "}
        </a>
        {/* End of Scroll Top */}
        {/* Start of Mobile Menu */}

        {/* End of .mobile-menu-close */}
      </div>
    </>
  );
}

export default Login;
