import React from "react";
import MainHeader from "../../../images/bpp_preisvergleich_banner.png";

import styles from "./styles/heroHeader.css";

function HeroHeader(props) {
  const { ctaText, ctaLink, customSubline, customHeadline } = props;
  return (
    <div
      style={{
        backgroundImage: `url(${MainHeader})`,
        filter: "brightness(0.9)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "60vh",
        marginTop: "5rem",
        marginBottom: "5rem",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "80%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          WebkitTransform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        {customHeadline ? (
          <h1
            style={{ color: "#fff", fontFamily: "Poppins" }}
            className="header-title"
          >
            {customHeadline}
          </h1>
        ) : (
          <h1
            style={{ color: "#fff", fontFamily: "Poppins", fontSize: "40px", fontWeight: "bold", marginBottom: "10px" }}
            className="header-title"
          >
            Willkommen auf <span style={{ color: "#F4442C" }}>Best Preis</span>{" "}
            Pyro!
          </h1>
        )}
        <div
          className="header-subtitle"
          style={
            customSubline
              ? { color: "#fff", fontFamily: "Poppins" }
              : { color: "#fff", fontFamily: "Poppins" }
          }
        >
          {customSubline
            ? customSubline
            : "Deutschlands größtem Feuerwerk & Pyro Preisvergleich"}
        </div>

        <a href={ctaLink ? ctaLink : "/shop"}>
          <button
            className="btn btn-primary btn-rounded"
            style={{ marginTop: "2rem" }}
          >
            {ctaText ? ctaText : "Jetzt Vergleichen"}
          </button>
        </a>
      </div>
    </div>
  );
}

export default HeroHeader;
