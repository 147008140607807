import React, { useEffect } from "react";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Footer from "./ui/footer/footer";
import Searchbar from "./includes/Searchbar";
import MiddleHeader from "./ui/headers/middleHeader";
import { Link } from "react-router-dom";
import Searchfield from "./ui/searchfield/Searchfield";

function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <main className="main">
        <div className="page-header"></div>

        <div className="page-content mb-12" style={{ marginTop: "2rem" }}>
          <div className="container pb-10 mb-2">
            <div className="main-content post-single-content">
              <div className="post post-grid post-single">
                <div className="post-details">
                  <div className="post-content">
                    <h2
                      className="title title-md font-weight-bold"
                      style={{ fontSize: "2.6rem" }}
                    >
                      Warum Best Preis Pyro deine Unterstützung benötigt!
                    </h2>
                    <p style={{ fontSize: "1.5rem" }}>
                      Klassische Preisvergleichseiten existieren nur aus einer
                      Motivation heraus: Viel Geld verdienen durch aufdringliche
                      Werbung, verfälschte Suchergebnisse, Affiliate Provisionen
                      und Verkauf von Daten der Anwender!
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Wir haben uns bewusst gegen all diese Methoden entschieden
                      und sind mit einem höheren 5-Stelligen Betrag in
                      Vorleistung gegangen, um diese Plattform zu erstellen. Die
                      monatliche Kosten der Plattform belaufen sich auf über
                      1.000€!
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Du feierst das Projekt, weil das Feuerwerk & Pyro Stöbern
                      so noch mehr Spaß macht und du echte Mehrwerte erhältst?
                      Dann würden wir uns über deine Unterstützung mittels
                      Mitgliedschaft deiner Wahl über Patreon freuen.
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Je nach Mitgliedsstufe bedanken wir uns jährlich mit einer
                      Überraschung bei dir, oder du erhältst für deinen Support
                      sogar Feuerwerksartikel! Alles unter dem Motto:{" "}
                      <strong>BEST PREIS PYRO!</strong>
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Danke von Herzen und viel Spaß beim Nutzen der Plattform,
                      euer Stefan alias PyroExtrem
                    </p>
                    <a
                      href="https://www.patreon.com/user?u=100031016"
                      target="_blank"
                    >
                      <button
                        className="btn btn-primary btn-rounded"
                        style={{ marginTop: "2rem" }}
                      >
                        Direkt zu Patreon
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Support;
