import React, { useState, useEffect, useContext } from "react";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Footer from "./ui/footer/footer";
import PickUpLocationFinder from "./ui/pickup-location-finder/PickUpLocationFinder";
import Searchbar from "./includes/Searchbar";
import MiddleHeader from "./ui/headers/middleHeader";
import NewsletterPopup from "./Popup/NewsletterPopup";
import Advantages from "./ui/advantages/Advantages";
import HeroHeader from "./ui/headers/heroHeader";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Searchfield from "./ui/searchfield/Searchfield";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import mainLocationIcon from "../images/storeIcon.png";
import sideLocationIcon from "../images/sideLocation.png";
import virtuellerWarenkorb from "../images/virtueller_warenkorb_banner.png";
import { UserContext } from "../context";
import io from "socket.io-client";
import ConsentPopup from "./notification/ConsentPopup";
import { toast } from "react-toastify";

const socket = io("https://monkfish-app-bxf3s.ondigitalocean.app", {
  withCredentials: true,
  transports: ["websocket", "polling"],
}); // Adjust the URL based on your server

function Home() {
  const [allShops, setAllShops] = useState([]);

  const [isSubscriptionAlreadyAdded, setIsSubscriptionAlreadyAdded] =
    useState(false);

  const [state, setState] = useContext(UserContext);

  const handleRedirect = () => {
    if (!state?.token) {
      toast.info(
        "Bitte registriere Dich oder melde Dich an, um den virtuellen Warenkorb nutzen zu können!"
      );
    }
  };

  const fetchedAllShops = async () => {
    try {
      const { data } = await axios.get("/shopnames");
      console.log(allShops, "allShops");
      console.log(data.data, "allShops");
      setAllShops(data.data.sort());
    } catch (error) {
      console.log(error);
    }
  };

  const [subscription, setSubscription] = useState(null);

  const subscribe = async () => {
    if (state && state.user.email) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/service-worker.js"
        );
        const innerSub = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BLt7fkNArtyZBUqn9rfc6X_gc8YGD1BgYjeUXWXIHwz3psJ2MY6Kpa0uNyCalvB1Btr1t6fFcZBqiI7OGQZoYjo",
        });
        setSubscription(innerSub);

        socket.on("newData", (data) => {
          console.log(data);
          sendNotification(data, innerSub);
        });

        sendSubscribe(innerSub);

        // Convert the object to a JSON string
        const objectString = JSON.stringify(innerSub);

        // Store the JSON string in local storage
        localStorage.setItem("subscriptionfromLocalstorage", objectString);
      } catch (error) {
        console.error("Error subscribing:", error);
      }
    }
  };

  const sendNotification = async (data, subscriptionFromArgs) => {
    console.log("The subcription from the sendNotification ====> ", {
      subscription: subscription ? subscription : subscriptionFromArgs,
    });
    try {
      await fetch(`${process.env.REACT_APP_API_ENDPOINT_URL}/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: subscription ? subscription : subscriptionFromArgs,
        }),
      });

      await fetch(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/sendNotification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            notification: {
              title: "Product alert",
              body: data,
            },
          }),
        }
      );
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const sendSubscribe = async (subscriptionFromArgs) => {
    console.log("The subcription from the sendNotification ====> ", {
      subscription: subscription ? subscription : subscriptionFromArgs,
    });
    try {
      await fetch(`${process.env.REACT_APP_API_ENDPOINT_URL}/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: subscription ? subscription : subscriptionFromArgs,
        }),
      });
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const registerLoggedInUserToSocketIO = () => {
    if (state && state.user.email) {
      socket.emit("logged_in_email_address", state.user.email);
    }

    // Retrieve the JSON string from local storage
    const storedObject = localStorage.getItem("subscriptionfromLocalstorage");

    // If it exists create new subscription
    if (storedObject) {
      subscribe();

      // Retrieve the new JSON string from local storage
      const newStoredObject = localStorage.getItem(
        "subscriptionfromLocalstorage"
      );

      const parsedObject = JSON.parse(newStoredObject);

      // establish connection with backend service
      sendSubscribe(parsedObject);

      setIsSubscriptionAlreadyAdded(true);
    }
  };

  useEffect(() => {
    fetchedAllShops();
    registerLoggedInUserToSocketIO();
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#pickuplocationfinder") {
      const element = document.getElementById("pickuplocationfinder");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div>
      <div className="page-wrapper">
        <h1 className="d-none">BPP template</h1>
        {/* Start of Header */}
        <header className="header">
          <TopNavbar />

          <MiddleHeader />
          {/* desktop Searchfield */}
          <Searchbar />
          {/* mobile Searchfield */}
          <Searchfield />
        </header>
        {/* End of Header */}
        {/* Start of Main */}
        <main className="main">
          <div className="container mb-2">
            <HeroHeader />

            {/* End of Intro-wrapper */}
            <Advantages />

            <div className="cols-md-1 row">
              <h4 className="title tab-pane-title font-weight-bold mb-2">
                Was ist Best Preis Pyro?
              </h4>
              <p className="mb-4">
                Vergleiche kostenlos tausende Produkte von über 100
                Feuerwerkshändlern und Pyro Onlineshops. Von Pyros - Für Pyros!
                Stöbern in Feuerwerk Onlineshops und Angebote von lokalen
                Händlern durchforsten gehört bei jedem Pyro und Feuerwerksfan,
                spätestens ab Herbst zur Tagesordnung. Best Preis Pyro hat es
                sich zur Aufgabe gemacht, diesen Prozess auf einer Plattform zu
                bündeln und dadurch noch spannender zu machen.
                <br />
                <strong>
                  {" "}
                  2024 revolutionieren wir das Ganze noch weiter mit der neuen
                  virtuellen Warenkorb Funktion!{" "}
                </strong>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link to={"/about"}>
                  {" "}
                  <button
                    className="btn btn-primary btn-rounded"
                    style={{ marginTop: "1rem", maxWidth: "200px" }}
                  >
                    Über uns
                  </button>
                </Link>
              </div>
            </div>
            <div className="cols-md-1 row">
              <h4
                className="title tab-pane-title font-weight-bold mb-2"
                style={{ marginTop: "2rem" }}
              >
                Virtueller Warenkorb
              </h4>
              <a
                style={{ marginBottom: "2rem" }}
                href={
                  "/shop?selectedbrands=undefined&selectedcategories=undefined&mincaliber=undefined&maxcaliber=undefined&minprice=undefined&maxprice=undefined&pgnumber=1&pgsize=20&searchquery=undefined&selectedshops=undefined&sortingorder=asc&isdiscounted=false&isavailable=false&onlyallyear=false&selectedfireworkscategories=undefined&onlyvirtualcart=true"
                }
              >
                <img
                  src={virtuellerWarenkorb}
                  width={"100%"}
                  alt="virtueller Warenkorb ausprobieren"
                />
              </a>
              <p>
                Was ist der virtuelle Warenkorb? Durch die neue virtuelle
                Warenkorb Funktion wird dein Feuerwerk Einkaufserlebnis noch
                besser. Füge beliebig viele Artikel zu deinem virtuellen
                Warenkorb hinzu und schau, welcher Onlineshop oder Händler
                (PLZ-Eingrenzung) deine Auswahl verfügbar hat.{" "}
                <strong>
                  Das Beste: der BPP-Algorithmus sucht ähnliche Produkte bei
                  jedem Händler!
                </strong>
              </p>
              <div className="my-4" style={{ display: "flex", justifyContent: "center" }}>
                {state?.token != "" && state?.token ? (
                  <Link to={"/virtualcartlist"}>
                    {" "}
                    <button
                      className="btn btn-primary btn-rounded"
                      style={{ marginTop: "2rem", maxWidth: "350px" }}
                    >
                      Zum Virtuellen Warenkorb
                    </button>
                  </Link>
                ) : (
                  <Link to={"/login"} onClick={handleRedirect}>
                    <button
                      className="btn btn-primary btn-rounded"
                      style={{ marginTop: "2rem", maxWidth: "350px" }}
                    >
                      Zum Virtuellen Warenkorb
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="container" id="pickuplocationfinder">
            <div className="cols-md-1 row">
              <h4
                className="title tab-pane-title font-weight-bold mb-2"
                style={{ fontFamily: "Poppins" }}
              >
                Verkaufs- und Abholpunkte
              </h4>
              <p style={{ margin: 0, fontFamily: "Poppins" }}>
                Standorte von über 150 Händlern.
              </p>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <img
                    src={
                      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png"
                    }
                    style={{ width: "1.2rem", marginRight: "0.5rem" }}
                  />
                  <span> Eigener Standort: dein eingegebener Standort</span>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <img
                    src={mainLocationIcon}
                    style={{ width: "1.5rem", marginRight: "0.5rem" }}
                  />
                  <span> Hauptstandort: Firmensitz</span>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={sideLocationIcon}
                    style={{ width: "1.5rem", marginRight: "0.5rem" }}
                  />
                  <span>
                    {" "}
                    Nebenstandort: Abholstation oder weitere Verkaufsstelle
                  </span>
                </li>
              </ul>

              <p style={{ margin: 0, fontFamily: "Poppins" }}>
                Für ausführliche Informationen zu Öffnungszeiten etc. bitte auf
                die jeweiligen Händlerverlinkungen klicken.
              </p>
              <PickUpLocationFinder />
            </div>
          </div>
          <div className="container pb-3 mb-2">
            <h4
              className="title tab-pane-title font-weight-bold mb-2"
              style={{ fontFamily: "Poppins" }}
            >
              Diese Händler sind im Preisvergleich aktuell enthalten:
            </h4>
            <p className="mb-4" style={{ marginTop: "2rem" }}>
              Weitere Händler folgen, doch dafür brauchen wir deine
              Unterstützung: <a href={"/support"}>Jetzt Supporten!</a>
            </p>

            {allShops
              ?.filter((shop) => shop !== "feuerwerk") // Filter out "feuerwerk" first
              .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
              .map((shop, index) => (
                <a
                  key={index}
                  href={`/shop?selectedbrands=undefined&selectedcategories=undefined&mincaliber=undefined&maxcaliber=undefined&minprice=undefined&maxprice=undefined&pgnumber=1&pgsize=20&searchquery=undefined&selectedshops=${shop}`}
                >
                  <span
                    style={{
                      fontFamily: "Poppins",
                      display: "inline-block",
                      padding: "3px",
                      backgroundColor: "#F4442C",
                      color: "#fff",
                      borderRadius: "4px",
                      marginTop: "4px",
                    }}
                  >
                    {capitalizeFirstLetter(shop)}
                  </span>{" "}
                </a>
              ))}

            <div className="cols-md-1 row"></div>
          </div>
        </main>

        <Footer />
      </div>

      <NewsletterPopup />

      {state && state.user.email && !isSubscriptionAlreadyAdded && (
        <ConsentPopup subscribe={subscribe} />
      )}
    </div>
  );
}

export default Home;
