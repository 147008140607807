import React, { useRef, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";

function Searchfield({
  PassedSubmit,
  updateSearchQuery,
  updateSearchCategoryQuery,
  searchQueryTerm,
  searchCategoryQueryTerm,
  shouldReload,
  showFilterIcon,
  showSidebar,
  setShowSidebar,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const debounceTimeout = useRef(null);

  const [autoSuggestList, setAutoSuggestList] = useState([]);
  const [autoSuggestTotalCount, setAutoSuggestTotalCount] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [searchCategoryQuery, setSearchCategoryQuery] = useState(
    searchCategoryQueryTerm || ""
  );
  const [searchQuery, setSearchQuery] = useState(
    searchParams.get("searchquery") === "undefined"
      ? ""
      : searchParams.get("searchquery") || ""
  );

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (updateSearchQuery) {
      updateSearchQuery(newValue);
    }

    setSearchQuery(newValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      fetchAutoSuggestions(newValue);
    }, 300);
  };

  const handleFilterIconClick = () => {
    setShowSidebar((prev) => !prev);
  };

  const fetchAutoSuggestions = async (query) => {
    if (!query.trim()) {
      setAutoSuggestList([]);
      return;
    }
    try {
      const response = await axios.get(`/autosuggest?q=${query}`);
      setAutoSuggestList(response.data.products);
      setAutoSuggestTotalCount(response.data.totalCount);
    } catch (err) {
      console.error("Error fetching autosuggestions", err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (PassedSubmit) {
      PassedSubmit();
    } else {
      searchProducts();
    }
  };

  const searchProducts = () => {
    if (searchQuery.trim().length > 0) {
      navigate(
        `/shop?selectedbrands=undefined&selectedcategories=undefined&mincaliber=undefined&maxcaliber=undefined&minprice=undefined&maxprice=undefined&pgnumber=1&pgsize=20&searchquery=${searchQuery}`
      );

      if (shouldReload) {
        window.location.reload();
      }
    }
  };

  return (
    <div
      style={{ backgroundColor: "#183544" }}
      id="searchfield"
      className="fixed z-50 w-full"
    >
      <div className="container flex justify-center items-center py-2 fixed top-0 bg-[#183544] z-50">
        {showFilterIcon && (
          <button onClick={handleFilterIconClick} className="text-white mr-2">
            {showSidebar ? (
              <AiOutlineClose size={42} />
            ) : (
              <FaFilter size={42} />
            )}
          </button>
        )}
        <div className="relative w-full max-w-md">
          <form
            className="hs-expanded hs-round bg-white br-xs d-md-flex input-wrapper flex"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              className="form-control text-light border-no flex-grow"
              name="search"
              id="search"
              placeholder="Suche..."
              value={searchQuery}
              onChange={handleInputChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            <button className="btn btn-search border-no" type="submit">
              <i className="w-icon-search" />
            </button>
          </form>
          {isFocused && autoSuggestList.length > 0 && (
            <ul
              className="absolute left-0 right-0 bg-white border border-gray-300 mt-1 z-10 cursor-default max-h-70 overflow-y-auto"
              style={{ top: "100%" }}
            >
              <li className="p-2 text-[#666666]">
                {`Gefundene Produkte: ${autoSuggestTotalCount}`}
              </li>
              {autoSuggestList?.map((product) => (
                <li
                  key={product.id}
                  className="px-4 py-2 hover:bg-gray-200 flex items-center"
                  onMouseDown={() => {
                    setAutoSuggestList([]);
                  }}
                >
                  <div>
                    <div className="font-semibold">{product.product_name}</div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Searchfield;
