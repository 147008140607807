import React from "react";
import {
  FaTiktok,
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaPatreon,
} from "react-icons/fa";

function SocialIcons(props) {
  return (
    <div
      className={`social-links" ${props.colored ? "social-icons-colored" : ""}`}
    >
      <div className="social-icons social-no-color border-thin" >
        <a style={{marginRight: "8px"}}
          href="https://www.facebook.com/profile.php?id=61551098403866"
          target="_blank"
        >
          <FaFacebook
            style={{ padding: "7px" }}
            className="social-icon social-youtube fab fa-tiktok"
          />
        </a>
        <a href="https://www.instagram.com/bestpreispyro/" target="_blank" style={{marginRight: "8px"}}>
          <FaInstagram
            style={{ padding: "7px" }}
            className="social-icon social-youtube fab fa-tiktok"
          />
        </a>
        <a href="https://www.patreon.com/user?u=100031016" target="_blank" style={{marginRight: "8px"}}>
          <FaPatreon
            style={{ padding: "7px" }}
            className="social-icon social-youtube fab fa-tiktok"
          />
        </a>
        <a style={{marginRight: "8px"}}
          href="https://www.youtube.com/channel/UCas3mrshXaOvCbteFFkYcPQ"
          target="_blank"
        >
          <FaYoutube
            style={{ padding: "7px" }}
            className="social-icon social-youtube fab fa-tiktok"
          />
        </a>
        <a href="https://www.tiktok.com/@bestpreispyro" target="_blank">
          <FaTiktok
            style={{ padding: "7px" }}
            className="social-icon social-youtube fab fa-tiktok"
          />
        </a>
      </div>
    </div>
  );
}

export default SocialIcons;
