import "./App.css";
import Home from "./components/Home";
import Product from "./components/Product";
import Shop from "./components/Shop";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context";
import Login from "./components/Login";
import Account from "./components/Account";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Wishlists from "./components/Wishlists";
import Pricealerts from "./components/Pricealerts";
import ContactForm from "./components/contactForm";
import BecomePartner from "./components/becomePartner";
import NewShop from "./components/newShop";
import Imprint from "./components/imprint";
import DataProtection from "./components/dataProtection";
import Blog from "./components/Blog";
import Article1 from "./components/blog-articles/Article1";
import About from "./components/about";
import Forgotpassword from "./components/Forgotpassword";
import AuthorizationPage from "./components/authorizationPage";
import NewsletterPage from "./components/newsletterPage";
import Agb from "./components/agb";
import Support from "./components/support";
import VirtualCartList from "./components/VirtualCartList";
import Gdpr from "./components/gdpr";
import Article2 from "./components/blog-articles/Article2";
//import ReactGA from "react-ga";

// entry point of the app system
function App() {
  //ReactGA.send(document.location.pathname);
  //rebuild for test
  return (
    <BrowserRouter>
      <UserProvider>
        <div className="App">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/datenschutz" element={<DataProtection />} />
            <Route path="/authorization" element={<AuthorizationPage />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/product" element={<Product />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<Forgotpassword />} />
            <Route path="/account" element={<Account />} />
            <Route path="/wishlists" element={<Wishlists />} />
            <Route path="/pricealerts" element={<Pricealerts />} />
            <Route path="/virtualcartlist" element={<VirtualCartList/>} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/partner" element={<BecomePartner />} />
            <Route path="/newsletter" element={<NewsletterPage />} />
            <Route path="/newshop" element={<NewShop />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/agb" element={<Agb />} />
            <Route path="/gdpr" element={<Gdpr/>} />
            <Route path="/support" element={<Support />} />
            <Route path="/datenschutz" element={<DataProtection />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/authorization" element={<AuthorizationPage />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/blog/article1" element={<Article1 />} />
            <Route exact path="/blog/article2" element={<Article2/>} />
          </Routes>
        </div>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
