import React from "react";

function ShopPageSwitcher(props) {
  const {
    handleNextPageClick,
    handlePrevPageClick,
    paginationInfo,
    prodcutResultNumber,
    showTotalResults,
    jumpTop
  } = props;
  return (
    <div className="toolbox toolbox-pagination justify-content-between">
      <p className="showing-info mb-2 mb-sm-0" style={{fontFamily: "poppins"}}>
        <span>
          Seite {paginationInfo?.currentPage}
          {showTotalResults &&
            ` | Produkte gefunden: ${paginationInfo?.totalCount}`}
        </span>
      </p>
      <ul className="pagination">
        {/* <li className="prev disabled"> */}
        <li className="prev" style={{ cursor: "pointer" }}>
          <a
            style={{
              padding: "10px 6px 10px 6px",
              backgroundColor: "#183544",
              color: "white",
              fontFamily: "Poppins",
              borderRadius: "4px",
            }}
            aria-label="Previous"
            tabIndex={-1}
            // aria-disabled="true"
            onClick={() => handlePrevPageClick(jumpTop)}
          >
            Vorherige
          </a>
        </li>

        <li className="next" style={{ cursor: "pointer" }}>
          <a
            aria-label="Next"
            onClick={() => handleNextPageClick(jumpTop)}
            style={{
              padding: "10px 6px 10px 6px",
              backgroundColor: "#183544",
              color: "white",
              fontFamily: "Poppins",
              borderRadius: "4px",
            }}
          >
            Nächste
          </a>
        </li>
      </ul>
    </div>
  );
}

export default ShopPageSwitcher;
