import React, { useEffect } from "react";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Footer from "./ui/footer/footer";
import Searchbar from "./includes/Searchbar";
import MiddleHeader from "./ui/headers/middleHeader";
import { Link } from "react-router-dom";
import Searchfield from "./ui/searchfield/Searchfield";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <main className="main">
        <div className="page-header"></div>

        <div className="page-content mb-12" style={{ marginTop: "2rem" }}>
          <div className="container pb-10 mb-2">
            <div className="main-content post-single-content">
              <div className="post post-grid post-single">
                <div className="post-details">
                  <div className="post-content">
                    <h2
                      className="title title-md font-weight-bold"
                      style={{ fontSize: "2.6rem" }}
                    >
                      Über Uns
                    </h2>
                    <p style={{ fontSize: "1.5rem" }}>
                      Eine Plattform, die tausende Produkte von über 100
                      Feuerwerk Shops und Händler vergleicht, gibt es nicht?
                      Doch, ab sofort unter dem Namen Best Preis Pyro! Ein zu
                      100% kostenfreier Preisvergleich, der die Preise
                      tagesaktuell widerspiegelt und direkt verlinkt. Das
                      Stöbern und Preisvergleichen war noch nie so einfach wie
                      jetzt!
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Mein Name ist Stefan, vielen auch bekannt als{" "}
                      <a href="https://pyroextrem.de/" target="_blank">
                        PyroExtrem!
                      </a>{" "}
                      Ich betreibe diese Plattform voller Leidenschaft, um den
                      maximalen Mehrwert für die Feuerwerks Szene zu schaffen.
                      Die Aussage “Von Pyros - für Pyros” war noch nie so ernst
                      zu nehmen wie bei diesem Projekt. Es steckt kein
                      Feuerwerkshändler oder Importeur hinter diesem Projekt,
                      sondern ein Feuerwerk Fanatiker, wie du es auch bist.{" "}
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Damit diese Plattform weiterhin werbefrei und ohne
                      Bevorzugung von einzelnen Shops und Händlern betrieben
                      werden kann, ist deine Unterstützung notwendig. Wie du
                      Best Preis Pyro unterstützen kannst, erfährst du hier:{" "}
                      <a href="/support">BPP Support</a>
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Freu dich auf weitere Features der Plattform in den
                      nächsten Jahren! Schau auf Social Media vorbei, abonniere
                      unseren Newsletter und erzähle deinen Feuerwerk Freunden
                      von der Plattform. Danke, dass du Teil von diesem Projekt
                      bist!{" "}
                    </p>
                    <p style={{ fontSize: "1.5rem" }}>
                      Du möchtest mehr erfahren zur Entstehung dieser Plattform?
                      Dann wirf gernen einen Blick auf diesen ausführlichen
                      Blogbeitrag:{" "}
                      <Link to={"/blog/article1"}>
                        Die Geschichte, Mission und Personen hinter Best Preis
                        Pyro
                      </Link>{" "}
                    </p>
                    <Link to="/support">
                      <button
                        className="btn btn-primary btn-rounded"
                        style={{ marginTop: "2rem" }}
                      >
                        Wie du uns unterstützen kannst
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default About;
