function isValidImageAddress(imageAddress) {
  const imageRegex = /^(https?|data):\/\/[^\s/$.?#].[^\s]*$/i;
  console.log(imageRegex.test(imageAddress));
  return imageRegex.test(imageAddress);
}

export function removeCommaFromEnd(thumbnail, image) {

  const removeCommaAndAfter = (url) => {
    console.log("hemlo");
    console.log(url);
    const commaIndex = url.indexOf(",");
    if (commaIndex !== -1) {
      console.log(url.slice(0, commaIndex));
      return url.slice(0, commaIndex); // Remove everything after the comma

    }

    return url; // No comma found, return the original URL
  };

  if (image) {
    if (!isValidImageAddress(image)) {
      return "invalid";
    }
    return removeCommaAndAfter(image);
  } else if (thumbnail) {
    if (!isValidImageAddress(thumbnail)) {
      return "invalid";
    }
    return removeCommaAndAfter(thumbnail);
  } else {
    return "invalid";
  }
}
