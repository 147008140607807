import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { removeCommaFromEnd } from "../utils";
import Footer from "./ui/footer/footer";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import imgh from "../images/mateusz-roman-4wM9P7c8its-unsplash.jpg";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import { UserContext } from "../context";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import ProductImage from "./ui/product-image/ProductImage";
import { GiFireworkRocket } from "react-icons/gi";
import Searchfield from "./ui/searchfield/Searchfield";
import checkBrandExists from "../utils/checkBrandExists";
import { FaRegBell } from "react-icons/fa";
import ConsentPopup from "./notification/ConsentPopup";
import io from "socket.io-client";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { useVirtualCartList } from "../hooks/useVirtualCartList";

const socket = io("https://monkfish-app-bxf3s.ondigitalocean.app", {
  withCredentials: true,
  transports: ["websocket", "polling"],
});

//hello world!

function Product() {
  const navigate = useNavigate();

  // const { productId } = useParams();

  const [state, setState] = useContext(UserContext);

  const [wishlists, setWishlists] = useState([]);

  const [enteredWishlists, setEnteredWishlists] = useState(false);
  const [showPushPermission, setshowPushPermission] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const productId = searchParams.get("productId");
  const [subscription, setSubscription] = useState(null);
  const [product, setProduct] = useState(null);

  const [loading, setLoading] = useState(false);
  const [productIsInWishlist, setProductIsInWishlist] = useState(false);
  const [productIsInPriceAlarm, setProductIsInPriceAlarm] = useState(false);
  //start remove later
  const subscribe = async () => {
    if (state && state.user.email) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/service-worker.js"
        );

        console.log(registration);
        const innerSub = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BLt7fkNArtyZBUqn9rfc6X_gc8YGD1BgYjeUXWXIHwz3psJ2MY6Kpa0uNyCalvB1Btr1t6fFcZBqiI7OGQZoYjo",
        });
        setSubscription(innerSub);

        socket.on("newData", (data) => {
          console.log(data);
          sendNotification(data, innerSub);
        });

        sendSubscribe(innerSub);

        // Convert the object to a JSON string
        const objectString = JSON.stringify(innerSub);

        // Store the JSON string in local storage
        localStorage.setItem("subscriptionfromLocalstorage", objectString);
      } catch (error) {
        console.error("Error subscribing:", error);
      }
    }
  };

  const sendSubscribe = async (subscriptionFromArgs) => {
    console.log("The subcription from the sendNotification ====> ", {
      subscription: subscription ? subscription : subscriptionFromArgs,
    });
    try {
      await fetch(`${process.env.REACT_APP_API_ENDPOINT_URL}/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: subscription ? subscription : subscriptionFromArgs,
        }),
      });
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const sendNotification = async (data, subscriptionFromArgs) => {
    console.log("The subcription from the sendNotification ====> ", {
      subscription: subscription ? subscription : subscriptionFromArgs,
    });
    try {
      await fetch(`${process.env.REACT_APP_API_ENDPOINT_URL}/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscription: subscription ? subscription : subscriptionFromArgs,
        }),
      });

      await fetch(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/sendNotification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            notification: {
              title: "Product alert",
              body: data,
            },
          }),
        }
      );
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  //stop remove later
  const getProduct = async () => {
    setLoading(true);
    console.log("The state of the product now  =====> ", product);

    try {
      const { data } = await axios.get(`/product/${productId}`);

      if (data.data.length === 0) {
        navigate(-1);
      }

      //console.log("Get products +===> ", data);

      setProduct(data.data[0]);

      //console.log("setting loading to false");
      //console.log(data.data[0]);
      setLoading(false);
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  const removeIframeTags = (inputString) => {
    if (inputString) return inputString.split("<iframe")[0];
  };

  const addToWishList = async (product_id) => {
    setLoading(true);
    setProduct(null);

    try {
      const { data } = await axios.post(`/wishlist`, {
        user_id: state.user.id,
        product_id: product_id,
      });

      if (data.message === "error") {
        toast.error(data.description);

        getProduct();
      } else if (data.message === "success") {
        //toast.success(data.description);

        getProduct();
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  const addToPriceAlerm = async (product_id) => {
    try {
      const { data } = await axios.post(`/add-price-alarm`, {
        email: state.user.email,
        product_id: product_id,
      });

      if (data.message === "error") {
        toast.error(data.description);

        checkIfProductIsInPriceAlermList(product_id);
      } else if (data.message === "success") {
        toast.success(data.description);

        checkIfProductIsInPriceAlermList(product_id);
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  const removeFromPriceAlerm = async (product_id) => {
    try {
      const { data } = await axios.delete(
        `/remove-price-alarm?email=${state.user.email}&product_id=${product_id}`
      );

      if (data.message === "error") {
        toast.error(data.description);

        checkIfProductIsInPriceAlermList(product_id);
      } else if (data.message === "success") {
        toast.success(data.description);

        checkIfProductIsInPriceAlermList(product_id);
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  const removeFromWishList = async (product_id) => {
    setLoading(true);
    setProduct(null);

    try {
      const { data } = await axios.delete(
        `/wishlist?user_id=${state.user.id}&product_id=${product_id}`
      );

      if (data.message === "error") {
        toast.error(data.description);

        getProduct();
      } else if (data.message === "success") {
        //toast.success(data.description);

        getProduct();
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  const checkIfProductIsInPriceAlermList = async (product_id) => {
    try {
      const { data } = await axios.get(
        `/get-single-price-alarm?email=${state.user.email}&product_id=${product_id}`
      );

      if (data.message === "error") {
        console.log(data.description);
      } else if (data.message === "success") {
        if (data.data) {
          setProductIsInPriceAlarm(true);
        } else {
          setProductIsInPriceAlarm(false);
        }

        console.log("Price alert list", data);
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  // Using the useVirtualCartList hook
  const { addItemToCart, removeItemFromCart, isProductInCart } =
    useVirtualCartList();

  const handleAddToCart = () => {
    if (state && state.token) {
      addItemToCart(productId);
    } else {
      navigate("/login");
    }
  };

  const handleRemoveFromCart = () => {
    if (state && state.token) {
      removeItemFromCart(productId);
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProduct();
    checkIfProductIsInPriceAlermList(productId);

    setProductIsInWishlist(wishlists.some((e) => e.id === productId));
  }, []);

  async function getUserWishlists() {
    if (state && state.user && state.user.id) {
      //console.log("The state of the user ====> ", state);
      try {
        const { data } = await axios.get(`/wishlist/${state.user.id}`);

        console.log(data);

        setWishlists(data.data);
        console.log("productId", productId);
        console.log(
          "wish list",
          data.data.some((e) => e.product_id === productId)
        );
        setProductIsInWishlist(
          data.data.some((e) => e.product_id === productId)
        );
        setEnteredWishlists(true);
      } catch (error) {
        console.log("An error occured ===> ", error);
      }
    }
  }

  useEffect(() => {
    getUserWishlists();
  }, [product]);

  const [existsInFirstArray, setExistsInFirstArray] = useState(false);

  useEffect(() => {
    // Calculate existsInFirstArray here
    if (wishlists.length > 0) {
      if (product) {
        const exists = wishlists.some(
          (firstItem) => firstItem.product_id === product.id
        );
        setExistsInFirstArray(exists);
      }
    }
  }, [wishlists, product]);

  const userLogInalert = () => {
    toast.info("Login to add to wishlist");
  };

  return (
    <>
      <div>
        <div className="page-wrapper">
          <header className="header">
            <TopNavbar />
            <MiddleHeader wishListCountFromParents={wishlists.length} />
            <Searchbar />
            <Searchfield />
          </header>
          <div>
            <main className="main mb-10 pb-1" style={{ marginTop: "48px" }}>
              {showPushPermission && <ConsentPopup subscribe={subscribe} />}
              <div className="page-content">
                {console.log(product)}
                <div className="container">
                  <div className="row gutter-lg">
                    <div className="main-content">
                      <div className="product product-single row">
                        {loading ? (
                          <>
                            <div className="col-md-6 mb-6">
                              <div className="product-gallery product-gallery-sticky">
                                <div
                                  className="swiper-container product-single-swiper swiper-theme nav-inner"
                                  data-swiper-options="{
                                      'navigation': {
                                          'nextEl': '.swiper-button-next',
                                          'prevEl': '.swiper-button-prev'
                                      }
                                  }"
                                >
                                  <div className="swiper-wrapper row cols-1 gutter-no">
                                    <div className="swiper-slide">
                                      <figure className="product-image">
                                        {/* {`${product.product_image}`} */}
                                        <Skeleton height={400} width={800} />
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4 mb-md-6">
                              <div
                                className="product-details"
                                data-sticky-options="{'minWidth': 767}"
                              >
                                <h1 className="product-title">
                                  <Skeleton />
                                </h1>
                                <div className="product-bm-wrapper">
                                  <div className="product-meta">
                                    <div className="product-categories">
                                      Kategorie:{" "}
                                      <span className="product-category">
                                        <a href="#">
                                          <Skeleton />
                                        </a>
                                      </span>
                                    </div>
                                    <div className="product-sku">
                                      Shop Name:{" "}
                                      <span>
                                        <Skeleton />
                                      </span>
                                    </div>
                                    <div className="product-sku">
                                      Hersteller:{" "}
                                      <span>
                                        <Skeleton />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <hr className="product-divider" />
                                <div className="product-price">
                                  <ins className="new-price">
                                    <Skeleton />
                                  </ins>
                                </div>
                                <div className="ratings-container"></div>
                                <div className="product-short-desc">
                                  <ul className="list-type-check list-style-none">
                                    <Skeleton />
                                  </ul>
                                </div>
                                <hr className="product-divider" />
                                <Skeleton />
                                <Skeleton />
                                <div className="fix-bottom product-sticky-content sticky-content">
                                  <div className="product-form container">
                                    <Skeleton />
                                  </div>
                                </div>
                                <div className="social-links-wrapper">
                                  <span className="divider d-xs-show" />
                                  <div className="product-link-wrapper d-flex">
                                    <Skeleton />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          product && (
                            <>
                              <div className="col-md-4 mb-4">
                                <ProductImage
                                  productName={product.product_name}
                                  imageUrl={
                                    removeCommaFromEnd(
                                      product.product_thumbnail,
                                      product.product_image
                                    ) === "invalid"
                                      ? imgh
                                      : removeCommaFromEnd(
                                          product.product_thumbnail,
                                          product.product_image
                                        )
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-4 mb-md-6">
                                <div
                                  className="product-details"
                                  data-sticky-options="{'minWidth': 767}"
                                >
                                  <h1 className="product-title">
                                    {product && product.product_name}
                                  </h1>
                                  <div className="product-bm-wrapper">
                                    <div className="product-meta">
                                      {checkBrandExists(
                                        product.product_brand
                                      ) && (
                                        <div className="product-categories">
                                          Hersteller:{" "}
                                          {checkBrandExists(
                                            product.product_brand
                                          )}
                                        </div>
                                      )}
                                      <div className="product-categories">
                                        Kategorie:{" "}
                                        <span className="product-category">
                                          {product && product.product_category}
                                        </span>
                                      </div>
                                      {product.product_info && (
                                        <div className="product-categories">
                                          Feuerwerkskategorie:{" "}
                                          <span className="product-category">
                                            {product && product.product_info}
                                          </span>
                                        </div>
                                      )}
                                      <div className="product-sku">
                                        Shop Name:{" "}
                                        <a
                                          href={product.shop.link}
                                          target="_blank"
                                        >
                                          {product &&
                                            capitalizeFirstLetter(
                                              product.product_shop_name
                                            )}
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <hr className="product-divider" />
                                  <div className="product-price">
                                    <ins className="new-price">
                                      {product &&
                                        (product.product_price <= 0 &&
                                        product.product_old_price > 0 ? (
                                          <>
                                            {product.product_old_price.toLocaleString(
                                              "de-AT",
                                              {
                                                style: "currency",
                                                currency: "EUR",
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <ins className="new-price">
                                              {product.product_price.toLocaleString(
                                                "de-AT",
                                                {
                                                  style: "currency",
                                                  currency: "EUR",
                                                }
                                              )}
                                            </ins>
                                            {product.product_old_price > 0 &&
                                              product.product_price !=
                                                product.product_old_price && (
                                                <del className="old-price">
                                                  {product.product_old_price.toLocaleString(
                                                    "de-AT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  )}
                                                </del>
                                              )}
                                          </>
                                        ))}
                                    </ins>
                                  </div>
                                  <div className="ratings-container"></div>
                                  <div className="product-short-desc">
                                    <ul className="list-type-check list-style-none">
                                      {product && (
                                        <>
                                          {removeIframeTags(
                                            product.product_text
                                          )}
                                        </>
                                      )}

                                      {product && (
                                        <p>
                                          <a
                                            href={
                                              product?.shop
                                                ?.shop_delivery_info_url
                                            }
                                            target="_blank"
                                          >
                                            Versandinformationen
                                          </a>
                                        </p>
                                      )}
                                    </ul>
                                  </div>

                                  <hr className="product-divider" />
                                  {product && product.product_caliber > 0 && (
                                    <>
                                      <div
                                        className="product-form product-variation-form product-color-swatch"
                                        style={{ marginBottom: "0px" }}
                                      >
                                        <label>Kaliber:</label>
                                        <label>
                                          {product.product_caliber}mm
                                        </label>
                                      </div>
                                    </>
                                  )}

                                  {product &&
                                    (product.product_availability !== null ||
                                      product.product_delivery_time !== null ||
                                      product.product_age_hint !== null) && (
                                      <div className="product-form product-variation-form product-size-swatch">
                                        <label className="mb-1">
                                          Produkt Info:
                                        </label>
                                        <div className="flex-wrap d-flex align-items-center product-variations">
                                          {product &&
                                            product.product_availability &&
                                            product?.product_availability !==
                                              product?.product_delivery_time && (
                                              <a className="size">
                                                {product.product_availability}
                                              </a>
                                            )}
                                          {product &&
                                            product.product_delivery_time && (
                                              <a className="size">
                                                {product.product_delivery_time}
                                              </a>
                                            )}
                                          {product &&
                                            product.product_age_hint && (
                                              <a href="#" className="size">
                                                {product.product_age_hint}
                                              </a>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  <div>
                                    <div
                                      className="product-form container"
                                      style={{ paddingLeft: "0" }}
                                    >
                                      {/* <div className="product-qty-form">
                          <div className="input-group">
                            <input
                              className="quantity form-control"
                              type="number"
                              min={1}
                              max={10000000}
                            />
                            <button className="quantity-plus w-icon-plus" />
                            <button className="quantity-minus w-icon-minus" />
                          </div>
                        </div> */}
                                      {product && product.product_link && (
                                        <a
                                          href={product.product_link}
                                          target="_blank"
                                        >
                                          <button
                                            className="btn btn-primary"
                                            style={{
                                              borderRadius: "4px",
                                              backgroundColor: "#183544",
                                              borderColor: "#183544",
                                            }}
                                          >
                                            <i className="w-icon-cart" />
                                            <span> Zum Shop</span>
                                          </button>
                                        </a>
                                      )}
                                      <div
                                        className="social-links-wrapper"
                                        style={{ marginLeft: "2rem" }}
                                      >
                                        <span className="divider d-xs-show" />
                                        <div className="product-link-wrapper d-flex">
                                          {productIsInWishlist ? (
                                            <a className="cart-toggle label-down link">
                                              <i
                                                className="w-icon-heart"
                                                onClick={() => {
                                                  removeFromWishList(
                                                    product.id
                                                  );
                                                  setProductIsInWishlist(false);
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "2.8rem",
                                                  color: "#F4442C",
                                                }}
                                              >
                                                <span />
                                              </i>
                                            </a>
                                          ) : (
                                            <a className="cart-toggle label-down link">
                                              <i
                                                className="w-icon-heart"
                                                onClick={() => {
                                                  if (state && state.token) {
                                                    addToWishList(product.id);
                                                    setProductIsInWishlist(
                                                      true
                                                    );
                                                  } else {
                                                    navigate("/login");
                                                  }
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "2.8rem",
                                                  color: "rgb(153, 153, 153)",
                                                }}
                                              >
                                                <span />
                                              </i>
                                            </a>
                                          )}
                                        </div>

                                        {productIsInPriceAlarm === true ? (
                                          <span className="icon-box-icon icon-orders">
                                            <FaRegBell
                                              style={{ cursor: "pointer" }}
                                              size={26}
                                              color="#F4442C"
                                              onClick={() => {
                                                removeFromPriceAlerm(
                                                  product.id
                                                );
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          <span className="icon-box-icon icon-orders">
                                            <FaRegBell
                                              size={26}
                                              style={{ cursor: "pointer" }}
                                              color="#999999"
                                              onClick={() => {
                                                console.log(
                                                  "hello unlogged",
                                                  state
                                                );
                                                if (state && state.token) {
                                                  addToPriceAlerm(product.id);
                                                } else {
                                                  navigate("/login");
                                                }
                                              }}
                                            />
                                          </span>
                                        )}
                                        <span>
                                          {product.db_id && (
                                            <MdOutlineAddShoppingCart
                                              size={28}
                                              color={
                                                isProductInCart(product.db_id)
                                                  ? "#F4442C"
                                                  : "#999999"
                                              }
                                              style={{ cursor: "pointer" }}
                                              onClick={
                                                isProductInCart(product.db_id)
                                                  ? handleRemoveFromCart
                                                  : handleAddToCart
                                              }
                                            />
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        fontFamily: "Poppins",
                                        marginTop: "2rem",
                                      }}
                                    >
                                      <div>
                                        {product?.db_id && <div>
                                        <MdOutlineAddShoppingCart
                                            style={{ display: "inline" }}
                                            size={16}
                                            color="#999999"
                                          />
                                          <span>
                                            Du willst wissen, wo du dieses
                                            Produkt zusammen mit weiteren
                                            Produkten als gesamten Warenkorb am
                                            günstigsten bzw. bei dir in der Nähe
                                            bekommst? Dann setze das Produkt
                                            jetzt auf deine virtuelle
                                            Warenkorb-Liste! (kostenlose
                                            Registrierung notwendig)
                                          </span>
                                        </div>}
                                        <span>
                                          <i
                                            className="w-icon-heart"
                                            style={{
                                              display: "inline",
                                              fontSize: "1.2rem",
                                              color: "#999999",
                                            }}
                                          />
                                        </span>{" "}
                                        <span>
                                          Setze das Produkt jetzt auf deine
                                          Wishlist! Sobald das Produkt wieder
                                          verfügbar ist, erhältst du eine E-Mail
                                          von uns!*
                                        </span>
                                      </div>
                                      <div>
                                        <span>
                                          <FaRegBell
                                            style={{ display: "inline" }}
                                            size={11}
                                            color="#999999"
                                          />
                                        </span>{" "}
                                        <span>
                                          Setze das Produkt jetzt auf deine
                                          Preisalarm-Liste! Sobald sich der
                                          Preis des Produkts ändert, erhältst du
                                          eine E-Mail von uns!*
                                        </span>
                                      </div>

                                      <div>
                                        *Neben E-Mails hast du auch die
                                        Möglichkeit Browser
                                        Push-Benachrichtigungen zu aktivieren -
                                        du musst allerdings dem Erhalt dieser
                                        Benachrichtigungen zustimmen. Noch nicht
                                        geschehen? Hol das{" "}
                                        <a
                                          onClick={() =>
                                            setshowPushPermission(true)
                                          }
                                        >
                                          hier
                                        </a>{" "}
                                        gerne nach.{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                    {/* End of Main Content */}
                    <aside className="sidebar product-sidebar sidebar-fixed right-sidebar sticky-sidebar-wrapper">
                      <div className="sidebar-overlay" />

                      <div className="sidebar-content scrollable">
                        <div className="sticky-sidebar">
                          <div className="widget widget-icon-box mb-6">
                            <div
                              className="icon-box icon-box-side text-dark"
                              style={{
                                padding: "2.5rem 0 2.4rem",
                              }}
                            >
                              <span className="icon-box-icon icon-shipping">
                                <i className="w-icon-sale" />
                              </span>
                              <div className="icon-box-content">
                                <h4 className="icon-box-title font-weight-bolder">
                                  Kostenlos
                                </h4>
                                <p className="text-default">
                                  <a
                                    href="https://www.patreon.com/user?u=100031016"
                                    target="_blank"
                                  >
                                    Jetzt unterstützen!
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div
                              className="icon-box icon-box-side text-dark"
                              style={{ padding: "2.5rem 0 2.4rem" }}
                            >
                              <span className="icon-box-icon icon-payment">
                                <i className="w-icon-store" />
                              </span>
                              <div className="icon-box-content">
                                <h4 className="icon-box-title font-weight-bolder">
                                  Über 100 Händler
                                </h4>
                                <p className="text-default">
                                  Unabhängiger Preisvergleich
                                </p>
                              </div>
                            </div>
                            <div
                              className="icon-box icon-box-side text-dark icon-box-money my-icon"
                              style={{
                                padding: "2.5rem 0 2.4rem",
                                marginRight: "2.5rem",
                              }}
                            >
                              <span className="icon-box-icon icon-money">
                                <GiFireworkRocket size={32} />
                              </span>
                              <div className="icon-box-content">
                                <h4 className="icon-box-title font-weight-bolder">
                                  Tausende Produkte
                                </h4>
                                <p className="text-default">
                                  Täglich aktualisiert
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* End of Widget Icon Box */}
                          <a
                            href="https://www.amazon.de/Lurch-10890-Butangasbrenner/dp/B00083BI8M?&_encoding=UTF8&tag=bestpp0a-21&linkCode=ur2&linkId=5d6b259085b36b6ac7983cdba75ff085&camp=1638&creative=6742"
                            target="_blank"
                          >
                            <div className="widget widget-banner mb-9">
                              <div className="banner banner-fixed br-sm">
                                <figure>
                                  <img
                                    src="assets/images/shop/lurch_image.jpeg"
                                    alt="Lurch Feuerzeug kaufen"
                                    width={266}
                                    height={220}
                                    style={{ backgroundColor: "#1D2D44" }}
                                  />
                                </figure>
                                <div className="banner-content">
                                  <div
                                    className="banner-price-info text-white font-weight-bolder lh-1 ls-25"
                                    style={{
                                      fontSize: "3.6rem",
                                      color: "white",
                                      marginRight: "1rem",
                                      marginBottom: "1rem",
                                      textShadow: "1px 1px 4px black",
                                    }}
                                  >
                                    Das beste Feuerzeug für Silvester
                                  </div>
                                  <h4
                                    className="banner-subtitle  font-weight-bolder mb-0"
                                    style={{
                                      color: "white",
                                      display: "inline-block",
                                      backgroundColor: "#F4442C",
                                      padding: "1rem 2rem",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    #teamlurch
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </a>
                          {/* End of Widget Banner */}
                        </div>
                      </div>
                    </aside>
                    <a
                      href="https://www.amazon.de/Lurch-10890-Butangasbrenner/dp/B00083BI8M?&_encoding=UTF8&tag=bestpp0a-21&linkCode=ur2&linkId=5d6b259085b36b6ac7983cdba75ff085&camp=1638&creative=6742"
                      target="_blank"
                      id="lurch_ad"
                    >
                      <div className="widget widget-banner mb-9">
                        <div className="banner banner-fixed br-sm">
                          <figure>
                            <img
                              src="assets/images/shop/lurch_image.jpeg"
                              alt="Banner"
                              width={266}
                              height={220}
                              style={{ backgroundColor: "#1D2D44" }}
                            />
                          </figure>
                          <div className="banner-content">
                            <div
                              className="banner-price-info font-weight-bolder lh-1 ls-25"
                              style={{
                                fontSize: "3.6rem",
                                color: "white",
                                marginRight: "1rem",
                                marginBottom: "1rem",
                                textShadow: "1px 1px 4px black",
                              }}
                            >
                              Das beste Feuerzeug für Silvester
                            </div>
                            <h4
                              className="banner-subtitle font-weight-bolder mb-0"
                              style={{
                                color: "white",
                                display: "inline-block",
                                backgroundColor: "#F4442C",
                                padding: "1rem 2rem",
                                borderRadius: "4px",
                              }}
                            >
                              Hier klicken!
                            </h4>
                          </div>
                        </div>
                      </div>
                    </a>
                    {/* End of Sidebar */}
                  </div>
                </div>
              </div>
              {/* End of Page Content */}
            </main>
          </div>
          {/* End of Main */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Product;
