import React, {useEffect, useContext} from "react";
import TopNavbar from "../ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "../ui/headers/middleHeader";
import Searchbar from "../includes/Searchbar";
import Footer from "../ui/footer/footer";
import { Link } from "react-router-dom";
import Banner from "../../images/virtueller_warenkorb_banner.png";
import Searchfield from "../ui/searchfield/Searchfield";
import { UserContext } from "../../context";


function Article2() {
  const [state, setState] = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <main className="main">
        <div className="page-header">
          <div className="container">
            <h1
              className="page-title mb-0 text-5xl font-bold"
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              Willkommen zurück zur heißen Phase 2024! Was ist neu?{" "}
            </h1>
          </div>
        </div>

        <div className="page-content mb-12" style={{ marginTop: "2rem" }}>
          <div className="container pb-10 mb-2">
            <div className="main-content post-single-content">
              <div className="post post-grid post-single">
                <figure className="post-media br-sm">
                  <img src={Banner} alt="Blog" width="930" height="500" />
                </figure>
                <div className="post-details">
                  <div className="post-meta">
                    von <a className="post-author">Stefan Schiller</a>-{" "}
                    <a className="post-date">09.10.2024</a>
                  </div>

                  <div>
                    <p>
                      Das ganze Jahr über haben wir im BPP-Team intensiv an der
                      nagelneuen {state && state.user?<Link to="/virtualcartlist" className="text-[#F4442C]">virtuellen Warenkorb Funktion</Link>: <Link to="/login" className="text-[#F4442C]">virtuellen Warenkorb Funktion</Link>} getüftelt! Uns
                      war es ein persönliches Anliegen, den Preisvergleich nicht
                      nur auf das einzelne Produkte zu reduzieren, wir wollten
                      einen so umfangreichen und fairen Vergleich wie möglich
                      schaffen. Alles unter der Prämisse, dass mit dieser
                      Funktion ein Mehrwert geschaffen wir, für alle Nutzer,
                      aber auch für alle Händler und Shops, die hierbei
                      mitmachen möchten.
                    </p>
                    <br />
                    <p>
                      Füge ganz einfach bei der klassischen Produktsuche bzw.
                      Übersicht Artikel zu deinem virtuellen Warenkorb hinzu
                      (geht nur mit kostenlosen Account). Im Anschluss kannst du
                      dann den ganzen Warenkorb mit all deinen Wunschprodukten
                      sehen.<strong> Doch das BESTE kommt erst noch</strong> . Es
                      werden dir nun Onlineshops bzw. Händler in deiner Nähe
                      (PLZ-Eingrenzung möglich) angezeigt, die eine große Anzahl
                      deiner Wunschprodukte aktuell verfügbar im Sortiment
                      haben. Für einzelne nicht vorhandene Produkte sucht unser
                      BPP-Algorithmus passende ähnliche Produkte im gesamten
                      Sortiment des Händlers, damit du möglichst nah an deine
                      ursprüngliche Auswahl herankommst.
                    </p>
                    <br />
                    <p>
                      Zum Launch sind bereits erste Händler und Onlineshops am
                      Start, weitere werden kontinuierlich folgen. Das
                      Besondere: wir fahren zu jedem dieser Händler bzw. Shops
                      und erstellen ausführliche Vorstellungen. Die
                      Feuerwerkshändler-Landschaft in Deutschland ist so
                      vielfältig wie das Angebot selbst, das zu zeigen wird
                      unsere Mission für die heiße Phase 2024. Um hier nichts zu
                      verpassen, auf jeden Fall den <Link to="/newsletter" className="text-[#F4442C]">Newsletter</Link>  abonnieren sowie
                      auf Social Media (siehe Footer) folgen.
                    </p>
                    <p>
                      Aussicht 2025: Für das nächste Jahr werden wir in den
                      intensiven Austausch mit allen gelisteten Händlern und
                      Shops gehen, damit im virtuellen Warenkorb auch
                      Versandkosten sowie eventuelle Frühbestellerrabatte
                      berücksichtigt werden. Die Umsetzung für 2024 war aus
                      vielerlei Hinsicht leider noch nicht umsetzbar, macht das
                      kommende Jahr für alle aber dann nochmal spannender. Wenn
                      du uns bei unserer Arbeit unterstützen willst, würde es
                      uns unglaublich freuen, wenn du uns über <a href="https://www.patreon.com/bestpreispyro" target="_blank" className="text-[#F4442C]">Patreon </a>
                       unterstützt.{" "}
                    </p>
                    <br />
                    <p>
                      Jetzt aber erstmal ganz viel Spaß beim Stöbern,
                      Vergleichen und Shoppen! Euer Stefan & das gesamte
                      BPP-Team
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Article2;
