import React, { useState } from "react";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import VCartMatchedProductList from "./vCartMatchedProductList";
import haversine from "haversine-distance"; // Import the haversine library
import { ClipLoader } from "react-spinners";
import { useVirtualCartList } from "../../../hooks/useVirtualCartList";

const VirtualCartMatchingView = (props) => {
  const { loadingMatchingData } = useVirtualCartList();
  console.log(loadingMatchingData);
  const { shops } = props;
  const [expandedShops, setExpandedShops] = useState([]);
  const [removedProducts, setRemovedProducts] = useState([]);
  const [showSimilarProducts, setShowSimilarProducts] = useState(true);
  const [showPickUpShops, setShowPickUpShops] = useState(false);
  const [location, setLocation] = useState(""); // State for location input
  const [locationSuggestions, setLocationSuggestions] = useState([]); // State for suggestions
  const [selectedLatLng, setSelectedLatLng] = useState(null); // State for lat/lng
  const [radius, setRadius] = useState(10); // State for radius dropdown

  // Fetch location suggestions based on input and limit to Germany
  const fetchLocationSuggestions = async (input) => {
    if (input.length > 2) {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          input
        )}&format=json&addressdetails=1&limit=5&countrycodes=DE`
      );
      const data = await response.json();
      setLocationSuggestions(data); // Update the suggestions state
    } else {
      setLocationSuggestions([]); // Clear suggestions if input is too short
    }
  };

  // Handle input change and fetch suggestions
  const handleLocationInputChange = (e) => {
    setLocation(e.target.value);
    fetchLocationSuggestions(e.target.value);
  };

  // Handle location selection and set lat/lng
  const handleLocationSelect = (suggestion) => {
    setLocation(`${suggestion.display_name}`);
    setSelectedLatLng({
      lat: suggestion.lat,
      lon: suggestion.lon,
    });
    setLocationSuggestions([]); // Clear suggestions after selection
  };

  const toggleExpand = (shopName) => {
    if (expandedShops.includes(shopName)) {
      setExpandedShops(expandedShops?.filter((name) => name !== shopName));
    } else {
      setExpandedShops([...expandedShops, shopName]);
    }
  };

  const findDuplicateDbIds = (products) => {
    const dbIdGroups = products.reduce((acc, product) => {
      if (!acc[product.db_id]) {
        acc[product.db_id] = [];
      }
      acc[product.db_id].push(product);
      return acc;
    }, {});

    // Find db_ids where there are multiple products with the same db_id but different names
    return Object.keys(dbIdGroups)?.filter((db_id) => {
      const productsWithSameDbId = dbIdGroups[db_id];
      // Check if products have different names
      const uniqueNames = new Set(
        productsWithSameDbId.map((product) =>
          product.product_name.toLowerCase()
        )
      );
      return uniqueNames.size > 1; // Only add to duplicateDbIds if the names are different
    });
  };

  const getNumberUniqueProducts = (products) => {
    const dbIdCounts = products.reduce((acc, product) => {
      acc[product.db_id] = (acc[product.db_id] || 0) + 1;
      return acc;
    }, {});
    return Object.keys(dbIdCounts).length;
  };

  const getTotalShopPrice = (products) => {
    let totalPrice = 0;
    const productGroups = {};

    // Group products by product_reference
    products.forEach((product) => {
      if ("product_reference" in product && product.product_reference) {
        const reference = product.product_reference;
        if (!productGroups[reference]) {
          productGroups[reference] = [];
        }
        productGroups[reference].push(product.product_data.product_price);
      } else {
        // For products without product_reference, add the individual price directly
        totalPrice += product.product_price;
      }
    });

    // Calculate average price for each product group and add to total price
    Object.values(productGroups).forEach((group) => {
      const averagePrice =
        group.reduce((sum, price) => sum + price, 0) / group.length;
      totalPrice += averagePrice;
    });

    return totalPrice;
  };

  const handleShowPickUpShops = () => {
    setShowPickUpShops(!showPickUpShops);
  };

  const handleShowSimilarProductsChange = () => {
    setShowSimilarProducts(!showSimilarProducts);
  };

  const handleRemoveProduct = (productId) => {
    setRemovedProducts([...removedProducts, productId]);
  };

  const calculateDistance = (shopLat, shopLng) => {
    if (!selectedLatLng) return null;

    const userCoords = { lat: selectedLatLng.lat, lon: selectedLatLng.lon };
    const shopCoords = { lat: shopLat, lon: shopLng };

    return haversine(userCoords, shopCoords); // Distance in meters
  };

  const filterShopsByRadius = (shops) => {
    if (!selectedLatLng || location.trim() === "") {
      return shops;
    }
    return shops?.filter((shop) => {
      const distance = calculateDistance(shop.lat, shop.lng);
      return distance !== null && distance / 1000 <= radius;
    });
  };

  const filteredShops = filterShopsByRadius(
    shops?.filter((shop) => !showPickUpShops || shop.shopPickUp)
  );

  return (
    <div style={{ padding: "20px", fontFamily: "Poppins" }}>
      {shops?.length > 0 ? (
        <div className="flex flex-col gap-2 mb-5 p-4 bg-white rounded-lg shadow-md">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex items-center flex-wrap">
              <label
                htmlFor="similarProducts"
                className="mr-2 text-md font-medium text-gray-800 mt-4 sm:mt-0"
              >
                Zeige ähnliche Produkte
              </label>
              <input
                type="checkbox"
                id="similarProducts"
                checked={showSimilarProducts}
                onChange={handleShowSimilarProductsChange}
                className="mr-5 scale-110 cursor-pointer transition-transform duration-300 ease-in-out text-[#183544] mt-4 sm:mt-0"
              />

              <label
                htmlFor="availability"
                className="mr-2 text-md font-medium text-gray-800  mt-4 sm:mt-0"
              >
                Shops mit Abholmöglichkeit
              </label>
              <input
                type="checkbox"
                id="availability"
                checked={showPickUpShops}
                onChange={handleShowPickUpShops}
                className="mr-5 scale-110 cursor-pointer transition-transform duration-300 ease-in-out text-[#183544] mt-4 sm:mt-0"
              />

              {/* Location Input Field */}
              <div className="relative">
                <input
                  type="text"
                  value={location}
                  onChange={handleLocationInputChange}
                  placeholder="Postleitzahl eingeben"
                  className="p-2 border border-gray-300 rounded-md mr-2 w-80 mt-4 sm:mt-0"
                />
                {/* Suggestions Dropdown */}
                {locationSuggestions.length > 0 && (
                  <ul className="absolute top-full left-0 z-10 bg-white border border-gray-300 w-full rounded-md max-h-36 overflow-y-auto">
                    {locationSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className="p-2 cursor-pointer border-b border-gray-100 hover:bg-gray-100"
                        onClick={() => handleLocationSelect(suggestion)}
                      >
                        {suggestion.display_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Radius Dropdown */}
              <select
                value={radius}
                onChange={(e) => setRadius(e.target.value)}
                className="p-2 border border-gray-300 rounded-md mr-5 mt-4 sm:mt-0"
              >
                <option value={10}>10 km</option>
                <option value={20}>20 km</option>
                <option value={50}>50 km</option>
                <option value={100}>100 km</option>
              </select>
            </div>
          </div>
        </div>
      ) : loadingMatchingData === true ? (
        <>
          <span>
            Es wurden keine Shops gefunden, die die Mindestanzahl an Produkten
            aus deiner virtuellen Warenkorbsliste führen. Entferne Produkte aus deiner virtuellen Warenkorbsliste und lade die Seite neu!
          </span>
        </>
      ) : (
        <div
          className="loading-container"
          style={{
            textAlign: "center",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader color={"#123abc"} loading={true} size={50} />
          {console.log(shops)}
          {shops?.length === 0 && (
            <span className="mt-5">
              Es wurden keine Shops gefunden, die die Mindestanzahl an Produkten
              aus deiner virtuellen Warenkorbsliste führen. Passe deine
              virtuelle Warenkorbsliste an!
            </span>
          )}
        </div>
      )}

      {filteredShops?.map((shop, index) => {
        const filteredProducts = shop.shopMatchingProducts.filter(
          (product) => !removedProducts.includes(product.product_id)
        );

        const filteredSimilarProducts = shop.shopSimilarProducts.filter(
          (product) =>
            !removedProducts.includes(product.product_data.product_id)
        );

        const duplicateDbIds = findDuplicateDbIds(filteredProducts);
        return (
          <>
            <VCartMatchedProductList
              key={index}
              shop={shop}
              filteredProducts={filteredProducts}
              expandedShops={expandedShops}
              toggleExpand={toggleExpand}
              duplicateDbIds={duplicateDbIds}
              handleRemoveProduct={handleRemoveProduct}
              capitalizeFirstLetter={capitalizeFirstLetter}
              getNumberUniqueProducts={getNumberUniqueProducts}
              getTotalShopPrice={getTotalShopPrice}
              similarProducts={filteredSimilarProducts}
              showSimilarProducts={showSimilarProducts}
            />
          </>
        );
      })}
    </div>
  );
};

export default VirtualCartMatchingView;
