import { useEffect, useState } from "react";
import "./styles.css";

export default function Countdown() {
  let [leftdays, setLeftDays] = useState(null);
  let [lefthr, setLefthr] = useState(null);
  let [leftmin, setLeftMins] = useState(null);
  let [leftsec, setLeftSec] = useState(null);

  useEffect(() => {
    let interval = setInterval(() => {
      const newyr = new Date("January 1, 2025 00:00:00");
      const newyeartime = newyr.getTime();
      const todaytm = new Date();
      const todaytime = todaytm.getTime();
      const timeleft = newyeartime - todaytime;
      setLeftDays(Math.floor(timeleft / (24 * 60 * 60 * 1000)));
      setLefthr(23 - todaytm.getHours());
      setLeftMins(59 - todaytm.getMinutes());
      setLeftSec(59 - todaytm.getSeconds());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  if (leftdays <= -1) {
    return (
      <div className="countdown">
        <h1>Happy New Year!!!</h1>
      </div>
    );
  }

  return (
    <div className="countdown font-bold">
      <section>
        <div className="wrapper">
          <h2>Silvester Countdown:</h2>
        </div>
        <div className="wrapper">
          <h2>{leftdays}t</h2>
        </div>
        <div className="wrapper">
          <h2>{lefthr}h</h2>
        </div>
        <div className="wrapper">
          <h2>{leftmin}min</h2>
        </div>
      </section>
    </div>
  );
}
