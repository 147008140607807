import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import UserRoute from "./routes/UserRoute";
import { UserContext } from "../context";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import Footer from "./ui/footer/footer";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./ui/account/Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-grid-system";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import { removeCommaFromEnd } from "../utils";
import imgh from "../images/mateusz-roman-4wM9P7c8its-unsplash.jpg";
import Skeleton from "react-loading-skeleton";

function Wishlists() {
  const [state, setState] = useContext(UserContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [pgSize, setPgSize] = useState(30);

  const [pgNumber, setPgNumber] = useState(1);

  const [products, setProducts] = useState([]);

  const logout = () => {
    setState({
      user: {},
      token: "",
    });

    // save in local storage
    window.localStorage.removeItem("auth");

    navigate("/");
  };

  const [wishlists, setWishlists] = useState([]);

  async function getUserWishlists() {
    if (state && state.user && state.user.id) {
      setLoading(true);
      console.log("The state of the user ====> ", state);
      try {
        const { data } = await axios.get(
          `/readAllWishlistWithDetails/${state.user.id}`
        );

        console.log(data);

        // Sort the products array in ascending order based on product_price
        const sortedProducts = data.data.sort(
          (a, b) => a.product_price - b.product_price
        );

        setLoading(false);

        setProducts(sortedProducts);

        console.log(`sortedProducts`, sortedProducts);
      } catch (error) {
        console.log("An error occured ===> ", error);
      }
    }
  }

  const removeFromWishList = async (product_id) => {
    setLoading(true)
    try {
      const { data } = await axios.delete(
        `/wishlist?user_id=${state.user.id}&product_id=${product_id}`
      );

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        //toast.success(data.description);

        getUserWishlists();
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  useEffect(() => {
    getUserWishlists();
  }, [state]);

  return (
    <UserRoute>
      <div>
        <div className="page-wrapper">
          {/* Start of Header */}
          <header className="header">
            <TopNavbar />

            <MiddleHeader />

            <Searchbar />
          </header>
          {/* End of Header */}
          {/* Start of Main */}
          <main className="main">
            {/* Start of Page Header */}
            <div className="page-header">
              <div className="container">
                <h1 className="page-title mb-0">Account</h1>
              </div>
            </div>
            {/* End of Page Header */}
            {/* Start of Breadcrumb */}
            <nav className="breadcrumb-nav">
              <div className="container">
                <ul className="breadcrumb"></ul>
              </div>
            </nav>
            {/* End of Breadcrumb */}
            {/* Start of PageContent */}
            <div className="page-content pt-2">
              <div className="container">
                <div className="tab tab-vertical row gutter-lg">
                  <Sidebar logout={logout} />
                  <div
                    className="tab-content mb-6"
                    style={{ borderTop: "none" }}
                  >
                    <>
                      <Container fluid>
                        <Row style={{ borderBottom: "1px solid #EEEEEE" }}>
                          <Col></Col>
                          <Col md={2}>
                            <h3 className="product-name">Kategorie/Shop</h3>
                          </Col>
                          <Col md={3}>
                            <h3 className="product-name">Produkt Name</h3>
                          </Col>
                          <Col md={3}>
                            <h3 className="product-name">Preis</h3>
                          </Col>
                          <Col md={2}></Col>
                        </Row>
                      </Container>
                      <Container fluid>
                        {loading ? (
                          <div>
                            <Row>
                              <Col
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <figure className="product-media">
                                  <Skeleton height={64} width={150} />
                                </figure>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div>
                                  <Skeleton />
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={3}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div className="product-pa-wrapper">
                                  <div className="product-price">
                                    <Skeleton />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                md={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Skeleton />
                                <div className=" btn-wishlist ">
                                  <Skeleton />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <figure className="product-media">
                                  <Skeleton height={64} width={150} />
                                </figure>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div>
                                  <Skeleton />
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={3}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div className="product-pa-wrapper">
                                  <div className="product-price">
                                    <Skeleton />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                md={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Skeleton />
                                <div className=" btn-wishlist ">
                                  <Skeleton />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <figure className="product-media">
                                  <Skeleton height={64} width={150} />
                                </figure>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div>
                                  <Skeleton />
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={3}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div className="product-pa-wrapper">
                                  <div className="product-price">
                                    <Skeleton />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                md={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Skeleton />
                                <div className=" btn-wishlist ">
                                  <Skeleton />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <figure className="product-media">
                                  <Skeleton height={64} width={150} />
                                </figure>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div>
                                  <Skeleton />
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={3}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div className="product-pa-wrapper">
                                  <div className="product-price">
                                    <Skeleton />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                md={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Skeleton />
                                <div className=" btn-wishlist ">
                                  <Skeleton />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <figure className="product-media">
                                  <Skeleton height={64} width={150} />
                                </figure>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div>
                                  <Skeleton />
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={3}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                              </Col>
                              <Col
                                md={2}
                                style={{
                                  border: "1px solid #EEEEEE",
                                }}
                              >
                                <div className="product-pa-wrapper">
                                  <div className="product-price">
                                    <Skeleton />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                md={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Skeleton />
                                <div className=" btn-wishlist ">
                                  <Skeleton />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          products &&
                          products.length > 0 &&
                          products.map((product) => {
                            if (
                              product.product_price > 0 ||
                              product.product_old_price > 0
                            ) {
                              if (state && state.user && state.user.id) {
                                return (
                                  <div key={product.id}>
                                    <Row>
                                      <Col
                                        style={{
                                          border: "1px solid #EEEEEE",
                                        }}
                                      >
                                        <figure className="product-media">
                                          <Link
                                            to={`/product?productId=${product.product_id}`}
                                          >
                                            <img
                                              src={
                                                removeCommaFromEnd(
                                                  product.product_thumbnail,
                                                  product.product_image
                                                ) === "invalid"
                                                  ? imgh
                                                  : removeCommaFromEnd(
                                                      product.product_thumbnail,
                                                      product.product_image
                                                    )
                                              }
                                              alt="Product"
                                              width={150}
                                              height={150}
                                              style={{
                                                objectFit: "contain",
                                                height: "64px",
                                              }}
                                            />
                                          </Link>
                                        </figure>
                                      </Col>
                                      <Col
                                        md={2}
                                        style={{
                                          border: "1px solid #EEEEEE",
                                        }}
                                      >
                                        <div>
                                          <a href="#" style={{ color: "#999" }}>
                                            {product.product_category}
                                          </a>
                                        </div>
                                        <h3 className="product-name">
                                          <a href="#">
                                            {capitalizeFirstLetter(
                                              product.product_shop_name
                                            )}
                                          </a>
                                        </h3>
                                      </Col>
                                      <Col
                                        md={3}
                                        style={{
                                          border: "1px solid #EEEEEE",
                                        }}
                                      >
                                        <h3 className="product-name">
                                          <a href="#">{product.product_name}</a>
                                        </h3>
                                      </Col>
                                      <Col
                                        md={2}
                                        style={{
                                          border: "1px solid #EEEEEE",
                                        }}
                                      >
                                        <div className="product-pa-wrapper">
                                          <div className="product-price">
                                            {product.product_price <= 0 &&
                                            product.product_old_price > 0 ? (
                                              <>
                                                <ins className="new-price">
                                                  {product.product_old_price.toLocaleString(
                                                    "de-AT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  )}
                                                </ins>
                                              </>
                                            ) : (
                                              <>
                                                <ins className="new-price">
                                                  {product.product_price.toLocaleString(
                                                    "de-AT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  )}
                                                </ins>
                                                {product.product_old_price >
                                                  0 && (
                                                  <del className="old-price">
                                                    {product.product_old_price.toLocaleString(
                                                      "de-AT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )}
                                                  </del>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </Col>

                                      <Col
                                        md={3}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {product.product_link && (
                                          <Link
                                            to={`/product?productId=${product.product_id}`}
                                          >
                                            <button
                                              className="btn btn-primary"
                                              style={{
                                                backgroundColor: "#183544",
                                                borderColor: "#183544",
                                                borderRadius: "4px"
                                              }}
                                            >
                                              <span>Details</span>
                                            </button>
                                          </Link>
                                        )}
                                        <div
                                          className="btn-product-icon btn-wishlist w-icon-heart-full"
                                          onClick={() =>
                                            removeFromWishList(product.product_id)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#F4442C",
                                            border: "none",
                                          }}
                                        >
                                          <span />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              }
                            }
                          })
                        )}
                      </Container>
                    </>
                    <div className="tab-pane mb-4" id="account-orders">
                      <div className="icon-box icon-box-side icon-box-light">
                        <span className="icon-box-icon icon-orders">
                          <i className="w-icon-orders" />
                        </span>
                        <div className="icon-box-content">
                          <h4 className="icon-box-title text-capitalize ls-normal mb-0">
                            Orders
                          </h4>
                        </div>
                      </div>
                      <table className="shop-table account-orders-table mb-6">
                        <thead>
                          <tr>
                            <th className="order-id">Order</th>
                            <th className="order-date">Date</th>
                            <th className="order-status">Status</th>
                            <th className="order-total">Total</th>
                            <th className="order-actions">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="order-id">#2321</td>
                            <td className="order-date">August 20, 2021</td>
                            <td className="order-status">Processing</td>
                            <td className="order-total">
                              <span className="order-price">$121.00</span> for
                              <span className="order-quantity"> 1</span> item
                            </td>
                            <td className="order-action">
                              <a
                                href="#"
                                className="btn btn-outline btn-default btn-block btn-sm btn-rounded"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="order-id">#2321</td>
                            <td className="order-date">August 20, 2021</td>
                            <td className="order-status">Processing</td>
                            <td className="order-total">
                              <span className="order-price">$150.00</span> for
                              <span className="order-quantity"> 1</span> item
                            </td>
                            <td className="order-action">
                              <a
                                href="#"
                                className="btn btn-outline btn-default btn-block btn-sm btn-rounded"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="order-id">#2319</td>
                            <td className="order-date">August 20, 2021</td>
                            <td className="order-status">Processing</td>
                            <td className="order-total">
                              <span className="order-price">$201.00</span> for
                              <span className="order-quantity"> 1</span> item
                            </td>
                            <td className="order-action">
                              <a
                                href="#"
                                className="btn btn-outline btn-default btn-block btn-sm btn-rounded"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="order-id">#2318</td>
                            <td className="order-date">August 20, 2021</td>
                            <td className="order-status">Processing</td>
                            <td className="order-total">
                              <span className="order-price">$321.00</span> for
                              <span className="order-quantity"> 1</span> item
                            </td>
                            <td className="order-action">
                              <a
                                href="#"
                                className="btn btn-outline btn-default btn-block btn-sm btn-rounded"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <a
                        href="shop-banner-sidebar.html"
                        className="btn btn-dark btn-rounded btn-icon-right"
                      >
                        Go Shop
                        <i className="w-icon-long-arrow-right" />
                      </a>
                    </div>
                    <div className="tab-pane" id="account-downloads">
                      <div className="icon-box icon-box-side icon-box-light">
                        <span className="icon-box-icon icon-downloads mr-2">
                          <i className="w-icon-download" />
                        </span>
                        <div className="icon-box-content">
                          <h4 className="icon-box-title ls-normal">
                            Downloads
                          </h4>
                        </div>
                      </div>
                      <p className="mb-4">No downloads available yet.</p>
                      <a
                        href="shop-right-sidebar-2.html"
                        className="btn btn-dark btn-rounded btn-icon-right"
                      >
                        Go Shop
                        <i className="w-icon-long-arrow-right" />
                      </a>
                    </div>
                    <div className="tab-pane" id="account-addresses">
                      <div className="icon-box icon-box-side icon-box-light">
                        <span className="icon-box-icon icon-map-marker">
                          <i className="w-icon-map-marker" />
                        </span>
                        <div className="icon-box-content">
                          <h4 className="icon-box-title mb-0 ls-normal">
                            Addresses
                          </h4>
                        </div>
                      </div>
                      <p>
                        The following addresses will be used on the checkout
                        page by default.
                      </p>
                      <div className="row">
                        <div className="col-sm-6 mb-6">
                          <div className="ecommerce-address billing-address pr-lg-8">
                            <h4 className="title title-underline ls-25 font-weight-bold">
                              Billing Address
                            </h4>
                            <address className="mb-4">
                              <table className="address-table">
                                <tbody>
                                  <tr>
                                    <th>Name:</th>
                                    <td>John Doe</td>
                                  </tr>
                                  <tr>
                                    <th>Company:</th>
                                    <td>Conia</td>
                                  </tr>
                                  <tr>
                                    <th>Address:</th>
                                    <td>Wall Street</td>
                                  </tr>
                                  <tr>
                                    <th>City:</th>
                                    <td>California</td>
                                  </tr>
                                  <tr>
                                    <th>Country:</th>
                                    <td>United States (US)</td>
                                  </tr>
                                  <tr>
                                    <th>Postcode:</th>
                                    <td>92020</td>
                                  </tr>
                                  <tr>
                                    <th>Phone:</th>
                                    <td>1112223334</td>
                                  </tr>
                                </tbody>
                              </table>
                            </address>
                            <a
                              href="#"
                              className="btn btn-link btn-underline btn-icon-right text-primary"
                            >
                              Edit your billing address
                              <i className="w-icon-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-6">
                          <div className="ecommerce-address shipping-address pr-lg-8">
                            <h4 className="title title-underline ls-25 font-weight-bold">
                              Shipping Address
                            </h4>
                            <address className="mb-4">
                              <table className="address-table">
                                <tbody>
                                  <tr>
                                    <th>Name:</th>
                                    <td>John Doe</td>
                                  </tr>
                                  <tr>
                                    <th>Company:</th>
                                    <td>Conia</td>
                                  </tr>
                                  <tr>
                                    <th>Address:</th>
                                    <td>Wall Street</td>
                                  </tr>
                                  <tr>
                                    <th>City:</th>
                                    <td>California</td>
                                  </tr>
                                  <tr>
                                    <th>Country:</th>
                                    <td>United States (US)</td>
                                  </tr>
                                  <tr>
                                    <th>Postcode:</th>
                                    <td>92020</td>
                                  </tr>
                                </tbody>
                              </table>
                            </address>
                            <a
                              href="#"
                              className="btn btn-link btn-underline btn-icon-right text-primary"
                            >
                              Edit your shipping address
                              <i className="w-icon-long-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="account-details">
                      <div className="icon-box icon-box-side icon-box-light">
                        <span className="icon-box-icon icon-account mr-2">
                          <i className="w-icon-user" />
                        </span>
                        <div className="icon-box-content">
                          <h4 className="icon-box-title mb-0 ls-normal">
                            Account Details
                          </h4>
                        </div>
                      </div>
                      <form
                        className="form account-details-form"
                        action="#"
                        method="post"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="firstname">First name *</label>
                              <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder="John"
                                className="form-control form-control-md"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="lastname">Last name *</label>
                              <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                placeholder="Doe"
                                className="form-control form-control-md"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="display-name">Display name *</label>
                          <input
                            type="text"
                            id="display-name"
                            name="display_name"
                            placeholder="John Doe"
                            className="form-control form-control-md mb-0"
                          />
                          <p>
                            This will be how your name will be displayed in the
                            account section and in reviews
                          </p>
                        </div>
                        <div className="form-group mb-6">
                          <label htmlFor="email_1">Email address *</label>
                          <input
                            type="email"
                            id="email_1"
                            name="email_1"
                            className="form-control form-control-md"
                          />
                        </div>
                        <h4 className="title title-password ls-25 font-weight-bold">
                          Password change
                        </h4>
                        <div className="form-group">
                          <label className="text-dark" htmlFor="cur-password">
                            Current Password leave blank to leave unchanged
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-md"
                            id="cur-password"
                            name="cur_password"
                          />
                        </div>
                        <div className="form-group">
                          <label className="text-dark" htmlFor="new-password">
                            New Password leave blank to leave unchanged
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-md"
                            id="new-password"
                            name="new_password"
                          />
                        </div>
                        <div className="form-group mb-10">
                          <label className="text-dark" htmlFor="conf-password">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control form-control-md"
                            id="conf-password"
                            name="conf_password"
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-dark btn-rounded btn-sm mb-4"
                        >
                          Save Changes
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of PageContent */}
          </main>
          {/* End of Main */}
          <Footer />
        </div>
        {/* End of Page Wrapper */}
        {/* Start of Sticky Footer */}

        {/* End of Sticky Footer */}
        {/* Start of Scroll Top */}
        <a
          id="scroll-top"
          className="scroll-top"
          href="#top"
          title="Top"
          role="button"
        >
          {" "}
          <i className="w-icon-angle-up" />{" "}
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 70 70"
          >
            {" "}
            <circle
              id="progress-indicator"
              fill="transparent"
              stroke="#000000"
              strokeMiterlimit={10}
              cx={35}
              cy={35}
              r={34}
              style={{ strokeDasharray: "16.4198, 400" }}
            />{" "}
          </svg>{" "}
        </a>
        {/* End of Scroll Top */}
        {/* Start of Mobile Menu */}

        {/* End of .mobile-menu-overlay */}

        {/* End of .mobile-menu-close */}
      </div>
    </UserRoute>
  );
}

export default Wishlists;
