import React, { useState } from "react";
import Switch from "react-switch";

function FilterSwitcher(props) {
  const { setChecked, checked, text } = props;

  const handleChange = () => {
    console.log(checked)
    setChecked(!checked);
  };
  return (
    <label style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
      <Switch onChange={handleChange} checked={checked} onColor="#F4442C" />
      <span style={{marginLeft: "8px", fontFamily: "Poppins", fontSize: "14px"}}>{text}</span>
    </label>
  );
}

export default FilterSwitcher;
