import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
function NewsletterPopup() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`/subscribeNewsletter`, {
        email: email,
      });
      toast.success(
        "Vielen Dank für die Anmeldung! Bestätige jetzt die Anmeldung in deinem E-Mail Postfach!"
      );

      setShowPopUp(false);
    } catch (error) {
      toast.error("Bei der Anmeldung ist etwas schief gelaufen!");
    }
  };
  const [email, setEmail] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (document.cookie.indexOf("popupShown=true") == -1) {
        setShowPopUp(true);
        document.cookie = "popupShown=true; max-age=86400"; // 86400: seconds in a day
      }
    }, 5000);
  }, []);

  return (
    <>
      {showPopUp && (
        <div
          style={{
            background: "rgba(0,0,0,0.5)",
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: "100",
          }}
        >
          <div
            className="my-popup min-w-[100vw] md:min-w-[500px]"
            style={{
              backgroundColor: "#183544",
              position: "fixed",
              transform: "translate(-50%, -50%)",
              WebkitTransform: "translate(-50%, -50%)",
              zIndex: "999999999999999999",
              top: "50%",
              left: "50%",
              borderRadius: "8px",
            }}
          >
            <div className="newsletter-content pl-8 py-16">
              <div className="">
                <h2
                  className="ls-25 text-primary"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                >
                  BPPyro VIP
                </h2>
              </div>
              <p
                className="newsletter-text"
                style={{
                  color: "#fff",
                  fontFamily: "Poppins",
                  letterSpacing: "-.01em",
                }}
              >
                Jetzt Bestpreis Pyro VIP werden für exklusive Vorteile! Sei
                immer up-to-date was Angebote, Neuheiten und News rund um
                Feuerwerk betrifft.
              </p>
              <form
                className="input-wrapper input-wrapper-inline input-wrapper-round"
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                <input
                  type="email"
                  className="form-control email font-size-md"
                  name="email"
                  id="email2"
                  style={{ color: "#999" }}
                  placeholder="Deine Email Adresse"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="btn btn-dark" type="submit">
                  Abonnieren
                </button>
              </form>
              <span className="custom-checkbox"></span>
              <div className="newsletter-button-box">
                <div
                  onClick={() => setShowPopUp(false)}
                  style={{
                    backgroundColor: "#333333",
                    color: "#fff",
                    fontFamily: "Poppins",
                    borderRadius: "5px",
                    padding: "6px 10px 6px 10px",
                    boxShadow: "none",
                    display: "inline-block",
                    cursor: "pointer",
                    textAlign: "center",
                    boxShadow: "0 0 5px -1px rgba(0,0,0,0.2)",
                    marginTop: "2rem",
                  }}
                >
                  Schließen
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NewsletterPopup;
