import axios from "axios";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import Footer from "./ui/footer/footer";
import { UserContext } from "../context";

function Forgotpassword() {
  const [state, setState] = useContext(UserContext);

  const [firstname, setFirstname] = React.useState("");

  const [lastname, setLastname] = React.useState("");

  const [email, setEmail] = React.useState("");

  const [otp, setOtp] = React.useState("");

  const [password, setPassword] = React.useState("");

  const [loading, setLoading] = useState(false);

  const [emailboxVisible, setEmailboxVisible] = useState(true);

  const [verifyboxVisible, setVerifyboxVisible] = useState(false);

  const [passwordboxVisible, setPasswordboxVisible] = useState(false);

  const navigate = useNavigate();

  const handlesendEmail = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(`/send-otp-forgotpassword`, {
        email,
      });

      console.log("The dataa ===> ", data);

      setLoading(false);

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        setEmailboxVisible(false);

        setVerifyboxVisible(true);

        toast.success(data.description);
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  const handlesendOTP = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(`/verify-otp-forgotpassword`, {
        email,
        otp,
      });

      console.log("The dataa ===> ", data);

      setLoading(false);

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        setVerifyboxVisible(false);

        setPasswordboxVisible(true);

        toast.success(data.description);
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  const handlesendPassword = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data } = await axios.post(`/reset-password-forget`, {
        email,
        password,
      });

      console.log("The dataa ===> ", data);

      setLoading(false);

      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        toast.success(data.description);

        navigate("/login");
      }
    } catch (error) {
      console.log("An error occured ===> ", error);
    }
  };

  if (state && state.token) navigate("/account");

  return (
    <>
      <div>
        <div className="page-wrapper">
          <header className="header">
            <TopNavbar />
            <MiddleHeader />
            <Searchbar />
          </header>

          {/* Start of Main */}
          <main className="main login-page" style={{ margin: "40px" }}>
            {/* Start of Page Header */}
            {
              <div className="page-header">
                <div className="container">
                  <h1 className="page-title mb-0">Passwort vergessen</h1>
                </div>
              </div>
            }
            {/* End of Page Header */}

            <div className="page-content">
              <div className="container">
                <div className="login-popup" style={{ padding: "0px" }}>
                  <div className="tab tab-nav-boxed tab-nav-center tab-nav-underline">
                    <div className="tab-content">
                      <div className="tab-pane active" id="sign-in">
                        <form
                          onSubmit={handlesendEmail}
                          style={{
                            display: emailboxVisible ? "block" : "none",
                          }}
                        >
                          <div className="form-group">
                            <label>Email Adresse *</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-checkbox d-flex align-items-center justify-content-between"></div>
                          <button
                            className="btn btn-primary"
                            style={{
                              width: `100%`,
                              backgroundColor: loading && "grey",
                              cursor: loading && "not-allowed",
                            }}
                            disabled={loading}
                          >
                            {loading ? "Senden..." : "Senden"}
                          </button>
                        </form>
                        <form
                          onSubmit={handlesendOTP}
                          style={{
                            display: verifyboxVisible ? "block" : "none",
                          }}
                        >
                          <div className="form-group">
                            <label>
                              Bitte gebe zur Verifikation den Code ein, der dir
                              als Email zugesendet wurde. Im Anschluss kannst du
                              dein Passwort ändern.
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              required
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                            />
                          </div>
                          <button
                            className="btn btn-primary"
                            style={{
                              width: `100%`,
                              backgroundColor: loading && "grey",
                              cursor: loading && "not-allowed",
                            }}
                            disabled={loading}
                          >
                            {loading ? "Senden..." : "Anmelden"}
                          </button>
                        </form>
                        <form
                          onSubmit={handlesendPassword}
                          style={{
                            display: passwordboxVisible ? "block" : "none",
                          }}
                        >
                          <div className="form-group">
                            <label>Bitte gebe dein neues Passwort ein</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <button
                            className="btn btn-primary"
                            style={{
                              width: `100%`,
                              backgroundColor: loading && "grey",
                              cursor: loading && "not-allowed",
                            }}
                            disabled={loading}
                          >
                            {loading ? "Senden..." : "Anmelden"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {/* End of Main */}
          <Footer />
        </div>
        {/* End of Page Wrapper */}
        {/* Start of Sticky Footer */}

        {/* End of Sticky Footer */}
        {/* Start of Scroll Top */}
        <a
          id="scroll-top"
          className="scroll-top"
          href="#top"
          title="Top"
          role="button"
        >
          {" "}
          <i className="w-icon-angle-up" />{" "}
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 70 70"
          >
            {" "}
            <circle
              id="progress-indicator"
              fill="transparent"
              stroke="#000000"
              strokeMiterlimit={10}
              cx={35}
              cy={35}
              r={34}
              style={{ strokeDasharray: "16.4198, 400" }}
            />{" "}
          </svg>{" "}
        </a>
        {/* End of Scroll Top */}
        {/* Start of Mobile Menu */}

        {/* End of .mobile-menu-close */}
      </div>
    </>
  );
}

export default Forgotpassword;
