import React, { useState } from "react";
import { LiaPatreon } from "react-icons/lia";
import SocialIcons from "../social-icons/socialcons";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
function Footer() {
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`/subscribeNewsletter`, {
        email: email,
      });
      toast.success(
        "Vielen Dank für die Anmeldung! Bestätige jetzt die Anmeldung in deinem E-Mail Postfach!"
      );
    } catch (error) {
      toast.error("Bei der Anmeldung ist etwas schief gelaufen!");
    }
  };
  return (
    <>
      <footer className="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-5 col-lg-6">
                <div className="icon-box icon-box-side text-white">
                  <div className="icon-box-icon d-inline-flex">
                    <i className="w-icon-envelop3" />
                  </div>
                  <div className="icon-box-content">
                    <h4 className="icon-box-title text-white text-uppercase font-weight-bold">
                      Werde jetzt kostenlos Bestpreis Pyro VIP und sichere dir
                      den entscheidenden Vorsprung!
                    </h4>
                    <ul className="text-white">
                      <li>
                        Kein Spam, nur Mehrwert: handverlesene Informationen,
                        die dich als Feuerwerk-Liebhaber weiterbringen!
                      </li>
                      <li>
                        Erste Zugriffsrechte: erfahre vor allen anderen von
                        neuen Funktionen und erhalte limitierten Zugriff auf
                        Betaversion-Tests.
                      </li>
                      <li>
                        Feuerwerk-News: erfahre immer direkt von Neuheiten und
                        News der Branche, allumfassend von allen Herstellern und
                        Shops / Händlern!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6 col-md-9 mt-4 mt-lg-0 ">
                <form
                  className="input-wrapper input-wrapper-inline input-wrapper-rounded"
                  onSubmit={handleSubmit}
                >
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control mr-2 bg-white pl-3"
                    name="email"
                    id="email"
                    placeholder="Deine Email Adresse"
                    required
                  />
                  <button className="btn btn-primary btn-rounded" type="submit">
                    Abonnieren
                    <i className="w-icon-long-arrow-right" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="widget widget-about mt-0 mb-4">
                  <a href="#" className="logo-footer">
                    <img
                      src="assets/images/demos/demo14/BPPyro-footer-logo.png"
                      alt="logo-footer"
                      style={{ width: "60%" }}
                    />
                  </a>
                  <div className="widget-body">
                    <p className="widget-about-desc">
                      Du willst den virtuellen Warenkorb nutzen oder
                      deine Suche speichern? Erstelle jetzt einen{" "}
                      <a style={{ color: "#F4442C" }} href="/login">
                        kostenlosen Account
                      </a>
                      !
                    </p>
                    <SocialIcons colored={true} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h3 className="widget-title">Info & Kontakt</h3>
                  <ul className="widget-body">
                    <li>
                      <Link to="/about">Über Uns</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/newsletter">Newsletter</Link>
                    </li>
                    <li>
                      <a
                        href="https://www.patreon.com/user?u=100031016"
                        target="_blank"
                      >
                        Patreon
                      </a>
                    </li>
                    <li>
                      <Link to="/contact">Kontakt</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">Partner</h4>
                  <ul className="widget-body">
                    <li>
                      <a href="https://pyroextrem.de/" target="_blank">
                        pyroextrem.de
                      </a>
                    </li>
                    {/*<li>
                  <a href="cart.html">Feuerwerkfanpage</a>
</li>*/}
                    {/* <li>
                  <a href="login.html">BVPK</a>
</li>*/}
                    <li>
                      <Link to="/partner">
                        <strong>Partner werden</strong>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">Rechtliches</h4>
                  <ul className="widget-body">
                    <li>
                      <Link to={"/impressum"}>Impressum</Link>
                    </li>
                    <li>
                      <Link to={"/datenschutz"}>Datenschutz</Link>
                    </li>
                    <li>
                      <Link to={"/agb"}>AGB</Link>
                    </li>
                    <li>
                      <Link to={"/gdpr"}>Gdpr</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-middle" style={{ borderBottom: "none" }}></div>
          <div className="footer-bottom">
            <div className="footer-left">
              <p className="copyright">
                Copyright © 2024 BestPreisPyro Alle Rechte vorbehalten
              </p>
            </div>
            <div className="footer-right"></div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
