import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaPatreon } from "react-icons/fa";
import axios from "axios";

function Searchbar({
  PassedSubmit,
  updateSearchQuery,
  searchCategoryQueryTerm,
  shouldReload,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [searchQuery, setSearchQuery] = useState(
    searchParams.get("searchquery") === "undefined"
      ? ""
      : searchParams.get("searchquery") || ""
  );
  const [autoSuggestList, setAutoSuggestList] = useState([]);
  const [autoSuggestTotalCount, setAutoSuggestTotalCount] = useState(0);
  const [isNavBarFixed, setIsNavBarFixed] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const debounceTimeout = useRef(null);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);

    if (updateSearchQuery) {
      updateSearchQuery(newValue);
    }

    // Debounce API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      fetchAutoSuggestions(newValue);
    }, 300); // Adjust the delay as needed
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (PassedSubmit) {
      PassedSubmit();
    } else {
      searchProducts();
    }
  };

  const searchProducts = () => {
    if (searchQuery.trim().length > 0) {
      navigate(
        `/shop?selectedbrands=undefined&selectedcategories=undefined&mincaliber=undefined&maxcaliber=undefined&minprice=undefined&maxprice=undefined&pgnumber=1&pgsize=20&searchquery=${searchQuery}`
      );

      if (shouldReload) {
        window.location.reload();
      }
    }
  };

  const fetchAutoSuggestions = async (query) => {
    if (!query.trim()) {
      setAutoSuggestList([]);
      return;
    }
    try {
      const response = await axios.get(`/autosuggest?q=${query}`);
      setAutoSuggestList(response.data.products);
      setAutoSuggestTotalCount(response.data.totalCount);
    } catch (err) {
      console.error("Error fetching autosuggestions", err);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setIsNavBarFixed(position >= 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="sticky-content-wrapper"
      style={isNavBarFixed ? { height: "83px" } : {}}
    >
      <div
        className={`header-bottom sticky-content fix-top sticky-header has-dropdown ${
          isNavBarFixed ? "fixed" : ""
        }`}
        style={isNavBarFixed ? { marginTop: "0px" } : {}}
      >
        <div className="container">
          <div className="inner-wrap">
            <div className="header-left flex-1">
              <div className="dropdown category-dropdown" data-visible="true">
                <a
                  href="https://www.patreon.com/user?u=100031016"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginRight: "8px" }}
                  className="category-toggle text-white"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  data-display="static"
                  title="unterstützen"
                >
                  <FaPatreon
                    style={{ padding: "5px", color: "#fff", border: "none" }}
                    className="social-icon social-youtube fab fa-tiktok"
                  />
                  <span>Projekt unterstützen</span>
                </a>
              </div>
              <div
                className="mr-4 ml-4 relative"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <form
                  className="header-search hs-expanded hs-round bg-white br-xs d-md-flex input-wrapper"
                  onSubmit={handleSubmit}
                >
                  <input
                    type="text"
                    className="form-control text-light border-no"
                    name="search"
                    id="search"
                    placeholder="Suche..."
                    value={searchQuery}
                    onChange={handleInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                  <button className="btn btn-search border-no" type="submit">
                    <i className="w-icon-search" />
                  </button>
                </form>
                {isFocused && autoSuggestList.length > 0 && (
                  <>
                    <ul
                      className="text-[#333333] flex flex-col absolute bg-white border border-gray-300 mt-10 z-10 cursor-default"
                      style={{ width: "100%" }}
                    >
                      <li>
                        <span className="text-[#666666] pt-2 pl-2">{`Gefundene Produkte: ${autoSuggestTotalCount}`}</span>
                      </li>
                      {autoSuggestList?.map((product) => (
                        <li
                          key={product.id}
                          className="px-4 py-2 hover:bg-gray-200 flex items-center"
                          onMouseDown={() => {
                            setAutoSuggestList([]);
                          }}
                        >
                          <div>
                            <div className="font-semibold">
                              {product.product_name}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="header-right ml-4">
              <a href="https://www.bestpreispyro.de#pickuplocationfinder">
                <i className="w-icon-map-marker" />
                Verkaufs- und Abholpunkte finden
              </a>
              <a href="/shop">
                <i className="w-icon-sale" />
                Kostenloser Vergleich
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Searchbar;
