import React, { useEffect } from "react";
import Footer from "./ui/footer/footer";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Searchfield from "./ui/searchfield/Searchfield";

function Agb() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <div className="container">
        <h2
          className="title tab-pane-title font-weight-bold mb-2"
          style={{ fontSize: "3rem", marginTop: "2rem" }}
        >
          **Allgemeine Geschäftsbedingungen (AGB) für "Best Preis Pyro"**
        </h2>

        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **1. Geltungsbereich**
        </h4>
        <p className="mb-4">
          Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") gelten für
          alle Nutzer von "Best Preis Pyro", betrieben von der helpaholiCom UG
          (haftungsbeschränkt), Gewerbepark 2 92526 Oberviechtach Deutschland
          (nachfolgend "Betreibergesellschaft"). Mit der Nutzung der Plattform
          akzeptieren Sie diese AGB in ihrer jeweiligen Fassung.
        </p>

        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **2. Dienstleistung**
        </h4>
        <p className="mb-4">
          "Best Preis Pyro" bietet eine Vergleichsplattform für pyrotechnische
          Produkte im Konsumentenbereich. Die Plattform listet Produkte
          verschiedener Händler und stellt Preisinformationen, einschließlich
          Versandkosten, bereit. Wir verkaufen oder liefern keine Produkte
          direkt.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **3. AGB der Händler**
        </h4>
        <p className="mb-4">
          Bei einer Weiterleitung auf die Website eines gelisteten Händlers
          gelten ausschließlich dessen allgemeine Geschäftsbedingungen. Die
          Betreibergesellschaft ist nicht verantwortlich für die Inhalte und AGB
          der gelisteten Händler. Es wird empfohlen, die AGB des jeweiligen
          Händlers sorgfältig zu lesen.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **4. Haftung**
        </h4>
        <p className="mb-4">
          Die Betreibergesellschaft übernimmt keine Haftung für die Richtigkeit,
          Vollständigkeit und Aktualität der auf der Plattform angezeigten
          Informationen. Ebenfalls besteht keine Haftung für die Verfügbarkeit
          der Produkte oder Dienstleistungen der gelisteten Händler.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **5. Verlinkung zu Drittanbietern**
        </h4>
        <p className="mb-4">
          Unsere Plattform enthält Links zu externen Websites von Händlern und
          Dritten. Für den Inhalt und die Angebote dieser Seiten sind
          ausschließlich die jeweiligen Anbieter verantwortlich.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **6. Hinweis an Händler**
        </h4>
        <p className="mb-4">
          Sollten Sie als Händler nicht auf unserer Plattform gelistet sein
          wollen, kontaktieren Sie uns bitte umgehend. Wir werden Ihren Eintrag
          dann zeitnah entfernen.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **7. Urheberrechte**
        </h4>
        <p className="mb-4">
          Sämtliche Inhalte unserer Plattform, insbesondere Texte, Grafiken und
          Logos, sind urheberrechtlich geschützt. Jegliche Nutzung außerhalb der
          Grenzen des Urheberrechts bedarf der vorherigen schriftlichen
          Zustimmung der Betreibergesellschaft.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **8. Nutzungsrechte**
        </h4>
        <p className="mb-4">
          Die Verwendung der auf "Best Preis Pyro" zur Verfügung gestellten
          Inhalte ist nur für den persönlichen, nichtkommerziellen Gebrauch
          gestattet.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **9. Datenschutz**
        </h4>
        <p className="mb-4">
          Die Betreibergesellschaft behandelt alle personenbezogenen Daten der
          Nutzer vertraulich und gemäß den gesetzlichen Datenschutzvorschriften.
          Weitere Informationen zum Datenschutz finden Sie in unserer
          Datenschutzerklärung.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **10. Änderungen der AGB**
        </h4>
        <p className="mb-4">
          Die Betreibergesellschaft behält sich das Recht vor, diese AGB
          jederzeit ohne Nennung von Gründen zu ändern. Die geänderten
          Bedingungen werden den Nutzern spätestens zwei Wochen vor ihrem
          Inkrafttreten per E-Mail zugesandt.
        </p>
        <h4 className="title tab-pane-title font-weight-bold mb-2">
          **11. Schlussbestimmungen**
        </h4>
        <p className="mb-4">
          DEs gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des
          UN-Kaufrechts. Gerichtsstand ist Oberviechtach.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Agb;
