import React, { useEffect, useContext } from "react";
import AccountLayout from "./ui/account/AccountLayout";
import { useVirtualCartList } from "../hooks/useVirtualCartList";
import ProductView from "./ui/shop-views/ProductView";
import VirtualCartMatchingView from "./ui/virtual-cart-views/virtualCartMatchingView";
import { UserContext } from "../context";
import { Link } from "react-router-dom";

function VirtualCartList() {
  const [state, setState] = useContext(UserContext);
  const { virtualCartList, loading, matchedShopData, getMatchedShopData } =
    useVirtualCartList();
  console.log(virtualCartList);

  useEffect(() => {
    getMatchedShopData();
    console.log("hemlo");
    console.log(matchedShopData);
  }, [state.user.id, virtualCartList]);

  return (
    <AccountLayout heading="Virtueller Warenkorb">
      <ProductView products={virtualCartList} />
      <VirtualCartMatchingView shops={matchedShopData} loading={loading} />
      {virtualCartList.length > 0 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/shop?selectedbrands=undefined&selectedcategories=undefined&mincaliber=undefined&maxcaliber=undefined&minprice=undefined&maxprice=undefined&pgnumber=1&pgsize=20&searchquery=undefined&selectedshops=undefined&sortingorder=asc&isdiscounted=false&isavailable=false&onlyallyear=false&selectedfireworkscategories=undefined&onlyvirtualcart=true"}>
            {" "}
            <button
              className="btn btn-primary btn-rounded mb-8"
              
            >
              Produkte der v. Warenkorbsliste hinzufügen
            </button>
          </Link>
        </div>
      )}
    </AccountLayout>
  );
}

export default VirtualCartList;
