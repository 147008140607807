import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import UserRoute from "./routes/UserRoute";
import { UserContext } from "../context";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import Footer from "./ui/footer/footer";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./ui/account/Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-grid-system";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import { removeCommaFromEnd } from "../utils";
import imgh from "../images/mateusz-roman-4wM9P7c8its-unsplash.jpg";
import Skeleton from "react-loading-skeleton";
import Searchfield from "./ui/searchfield/Searchfield";
import { FaRegBell } from "react-icons/fa"; // Importing the new icon

function Pricealerts() {
  const [state, setState] = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pgSize, setPgSize] = useState(30);
  const [pgNumber, setPgNumber] = useState(1);
  const [products, setProducts] = useState([]);
  const [wishlists, setWishlists] = useState([]);
  const [enteredWishlists, setEnteredWishlists] = useState(false);

  const logout = () => {
    setState({
      user: {},
      token: "",
    });

    // save in local storage
    window.localStorage.removeItem("auth");

    navigate("/");
  };

  async function getUserWishlists() {
    if (state && state.user && state.user.id) {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `/getAllPriceAlarmsWithDetails?email=${state.user.email}`
        );
        console.log(data);
        // Sort the products array in ascending order based on product_price
        const sortedProducts = data.data.sort(
          (a, b) => a.product_price - b.product_price
        );
        setLoading(false);
        setProducts(sortedProducts);
      } catch (error) {
        console.log("An error occurred ===> ", error);
      }
    }
  }

  const removeFromWishList = async (product_id) => {
    setLoading(true);
    try {
      const { data } = await axios.delete(
        `/remove-price-alarm?email=${state.user.email}&product_id=${product_id}`
      );
      if (data.message === "error") {
        toast.error(data.description);
      } else if (data.message === "success") {
        getUserWishlists();
        toast.success("Produkt wurde aus dem Preisalarm entfernt");
      }
    } catch (error) {
      console.log("An error occurred ===> ", error);
    }
  };

  useEffect(() => {
    getUserWishlists();
  }, [state]);

  return (
    <UserRoute>
      <div>
        <div className="page-wrapper">
          {/* Start of Header */}
          <header className="header">
            <TopNavbar />
            <MiddleHeader />
            <Searchbar />
            <Searchfield />
          </header>
          {/* End of Header */}
          {/* Start of Main */}
          <main className="main">
            {/* Start of Page Header */}
            <div className="page-header">
              <div className="container">
                <h1 className="page-title mb-0">Account</h1>
              </div>
            </div>
            {/* End of Page Header */}
            {/* Start of Breadcrumb */}
            <nav className="breadcrumb-nav">
              <div className="container">
                <ul className="breadcrumb"></ul>
              </div>
            </nav>
            {/* End of Breadcrumb */}
            {/* Start of PageContent */}
            <div className="page-content pt-2">
              <div className="container">
                <div className="tab tab-vertical row gutter-lg">
                  <Sidebar logout={logout} />
                  <div
                    className="tab-content mb-6"
                    style={{ borderTop: "none" }}
                  >
                    <>
                      <Container fluid>
                        <Row style={{ borderBottom: "1px solid #EEEEEE" }}>
                          <Col></Col>
                          <Col md={2}>
                            <h3 className="product-name">Kategorie/Shop</h3>
                          </Col>
                          <Col md={3}>
                            <h3 className="product-name">Produkt Name</h3>
                          </Col>
                          <Col md={3}>
                            <h3 className="product-name">Preis</h3>
                          </Col>
                          <Col md={2}></Col>
                        </Row>
                      </Container>
                      <Container fluid>
                        {loading ? (
                          <div>{/* Skeleton loading code */}</div>
                        ) : (
                          products &&
                          products.length > 0 &&
                          products.map((product) => {
                            if (
                              product.product_price > 0 ||
                              product.product_old_price > 0
                            ) {
                              if (state && state.user && state.user.id) {
                                return (
                                  <div key={product.id}>
                                    <Row>
                                      <Col
                                        style={{ border: "1px solid #EEEEEE" }}
                                      >
                                        <figure className="product-media">
                                          <Link
                                            to={`/product?productId=${product.product_id}`}
                                          >
                                            <img
                                              src={
                                                removeCommaFromEnd(
                                                  product.product_thumbnail,
                                                  product.product_image
                                                ) === "invalid"
                                                  ? imgh
                                                  : removeCommaFromEnd(
                                                      product.product_thumbnail,
                                                      product.product_image
                                                    )
                                              }
                                              alt="Product"
                                              width={150}
                                              height={150}
                                              style={{
                                                objectFit: "contain",
                                                height: "64px",
                                              }}
                                            />
                                          </Link>
                                        </figure>
                                      </Col>
                                      <Col
                                        md={2}
                                        style={{ border: "1px solid #EEEEEE" }}
                                      >
                                        <div>
                                          <a style={{ color: "#999" }}>
                                            {product.product_category}
                                          </a>
                                        </div>
                                        <h3 className="product-name">
                                          <a>
                                            {capitalizeFirstLetter(
                                              product.product_shop_name
                                            )}
                                          </a>
                                        </h3>
                                      </Col>
                                      <Col
                                        md={3}
                                        style={{ border: "1px solid #EEEEEE" }}
                                      >
                                        <h3 className="product-name">
                                          <a href="#">{product.product_name}</a>
                                        </h3>
                                      </Col>
                                      <Col
                                        md={2}
                                        style={{ border: "1px solid #EEEEEE" }}
                                      >
                                        <div className="product-pa-wrapper">
                                          <div className="product-price">
                                            {product.product_price <= 0 &&
                                            product.product_old_price > 0 ? (
                                              <ins className="new-price">
                                                {product.product_old_price.toLocaleString(
                                                  "de-AT",
                                                  {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  }
                                                )}
                                              </ins>
                                            ) : (
                                              <>
                                                <ins className="new-price">
                                                  {product.product_price.toLocaleString(
                                                    "de-AT",
                                                    {
                                                      style: "currency",
                                                      currency: "EUR",
                                                    }
                                                  )}
                                                </ins>
                                                {product.product_old_price >
                                                  0 && (
                                                  <del className="old-price">
                                                    {product.product_old_price.toLocaleString(
                                                      "de-AT",
                                                      {
                                                        style: "currency",
                                                        currency: "EUR",
                                                      }
                                                    )}
                                                  </del>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </Col>

                                      <Col
                                        md={3}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {product.product_link && (
                                          <Link
                                            to={`/product?productId=${product.product_id}`}
                                          >
                                            <button
                                              className="btn btn-primary"
                                              style={{
                                                backgroundColor: "#183544",
                                                borderColor: "#183544",
                                                borderRadius: "4px",
                                              }}
                                            >
                                              <span>Details</span>
                                            </button>
                                          </Link>
                                        )}
                                        <span className="icon-box-icon icon-orders">
                                          <FaRegBell
                                            size={36}
                                            onClick={() =>
                                              removeFromWishList(
                                                product.product_id
                                              )
                                            }
                                            style={{
                                              marginLeft: "6px",
                                              fontSize: "3.8rem",
                                              cursor: "pointer",
                                              color: "#F4442C",
                                            }}
                                          />
                                        </span>
                                        <span />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              }
                            }
                          })
                        )}
                      </Container>
                    </>
                  </div>
                </div>
              </div>
            </div>
            {/* End of PageContent */}
          </main>
          {/* End of Main */}
          <Footer />
        </div>
      </div>
    </UserRoute>
  );
}

export default Pricealerts;
