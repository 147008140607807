export const allBrandsData = [
  { name: "Apel", value: false },
  { name: "Argento", value: false },
  { name: "Blackboxx", value: false },
  { name: "Broekhoff", value: false },
  { name: "Funke", value: false },
  { name: "Lesli", value: false },
  { name: "Lonestar", value: false },
  { name: "Nico", value: false },
  { name: "Tropic", value: false },
  { name: "WECO", value: false },
  { name: "Xplode", value: false },
  { name: "Jorge", value: false },
  { name: "Comet", value: false },
  { name: "diBlasio", value: false },
  { name: "Dum Bum", value: false },
  { name: "Fireevent", value: false },
  { name: "Gaoo", value: false },
  { name: "Heron", value: false },
  { name: "JGW", value: false },
  { name: "partyba", value: false },
  { name: "piromax", value: false },
  { name: "PyroCentury", value: false },
  { name: "Pyrogiochi", value: false },
  { name: "Startrade", value: false },
  { name: "Vulcan", value: false },
  { name: "Iskra", value: false },
  { name: "Bonfireworks", value: false },
  { name: "Nordlicht", value: false },
  { name: "Läubli", value: false },
  { name: "Mr Pyrotechnik", value: false },
  { name: "Vurrwerk", value: false },
  { name: "Evolution", value: false },
  { name: "Zena", value: false },
  { name: "Triplex", value: false },
  { name: "Klasek", value: false },
  { name: "Zink", value: false },
  { name: "Pyrospecials", value: false },
  { name: "Bavaria", value: false },
  { name: "Panda", value: false },
  { name: "Pyrogenie", value: false },
  { name: "Pyrotrade", value: false },
  { name: "PyroProdukt", value: false },
  { name: "VOLT!", value: false },
  { name: "Bothmer", value: false },
  { name: "PYROUNION", value: false },
  { name: "Riakeo", value: false },
  { name: "Rubro", value: false },
  { name: "Katan", value: false },
  { name: "Pyrofactory", value: false },
  { name: "El Gato", value: false },
  { name: "Geisha", value: false },
  { name: "Keller", value: false },
  { name: "Volksfeuerwerk", value: false },
  { name: "Wano", value: false },
  { name: "Wolff", value: false },
  { name: "PGE", value: false },
];

export const allFireworksCategories = [
  { name: "F1", value: false },
  { name: "F2", value: false },
  { name: "F3", value: false },
  { name: "F4", value: false },
  { name: "T1", value: false },
  { name: "T2", value: false },
  { name: "P1", value: false },
  { name: "P2", value: false },
];

export const allCategoriesData = [
  { name: "Batterie", value: false },
  { name: "Verbund", value: false },
  { name: "Einzelschuss", value: false },
  { name: "Rakete", value: false },
  { name: "Leuchtfeuerwerk", value: false },
  { name: "Knallkörper", value: false },
  { name: "Rauchartikel", value: false },
  { name: "Bengalartikel", value: false },
  { name: "Sortiment", value: false },
  { name: "Jugendfeuerwerk", value: false },
  { name: "Römische Lichter", value: false },
  { name: "Indoorartikel", value: false },
  
];

export const allFilters = {
  selectedbrands: { name: "selectedbrands", value: undefined, type: "list" },
  selectedcategories: {
    name: "selectedcategories",
    value: undefined,
    type: "list",
  },
  mincaliber: { name: "mincaliber", value: undefined, type: "value" },
  maxcaliber: { name: "maxcaliber", value: undefined, type: "value" },
  minprice: { name: "minprice", value: undefined, type: "value" },
  maxprice: { name: "maxprice", value: undefined, type: "value" },
  searchquery: { name: "searchquery", value: undefined, type: "value" },
  pgsize: { name: "pgsize", value: undefined, type: "value" },
  pgnumber: { name: "pgnumber", value: undefined, type: "value" },
};

export function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
export function isNotUndefined(value) {
  if (value !== undefined && value !== null && value !== "undefined") {
    return true;
  } else {
    return false;
  }
}
export const shopBaseUrl =
  "/shop?selectedbrands=undefined&selectedcategories=undefined&mincaliber=undefined&maxcaliber=undefined&minprice=undefined&maxprice=undefined&pgnumber=1&pgsize=20&searchquery=undefined&selectedshops=undefined&sortingorder=asc&isdiscounted=false&isavailable=false&onlyallyear=false&selectedfireworkscategories=undefined";

export const pyroWebAffilateDict = {
  "https://www.pyroweb.de/yeswebang-flyer-stickerset/":
    "JumpIn=1&WebID=2553&intItemID=2885",
  "https://www.pyroweb.de/15-magnum-shell-rockets/":
    "JumpIn=1&WebID=2553&intItemID=3924",
  "https://www.pyroweb.de/10-techno-color-blister/":
    "JumpIn=1&WebID=2553&intItemID=3079",
  "https://www.pyroweb.de/1000-kristalle-fontaenenset/":
    "JumpIn=1&WebID=2553&intItemID=3062",
  "https://www.pyroweb.de/2-color-smoke-blue-white/":
    "JumpIn=1&WebID=2553&intItemID=3084",
  "https://www.pyroweb.de/2-color-smoke-green-white/":
    "JumpIn=1&WebID=2553&intItemID=3086",
  "https://www.pyroweb.de/2-color-smoke-purple-white/":
    "JumpIn=1&WebID=2553&intItemID=3085",
  "https://www.pyroweb.de/2-color-smoke-red-white/":
    "JumpIn=1&WebID=2553&intItemID=3083",
  "https://www.pyroweb.de/300-shots-ultimate-mixed-display/":
    "JumpIn=1&WebID=2553&intItemID=3951",
  "https://www.pyroweb.de/400-shots-total-display-show-400-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2846",
  "https://www.pyroweb.de/60-sec-strobe-fontaene/":
    "JumpIn=1&WebID=2553&intItemID=3942",
  "https://www.pyroweb.de/8-meter-anzuendlitze-gelb-18-bis-28-sm/":
    "JumpIn=1&WebID=2553&intItemID=241",
  "https://www.pyroweb.de/8-meter-anzuendlitze-rot-8-bis-12-sm/":
    "JumpIn=1&WebID=2553&intItemID=279",
  "https://www.pyroweb.de/8-schuss-roemisches-licht-kal-c-rot/":
    "JumpIn=1&WebID=2553&intItemID=3831",
  "https://www.pyroweb.de/8-schuss-roemisches-licht-kal-c-blau/":
    "JumpIn=1&WebID=2553&intItemID=3919",
  "https://www.pyroweb.de/8-schuss-roemisches-licht-kal-c-orange/":
    "JumpIn=1&WebID=2553&intItemID=3920",
  "https://www.pyroweb.de/8-schuss-roemisches-licht-kal-c-purple/":
    "JumpIn=1&WebID=2553&intItemID=3921",
  "https://www.pyroweb.de/90-sec-strobe-xxl-sechserpack/":
    "JumpIn=1&WebID=2553&intItemID=3943",
  "https://www.pyroweb.de/96-great-balls-of-fire/":
    "JumpIn=1&WebID=2553&intItemID=2148",
  "https://www.pyroweb.de/abschussrohr-gfk-kal-100/":
    "JumpIn=1&WebID=2553&intItemID=387",
  "https://www.pyroweb.de/abschussrohr-gfk-kal-125/":
    "JumpIn=1&WebID=2553&intItemID=388",
  "https://www.pyroweb.de/abschussrohr-gfk-kal-150/":
    "JumpIn=1&WebID=2553&intItemID=389",
  "https://www.pyroweb.de/abschussrohr-gfk-kal-50/":
    "JumpIn=1&WebID=2553&intItemID=384",
  "https://www.pyroweb.de/abschussrohr-gfk-kal-65/":
    "JumpIn=1&WebID=2553&intItemID=380",
  "https://www.pyroweb.de/abschussrohr-gfk-kal-75/":
    "JumpIn=1&WebID=2553&intItemID=386",
  "https://www.pyroweb.de/absperrband-feuerwerk-abbrennplatz-500m-rolle/":
    "JumpIn=1&WebID=2553&intItemID=150",
  "https://www.pyroweb.de/ace-of-spades/": "JumpIn=1&WebID=2553&intItemID=3889",
  "https://www.pyroweb.de/acido-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2821",
  "https://www.pyroweb.de/acryl-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3917",
  "https://www.pyroweb.de/aeon-7-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2640",
  "https://www.pyroweb.de/affenzahn-3er-fontaenen/":
    "JumpIn=1&WebID=2553&intItemID=1585",
  "https://www.pyroweb.de/aidos-7-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2641",
  "https://www.pyroweb.de/airscreamers-luftheuler/":
    "JumpIn=1&WebID=2553&intItemID=3214",
  "https://www.pyroweb.de/akira-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3851",
  "https://www.pyroweb.de/albatros-family/":
    "JumpIn=1&WebID=2553&intItemID=2858",
  "https://www.pyroweb.de/alpha-7-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2642",
  "https://www.pyroweb.de/amore-rosentischfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=852",
  "https://www.pyroweb.de/amsterdam-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3774",
  "https://www.pyroweb.de/anomalia-102-schuss-komplettfeuerwerk-f3/":
    "JumpIn=1&WebID=2553&intItemID=3179",
  "https://www.pyroweb.de/anubis-fontaene/":
    "JumpIn=1&WebID=2553&intItemID=2162",
  "https://www.pyroweb.de/anzuendlichter-4er-pack/":
    "JumpIn=1&WebID=2553&intItemID=876",
  "https://www.pyroweb.de/apocalypse-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2109",
  "https://www.pyroweb.de/aquila-16-schuss/":
    "JumpIn=1&WebID=2553&intItemID=268",
  "https://www.pyroweb.de/argento-carbon-rockets/":
    "JumpIn=1&WebID=2553&intItemID=2547",
  "https://www.pyroweb.de/argento-chiara-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2564",
  "https://www.pyroweb.de/argento-demonio-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2544",
  "https://www.pyroweb.de/argento-dualsmoke-38mm-in-blau/":
    "JumpIn=1&WebID=2553&intItemID=3262",
  "https://www.pyroweb.de/argento-dualsmoke-38mm-in-pink/":
    "JumpIn=1&WebID=2553&intItemID=3264",
  "https://www.pyroweb.de/argento-dualsmoke-38mm-in-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3263",
  "https://www.pyroweb.de/argento-eisblume-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2550",
  "https://www.pyroweb.de/argento-goya-49-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2567",
  "https://www.pyroweb.de/argento-hanami-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2558",
  "https://www.pyroweb.de/argento-hysteria-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2546",
  "https://www.pyroweb.de/argento-medusa-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2543",
  "https://www.pyroweb.de/argento-mephisto-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2552",
  "https://www.pyroweb.de/argento-ortega-49-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2566",
  "https://www.pyroweb.de/argento-picasso-49-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2565",
  "https://www.pyroweb.de/argento-rauchbombe-18mm-schwarz/":
    "JumpIn=1&WebID=2553&intItemID=3267",
  "https://www.pyroweb.de/argento-rauchbombe-30mm-blau/":
    "JumpIn=1&WebID=2553&intItemID=3265",
  "https://www.pyroweb.de/argento-rauchbombe-30mm-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3266",
  "https://www.pyroweb.de/argento-rauchbombe-38mm-pink/":
    "JumpIn=1&WebID=2553&intItemID=3259",
  "https://www.pyroweb.de/argento-rauchbombe-38mm-rot/":
    "JumpIn=1&WebID=2553&intItemID=3258",
  "https://www.pyroweb.de/argento-rauchbombe-38mm-schwarz/":
    "JumpIn=1&WebID=2553&intItemID=3260",
  "https://www.pyroweb.de/argento-rauchbombe-38mm-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3261",
  "https://www.pyroweb.de/argento-sakura-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2551",
  "https://www.pyroweb.de/argento-saphir-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2556",
  "https://www.pyroweb.de/argento-scorpio-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2545",
  "https://www.pyroweb.de/argento-trygonus-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2563",
  "https://www.pyroweb.de/argento-urania-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2542",
  "https://www.pyroweb.de/argento-veronica-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2549",
  "https://www.pyroweb.de/argento-zirkonia-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2559",
  "https://www.pyroweb.de/arrows-9er-raketenset/":
    "JumpIn=1&WebID=2553&intItemID=3964",
  "https://www.pyroweb.de/asoka-7-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2643",
  "https://www.pyroweb.de/asteroids-24-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1462",
  "https://www.pyroweb.de/astral-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2091",
  "https://www.pyroweb.de/astronaut-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3166",
  "https://www.pyroweb.de/atom-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2343",
  "https://www.pyroweb.de/aurora-13-schuss-feuerwerks-batterie/":
    "JumpIn=1&WebID=2553&intItemID=810",
  "https://www.pyroweb.de/auten-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1956",
  "https://www.pyroweb.de/ausser-kontrolle-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1844",
  "https://www.pyroweb.de/azzurro-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2939",
  "https://www.pyroweb.de/b-boy-8-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3744",
  "https://www.pyroweb.de/babel-7-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2522",
  "https://www.pyroweb.de/baby-kacke/": "JumpIn=1&WebID=2553&intItemID=2316",
  "https://www.pyroweb.de/baby-raketen-crackling/":
    "JumpIn=1&WebID=2553&intItemID=3790",
  "https://www.pyroweb.de/baby-raketen-easypack/":
    "JumpIn=1&WebID=2553&intItemID=1547",
  "https://www.pyroweb.de/baby-raketen-silberknister/":
    "JumpIn=1&WebID=2553&intItemID=3791",
  "https://www.pyroweb.de/baby-raketen-titansalut/":
    "JumpIn=1&WebID=2553&intItemID=3789",
  "https://www.pyroweb.de/bad-smiley-6er-figurenbomben-raketen/":
    "JumpIn=1&WebID=2553&intItemID=1941",
  "https://www.pyroweb.de/bad-wolff-108-schuss-verbundbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1611",
  "https://www.pyroweb.de/bada-bang-bada-boom-4er-set/":
    "JumpIn=1&WebID=2553&intItemID=2701",
  "https://www.pyroweb.de/ball-changing-dragon/":
    "JumpIn=1&WebID=2553&intItemID=2080",
  "https://www.pyroweb.de/ballistic-64-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1568",
  "https://www.pyroweb.de/ballon-beautiful-horses-latex-6er/":
    "JumpIn=1&WebID=2553&intItemID=3377",
  "https://www.pyroweb.de/ballon-beautiful-horses-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3381",
  "https://www.pyroweb.de/ballon-blau-led-latex-5er/":
    "JumpIn=1&WebID=2553&intItemID=3617",
  "https://www.pyroweb.de/ballon-bunt-led-latex-5er/":
    "JumpIn=1&WebID=2553&intItemID=3618",
  "https://www.pyroweb.de/ballon-chase-folie/":
    "JumpIn=1&WebID=2553&intItemID=3398",
  "https://www.pyroweb.de/ballon-folie-18-happy-birthday-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3503",
  "https://www.pyroweb.de/ballon-folie-18-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3546",
  "https://www.pyroweb.de/ballon-folie-21-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3508",
  "https://www.pyroweb.de/ballon-folie-30-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3512",
  "https://www.pyroweb.de/ballon-folie-40-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3516",
  "https://www.pyroweb.de/ballon-folie-50-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3521",
  "https://www.pyroweb.de/ballon-folie-60-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3525",
  "https://www.pyroweb.de/ballon-folie-70-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3532",
  "https://www.pyroweb.de/ballon-folie-70-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3547",
  "https://www.pyroweb.de/ballon-folie-80-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3540",
  "https://www.pyroweb.de/ballon-folie-80-45cm/":
    "JumpIn=1&WebID=2553&intItemID=3548",
  "https://www.pyroweb.de/ballon-folie-90-45cm/":
    "JumpIn=1&WebID=2553&intItemID=3549",
  "https://www.pyroweb.de/ballon-folie-alles-gute-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3501",
  "https://www.pyroweb.de/ballon-folie-bvb-dortmund-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3470",
  "https://www.pyroweb.de/ballon-folie-clearz-crystal-led-bunt/":
    "JumpIn=1&WebID=2553&intItemID=3621",
  "https://www.pyroweb.de/ballon-folie-clearz-crystal-led-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3620",
  "https://www.pyroweb.de/ballon-folie-fc-bayern-muenchen-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3486",
  "https://www.pyroweb.de/ballon-folie-fireman-sam/":
    "JumpIn=1&WebID=2553&intItemID=3420",
  "https://www.pyroweb.de/ballon-folie-fireman-sam-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3419",
  "https://www.pyroweb.de/ballon-folie-happy-birthday-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3500",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-18/":
    "JumpIn=1&WebID=2553&intItemID=3671",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-30/":
    "JumpIn=1&WebID=2553&intItemID=3550",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-30-pink/":
    "JumpIn=1&WebID=2553&intItemID=3672",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-40/":
    "JumpIn=1&WebID=2553&intItemID=3551",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-40-pink/":
    "JumpIn=1&WebID=2553&intItemID=3673",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-50/":
    "JumpIn=1&WebID=2553&intItemID=3552",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-50-pink/":
    "JumpIn=1&WebID=2553&intItemID=3674",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-60/":
    "JumpIn=1&WebID=2553&intItemID=3553",
  "https://www.pyroweb.de/ballon-folie-multi-sparkling-60-pink/":
    "JumpIn=1&WebID=2553&intItemID=3675",
  "https://www.pyroweb.de/ballon-folie-number-0-gold/":
    "JumpIn=1&WebID=2553&intItemID=3622",
  "https://www.pyroweb.de/ballon-folie-number-0-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3642",
  "https://www.pyroweb.de/ballon-folie-number-0-silber/":
    "JumpIn=1&WebID=2553&intItemID=3632",
  "https://www.pyroweb.de/ballon-folie-number-1-gold/":
    "JumpIn=1&WebID=2553&intItemID=3623",
  "https://www.pyroweb.de/ballon-folie-number-1-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3643",
  "https://www.pyroweb.de/ballon-folie-number-1-silber/":
    "JumpIn=1&WebID=2553&intItemID=3633",
  "https://www.pyroweb.de/ballon-folie-number-2-gold/":
    "JumpIn=1&WebID=2553&intItemID=3624",
  "https://www.pyroweb.de/ballon-folie-number-2-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3644",
  "https://www.pyroweb.de/ballon-folie-number-2-silber/":
    "JumpIn=1&WebID=2553&intItemID=3634",
  "https://www.pyroweb.de/ballon-folie-number-3-gold/":
    "JumpIn=1&WebID=2553&intItemID=3625",
  "https://www.pyroweb.de/ballon-folie-number-3-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3645",
  "https://www.pyroweb.de/ballon-folie-number-3-silber/":
    "JumpIn=1&WebID=2553&intItemID=3635",
  "https://www.pyroweb.de/ballon-folie-number-4-gold/":
    "JumpIn=1&WebID=2553&intItemID=3626",
  "https://www.pyroweb.de/ballon-folie-number-4-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3646",
  "https://www.pyroweb.de/ballon-folie-number-4-silber/":
    "JumpIn=1&WebID=2553&intItemID=3636",
  "https://www.pyroweb.de/ballon-folie-number-5-gold/":
    "JumpIn=1&WebID=2553&intItemID=3627",
  "https://www.pyroweb.de/ballon-folie-number-5-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3647",
  "https://www.pyroweb.de/ballon-folie-number-5-silber/":
    "JumpIn=1&WebID=2553&intItemID=3637",
  "https://www.pyroweb.de/ballon-folie-number-6-gold/":
    "JumpIn=1&WebID=2553&intItemID=3628",
  "https://www.pyroweb.de/ballon-folie-number-6-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3648",
  "https://www.pyroweb.de/ballon-folie-number-6-silber/":
    "JumpIn=1&WebID=2553&intItemID=3638",
  "https://www.pyroweb.de/ballon-folie-number-7-gold/":
    "JumpIn=1&WebID=2553&intItemID=3629",
  "https://www.pyroweb.de/ballon-folie-number-7-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3649",
  "https://www.pyroweb.de/ballon-folie-number-7-silber/":
    "JumpIn=1&WebID=2553&intItemID=3639",
  "https://www.pyroweb.de/ballon-folie-number-8-gold/":
    "JumpIn=1&WebID=2553&intItemID=3630",
  "https://www.pyroweb.de/ballon-folie-number-8-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3650",
  "https://www.pyroweb.de/ballon-folie-number-8-silber/":
    "JumpIn=1&WebID=2553&intItemID=3640",
  "https://www.pyroweb.de/ballon-folie-number-9-gold/":
    "JumpIn=1&WebID=2553&intItemID=3631",
  "https://www.pyroweb.de/ballon-folie-number-9-rose-gold/":
    "JumpIn=1&WebID=2553&intItemID=3651",
  "https://www.pyroweb.de/ballon-folie-number-9-silber/":
    "JumpIn=1&WebID=2553&intItemID=3641",
  "https://www.pyroweb.de/ballon-folie-sparkling-30-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3554",
  "https://www.pyroweb.de/ballon-folie-sparkling-40-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3555",
  "https://www.pyroweb.de/ballon-folie-sparkling-60-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3557",
  "https://www.pyroweb.de/ballon-folie-wort/":
    "JumpIn=1&WebID=2553&intItemID=3654",
  "https://www.pyroweb.de/ballon-folie-wort-2/":
    "JumpIn=1&WebID=2553&intItemID=3655",
  "https://www.pyroweb.de/ballon-folie-wort-2-3/":
    "JumpIn=1&WebID=2553&intItemID=3656",
  "https://www.pyroweb.de/ballon-gold-led-latex-5er/":
    "JumpIn=1&WebID=2553&intItemID=3614",
  "https://www.pyroweb.de/ballon-holo-magical-unicorn-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3366",
  "https://www.pyroweb.de/ballon-latex-18-birthday-gefuellt-6er/":
    "JumpIn=1&WebID=2553&intItemID=3507",
  "https://www.pyroweb.de/ballon-latex-18-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3692",
  "https://www.pyroweb.de/ballon-latex-21-birthday-gefuellt-6er/":
    "JumpIn=1&WebID=2553&intItemID=3511",
  "https://www.pyroweb.de/ballon-latex-21-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3693",
  "https://www.pyroweb.de/ballon-latex-30-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3694",
  "https://www.pyroweb.de/ballon-latex-40-birthday-gefuellt-6er/":
    "JumpIn=1&WebID=2553&intItemID=3520",
  "https://www.pyroweb.de/ballon-latex-40-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3695",
  "https://www.pyroweb.de/ballon-latex-50-birthday-gefuellt-6er/":
    "JumpIn=1&WebID=2553&intItemID=3524",
  "https://www.pyroweb.de/ballon-latex-50-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3696",
  "https://www.pyroweb.de/ballon-latex-60-birthday-gefuellt-6er/":
    "JumpIn=1&WebID=2553&intItemID=3531",
  "https://www.pyroweb.de/ballon-latex-60-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3697",
  "https://www.pyroweb.de/ballon-latex-70-birthday-gefuellt-6er/":
    "JumpIn=1&WebID=2553&intItemID=3538",
  "https://www.pyroweb.de/ballon-latex-70-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3691",
  "https://www.pyroweb.de/ballon-latex-80-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3698",
  "https://www.pyroweb.de/ballon-latex-bvb-dortmund-6er/":
    "JumpIn=1&WebID=2553&intItemID=3462",
  "https://www.pyroweb.de/ballon-latex-fc-bayern-muenchen-6er/":
    "JumpIn=1&WebID=2553&intItemID=3485",
  "https://www.pyroweb.de/ballon-latex-fireman-sam-6er/":
    "JumpIn=1&WebID=2553&intItemID=3415",
  "https://www.pyroweb.de/ballon-latex-kicker-party-6er/":
    "JumpIn=1&WebID=2553&intItemID=3446",
  "https://www.pyroweb.de/ballon-latex-magical-unicorn/":
    "JumpIn=1&WebID=2553&intItemID=3357",
  "https://www.pyroweb.de/ballon-latex-miraculous-6er/":
    "JumpIn=1&WebID=2553&intItemID=3340",
  "https://www.pyroweb.de/ballon-latex-paw-patrol-6er/":
    "JumpIn=1&WebID=2553&intItemID=3392",
  "https://www.pyroweb.de/ballon-latex-pirat-6er/":
    "JumpIn=1&WebID=2553&intItemID=3429",
  "https://www.pyroweb.de/ballon-latex-pirat-6er-2/":
    "JumpIn=1&WebID=2553&intItemID=3432",
  "https://www.pyroweb.de/ballon-magical-unicorn-folie/":
    "JumpIn=1&WebID=2553&intItemID=3364",
  "https://www.pyroweb.de/ballon-magical-unicorn-5er/":
    "JumpIn=1&WebID=2553&intItemID=3367",
  "https://www.pyroweb.de/ballon-miraculous-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3344",
  "https://www.pyroweb.de/ballon-miraculous-5er/":
    "JumpIn=1&WebID=2553&intItemID=3343",
  "https://www.pyroweb.de/ballon-multi-magical-unicorn-folie/":
    "JumpIn=1&WebID=2553&intItemID=3365",
  "https://www.pyroweb.de/ballon-paw-patro-skye-und-everest-5er/":
    "JumpIn=1&WebID=2553&intItemID=3351",
  "https://www.pyroweb.de/ballon-paw-patrol-folie-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3390",
  "https://www.pyroweb.de/ballon-paw-patrol-girls/":
    "JumpIn=1&WebID=2553&intItemID=3350",
  "https://www.pyroweb.de/ballon-paw-patrol-pink-herz-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3349",
  "https://www.pyroweb.de/ballon-paw-patrol-skye-und-everest-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3348",
  "https://www.pyroweb.de/ballon-pink-led-latex-5er/":
    "JumpIn=1&WebID=2553&intItemID=3616",
  "https://www.pyroweb.de/ballon-satin-gold-sateen-6er/":
    "JumpIn=1&WebID=2553&intItemID=3609",
  "https://www.pyroweb.de/ballon-satin-luxe-azur-6er/":
    "JumpIn=1&WebID=2553&intItemID=3612",
  "https://www.pyroweb.de/ballon-satin-luxe-flamingo-6er/":
    "JumpIn=1&WebID=2553&intItemID=3610",
  "https://www.pyroweb.de/ballon-satin-luxe-platinum-6er/":
    "JumpIn=1&WebID=2553&intItemID=3607",
  "https://www.pyroweb.de/ballon-satin-luxe-pomegranate-6er/":
    "JumpIn=1&WebID=2553&intItemID=3611",
  "https://www.pyroweb.de/ballon-satin-rose-copper-6er/":
    "JumpIn=1&WebID=2553&intItemID=3608",
  "https://www.pyroweb.de/ballon-schulstart-43cm/":
    "JumpIn=1&WebID=2553&intItemID=3328",
  "https://www.pyroweb.de/ballon-schulstart-mini-abc/":
    "JumpIn=1&WebID=2553&intItemID=3329",
  "https://www.pyroweb.de/ballon-schultuete-abc-35cm/":
    "JumpIn=1&WebID=2553&intItemID=3330",
  "https://www.pyroweb.de/ballon-schultuete-abc-personalisierbar-111cm/":
    "JumpIn=1&WebID=2553&intItemID=3332",
  "https://www.pyroweb.de/ballon-schultuete-abc-pink-76cm/":
    "JumpIn=1&WebID=2553&intItemID=3331",
  "https://www.pyroweb.de/ballon-silber-led-latex-5er/":
    "JumpIn=1&WebID=2553&intItemID=3613",
  "https://www.pyroweb.de/ballon-sparkling-birthday-30-5er/":
    "JumpIn=1&WebID=2553&intItemID=3676",
  "https://www.pyroweb.de/ballon-sparkling-birthday-50-5er/":
    "JumpIn=1&WebID=2553&intItemID=3677",
  "https://www.pyroweb.de/ballon-sparkling-birthday-60-5er/":
    "JumpIn=1&WebID=2553&intItemID=3678",
  "https://www.pyroweb.de/ballon-sparkling-birthday-70-5er/":
    "JumpIn=1&WebID=2553&intItemID=3679",
  "https://www.pyroweb.de/ballon-weiss-led-latex-5er/":
    "JumpIn=1&WebID=2553&intItemID=3615",
  "https://www.pyroweb.de/ballon-white-with-color-led-latex-5er/":
    "JumpIn=1&WebID=2553&intItemID=3619",
  "https://www.pyroweb.de/ballongewicht-herzen-gold/":
    "JumpIn=1&WebID=2553&intItemID=3669",
  "https://www.pyroweb.de/ballongewicht-herzen-rot/":
    "JumpIn=1&WebID=2553&intItemID=3670",
  "https://www.pyroweb.de/ballongewicht-herzen-silber/":
    "JumpIn=1&WebID=2553&intItemID=3668",
  "https://www.pyroweb.de/ballongewicht-stern-schwarz/":
    "JumpIn=1&WebID=2553&intItemID=3667",
  "https://www.pyroweb.de/ballongewicht-stern-silber/":
    "JumpIn=1&WebID=2553&intItemID=3666",
  "https://www.pyroweb.de/balza-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3297",
  "https://www.pyroweb.de/bamboo-torch-bambusfackel/":
    "JumpIn=1&WebID=2553&intItemID=2971",
  "https://www.pyroweb.de/bang-und-scream-11er-raketen-beutel/":
    "JumpIn=1&WebID=2553&intItemID=2975",
  "https://www.pyroweb.de/bangaz/": "JumpIn=1&WebID=2553&intItemID=3307",
  "https://www.pyroweb.de/barbarossa-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1098",
  "https://www.pyroweb.de/barockfackel-2er-set/":
    "JumpIn=1&WebID=2553&intItemID=3301",
  "https://www.pyroweb.de/basilisk-gold-49-schuss/":
    "JumpIn=1&WebID=2553&intItemID=634",
  "https://www.pyroweb.de/bat-teeth-150-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2332",
  "https://www.pyroweb.de/bazinga/": "JumpIn=1&WebID=2553&intItemID=3316",
  "https://www.pyroweb.de/bazookas-12er-boeller/":
    "JumpIn=1&WebID=2553&intItemID=2850",
  "https://www.pyroweb.de/beatbox-2-112-schuss-verbundfeuerwerk-2/":
    "JumpIn=1&WebID=2553&intItemID=3911",
  "https://www.pyroweb.de/becher-beautiful-horses-8er/":
    "JumpIn=1&WebID=2553&intItemID=3369",
  "https://www.pyroweb.de/becher-bvb-dortmund-8er/":
    "JumpIn=1&WebID=2553&intItemID=3451",
  "https://www.pyroweb.de/becher-confetti-birthday-8er/":
    "JumpIn=1&WebID=2553&intItemID=3489",
  "https://www.pyroweb.de/becher-fc-bayern-muenchen-8er/":
    "JumpIn=1&WebID=2553&intItemID=3472",
  "https://www.pyroweb.de/becher-fireman-sam-8er-250ml/":
    "JumpIn=1&WebID=2553&intItemID=3403",
  "https://www.pyroweb.de/becher-gross-bvb-dortmund-6er/":
    "JumpIn=1&WebID=2553&intItemID=3452",
  "https://www.pyroweb.de/becher-kicker-party-8er/":
    "JumpIn=1&WebID=2553&intItemID=3438",
  "https://www.pyroweb.de/becher-magical-unicorn-8er-250ml/":
    "JumpIn=1&WebID=2553&intItemID=3353",
  "https://www.pyroweb.de/becher-miraculous-papier-250ml/":
    "JumpIn=1&WebID=2553&intItemID=3335",
  "https://www.pyroweb.de/becher-paw-patrol-pink-8er-250ml/":
    "JumpIn=1&WebID=2553&intItemID=3346",
  "https://www.pyroweb.de/becher-paw-patrol-8er-250ml/":
    "JumpIn=1&WebID=2553&intItemID=3383",
  "https://www.pyroweb.de/becher-pirat-8er/":
    "JumpIn=1&WebID=2553&intItemID=3422",
  "https://www.pyroweb.de/bees-4er/": "JumpIn=1&WebID=2553&intItemID=2379",
  "https://www.pyroweb.de/bella-ciao-12er-raketenset/":
    "JumpIn=1&WebID=2553&intItemID=2702",
  "https://www.pyroweb.de/bellabomba-10er/":
    "JumpIn=1&WebID=2553&intItemID=2504",
  "https://www.pyroweb.de/bellisimo-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3907",
  "https://www.pyroweb.de/bengal-fire-assortet-box/":
    "JumpIn=1&WebID=2553&intItemID=3230",
  "https://www.pyroweb.de/bengal-fuego-mix/":
    "JumpIn=1&WebID=2553&intItemID=3076",
  "https://www.pyroweb.de/bengal-fuego-red/":
    "JumpIn=1&WebID=2553&intItemID=3077",
  "https://www.pyroweb.de/bengal-torch-orange/":
    "JumpIn=1&WebID=2553&intItemID=1348",
  "https://www.pyroweb.de/bengal-torch-yellow/":
    "JumpIn=1&WebID=2553&intItemID=1347",
  "https://www.pyroweb.de/bengal-rauchfontaene-gelb/":
    "JumpIn=1&WebID=2553&intItemID=2188",
  "https://www.pyroweb.de/bengal-rauchfontaene-rot/":
    "JumpIn=1&WebID=2553&intItemID=3067",
  "https://www.pyroweb.de/bengalische-fackeln-6er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=97",
  "https://www.pyroweb.de/bengallanze-3er-pack-3-min/":
    "JumpIn=1&WebID=2553&intItemID=962",
  "https://www.pyroweb.de/bengallicht-gelb/":
    "JumpIn=1&WebID=2553&intItemID=682",
  "https://www.pyroweb.de/bengallicht-purpur/":
    "JumpIn=1&WebID=2553&intItemID=684",
  "https://www.pyroweb.de/bengallicht-rot-zu-silber-2/":
    "JumpIn=1&WebID=2553&intItemID=688",
  "https://www.pyroweb.de/bengalo-fatalo-no2/":
    "JumpIn=1&WebID=2553&intItemID=997",
  "https://www.pyroweb.de/bengalo-h50-gruen-nc-kalte-pyrotechnik/":
    "JumpIn=1&WebID=2553&intItemID=1497",
  "https://www.pyroweb.de/bengalo-h50-lila-nc-kalte-pyrotechnik/":
    "JumpIn=1&WebID=2553&intItemID=1500",
  "https://www.pyroweb.de/bengalo-s20-blau-2/":
    "JumpIn=1&WebID=2553&intItemID=2419",
  "https://www.pyroweb.de/bengalo-s20-blau-zu-gold-2/":
    "JumpIn=1&WebID=2553&intItemID=2430",
  "https://www.pyroweb.de/bengalo-s20-blau-zu-silber-2/":
    "JumpIn=1&WebID=2553&intItemID=2426",
  "https://www.pyroweb.de/bengalo-s20-gelb-2/":
    "JumpIn=1&WebID=2553&intItemID=2420",
  "https://www.pyroweb.de/bengalo-s20-gold-2/":
    "JumpIn=1&WebID=2553&intItemID=2421",
  "https://www.pyroweb.de/bengalo-s20-gold-zu-silber/":
    "JumpIn=1&WebID=2553&intItemID=2428",
  "https://www.pyroweb.de/bengalo-s20-gruen-2/":
    "JumpIn=1&WebID=2553&intItemID=2422",
  "https://www.pyroweb.de/bengalo-s20-magnesium-mix-10er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2432",
  "https://www.pyroweb.de/bengalo-s20-purpur-2-3/":
    "JumpIn=1&WebID=2553&intItemID=2423",
  "https://www.pyroweb.de/bengalo-s20-rot-2/":
    "JumpIn=1&WebID=2553&intItemID=2424",
  "https://www.pyroweb.de/bengalo-s20-rot-zu-gold-2/":
    "JumpIn=1&WebID=2553&intItemID=2431",
  "https://www.pyroweb.de/bengalo-s20-rot-zu-silber-2/":
    "JumpIn=1&WebID=2553&intItemID=2425",
  "https://www.pyroweb.de/bengalo-s20-silber-2/":
    "JumpIn=1&WebID=2553&intItemID=2427",
  "https://www.pyroweb.de/bengalo-s20-weiss-zu-rot-2/":
    "JumpIn=1&WebID=2553&intItemID=2429",
  "https://www.pyroweb.de/bengalo-sf1-gelb/":
    "JumpIn=1&WebID=2553&intItemID=1484",
  "https://www.pyroweb.de/bengalo-sf1-gruen/":
    "JumpIn=1&WebID=2553&intItemID=1485",
  "https://www.pyroweb.de/bengalo-sf1-lila/":
    "JumpIn=1&WebID=2553&intItemID=1486",
  "https://www.pyroweb.de/bengalo-sf1-rot/":
    "JumpIn=1&WebID=2553&intItemID=1487",
  "https://www.pyroweb.de/bengalo-sf1-weiss/":
    "JumpIn=1&WebID=2553&intItemID=1488",
  "https://www.pyroweb.de/bengalos-5er-set/":
    "JumpIn=1&WebID=2553&intItemID=2668",
  "https://www.pyroweb.de/bengalos-5er-set-blau/":
    "JumpIn=1&WebID=2553&intItemID=3285",
  "https://www.pyroweb.de/bengalos-5er-set-gelb/":
    "JumpIn=1&WebID=2553&intItemID=3286",
  "https://www.pyroweb.de/bengalos-5er-set-gruen/":
    "JumpIn=1&WebID=2553&intItemID=3283",
  "https://www.pyroweb.de/bengalos-5er-set-rot/":
    "JumpIn=1&WebID=2553&intItemID=3284",
  "https://www.pyroweb.de/bengalux-200-bengaltopf-pyropot-5er-color-set/":
    "JumpIn=1&WebID=2553&intItemID=903",
  "https://www.pyroweb.de/bengalux-200-bengaltopf-pyropot-blau/":
    "JumpIn=1&WebID=2553&intItemID=665",
  "https://www.pyroweb.de/bengalux-200-bengaltopf-pyropot-gelb/":
    "JumpIn=1&WebID=2553&intItemID=670",
  "https://www.pyroweb.de/bengalux-200-bengaltopf-pyropot-gruen/":
    "JumpIn=1&WebID=2553&intItemID=666",
  "https://www.pyroweb.de/bengalux-200-bengaltopf-pyropot-purpur/":
    "JumpIn=1&WebID=2553&intItemID=671",
  "https://www.pyroweb.de/bengalux-200-bengaltopf-pyropot-rot/":
    "JumpIn=1&WebID=2553&intItemID=664",
  "https://www.pyroweb.de/bengalux-200l-rauch-bengaltopf-pyropot-weiss/":
    "JumpIn=1&WebID=2553&intItemID=1807",
  "https://www.pyroweb.de/bengalux-200s-rauch-bengaltopf-pyropot-gelb/":
    "JumpIn=1&WebID=2553&intItemID=1805",
  "https://www.pyroweb.de/bengalux-200s-rauch-bengaltopf-pyropot-gruen/":
    "JumpIn=1&WebID=2553&intItemID=1804",
  "https://www.pyroweb.de/bengalux-200s-rauch-bengaltopf-pyropot-purpur/":
    "JumpIn=1&WebID=2553&intItemID=1806",
  "https://www.pyroweb.de/bengalux-200s-rauch-bengaltopf-pyropot-rot/":
    "JumpIn=1&WebID=2553&intItemID=1803",
  "https://www.pyroweb.de/bengalux-8000-blau-2/":
    "JumpIn=1&WebID=2553&intItemID=2438",
  "https://www.pyroweb.de/bengalux-8000-gelb/":
    "JumpIn=1&WebID=2553&intItemID=1068",
  "https://www.pyroweb.de/bengalux-8000-gruen-2/":
    "JumpIn=1&WebID=2553&intItemID=2439",
  "https://www.pyroweb.de/bengalux-8000-orange-2/":
    "JumpIn=1&WebID=2553&intItemID=2442",
  "https://www.pyroweb.de/bengalux-8000-purpur-2/":
    "JumpIn=1&WebID=2553&intItemID=2440",
  "https://www.pyroweb.de/bengalux-8000-rot-2/":
    "JumpIn=1&WebID=2553&intItemID=1354",
  "https://www.pyroweb.de/bengalux-8000-weiss-2/":
    "JumpIn=1&WebID=2553&intItemID=2441",
  "https://www.pyroweb.de/best-of-blackboxx-rockets-15er-beutel-premiumraketen/":
    "JumpIn=1&WebID=2553&intItemID=1920",
  "https://www.pyroweb.de/bierdeckel-bvb-dortmund-12er/":
    "JumpIn=1&WebID=2553&intItemID=3455",
  "https://www.pyroweb.de/bierdeckel-fc-bayern-muenchen-12er/":
    "JumpIn=1&WebID=2553&intItemID=3481",
  "https://www.pyroweb.de/big-bang-mars-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1694",
  "https://www.pyroweb.de/big-bang-uran-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1446",
  "https://www.pyroweb.de/big-bang-wenus-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1695",
  "https://www.pyroweb.de/big-box-100-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3934",
  "https://www.pyroweb.de/big-challenge-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3132",
  "https://www.pyroweb.de/big-magic-balls-3er-set/":
    "JumpIn=1&WebID=2553&intItemID=1660",
  "https://www.pyroweb.de/big-pack-starklicht-bengalos-rot/":
    "JumpIn=1&WebID=2553&intItemID=3989",
  "https://www.pyroweb.de/big-shot-3er-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=1459",
  "https://www.pyroweb.de/black-devils/": "JumpIn=1&WebID=2553&intItemID=3220",
  "https://www.pyroweb.de/black-jack-21er-raketen-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=1122",
  "https://www.pyroweb.de/black-mystery-16-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1070",
  "https://www.pyroweb.de/black-pain-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2224",
  "https://www.pyroweb.de/blackboxx-bengalfackeln-8er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=1802",
  "https://www.pyroweb.de/blackboxx-bombenraketen-9er-set/":
    "JumpIn=1&WebID=2553&intItemID=1919",
  "https://www.pyroweb.de/blackboxx-boeller-xl-10er/":
    "JumpIn=1&WebID=2553&intItemID=3913",
  "https://www.pyroweb.de/blackboxx-pyro-spektakel-level-1-97-schuss-verbund/":
    "JumpIn=1&WebID=2553&intItemID=1719",
  "https://www.pyroweb.de/blackboxx-pyro-spektakel-level-4-142-schuss-verbundbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1739",
  "https://www.pyroweb.de/blackboxx-pyro-spektakel-level-8-140-schuss-verbundbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1740",
  "https://www.pyroweb.de/blackboxx-rapiator-300er-knallkette/":
    "JumpIn=1&WebID=2553&intItemID=1723",
  "https://www.pyroweb.de/blackboxx-rapiator-500er-knallkette/":
    "JumpIn=1&WebID=2553&intItemID=1724",
  "https://www.pyroweb.de/blast-off-64-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3733",
  "https://www.pyroweb.de/blaster-88-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2156",
  "https://www.pyroweb.de/blauer-berber/": "JumpIn=1&WebID=2553&intItemID=3793",
  "https://www.pyroweb.de/blink-cracker-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2646",
  "https://www.pyroweb.de/blinkbengale-rot-5er/":
    "JumpIn=1&WebID=2553&intItemID=2592",
  "https://www.pyroweb.de/blinkers-10er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=3047",
  "https://www.pyroweb.de/blinking-fermata-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2837",
  "https://www.pyroweb.de/blinking-sky-50-schuss-combi-box/":
    "JumpIn=1&WebID=2553&intItemID=2054",
  "https://www.pyroweb.de/blistering-skies-72-schuss-verbundbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2845",
  "https://www.pyroweb.de/blitz-rums-50-shot/":
    "JumpIn=1&WebID=2553&intItemID=3293",
  "https://www.pyroweb.de/blitz-knatterbaelle-25er/":
    "JumpIn=1&WebID=2553&intItemID=1394",
  "https://www.pyroweb.de/blitz-knatterbaelle-9-teilig/":
    "JumpIn=1&WebID=2553&intItemID=3290",
  "https://www.pyroweb.de/blitzbengalo-gelb/":
    "JumpIn=1&WebID=2553&intItemID=3210",
  "https://www.pyroweb.de/blitzbengalo-gruen/":
    "JumpIn=1&WebID=2553&intItemID=3211",
  "https://www.pyroweb.de/blitzbengalo-rot/":
    "JumpIn=1&WebID=2553&intItemID=3212",
  "https://www.pyroweb.de/blitzbengalo-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3213",
  "https://www.pyroweb.de/blitzies-4er-pack-single-shots/":
    "JumpIn=1&WebID=2553&intItemID=2703",
  "https://www.pyroweb.de/bloodhound-144-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1548",
  "https://www.pyroweb.de/bloody-strobe-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2954",
  "https://www.pyroweb.de/blossom-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1960",
  "https://www.pyroweb.de/blossom-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3053",
  "https://www.pyroweb.de/blue-blood-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2722",
  "https://www.pyroweb.de/blue-mine-to-green-falling-leaves-with-silver-blink-42-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3933",
  "https://www.pyroweb.de/blue-mine-with-golden-spider-tail-100-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=2992",
  "https://www.pyroweb.de/blue-mobster-kunststoffauto/":
    "JumpIn=1&WebID=2553&intItemID=1869",
  "https://www.pyroweb.de/blumenfontaenen/":
    "JumpIn=1&WebID=2553&intItemID=3294",
  "https://www.pyroweb.de/blutsbrueder-21-schuss-kombinations-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2750",
  "https://www.pyroweb.de/bombas-blancas-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3835",
  "https://www.pyroweb.de/bombastica-27-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1915",
  "https://www.pyroweb.de/bombenstimmung-3er-single-shots/":
    "JumpIn=1&WebID=2553&intItemID=1635",
  "https://www.pyroweb.de/bombuster-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3064",
  "https://www.pyroweb.de/bonsai-mini-fontaene/":
    "JumpIn=1&WebID=2553&intItemID=2265",
  "https://www.pyroweb.de/born-to-rock-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3174",
  "https://www.pyroweb.de/brocade-und-blue-wall-765-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3947",
  "https://www.pyroweb.de/brocade-bomber-80-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2400",
  "https://www.pyroweb.de/brocade-bunte-peonie-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2482",
  "https://www.pyroweb.de/brocade-crown-color-tips-175-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2831",
  "https://www.pyroweb.de/brocade-crown-purple-parade/":
    "JumpIn=1&WebID=2553&intItemID=3938",
  "https://www.pyroweb.de/brocade-crown-silver-parade-175-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2832",
  "https://www.pyroweb.de/brocade-crown-to-color-wall-765-schuss/":
    "JumpIn=1&WebID=2553&intItemID=3946",
  "https://www.pyroweb.de/brocade-crown-with-blue-tip-175-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2398",
  "https://www.pyroweb.de/brocade-crown-with-red-tip-175-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2396",
  "https://www.pyroweb.de/brocade-mine-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1946",
  "https://www.pyroweb.de/brocade-to-red-mine-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1944",
  "https://www.pyroweb.de/brocade-to-silver-mine-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1945",
  "https://www.pyroweb.de/broekhoff-eisfontaene-herz/":
    "JumpIn=1&WebID=2553&intItemID=1362",
  "https://www.pyroweb.de/broekhoff-grondbloem-bodenwirbel/":
    "JumpIn=1&WebID=2553&intItemID=1356",
  "https://www.pyroweb.de/broekhoff-knallerbsen-tricky-mega/":
    "JumpIn=1&WebID=2553&intItemID=1358",
  "https://www.pyroweb.de/broekhoff-mega-tricky/":
    "JumpIn=1&WebID=2553&intItemID=3241",
  "https://www.pyroweb.de/broekhoff-crazy-fire/":
    "JumpIn=1&WebID=2553&intItemID=3103",
  "https://www.pyroweb.de/broekhoff-mojo-wonder/":
    "JumpIn=1&WebID=2553&intItemID=3245",
  "https://www.pyroweb.de/brokat-pastell-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3788",
  "https://www.pyroweb.de/brokat-fontaene-im-6-er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1725",
  "https://www.pyroweb.de/brokat-fontaene-mit-blauer-basis-6-er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1726",
  "https://www.pyroweb.de/brokat-fontaene-titansilber-6-er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1727",
  "https://www.pyroweb.de/brokatregen-fontaenen/":
    "JumpIn=1&WebID=2553&intItemID=3853",
  "https://www.pyroweb.de/brummkreisel-3er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=88",
  "https://www.pyroweb.de/brutal-buffalo-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3843",
  "https://www.pyroweb.de/bull-frogs-4er/":
    "JumpIn=1&WebID=2553&intItemID=3089",
  "https://www.pyroweb.de/bunte-flamme-4er/":
    "JumpIn=1&WebID=2553&intItemID=2361",
  "https://www.pyroweb.de/bunte-peonie-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2481",
  "https://www.pyroweb.de/burner-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3861",
  "https://www.pyroweb.de/burning-air-116-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2365",
  "https://www.pyroweb.de/burning-wolf-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1936",
  "https://www.pyroweb.de/buehnen-effektwerfer-xxl-metallig-silber/":
    "JumpIn=1&WebID=2553&intItemID=3824",
  "https://www.pyroweb.de/boellerbox-mit-individuellem-namen-holz/":
    "JumpIn=1&WebID=2553&intItemID=1808",
  "https://www.pyroweb.de/cake-16-1-30-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2594",
  "https://www.pyroweb.de/cake-16-2-30-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2595",
  "https://www.pyroweb.de/california-dreaming-72-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3061",
  "https://www.pyroweb.de/caminus-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1887",
  "https://www.pyroweb.de/candy-mountain-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3233",
  "https://www.pyroweb.de/cap-rocket-bommetje-met-munitie/":
    "JumpIn=1&WebID=2553&intItemID=2847",
  "https://www.pyroweb.de/capri-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2974",
  "https://www.pyroweb.de/captain-red-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2705",
  "https://www.pyroweb.de/carolus-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3003",
  "https://www.pyroweb.de/celebr8/": "JumpIn=1&WebID=2553&intItemID=3081",
  "https://www.pyroweb.de/charivari/": "JumpIn=1&WebID=2553&intItemID=3817",
  "https://www.pyroweb.de/chilli-spinners/":
    "JumpIn=1&WebID=2553&intItemID=1366",
  "https://www.pyroweb.de/china-boeller-a-240er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1643",
  "https://www.pyroweb.de/china-boeller-b-240er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1644",
  "https://www.pyroweb.de/china-boeller-d-80-stueck/":
    "JumpIn=1&WebID=2553&intItemID=3216",
  "https://www.pyroweb.de/china-boeller-d-80er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1645",
  "https://www.pyroweb.de/chinese-wall-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3128",
  "https://www.pyroweb.de/chronos-12-teiliges-raketenset/":
    "JumpIn=1&WebID=2553&intItemID=3247",
  "https://www.pyroweb.de/cimelia-69-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2676",
  "https://www.pyroweb.de/cito-color-48-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1890",
  "https://www.pyroweb.de/city-lights-10-teiliges-leuchtsortiment/":
    "JumpIn=1&WebID=2553&intItemID=2740",
  "https://www.pyroweb.de/clean-machine-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3871",
  "https://www.pyroweb.de/cleopatra-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1829",
  "https://www.pyroweb.de/cloud-chaser-3er-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3311",
  "https://www.pyroweb.de/cluster-v20-25-schuss-premium-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1825",
  "https://www.pyroweb.de/cobra-3-boeller-von-di-blasio-elio-fireworks-5er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1124",
  "https://www.pyroweb.de/cobra-3-r-3er-packung/":
    "JumpIn=1&WebID=2553&intItemID=1699",
  "https://www.pyroweb.de/cobra-6-reibkopfknaller-3er-silber/":
    "JumpIn=1&WebID=2553&intItemID=1938",
  "https://www.pyroweb.de/cobra-black-reibkopfknaller-50er-packung/":
    "JumpIn=1&WebID=2553&intItemID=1700",
  "https://www.pyroweb.de/cobra-mini-30er-packung/":
    "JumpIn=1&WebID=2553&intItemID=1924",
  "https://www.pyroweb.de/code-red-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3873",
  "https://www.pyroweb.de/color-cascade/": "JumpIn=1&WebID=2553&intItemID=2059",
  "https://www.pyroweb.de/color-crackling-balls/":
    "JumpIn=1&WebID=2553&intItemID=1351",
  "https://www.pyroweb.de/color-crossette-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3026",
  "https://www.pyroweb.de/color-experiment-84-schuss-komplettfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3009",
  "https://www.pyroweb.de/color-me-crazy/":
    "JumpIn=1&WebID=2553&intItemID=2893",
  "https://www.pyroweb.de/ster-color-sparklers/":
    "JumpIn=1&WebID=2553&intItemID=1344",
  "https://www.pyroweb.de/color-thunders-50er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=3048",
  "https://www.pyroweb.de/coloreado-st8ment-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3976",
  "https://www.pyroweb.de/colour-cracker-20er/":
    "JumpIn=1&WebID=2553&intItemID=2622",
  "https://www.pyroweb.de/colour-flames-4er/":
    "JumpIn=1&WebID=2553&intItemID=2269",
  "https://www.pyroweb.de/coloured-crossette-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1857",
  "https://www.pyroweb.de/coloured-leopard-72-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3845",
  "https://www.pyroweb.de/colourful-glittering-willow-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1871",
  "https://www.pyroweb.de/comet-familenzauber-familiensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1755",
  "https://www.pyroweb.de/comet-familien-mix-deluxe-familiensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1756",
  "https://www.pyroweb.de/comet-goldene-wunderkerzen-50cm-5er/":
    "JumpIn=1&WebID=2553&intItemID=1761",
  "https://www.pyroweb.de/comet-high-professional-220-schuss/":
    "JumpIn=1&WebID=2553&intItemID=947",
  "https://www.pyroweb.de/comet-party-alarm-12-teiliges-stimmungssortiment/":
    "JumpIn=1&WebID=2553&intItemID=1758",
  "https://www.pyroweb.de/comet-schwindelfrei-24-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1759",
  "https://www.pyroweb.de/comet-thriller-30-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1718",
  "https://www.pyroweb.de/comet-wunschraketen-4er-set/":
    "JumpIn=1&WebID=2553&intItemID=1760",
  "https://www.pyroweb.de/confetti-shooter-80-cm-herzen-rot/":
    "JumpIn=1&WebID=2553&intItemID=3776",
  "https://www.pyroweb.de/confetti-shooter-80cm-bunt-metallic/":
    "JumpIn=1&WebID=2553&intItemID=3775",
  "https://www.pyroweb.de/confetti-shooter-30cm/":
    "JumpIn=1&WebID=2553&intItemID=3091",
  "https://www.pyroweb.de/confetti-shooter-gold-30-cm/":
    "JumpIn=1&WebID=2553&intItemID=2249",
  "https://www.pyroweb.de/confetti-shooter-schmetterlinge-30-cm/":
    "JumpIn=1&WebID=2553&intItemID=3134",
  "https://www.pyroweb.de/confetti-shooter-weisse-herzen-30-cm/":
    "JumpIn=1&WebID=2553&intItemID=3133",
  "https://www.pyroweb.de/conic-crown-fountain/":
    "JumpIn=1&WebID=2553&intItemID=3923",
  "https://www.pyroweb.de/cooles-jugendfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1336",
  "https://www.pyroweb.de/core-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2529",
  "https://www.pyroweb.de/corolla-blue-100-schuss-verbundfeuerwerk-13g/":
    "JumpIn=1&WebID=2553&intItemID=2944",
  "https://www.pyroweb.de/cosmic-comets-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2037",
  "https://www.pyroweb.de/cracker-fontaenen-mix-5er/":
    "JumpIn=1&WebID=2553&intItemID=2656",
  "https://www.pyroweb.de/crackerstern-fontaene-5er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2497",
  "https://www.pyroweb.de/crackling-1/": "JumpIn=1&WebID=2553&intItemID=3122",
  "https://www.pyroweb.de/crackling-astronaut/":
    "JumpIn=1&WebID=2553&intItemID=3068",
  "https://www.pyroweb.de/crackling-bird-feuervogel/":
    "JumpIn=1&WebID=2553&intItemID=1872",
  "https://www.pyroweb.de/crackling-bullets/":
    "JumpIn=1&WebID=2553&intItemID=3868",
  "https://www.pyroweb.de/crackling-carpet-knatter-peitschen/":
    "JumpIn=1&WebID=2553&intItemID=2995",
  "https://www.pyroweb.de/crackling-dragon/":
    "JumpIn=1&WebID=2553&intItemID=3070",
  "https://www.pyroweb.de/crackling-fire-vulkan/":
    "JumpIn=1&WebID=2553&intItemID=3759",
  "https://www.pyroweb.de/crackling-palms-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2955",
  "https://www.pyroweb.de/crackling-thunder-mix/":
    "JumpIn=1&WebID=2553&intItemID=3855",
  "https://www.pyroweb.de/crackling-thunder-100-stueck/":
    "JumpIn=1&WebID=2553&intItemID=2978",
  "https://www.pyroweb.de/crackling-faecherbatterie-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2480",
  "https://www.pyroweb.de/crackling-vulkan/":
    "JumpIn=1&WebID=2553&intItemID=2485",
  "https://www.pyroweb.de/cranky-cactus/": "JumpIn=1&WebID=2553&intItemID=3859",
  "https://www.pyroweb.de/crazy-box-83-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2872",
  "https://www.pyroweb.de/crazy-gold-60-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2691",
  "https://www.pyroweb.de/creepers/": "JumpIn=1&WebID=2553&intItemID=1367",
  "https://www.pyroweb.de/criminal-pitstop-300-schuss-komplettfeuerwerk-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=3767",
  "https://www.pyroweb.de/crimson-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2525",
  "https://www.pyroweb.de/crooky-bastard-224-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3765",
  "https://www.pyroweb.de/crossfire-crossette-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1860",
  "https://www.pyroweb.de/crouching-tiger-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3761",
  "https://www.pyroweb.de/cubic-thunder-3er-pack/":
    "JumpIn=1&WebID=2553&intItemID=851",
  "https://www.pyroweb.de/cuby-blizzard/": "JumpIn=1&WebID=2553&intItemID=2765",
  "https://www.pyroweb.de/cuckoo-cuckoo/": "JumpIn=1&WebID=2553&intItemID=2609",
  "https://www.pyroweb.de/cuckoo-cuckoo-fontaene-2/":
    "JumpIn=1&WebID=2553&intItemID=2650",
  "https://www.pyroweb.de/cuckoo-cuckoo-fontaenen-12/":
    "JumpIn=1&WebID=2553&intItemID=2649",
  "https://www.pyroweb.de/curves-fontaenen-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1661",
  "https://www.pyroweb.de/cyber-crasher-108-schuss-verbundfeuerwerk-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=3763",
  "https://www.pyroweb.de/cyclone/": "JumpIn=1&WebID=2553&intItemID=3075",
  "https://www.pyroweb.de/danger-ranger-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3972",
  "https://www.pyroweb.de/das-gold-monster-126-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2219",
  "https://www.pyroweb.de/deep-pulse-30-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3870",
  "https://www.pyroweb.de/deko-kicker-party-4er/":
    "JumpIn=1&WebID=2553&intItemID=3444",
  "https://www.pyroweb.de/deko-kit-beautiful-horses/":
    "JumpIn=1&WebID=2553&intItemID=3372",
  "https://www.pyroweb.de/deko-mini-magical-unicorn/":
    "JumpIn=1&WebID=2553&intItemID=3363",
  "https://www.pyroweb.de/deko-spiralen-beautiful-horses-6er/":
    "JumpIn=1&WebID=2553&intItemID=3373",
  "https://www.pyroweb.de/deko-spiralen-18-gold-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3559",
  "https://www.pyroweb.de/deko-spiralen-18-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3504",
  "https://www.pyroweb.de/deko-spiralen-18-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3564",
  "https://www.pyroweb.de/deko-spiralen-21-gold-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3715",
  "https://www.pyroweb.de/deko-spiralen-21-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3510",
  "https://www.pyroweb.de/deko-spiralen-21-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3722",
  "https://www.pyroweb.de/deko-spiralen-30-gold-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3578",
  "https://www.pyroweb.de/deko-spiralen-30-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3515",
  "https://www.pyroweb.de/deko-spiralen-30-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3563",
  "https://www.pyroweb.de/deko-spiralen-40-gold-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3577",
  "https://www.pyroweb.de/deko-spiralen-40-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3519",
  "https://www.pyroweb.de/deko-spiralen-40-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3562",
  "https://www.pyroweb.de/deko-spiralen-50-gold-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3566",
  "https://www.pyroweb.de/deko-spiralen-50-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3523",
  "https://www.pyroweb.de/deko-spiralen-50-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3561",
  "https://www.pyroweb.de/deko-spiralen-60-gold-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3565",
  "https://www.pyroweb.de/deko-spiralen-60-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3529",
  "https://www.pyroweb.de/deko-spiralen-60-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3560",
  "https://www.pyroweb.de/deko-spiralen-70-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3537",
  "https://www.pyroweb.de/deko-spiralen-70-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3704",
  "https://www.pyroweb.de/deko-spiralen-70-sparkling-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3689",
  "https://www.pyroweb.de/deko-spiralen-80-happy-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3544",
  "https://www.pyroweb.de/deko-spiralen-80-pink-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3710",
  "https://www.pyroweb.de/deko-spiralen-80-sparkling-folie-12er/":
    "JumpIn=1&WebID=2553&intItemID=3690",
  "https://www.pyroweb.de/deko-spiralen-confetti-birthday-4er/":
    "JumpIn=1&WebID=2553&intItemID=3496",
  "https://www.pyroweb.de/deko-spiralen-confetti-birthday-6er/":
    "JumpIn=1&WebID=2553&intItemID=3495",
  "https://www.pyroweb.de/deko-spiralen-fireman-sam-6er/":
    "JumpIn=1&WebID=2553&intItemID=3410",
  "https://www.pyroweb.de/deko-spiralen-magical-unicorn-12er/":
    "JumpIn=1&WebID=2553&intItemID=3362",
  "https://www.pyroweb.de/deko-spiralen-paw-patrol-6er/":
    "JumpIn=1&WebID=2553&intItemID=3385",
  "https://www.pyroweb.de/deko-spiralen-schulstart-papier-6er/":
    "JumpIn=1&WebID=2553&intItemID=3325",
  "https://www.pyroweb.de/delta-fire-r10-200-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=3228",
  "https://www.pyroweb.de/der-klopper-50-schuss-betterie/":
    "JumpIn=1&WebID=2553&intItemID=3762",
  "https://www.pyroweb.de/detonator-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=623",
  "https://www.pyroweb.de/diabolo-72-teilges-jugendsortiment/":
    "JumpIn=1&WebID=2553&intItemID=3093",
  "https://www.pyroweb.de/dicker-brummer-xxl/":
    "JumpIn=1&WebID=2553&intItemID=2492",
  "https://www.pyroweb.de/die-volle-droehnung-mega-shot-boeller-set/":
    "JumpIn=1&WebID=2553&intItemID=2500",
  "https://www.pyroweb.de/dino-power/": "JumpIn=1&WebID=2553&intItemID=1153",
  "https://www.pyroweb.de/diva-liga-44-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2271",
  "https://www.pyroweb.de/dna-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2364",
  "https://www.pyroweb.de/dock-12-10-schuss-feuerwerksbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3239",
  "https://www.pyroweb.de/doctor-beam-mini-fontaenen/":
    "JumpIn=1&WebID=2553&intItemID=3071",
  "https://www.pyroweb.de/donnerdrachen-19-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1069",
  "https://www.pyroweb.de/donnervogel-kaliber-b-12er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2921",
  "https://www.pyroweb.de/donnerwuerfel-6er/":
    "JumpIn=1&WebID=2553&intItemID=2503",
  "https://www.pyroweb.de/doppelknall-doubleshock-5er-set/":
    "JumpIn=1&WebID=2553&intItemID=1662",
  "https://www.pyroweb.de/doppelschlag-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2953",
  "https://www.pyroweb.de/double-action-57-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1338",
  "https://www.pyroweb.de/double-deck-blue-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3962",
  "https://www.pyroweb.de/double-deck-green-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2747",
  "https://www.pyroweb.de/double-deck-inferno-108-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2749",
  "https://www.pyroweb.de/double-deck-purple-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1864",
  "https://www.pyroweb.de/double-deck-red-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1863",
  "https://www.pyroweb.de/double-trouble-205-schuss-verbundfeuerwerk-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=3891",
  "https://www.pyroweb.de/double-power-11-schuss/":
    "JumpIn=1&WebID=2553&intItemID=3900",
  "https://www.pyroweb.de/downforce-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3735",
  "https://www.pyroweb.de/downhill-thrill-93-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3866",
  "https://www.pyroweb.de/drachentanz-49-teiliges-familien-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2768",
  "https://www.pyroweb.de/drag-racer-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3890",
  "https://www.pyroweb.de/dragon-eggs/": "JumpIn=1&WebID=2553&intItemID=3796",
  "https://www.pyroweb.de/dragon-empire-399-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2152",
  "https://www.pyroweb.de/dragon-inferno/":
    "JumpIn=1&WebID=2553&intItemID=2395",
  "https://www.pyroweb.de/dragon-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2570",
  "https://www.pyroweb.de/drama-queen-56-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2260",
  "https://www.pyroweb.de/dream-team/": "JumpIn=1&WebID=2553&intItemID=1373",
  "https://www.pyroweb.de/dreamland-49-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2347",
  "https://www.pyroweb.de/dropbox-hero-150-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2704",
  "https://www.pyroweb.de/drummer-hammer-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3961",
  "https://www.pyroweb.de/dualsmoke-38mm-rot/":
    "JumpIn=1&WebID=2553&intItemID=3986",
  "https://www.pyroweb.de/dum-bum-black-powder-pirat/":
    "JumpIn=1&WebID=2553&intItemID=3978",
  "https://www.pyroweb.de/dum-bum-explosive-ball/":
    "JumpIn=1&WebID=2553&intItemID=3979",
  "https://www.pyroweb.de/duplex-cracker-rain-20-schuss-batterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=2931",
  "https://www.pyroweb.de/e-raptor-24-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3958",
  "https://www.pyroweb.de/edaha-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3906",
  "https://www.pyroweb.de/edelstein-mix-4x-12-schuss-batterie-set/":
    "JumpIn=1&WebID=2553&intItemID=2734",
  "https://www.pyroweb.de/eden-36-schuss-crossette-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2510",
  "https://www.pyroweb.de/eins-zwei-polizei-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2340",
  "https://www.pyroweb.de/eisbrecher-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2827",
  "https://www.pyroweb.de/eissphinx-10-schuss-feuerwerksbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3238",
  "https://www.pyroweb.de/elektrozuender-35cm-mit-zuendpille/":
    "JumpIn=1&WebID=2553&intItemID=315",
  "https://www.pyroweb.de/elementum-144-schuss-verbundfeuerwerk-2/":
    "JumpIn=1&WebID=2553&intItemID=2461",
  "https://www.pyroweb.de/elysis-54-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2329",
  "https://www.pyroweb.de/endless-power-esp/":
    "JumpIn=1&WebID=2553&intItemID=1048",
  "https://www.pyroweb.de/endless-shooter-xxl-192-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1874",
  "https://www.pyroweb.de/energia-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2916",
  "https://www.pyroweb.de/epsilon-13-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1361",
  "https://www.pyroweb.de/erkloenig-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2017",
  "https://www.pyroweb.de/escaladas-fontaenenmix/":
    "JumpIn=1&WebID=2553&intItemID=3218",
  "https://www.pyroweb.de/estate-box-75-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2603",
  "https://www.pyroweb.de/etna-bulkpack/": "JumpIn=1&WebID=2553&intItemID=3102",
  "https://www.pyroweb.de/euphoria-v20-13-schuss-premium-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1821",
  "https://www.pyroweb.de/event-torch-red-2er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1634",
  "https://www.pyroweb.de/exclusive-collection-i-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2511",
  "https://www.pyroweb.de/exotica-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3292",
  "https://www.pyroweb.de/explorer-210-schuss-roemische-lichter/":
    "JumpIn=1&WebID=2553&intItemID=71",
  "https://www.pyroweb.de/express-to-hell-120-schuss-multi-effekt-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1409",
  "https://www.pyroweb.de/expression-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3786",
  "https://www.pyroweb.de/extreme-2-pack-70-schuss-verbund/":
    "JumpIn=1&WebID=2553&intItemID=2394",
  "https://www.pyroweb.de/extreme-strobe/":
    "JumpIn=1&WebID=2553&intItemID=3883",
  "https://www.pyroweb.de/extreme-zensation-100-schuss-komplettfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2401",
  "https://www.pyroweb.de/fu-fireworks-university-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2833",
  "https://www.pyroweb.de/f07-volcano-4er-set/":
    "JumpIn=1&WebID=2553&intItemID=2293",
  "https://www.pyroweb.de/f10-vulcano-4er-set/":
    "JumpIn=1&WebID=2553&intItemID=2294",
  "https://www.pyroweb.de/f15-vulcano-2er-set/":
    "JumpIn=1&WebID=2553&intItemID=2296",
  "https://www.pyroweb.de/f25-volcano-2er-set/":
    "JumpIn=1&WebID=2553&intItemID=2298",
  "https://www.pyroweb.de/fahne-pirat/": "JumpIn=1&WebID=2553&intItemID=3436",
  "https://www.pyroweb.de/falling-leaves-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2374",
  "https://www.pyroweb.de/family-pack/": "JumpIn=1&WebID=2553&intItemID=3302",
  "https://www.pyroweb.de/fanshape-crossette-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2375",
  "https://www.pyroweb.de/fantastico-170-teiliges-familiensortiment/":
    "JumpIn=1&WebID=2553&intItemID=3201",
  "https://www.pyroweb.de/fantasy-eissterne-xxl/":
    "JumpIn=1&WebID=2553&intItemID=1335",
  "https://www.pyroweb.de/fantasy-fun/": "JumpIn=1&WebID=2553&intItemID=3104",
  "https://www.pyroweb.de/fantasy-eissterne/":
    "JumpIn=1&WebID=2553&intItemID=401",
  "https://www.pyroweb.de/fantroete-fc-bayern-muenchen/":
    "JumpIn=1&WebID=2553&intItemID=3484",
  "https://www.pyroweb.de/farbkometen-powerpack/":
    "JumpIn=1&WebID=2553&intItemID=3146",
  "https://www.pyroweb.de/farbrausch-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2214",
  "https://www.pyroweb.de/feel-the-force-210-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2710",
  "https://www.pyroweb.de/feronia-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2926",
  "https://www.pyroweb.de/feuerfalke-feuervogel-set/":
    "JumpIn=1&WebID=2553&intItemID=2644",
  "https://www.pyroweb.de/feuerkegel-no1-blau-ganzjaehrig/":
    "JumpIn=1&WebID=2553&intItemID=709",
  "https://www.pyroweb.de/feuerkegel-no2-rot-ganzjaehrig/":
    "JumpIn=1&WebID=2553&intItemID=710",
  "https://www.pyroweb.de/feuerringe/": "JumpIn=1&WebID=2553&intItemID=1381",
  "https://www.pyroweb.de/feuerspucker/": "JumpIn=1&WebID=2553&intItemID=1379",
  "https://www.pyroweb.de/feuersaeule-no1-ganzjaehrig/":
    "JumpIn=1&WebID=2553&intItemID=708",
  "https://www.pyroweb.de/feuerteufel/": "JumpIn=1&WebID=2553&intItemID=2259",
  "https://www.pyroweb.de/feuertopf-rattle-bang/":
    "JumpIn=1&WebID=2553&intItemID=2125",
  "https://www.pyroweb.de/feuerzauber-207-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2690",
  "https://www.pyroweb.de/fiesta-mexicana-31-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2346",
  "https://www.pyroweb.de/figurenkerze-beautiful-horses/":
    "JumpIn=1&WebID=2553&intItemID=3374",
  "https://www.pyroweb.de/figurenkerze-fireman-sam/":
    "JumpIn=1&WebID=2553&intItemID=3416",
  "https://www.pyroweb.de/figurenkerze-mini-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3595",
  "https://www.pyroweb.de/figurenkerze-paw-patrol/":
    "JumpIn=1&WebID=2553&intItemID=3389",
  "https://www.pyroweb.de/finalbox-blau-9-schuss-batterie-3er-kiste/":
    "JumpIn=1&WebID=2553&intItemID=2217",
  "https://www.pyroweb.de/finalbox-gold-9-schuss-batterie-3er-kiste/":
    "JumpIn=1&WebID=2553&intItemID=2218",
  "https://www.pyroweb.de/fiori-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3884",
  "https://www.pyroweb.de/fire-blue/": "JumpIn=1&WebID=2553&intItemID=2403",
  "https://www.pyroweb.de/fire-eyes-bodenwirbel/":
    "JumpIn=1&WebID=2553&intItemID=3100",
  "https://www.pyroweb.de/fire-gold/": "JumpIn=1&WebID=2553&intItemID=2405",
  "https://www.pyroweb.de/fire-green/": "JumpIn=1&WebID=2553&intItemID=2407",
  "https://www.pyroweb.de/fire-pack-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=3101",
  "https://www.pyroweb.de/fire-purple/": "JumpIn=1&WebID=2553&intItemID=1349",
  "https://www.pyroweb.de/fire-rainbow-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3310",
  "https://www.pyroweb.de/fire-red/": "JumpIn=1&WebID=2553&intItemID=2404",
  "https://www.pyroweb.de/fire-silver/": "JumpIn=1&WebID=2553&intItemID=2406",
  "https://www.pyroweb.de/fire-sphinx-volcano/":
    "JumpIn=1&WebID=2553&intItemID=3072",
  "https://www.pyroweb.de/fire-vulkan/": "JumpIn=1&WebID=2553&intItemID=3760",
  "https://www.pyroweb.de/firebowls/": "JumpIn=1&WebID=2553&intItemID=1382",
  "https://www.pyroweb.de/fireevent-1960er-oldschool-china-boeller-d/":
    "JumpIn=1&WebID=2553&intItemID=1637",
  "https://www.pyroweb.de/fireevent-firecrackers-donnerschlag-3er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2736",
  "https://www.pyroweb.de/firefall-vulkanverbund/":
    "JumpIn=1&WebID=2553&intItemID=3217",
  "https://www.pyroweb.de/fireflood-49-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1079",
  "https://www.pyroweb.de/firefly-funk-zuendanlage-inkl-upgrade-auf-wlan/":
    "JumpIn=1&WebID=2553&intItemID=2232",
  "https://www.pyroweb.de/firewall-broekhoff/":
    "JumpIn=1&WebID=2553&intItemID=2408",
  "https://www.pyroweb.de/firewall-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1519",
  "https://www.pyroweb.de/fireworks-box-8-teilig-2/":
    "JumpIn=1&WebID=2553&intItemID=3080",
  "https://www.pyroweb.de/firlefanz-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3785",
  "https://www.pyroweb.de/first-strike-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3813",
  "https://www.pyroweb.de/flakkas/": "JumpIn=1&WebID=2553&intItemID=3857",
  "https://www.pyroweb.de/flame-falls-51-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3815",
  "https://www.pyroweb.de/flare-green-5er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2306",
  "https://www.pyroweb.de/flare-red-5er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2305",
  "https://www.pyroweb.de/flaschenoeffner/":
    "JumpIn=1&WebID=2553&intItemID=3665",
  "https://www.pyroweb.de/flash-fire-purple-crackling/":
    "JumpIn=1&WebID=2553&intItemID=2997",
  "https://www.pyroweb.de/flashing-fontains/":
    "JumpIn=1&WebID=2553&intItemID=2060",
  "https://www.pyroweb.de/flashing-thunder-10er-pack/":
    "JumpIn=1&WebID=2553&intItemID=843",
  "https://www.pyroweb.de/flashlight-8er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=96",
  "https://www.pyroweb.de/fliegergeschwader-7-teilig/":
    "JumpIn=1&WebID=2553&intItemID=958",
  "https://www.pyroweb.de/flitter-bombe-tischfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3018",
  "https://www.pyroweb.de/flora-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3004",
  "https://www.pyroweb.de/flotter-dreier-no-1-21-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3162",
  "https://www.pyroweb.de/flying-hattrick/":
    "JumpIn=1&WebID=2553&intItemID=3755",
  "https://www.pyroweb.de/flying-hearts-rot-30-cm-konfettishooter/":
    "JumpIn=1&WebID=2553&intItemID=2250",
  "https://www.pyroweb.de/folienballon-happy-birthday-gold/":
    "JumpIn=1&WebID=2553&intItemID=3653",
  "https://www.pyroweb.de/folienballon-happy-birthday-silber/":
    "JumpIn=1&WebID=2553&intItemID=3652",
  "https://www.pyroweb.de/fortuna-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1895",
  "https://www.pyroweb.de/foto-set-bvb-dortmund/":
    "JumpIn=1&WebID=2553&intItemID=3461",
  "https://www.pyroweb.de/fountain-box-20er-box/":
    "JumpIn=1&WebID=2553&intItemID=3231",
  "https://www.pyroweb.de/fountains-of-life-6er/":
    "JumpIn=1&WebID=2553&intItemID=2355",
  "https://www.pyroweb.de/four-in-one-123-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1340",
  "https://www.pyroweb.de/frankenstein-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2571",
  "https://www.pyroweb.de/frankinson-harley-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2588",
  "https://www.pyroweb.de/freestyler-64-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2344",
  "https://www.pyroweb.de/freimaurer-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3914",
  "https://www.pyroweb.de/frontline-fire-128-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2844",
  "https://www.pyroweb.de/frosch-b-4er-schtl/":
    "JumpIn=1&WebID=2553&intItemID=845",
  "https://www.pyroweb.de/fuego-del-mar-75sh-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3837",
  "https://www.pyroweb.de/full-moon-party-117-schuss-verbundfeuerwerk-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=3123",
  "https://www.pyroweb.de/full-moon-10-teiliges-raketensortiment-2/":
    "JumpIn=1&WebID=2553&intItemID=3281",
  "https://www.pyroweb.de/full-power-16-schuss/":
    "JumpIn=1&WebID=2553&intItemID=66",
  "https://www.pyroweb.de/full-power-16-schuss-gruen/":
    "JumpIn=1&WebID=2553&intItemID=1089",
  "https://www.pyroweb.de/full-power-16-schuss-rot/":
    "JumpIn=1&WebID=2553&intItemID=1090",
  "https://www.pyroweb.de/fun-for-party/": "JumpIn=1&WebID=2553&intItemID=1330",
  "https://www.pyroweb.de/fun-pack-5-teilig/":
    "JumpIn=1&WebID=2553&intItemID=1415",
  "https://www.pyroweb.de/fun-pack-xxl-jugenfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=853",
  "https://www.pyroweb.de/funk-division-2er-set-super-vulkane/":
    "JumpIn=1&WebID=2553&intItemID=2593",
  "https://www.pyroweb.de/funke-honey-badger-5er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2623",
  "https://www.pyroweb.de/funke-titanschlag-blau-20er-box/":
    "JumpIn=1&WebID=2553&intItemID=2624",
  "https://www.pyroweb.de/funke-4m-fontaene-violett-gold/":
    "JumpIn=1&WebID=2553&intItemID=3250",
  "https://www.pyroweb.de/funke-5m-fontaene-gold/":
    "JumpIn=1&WebID=2553&intItemID=3254",
  "https://www.pyroweb.de/funke-5m-fontaene-gold-blau/":
    "JumpIn=1&WebID=2553&intItemID=3255",
  "https://www.pyroweb.de/funke-5m-fontaene-gruen-silber/":
    "JumpIn=1&WebID=2553&intItemID=3256",
  "https://www.pyroweb.de/funke-5m-fontaene-rot-silber/":
    "JumpIn=1&WebID=2553&intItemID=3252",
  "https://www.pyroweb.de/funke-5m-fontaene-silber/":
    "JumpIn=1&WebID=2553&intItemID=3257",
  "https://www.pyroweb.de/funke-5m-fontaene-violett-gold/":
    "JumpIn=1&WebID=2553&intItemID=3253",
  "https://www.pyroweb.de/funke-7m-fontaene-3-stufig/":
    "JumpIn=1&WebID=2553&intItemID=3028",
  "https://www.pyroweb.de/funke-7m-fontaene-gold/":
    "JumpIn=1&WebID=2553&intItemID=3027",
  "https://www.pyroweb.de/funke-austrum-z-1-70-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1685",
  "https://www.pyroweb.de/funke-bengalfeuer-blau/":
    "JumpIn=1&WebID=2553&intItemID=2177",
  "https://www.pyroweb.de/funke-bengalfeuer-gelb/":
    "JumpIn=1&WebID=2553&intItemID=2290",
  "https://www.pyroweb.de/funke-bengalfeuer-gruen/":
    "JumpIn=1&WebID=2553&intItemID=2176",
  "https://www.pyroweb.de/funke-bengalfeuer-rot/":
    "JumpIn=1&WebID=2553&intItemID=2175",
  "https://www.pyroweb.de/funke-bengalfeuer-violett/":
    "JumpIn=1&WebID=2553&intItemID=2291",
  "https://www.pyroweb.de/funke-bengalfeuer-mix-r-g-y-b-p/":
    "JumpIn=1&WebID=2553&intItemID=2256",
  "https://www.pyroweb.de/funke-blau-gruenblinker-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1988",
  "https://www.pyroweb.de/funke-blinkweiden-dahlien-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2208",
  "https://www.pyroweb.de/funke-blinkweiden-blau-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2179",
  "https://www.pyroweb.de/funke-blue-strobe-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2245",
  "https://www.pyroweb.de/funke-blaettertanz-10er-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1678",
  "https://www.pyroweb.de/funke-blaettertanz-rakete-blau/":
    "JumpIn=1&WebID=2553&intItemID=2537",
  "https://www.pyroweb.de/funke-blaettertanz-rakete-gelb/":
    "JumpIn=1&WebID=2553&intItemID=2539",
  "https://www.pyroweb.de/funke-blaettertanz-rakete-gruen/":
    "JumpIn=1&WebID=2553&intItemID=2538",
  "https://www.pyroweb.de/funke-blaettertanz-rakete-rot/":
    "JumpIn=1&WebID=2553&intItemID=2536",
  "https://www.pyroweb.de/funke-brocade-scala-z-70-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1987",
  "https://www.pyroweb.de/funke-boeller-a/":
    "JumpIn=1&WebID=2553&intItemID=1979",
  "https://www.pyroweb.de/funke-china-boeller-a/":
    "JumpIn=1&WebID=2553&intItemID=2251",
  "https://www.pyroweb.de/funke-china-boeller-c/":
    "JumpIn=1&WebID=2553&intItemID=2252",
  "https://www.pyroweb.de/funke-china-boeller-b/":
    "JumpIn=1&WebID=2553&intItemID=1980",
  "https://www.pyroweb.de/funke-chrys-rain-variations-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2236",
  "https://www.pyroweb.de/funke-club-colors-smoke-green/":
    "JumpIn=1&WebID=2553&intItemID=1811",
  "https://www.pyroweb.de/funke-club-colors-smoke-white/":
    "JumpIn=1&WebID=2553&intItemID=1813",
  "https://www.pyroweb.de/funke-colorblinker-gold-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1995",
  "https://www.pyroweb.de/funke-corolla-variations-20-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=2235",
  "https://www.pyroweb.de/funke-corolla-blue-z-1-56-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1986",
  "https://www.pyroweb.de/funke-cracker-variations-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2237",
  "https://www.pyroweb.de/funke-dahlienblinker-z-1-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1994",
  "https://www.pyroweb.de/funke-dahlienblinker-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2758",
  "https://www.pyroweb.de/funke-dahliencracker-f-1-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2273",
  "https://www.pyroweb.de/funke-dahliencracker-z-1-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1991",
  "https://www.pyroweb.de/funke-dahliencracker-z-2-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2172",
  "https://www.pyroweb.de/funke-dahliencracker-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1993",
  "https://www.pyroweb.de/funke-dorado-rakete/":
    "JumpIn=1&WebID=2553&intItemID=2541",
  "https://www.pyroweb.de/funke-draco-rakete/":
    "JumpIn=1&WebID=2553&intItemID=2535",
  "https://www.pyroweb.de/funke-funkenpalmen-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2205",
  "https://www.pyroweb.de/funke-geysir-kaliber-c-3er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2258",
  "https://www.pyroweb.de/funke-glittering-palms-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2241",
  "https://www.pyroweb.de/funke-gold-blue-16-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1680",
  "https://www.pyroweb.de/funke-gold-blue-variations-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2234",
  "https://www.pyroweb.de/funke-goldfunken-6er-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1676",
  "https://www.pyroweb.de/funke-goldpalm-blue-z-1-56-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1683",
  "https://www.pyroweb.de/funke-himmelsstuermer-13er-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1675",
  "https://www.pyroweb.de/funke-hydra-rakete/":
    "JumpIn=1&WebID=2553&intItemID=2540",
  "https://www.pyroweb.de/funke-kamuro-variations-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2238",
  "https://www.pyroweb.de/funke-kamuro-mix-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1990",
  "https://www.pyroweb.de/funke-kamuro-weissblinker-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2289",
  "https://www.pyroweb.de/funke-kamuro-weissblinker-36-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=2460",
  "https://www.pyroweb.de/funke-kawumm-boeller-set/":
    "JumpIn=1&WebID=2553&intItemID=1981",
  "https://www.pyroweb.de/funke-knallfroesche-kaliber-b-5er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1670",
  "https://www.pyroweb.de/funke-knallfroesche-kaliber-c-4er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1671",
  "https://www.pyroweb.de/funke-knallfroesche-kaliber-d-4er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1672",
  "https://www.pyroweb.de/funke-kometenschauer-6er-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1677",
  "https://www.pyroweb.de/funke-laserballs-6er-beutel/":
    "JumpIn=1&WebID=2553&intItemID=2759",
  "https://www.pyroweb.de/funke-lemon-blue-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2181",
  "https://www.pyroweb.de/funke-mashang-188-schuss-komplettfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1999",
  "https://www.pyroweb.de/funke-mei-banfa-206-schuss-komplettfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2000",
  "https://www.pyroweb.de/funke-octuplus-heuler-3er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=1669",
  "https://www.pyroweb.de/funke-petarde-10er/":
    "JumpIn=1&WebID=2553&intItemID=2352",
  "https://www.pyroweb.de/funke-phoenix-rakete/":
    "JumpIn=1&WebID=2553&intItemID=2533",
  "https://www.pyroweb.de/funke-rainbow-in-the-dark-1-42-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1996",
  "https://www.pyroweb.de/funke-rainbow-in-the-dark-3-42-schuss-faecherbatterie-2/":
    "JumpIn=1&WebID=2553&intItemID=1998",
  "https://www.pyroweb.de/funke-red-blue-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2242",
  "https://www.pyroweb.de/funke-regenbogen-fontaene/":
    "JumpIn=1&WebID=2553&intItemID=2171",
  "https://www.pyroweb.de/funke-riesen-fontaene-2/":
    "JumpIn=1&WebID=2553&intItemID=2631",
  "https://www.pyroweb.de/funke-riesen-fontaene-3/":
    "JumpIn=1&WebID=2553&intItemID=2632",
  "https://www.pyroweb.de/funke-riesen-fontaene-4/":
    "JumpIn=1&WebID=2553&intItemID=2633",
  "https://www.pyroweb.de/funke-scala-25b-25-schuss-batterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=2896",
  "https://www.pyroweb.de/funke-scala-s25-b-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1679",
  "https://www.pyroweb.de/funke-silber-raketen-13-teiliges-set/":
    "JumpIn=1&WebID=2553&intItemID=1983",
  "https://www.pyroweb.de/funke-silberpalmen-blinker-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2207",
  "https://www.pyroweb.de/funke-silberpalmen-bunt-f-1-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2243",
  "https://www.pyroweb.de/funke-silberpalmen-bunt-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2239",
  "https://www.pyroweb.de/funke-snowblind-25s-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1681",
  "https://www.pyroweb.de/funke-snowblind-z-1-56-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1682",
  "https://www.pyroweb.de/funke-sternraketen-10-teiliges-set/":
    "JumpIn=1&WebID=2553&intItemID=1982",
  "https://www.pyroweb.de/funke-strobe-variations-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2240",
  "https://www.pyroweb.de/funke-taurus-rakete/":
    "JumpIn=1&WebID=2553&intItemID=2534",
  "https://www.pyroweb.de/funke-the-wall-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2244",
  "https://www.pyroweb.de/funke-tornado-wirbel-4er/":
    "JumpIn=1&WebID=2553&intItemID=2178",
  "https://www.pyroweb.de/funke-weissblinker-pink-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2209",
  "https://www.pyroweb.de/funke-weissblinker-dahlien-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2180",
  "https://www.pyroweb.de/funke-weissblinker-dahlien-z-1-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1992",
  "https://www.pyroweb.de/funke-weissblinker-dahlien-z-2-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2272",
  "https://www.pyroweb.de/funke-7m-fontaene-blau-gold/":
    "JumpIn=1&WebID=2553&intItemID=3030",
  "https://www.pyroweb.de/funke-7m-fontaene-gruen-silber/":
    "JumpIn=1&WebID=2553&intItemID=3029",
  "https://www.pyroweb.de/funke-7m-fontaene-kamuro/":
    "JumpIn=1&WebID=2553&intItemID=3031",
  "https://www.pyroweb.de/funke-rauchbaelle/":
    "JumpIn=1&WebID=2553&intItemID=3032",
  "https://www.pyroweb.de/funke-rauchspur-geschwader/":
    "JumpIn=1&WebID=2553&intItemID=3022",
  "https://www.pyroweb.de/funken-finale-1-36-schuss-batterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=1984",
  "https://www.pyroweb.de/funken-finale-2-36-schuss-batterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=3006",
  "https://www.pyroweb.de/funkencracker-fontaenen-5er/":
    "JumpIn=1&WebID=2553&intItemID=2655",
  "https://www.pyroweb.de/funkenweide-rotblinker-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3915",
  "https://www.pyroweb.de/funkenweide-scala-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3916",
  "https://www.pyroweb.de/funkenweide-violett-25-schuss-batterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=2946",
  "https://www.pyroweb.de/funtastic-rockets/":
    "JumpIn=1&WebID=2553&intItemID=3966",
  "https://www.pyroweb.de/fuse-connector-4er/":
    "JumpIn=1&WebID=2553&intItemID=2268",
  "https://www.pyroweb.de/fusion-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2530",
  "https://www.pyroweb.de/fusion-54-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1136",
  "https://www.pyroweb.de/future-class-30-teiliges-leuchtsortiment/":
    "JumpIn=1&WebID=2553&intItemID=2669",
  "https://www.pyroweb.de/future-hulk-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3734",
  "https://www.pyroweb.de/future-rockets-bombenschlag-feuertoepfe/":
    "JumpIn=1&WebID=2553&intItemID=2730",
  "https://www.pyroweb.de/galactic-storm-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3841",
  "https://www.pyroweb.de/galaxy-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1454",
  "https://www.pyroweb.de/galeras-silbervulkane-10er-set/":
    "JumpIn=1&WebID=2553&intItemID=2652",
  "https://www.pyroweb.de/game-on-60-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2757",
  "https://www.pyroweb.de/gaoo-wunderkerzen-28cm/":
    "JumpIn=1&WebID=2553&intItemID=3181",
  "https://www.pyroweb.de/garden-surprise-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1977",
  "https://www.pyroweb.de/geburtstagsfontaene-rot/":
    "JumpIn=1&WebID=2553&intItemID=281",
  "https://www.pyroweb.de/geburtstagsfontaene-kinder/":
    "JumpIn=1&WebID=2553&intItemID=1014",
  "https://www.pyroweb.de/gefahrguttransportkiste/":
    "JumpIn=1&WebID=2553&intItemID=3766",
  "https://www.pyroweb.de/gemini-doppelschlag-raketen/":
    "JumpIn=1&WebID=2553&intItemID=2610",
  "https://www.pyroweb.de/geoshocks-thunderkong/":
    "JumpIn=1&WebID=2553&intItemID=3860",
  "https://www.pyroweb.de/geysir-kaliber-b-10er/":
    "JumpIn=1&WebID=2553&intItemID=2828",
  "https://www.pyroweb.de/ghost-lights-16-schuss-brilliant-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1701",
  "https://www.pyroweb.de/ghost-of-jupiter-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2137",
  "https://www.pyroweb.de/ghosthouse/": "JumpIn=1&WebID=2553&intItemID=2045",
  "https://www.pyroweb.de/giftzwerg-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3000",
  "https://www.pyroweb.de/gigant-1-7-schuss-batterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=2918",
  "https://www.pyroweb.de/gillende-keukenmeid-luftheuler/":
    "JumpIn=1&WebID=2553&intItemID=3144",
  "https://www.pyroweb.de/gladiator-21-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3769",
  "https://www.pyroweb.de/glamour-shots/": "JumpIn=1&WebID=2553&intItemID=3248",
  "https://www.pyroweb.de/glamour-60-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2873",
  "https://www.pyroweb.de/glitzerregen-4er/":
    "JumpIn=1&WebID=2553&intItemID=2661",
  "https://www.pyroweb.de/global-50-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3172",
  "https://www.pyroweb.de/gloria-coloria-25-schuss-premium-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1939",
  "https://www.pyroweb.de/gluecksbringer-weco/":
    "JumpIn=1&WebID=2553&intItemID=1155",
  "https://www.pyroweb.de/glueckszinn/": "JumpIn=1&WebID=2553&intItemID=3967",
  "https://www.pyroweb.de/gluehwuermchen-60-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2210",
  "https://www.pyroweb.de/gold-criminal-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3732",
  "https://www.pyroweb.de/gold-digger-joe-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3225",
  "https://www.pyroweb.de/gold-fever-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2136",
  "https://www.pyroweb.de/gold-mine-70-schuss-compound-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=2052",
  "https://www.pyroweb.de/gold-rockets-6er-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=2715",
  "https://www.pyroweb.de/gold-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1552",
  "https://www.pyroweb.de/gold-color-vulkan/":
    "JumpIn=1&WebID=2553&intItemID=2486",
  "https://www.pyroweb.de/gold-und-silberregen-16er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2911",
  "https://www.pyroweb.de/gold-fontaenen-mit-farbsternen-4er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2494",
  "https://www.pyroweb.de/gold-strobe-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3012",
  "https://www.pyroweb.de/golden-blackhole-133-schuss-verbundfeuerwerk-2/":
    "JumpIn=1&WebID=2553&intItemID=3995",
  "https://www.pyroweb.de/golden-fountain/":
    "JumpIn=1&WebID=2553&intItemID=3229",
  "https://www.pyroweb.de/golden-lances-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3968",
  "https://www.pyroweb.de/golden-nights-10-schuss-multi-effekt-batterie/":
    "JumpIn=1&WebID=2553&intItemID=953",
  "https://www.pyroweb.de/golden-phoenix-21-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2345",
  "https://www.pyroweb.de/golden-rose-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1526",
  "https://www.pyroweb.de/golden-scorpion-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1855",
  "https://www.pyroweb.de/golden-spider-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1524",
  "https://www.pyroweb.de/golden-volcano/":
    "JumpIn=1&WebID=2553&intItemID=3771",
  "https://www.pyroweb.de/golden-willow-mine-with-blue-pearl-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2986",
  "https://www.pyroweb.de/goldjungs/": "JumpIn=1&WebID=2553&intItemID=3087",
  "https://www.pyroweb.de/goldkaefer-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3814",
  "https://www.pyroweb.de/goldrausch-raketen-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=1073",
  "https://www.pyroweb.de/goldsternschmeisser-1/":
    "JumpIn=1&WebID=2553&intItemID=3234",
  "https://www.pyroweb.de/goldsternschmeisser-2/":
    "JumpIn=1&WebID=2553&intItemID=3163",
  "https://www.pyroweb.de/good-vibes-leucht-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2164",
  "https://www.pyroweb.de/gratis-pyro-2023/":
    "JumpIn=1&WebID=2553&intItemID=3949",
  "https://www.pyroweb.de/graves-64-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1865",
  "https://www.pyroweb.de/greater-lion-box-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3856",
  "https://www.pyroweb.de/green-crosette-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3909",
  "https://www.pyroweb.de/green-crossette-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1858",
  "https://www.pyroweb.de/green-town-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3170",
  "https://www.pyroweb.de/griffin-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2572",
  "https://www.pyroweb.de/ground-ninjas/": "JumpIn=1&WebID=2553&intItemID=2671",
  "https://www.pyroweb.de/groundszliger-rauchtopf-pyrorauch-xl200-blau/":
    "JumpIn=1&WebID=2553&intItemID=691",
  "https://www.pyroweb.de/grosser-rauchtopf-pyrorauch-xl200-gelb/":
    "JumpIn=1&WebID=2553&intItemID=692",
  "https://www.pyroweb.de/grosser-rauchtopf-pyrorauch-xl200-gruen/":
    "JumpIn=1&WebID=2553&intItemID=690",
  "https://www.pyroweb.de/grosser-rauchtopf-pyrorauch-xl200-orange/":
    "JumpIn=1&WebID=2553&intItemID=693",
  "https://www.pyroweb.de/grosser-rauchtopf-pyrorauch-xl200-purpur/":
    "JumpIn=1&WebID=2553&intItemID=694",
  "https://www.pyroweb.de/grosser-rauchtopf-pyrorauch-xl200-rot/":
    "JumpIn=1&WebID=2553&intItemID=689",
  "https://www.pyroweb.de/grosser-rauchtopf-pyrorauch-xl200-schwarz/":
    "JumpIn=1&WebID=2553&intItemID=696",
  "https://www.pyroweb.de/grosser-rauchtopf-pyrorauch-xl200-weiss/":
    "JumpIn=1&WebID=2553&intItemID=695",
  "https://www.pyroweb.de/grosses-halloween-paket/":
    "JumpIn=1&WebID=2553&intItemID=3893",
  "https://www.pyroweb.de/guckst-du-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1846",
  "https://www.pyroweb.de/gutschein/": "JumpIn=1&WebID=2553&intItemID=324",
  "https://www.pyroweb.de/goetterfunken-premium-familiensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1916",
  "https://www.pyroweb.de/halloween-broomsticks/":
    "JumpIn=1&WebID=2553&intItemID=3226",
  "https://www.pyroweb.de/halludrin-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2228",
  "https://www.pyroweb.de/hamburg-42-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2069",
  "https://www.pyroweb.de/handdesinfektion-100ml/":
    "JumpIn=1&WebID=2553&intItemID=2675",
  "https://www.pyroweb.de/hans-dampf-8er-fontaene/":
    "JumpIn=1&WebID=2553&intItemID=1584",
  "https://www.pyroweb.de/happiness/": "JumpIn=1&WebID=2553&intItemID=3222",
  "https://www.pyroweb.de/happiness-fountain/":
    "JumpIn=1&WebID=2553&intItemID=3073",
  "https://www.pyroweb.de/happiness-4er-fontaenen/":
    "JumpIn=1&WebID=2553&intItemID=2057",
  "https://www.pyroweb.de/happy-birthday-12-schuss-luftschlangenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2384",
  "https://www.pyroweb.de/happy-new-year-63-teiliges-familiensortiment/":
    "JumpIn=1&WebID=2553&intItemID=2733",
  "https://www.pyroweb.de/happy-puppy-black-49-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3798",
  "https://www.pyroweb.de/happy-puppy-grey-49-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3799",
  "https://www.pyroweb.de/happy-puppy-orange-49-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3800",
  "https://www.pyroweb.de/hard-beat-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1452",
  "https://www.pyroweb.de/hawaii-24-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3304",
  "https://www.pyroweb.de/hawaiikette/": "JumpIn=1&WebID=2553&intItemID=469",
  "https://www.pyroweb.de/head-hunter-40-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2843",
  "https://www.pyroweb.de/heaven-gold-42-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1873",
  "https://www.pyroweb.de/hellfire-wheel/":
    "JumpIn=1&WebID=2553&intItemID=1589",
  "https://www.pyroweb.de/hellkalt-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3191",
  "https://www.pyroweb.de/helsinki-skycrapers-200-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3950",
  "https://www.pyroweb.de/herbstnacht-40-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3194",
  "https://www.pyroweb.de/hero-of-the-night-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1704",
  "https://www.pyroweb.de/heron-air-defence-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2230",
  "https://www.pyroweb.de/heron-cake-35-1-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1616",
  "https://www.pyroweb.de/heron-cake-35-10-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1615",
  "https://www.pyroweb.de/heron-cake-35-4-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1617",
  "https://www.pyroweb.de/heron-cake-35-6-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1609",
  "https://www.pyroweb.de/heron-dark-traveler-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2231",
  "https://www.pyroweb.de/heron-fat-wolff-35-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1714",
  "https://www.pyroweb.de/heron-verbundfeuerwerk-cake-4-5-6-178-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1608",
  "https://www.pyroweb.de/heron-single-shot-nr14-3er/":
    "JumpIn=1&WebID=2553&intItemID=1629",
  "https://www.pyroweb.de/heron-single-shot-nr16-3er/":
    "JumpIn=1&WebID=2553&intItemID=1631",
  "https://www.pyroweb.de/heron-single-shot-nr5-3er/":
    "JumpIn=1&WebID=2553&intItemID=1621",
  "https://www.pyroweb.de/heron-single-shot-nr7-3er/":
    "JumpIn=1&WebID=2553&intItemID=2645",
  "https://www.pyroweb.de/hexagon-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2870",
  "https://www.pyroweb.de/high-lights-leuchtfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2501",
  "https://www.pyroweb.de/highly-skilled-102-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2331",
  "https://www.pyroweb.de/hikaru-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2999",
  "https://www.pyroweb.de/himmelsschimmer-45-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3193",
  "https://www.pyroweb.de/hnusak-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3980",
  "https://www.pyroweb.de/hochzeit-12-schuss-luftschlangenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2386",
  "https://www.pyroweb.de/hooded-navy-booom-und-wieder-unbeliebt-gemacht/":
    "JumpIn=1&WebID=2553&intItemID=1250",
  "https://www.pyroweb.de/hooded-navy-wir-gluehen-nicht-vor-wir-fackeln-ab/":
    "JumpIn=1&WebID=2553&intItemID=1253",
  "https://www.pyroweb.de/hooded-schwarz-booom-und-wieder-unbeliebt-gemacht/":
    "JumpIn=1&WebID=2553&intItemID=1251",
  "https://www.pyroweb.de/hooded-schwarz-wir-gluehen-nicht-vor-wir-fackeln-ab/":
    "JumpIn=1&WebID=2553&intItemID=1252",
  "https://www.pyroweb.de/hooded-sweat-navy-nicht-lange-fackeln/":
    "JumpIn=1&WebID=2553&intItemID=1228",
  "https://www.pyroweb.de/hooded-sweat-navy-bam-pyrotechnik-ja-bitte/":
    "JumpIn=1&WebID=2553&intItemID=1244",
  "https://www.pyroweb.de/hooded-sweat-navy-eat-sleep-pyro-repeat/":
    "JumpIn=1&WebID=2553&intItemID=1220",
  "https://www.pyroweb.de/hooded-sweat-everyday-is-a-pyro-day/":
    "JumpIn=1&WebID=2553&intItemID=1222",
  "https://www.pyroweb.de/hooded-sweat-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1224",
  "https://www.pyroweb.de/hooded-sweat-navy-konfetti-und-randale/":
    "JumpIn=1&WebID=2553&intItemID=1226",
  "https://www.pyroweb.de/hooded-sweat-navy-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1249",
  "https://www.pyroweb.de/hooded-sweat-navy-no-pyro-no-party/":
    "JumpIn=1&WebID=2553&intItemID=1230",
  "https://www.pyroweb.de/hooded-sweat-navy-pyro-addict/":
    "JumpIn=1&WebID=2553&intItemID=1232",
  "https://www.pyroweb.de/hooded-sweat-navy-pyro-addiction/":
    "JumpIn=1&WebID=2553&intItemID=1234",
  "https://www.pyroweb.de/hooded-sweat-navy-pyro-is-not-a-crime/":
    "JumpIn=1&WebID=2553&intItemID=1238",
  "https://www.pyroweb.de/hooded-sweat-navy-pyro-statt-buero/":
    "JumpIn=1&WebID=2553&intItemID=1243",
  "https://www.pyroweb.de/hooded-sweat-navy-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1256",
  "https://www.pyroweb.de/hooded-sweat-navy-pyro-wuerfel/":
    "JumpIn=1&WebID=2553&intItemID=1254",
  "https://www.pyroweb.de/hooded-sweat-navy-pyroholic/":
    "JumpIn=1&WebID=2553&intItemID=1236",
  "https://www.pyroweb.de/hooded-sweat-navy-pyrotechnik-ist-kein-verbrechen/":
    "JumpIn=1&WebID=2553&intItemID=1246",
  "https://www.pyroweb.de/hooded-sweat-schwarz-nicht-lange-fackeln/":
    "JumpIn=1&WebID=2553&intItemID=1229",
  "https://www.pyroweb.de/hooded-sweat-schwarz-bam-pyrotechnik-ja-bitte/":
    "JumpIn=1&WebID=2553&intItemID=1245",
  "https://www.pyroweb.de/hooded-sweat-eat-sleep-pyro-repeat/":
    "JumpIn=1&WebID=2553&intItemID=1221",
  "https://www.pyroweb.de/hooded-sweat-schwarz-everyday-is-a-pyro-day/":
    "JumpIn=1&WebID=2553&intItemID=1223",
  "https://www.pyroweb.de/hooded-sweat-schwarz-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1225",
  "https://www.pyroweb.de/hooded-sweat-schwarz-konfetti-und-randale/":
    "JumpIn=1&WebID=2553&intItemID=1227",
  "https://www.pyroweb.de/hooded-sweat-schwarz-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1248",
  "https://www.pyroweb.de/hooded-sweat-schwarz-no-pyro-no-party/":
    "JumpIn=1&WebID=2553&intItemID=1231",
  "https://www.pyroweb.de/hooded-sweat-schwarz-pyro-addict/":
    "JumpIn=1&WebID=2553&intItemID=1233",
  "https://www.pyroweb.de/hooded-sweat-schwarz-pyro-addiction/":
    "JumpIn=1&WebID=2553&intItemID=1235",
  "https://www.pyroweb.de/hooded-sweat-schwarz-pyro-is-not-a-crime/":
    "JumpIn=1&WebID=2553&intItemID=1239",
  "https://www.pyroweb.de/hooded-sweat-schwarz-pyro-statt-buero/":
    "JumpIn=1&WebID=2553&intItemID=1242",
  "https://www.pyroweb.de/hooded-sweat-navy-pyro-icons-2/":
    "JumpIn=1&WebID=2553&intItemID=1257",
  "https://www.pyroweb.de/hooded-sweat-schwarz-pyro-wuerfel/":
    "JumpIn=1&WebID=2553&intItemID=1255",
  "https://www.pyroweb.de/hooded-sweat-schwarz-pyroholic/":
    "JumpIn=1&WebID=2553&intItemID=1237",
  "https://www.pyroweb.de/hooded-sweat-schwarz-pyrotechnik-ist-kein-verbrechen/":
    "JumpIn=1&WebID=2553&intItemID=1247",
  "https://www.pyroweb.de/hot-spot-24-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2876",
  "https://www.pyroweb.de/hot-tischfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3317",
  "https://www.pyroweb.de/hotstepper-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3746",
  "https://www.pyroweb.de/house-15-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1854",
  "https://www.pyroweb.de/hydra-38-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2940",
  "https://www.pyroweb.de/hyper-cannon-5/":
    "JumpIn=1&WebID=2553&intItemID=3812",
  "https://www.pyroweb.de/hoellenfeuer/": "JumpIn=1&WebID=2553&intItemID=3008",
  "https://www.pyroweb.de/i-am-willow-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3741",
  "https://www.pyroweb.de/ice-fontaene-4er-btl/":
    "JumpIn=1&WebID=2553&intItemID=3308",
  "https://www.pyroweb.de/igneus-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1896",
  "https://www.pyroweb.de/illusion-280-schuss-roemisches-licht/":
    "JumpIn=1&WebID=2553&intItemID=3939",
  "https://www.pyroweb.de/imaginator-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3795",
  "https://www.pyroweb.de/infinity-300-300-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3158",
  "https://www.pyroweb.de/inglourious-duo-122-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3862",
  "https://www.pyroweb.de/iris-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2724",
  "https://www.pyroweb.de/iro-kin-beauty-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3994",
  "https://www.pyroweb.de/irrlichter/": "JumpIn=1&WebID=2553&intItemID=1384",
  "https://www.pyroweb.de/jackie-chans/": "JumpIn=1&WebID=2553&intItemID=2317",
  "https://www.pyroweb.de/jane-100-schuss-batterie-zero-db/":
    "JumpIn=1&WebID=2553&intItemID=1952",
  "https://www.pyroweb.de/jorge-episode-xii-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1742",
  "https://www.pyroweb.de/jorge-hi-tec-7-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1746",
  "https://www.pyroweb.de/jorge-vulkan-bunt/":
    "JumpIn=1&WebID=2553&intItemID=866",
  "https://www.pyroweb.de/jorge-vulkan-crackling-2/":
    "JumpIn=1&WebID=2553&intItemID=865",
  "https://www.pyroweb.de/joysticks-gold-und-silberregen/":
    "JumpIn=1&WebID=2553&intItemID=3209",
  "https://www.pyroweb.de/jumbo-knallerbsen-dose/":
    "JumpIn=1&WebID=2553&intItemID=3867",
  "https://www.pyroweb.de/jumbo-knallerbsen-40-stueck/":
    "JumpIn=1&WebID=2553&intItemID=2934",
  "https://www.pyroweb.de/jumping-jack/": "JumpIn=1&WebID=2553&intItemID=3758",
  "https://www.pyroweb.de/jungle-fever-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2348",
  "https://www.pyroweb.de/kamikadze-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2573",
  "https://www.pyroweb.de/kanji-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3054",
  "https://www.pyroweb.de/kanonslag-xl-200er/":
    "JumpIn=1&WebID=2553&intItemID=2042",
  "https://www.pyroweb.de/kanonslag-100er/":
    "JumpIn=1&WebID=2553&intItemID=2043",
  "https://www.pyroweb.de/kapitan-nemo-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3168",
  "https://www.pyroweb.de/karate-kid-181-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2935",
  "https://www.pyroweb.de/karten-beautiful-horses-8er/":
    "JumpIn=1&WebID=2553&intItemID=3380",
  "https://www.pyroweb.de/karten-bvb-dortmund-8er/":
    "JumpIn=1&WebID=2553&intItemID=3459",
  "https://www.pyroweb.de/karten-fc-bayern-muenchen-8er/":
    "JumpIn=1&WebID=2553&intItemID=3476",
  "https://www.pyroweb.de/karten-fireman-sam-8er/":
    "JumpIn=1&WebID=2553&intItemID=3417",
  "https://www.pyroweb.de/karten-kicker-party-8er/":
    "JumpIn=1&WebID=2553&intItemID=3442",
  "https://www.pyroweb.de/karten-miraculous-8er/":
    "JumpIn=1&WebID=2553&intItemID=3342",
  "https://www.pyroweb.de/karten-paw-patrol-8er/":
    "JumpIn=1&WebID=2553&intItemID=3396",
  "https://www.pyroweb.de/karten-pirat-8er/":
    "JumpIn=1&WebID=2553&intItemID=3434",
  "https://www.pyroweb.de/bengalfeuer-rot/":
    "JumpIn=1&WebID=2553&intItemID=1144",
  "https://www.pyroweb.de/keller-fortuna-20-teiliges-premium-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1833",
  "https://www.pyroweb.de/keller-full-energy-27-schuss-power-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1766",
  "https://www.pyroweb.de/keller-ramses-19-schuss-bombetten-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1835",
  "https://www.pyroweb.de/keller-space-trip-28-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1769",
  "https://www.pyroweb.de/keller-wachsfackeln-2er-set-60min/":
    "JumpIn=1&WebID=2553&intItemID=1328",
  "https://www.pyroweb.de/kerzen-buchstaben-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3725",
  "https://www.pyroweb.de/kerzen-bunt-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3606",
  "https://www.pyroweb.de/kerzen-mini-pirat-4er/":
    "JumpIn=1&WebID=2553&intItemID=3426",
  "https://www.pyroweb.de/kickstarter/": "JumpIn=1&WebID=2553&intItemID=3773",
  "https://www.pyroweb.de/kids-bomb-4er-tischfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2505",
  "https://www.pyroweb.de/kinderfackel-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2743",
  "https://www.pyroweb.de/king-of-bling-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2889",
  "https://www.pyroweb.de/king-size-vulkanfontaene/":
    "JumpIn=1&WebID=2553&intItemID=1570",
  "https://www.pyroweb.de/kingstown-hangover-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3136",
  "https://www.pyroweb.de/kistenteufel-3er-fontaenen-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2853",
  "https://www.pyroweb.de/knall-kette-mit-500-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1120",
  "https://www.pyroweb.de/knallbonbon-2er-pack/":
    "JumpIn=1&WebID=2553&intItemID=202",
  "https://www.pyroweb.de/knallbonbon-4er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=176",
  "https://www.pyroweb.de/knallbonbon-6er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=1418",
  "https://www.pyroweb.de/knallbonbon-4er/":
    "JumpIn=1&WebID=2553&intItemID=1148",
  "https://www.pyroweb.de/knallbaer-15-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3066",
  "https://www.pyroweb.de/knallerbsen-10er-dose/":
    "JumpIn=1&WebID=2553&intItemID=86",
  "https://www.pyroweb.de/knallerbsen-tricky/":
    "JumpIn=1&WebID=2553&intItemID=1357",
  "https://www.pyroweb.de/knallfroesche-4er-schtl/":
    "JumpIn=1&WebID=2553&intItemID=3901",
  "https://www.pyroweb.de/knallkette-300-schuss/":
    "JumpIn=1&WebID=2553&intItemID=3195",
  "https://www.pyroweb.de/knallkraut/": "JumpIn=1&WebID=2553&intItemID=2672",
  "https://www.pyroweb.de/knallteufel-50er/":
    "JumpIn=1&WebID=2553&intItemID=1150",
  "https://www.pyroweb.de/knatter-blitze/":
    "JumpIn=1&WebID=2553&intItemID=1386",
  "https://www.pyroweb.de/knettaah-knatterband/":
    "JumpIn=1&WebID=2553&intItemID=3208",
  "https://www.pyroweb.de/knetterballen-bulkpack-35er/":
    "JumpIn=1&WebID=2553&intItemID=3033",
  "https://www.pyroweb.de/knetterlinten-bulkpack-50-stueck/":
    "JumpIn=1&WebID=2553&intItemID=3034",
  "https://www.pyroweb.de/knicklicht-becher-50ml-4er-set/":
    "JumpIn=1&WebID=2553&intItemID=2531",
  "https://www.pyroweb.de/knicklicht-deutschland/":
    "JumpIn=1&WebID=2553&intItemID=894",
  "https://www.pyroweb.de/knister-teufel-amethyst-3er/":
    "JumpIn=1&WebID=2553&intItemID=2584",
  "https://www.pyroweb.de/knister-teufel-berlin-mix/":
    "JumpIn=1&WebID=2553&intItemID=3887",
  "https://www.pyroweb.de/knister-teufel-kristall-mix/":
    "JumpIn=1&WebID=2553&intItemID=3886",
  "https://www.pyroweb.de/knister-teufel-rubin-3er/":
    "JumpIn=1&WebID=2553&intItemID=2585",
  "https://www.pyroweb.de/knister-teufel-saphir-3er/":
    "JumpIn=1&WebID=2553&intItemID=2586",
  "https://www.pyroweb.de/knisterfeuer-kaliber-b/":
    "JumpIn=1&WebID=2553&intItemID=3830",
  "https://www.pyroweb.de/knisterteufel-mix-3er-set/":
    "JumpIn=1&WebID=2553&intItemID=2744",
  "https://www.pyroweb.de/kobold-snapper-150er/":
    "JumpIn=1&WebID=2553&intItemID=2476",
  "https://www.pyroweb.de/kometenschauer-rotglitter-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2213",
  "https://www.pyroweb.de/kondor-feuerwirbel/":
    "JumpIn=1&WebID=2553&intItemID=3005",
  "https://www.pyroweb.de/konfetti-18-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3506",
  "https://www.pyroweb.de/konfetti-18-gold-folie/":
    "JumpIn=1&WebID=2553&intItemID=3567",
  "https://www.pyroweb.de/konfetti-18-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3572",
  "https://www.pyroweb.de/konfetti-21-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3509",
  "https://www.pyroweb.de/konfetti-21-sparkling-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3719",
  "https://www.pyroweb.de/konfetti-30-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3514",
  "https://www.pyroweb.de/konfetti-30-gold-folie/":
    "JumpIn=1&WebID=2553&intItemID=3576",
  "https://www.pyroweb.de/konfetti-30-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3571",
  "https://www.pyroweb.de/konfetti-40-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3518",
  "https://www.pyroweb.de/konfetti-40-gold-folie/":
    "JumpIn=1&WebID=2553&intItemID=3575",
  "https://www.pyroweb.de/konfetti-40-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3570",
  "https://www.pyroweb.de/konfetti-50-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3522",
  "https://www.pyroweb.de/konfetti-50-gold-folie/":
    "JumpIn=1&WebID=2553&intItemID=3574",
  "https://www.pyroweb.de/konfetti-50-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3569",
  "https://www.pyroweb.de/konfetti-60-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3528",
  "https://www.pyroweb.de/konfetti-60-gold-folie/":
    "JumpIn=1&WebID=2553&intItemID=3573",
  "https://www.pyroweb.de/konfetti-60-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3568",
  "https://www.pyroweb.de/konfetti-70-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3535",
  "https://www.pyroweb.de/konfetti-70-sparkling-gold-folie/":
    "JumpIn=1&WebID=2553&intItemID=3685",
  "https://www.pyroweb.de/konfetti-70-sparkling-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3702",
  "https://www.pyroweb.de/konfetti-80-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3543",
  "https://www.pyroweb.de/konfetti-80-sparkling-gold-folie/":
    "JumpIn=1&WebID=2553&intItemID=3686",
  "https://www.pyroweb.de/konfetti-80-sparkling-pink-folie/":
    "JumpIn=1&WebID=2553&intItemID=3708",
  "https://www.pyroweb.de/konfetti-beautiful-horses-papier/":
    "JumpIn=1&WebID=2553&intItemID=3375",
  "https://www.pyroweb.de/konfetti-kanone/":
    "JumpIn=1&WebID=2553&intItemID=2737",
  "https://www.pyroweb.de/konfetti-magical-unicorn-folie/":
    "JumpIn=1&WebID=2553&intItemID=3358",
  "https://www.pyroweb.de/konfetti-paw-patrol/":
    "JumpIn=1&WebID=2553&intItemID=3393",
  "https://www.pyroweb.de/konfetti-schulstart-papier/":
    "JumpIn=1&WebID=2553&intItemID=3323",
  "https://www.pyroweb.de/konfettikanone-bvb-dortmund-2er/":
    "JumpIn=1&WebID=2553&intItemID=3469",
  "https://www.pyroweb.de/konfettikanone-fc-bayern-muenchen-2er/":
    "JumpIn=1&WebID=2553&intItemID=3483",
  "https://www.pyroweb.de/konfettikanone-kicker-party-2er/":
    "JumpIn=1&WebID=2553&intItemID=3448",
  "https://www.pyroweb.de/kracherblitz-130-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1633",
  "https://www.pyroweb.de/kraftpaket-142-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2682",
  "https://www.pyroweb.de/krassandra-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2226",
  "https://www.pyroweb.de/krawall-monster-9-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2126",
  "https://www.pyroweb.de/krazy-panda-78-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2936",
  "https://www.pyroweb.de/krejser-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1549",
  "https://www.pyroweb.de/kubische-kanonenschlaege-15er-packung/":
    "JumpIn=1&WebID=2553&intItemID=494",
  "https://www.pyroweb.de/kubische-kanonenschlaege-4er-packung-2/":
    "JumpIn=1&WebID=2553&intItemID=2502",
  "https://www.pyroweb.de/kuchendeko-schulstart/":
    "JumpIn=1&WebID=2553&intItemID=3322",
  "https://www.pyroweb.de/kugelblitz-kaliber-a-6er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2725",
  "https://www.pyroweb.de/kunterbunt-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2216",
  "https://www.pyroweb.de/kurzschluss-fontaene/":
    "JumpIn=1&WebID=2553&intItemID=3164",
  "https://www.pyroweb.de/kyoto-kamuro-128-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2708",
  "https://www.pyroweb.de/ladies-night-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2071",
  "https://www.pyroweb.de/lady-killer-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2327",
  "https://www.pyroweb.de/lady-cracker-400er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2079",
  "https://www.pyroweb.de/lars-vom-mars-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3043",
  "https://www.pyroweb.de/las-fallas-121-schuss-megaverbund-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=2848",
  "https://www.pyroweb.de/launcherz-4er-pack/":
    "JumpIn=1&WebID=2553&intItemID=3039",
  "https://www.pyroweb.de/lava-rain-28-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3065",
  "https://www.pyroweb.de/lavanda-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3002",
  "https://www.pyroweb.de/legend-2-58-schuss-verbundfeuerwerk-2/":
    "JumpIn=1&WebID=2553&intItemID=3912",
  "https://www.pyroweb.de/leider-geil-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1845",
  "https://www.pyroweb.de/lesli-morning-glory/":
    "JumpIn=1&WebID=2553&intItemID=1374",
  "https://www.pyroweb.de/lesli-transformers/":
    "JumpIn=1&WebID=2553&intItemID=1363",
  "https://www.pyroweb.de/lesli-buergermeister-100-schuss-verbundfeuerwerk-2/":
    "JumpIn=1&WebID=2553&intItemID=1773",
  "https://www.pyroweb.de/lesli-copper-glow-gold-vulkan/":
    "JumpIn=1&WebID=2553&intItemID=1776",
  "https://www.pyroweb.de/lesli-crank-40-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1777",
  "https://www.pyroweb.de/lesli-moonshiners-6er-2/":
    "JumpIn=1&WebID=2553&intItemID=1785",
  "https://www.pyroweb.de/lesli-olympus-104-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1788",
  "https://www.pyroweb.de/lesli-palms-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1789",
  "https://www.pyroweb.de/lesli-power-pearls-192-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1790",
  "https://www.pyroweb.de/lesli-touch-of-gold-goldfontaene-2/":
    "JumpIn=1&WebID=2553&intItemID=1796",
  "https://www.pyroweb.de/lesli-bodenwirbel-eimer-xxl/":
    "JumpIn=1&WebID=2553&intItemID=3049",
  "https://www.pyroweb.de/lesli-crackling-ballz/":
    "JumpIn=1&WebID=2553&intItemID=3242",
  "https://www.pyroweb.de/lesli-lunatic-crackling-fountains/":
    "JumpIn=1&WebID=2553&intItemID=3017",
  "https://www.pyroweb.de/lesli-snakepit/":
    "JumpIn=1&WebID=2553&intItemID=3019",
  "https://www.pyroweb.de/lesli-tischbombe-4-stueck/":
    "JumpIn=1&WebID=2553&intItemID=3223",
  "https://www.pyroweb.de/lesli-wood-crackers/":
    "JumpIn=1&WebID=2553&intItemID=3016",
  "https://www.pyroweb.de/leucht-becher-250ml-blau/":
    "JumpIn=1&WebID=2553&intItemID=415",
  "https://www.pyroweb.de/leucht-becher-250ml-gelb/":
    "JumpIn=1&WebID=2553&intItemID=417",
  "https://www.pyroweb.de/leucht-becher-250ml-gruen/":
    "JumpIn=1&WebID=2553&intItemID=418",
  "https://www.pyroweb.de/leucht-becher-250ml-rot/":
    "JumpIn=1&WebID=2553&intItemID=416",
  "https://www.pyroweb.de/lichterbild-2-ringe/":
    "JumpIn=1&WebID=2553&intItemID=507",
  "https://www.pyroweb.de/lichterbild-feuerbild-blau/":
    "JumpIn=1&WebID=2553&intItemID=506",
  "https://www.pyroweb.de/lichterbild-feuerbild-gelb/":
    "JumpIn=1&WebID=2553&intItemID=504",
  "https://www.pyroweb.de/lichterbild-feuerbild-gold/":
    "JumpIn=1&WebID=2553&intItemID=502",
  "https://www.pyroweb.de/lichterbild-feuerbild-gruen/":
    "JumpIn=1&WebID=2553&intItemID=505",
  "https://www.pyroweb.de/lichterbild-feuerbild-purpur/":
    "JumpIn=1&WebID=2553&intItemID=715",
  "https://www.pyroweb.de/lichterbild-feuerbild-silber/":
    "JumpIn=1&WebID=2553&intItemID=716",
  "https://www.pyroweb.de/lichterstaebe-3er/":
    "JumpIn=1&WebID=2553&intItemID=2350",
  "https://www.pyroweb.de/light-my-fire/": "JumpIn=1&WebID=2553&intItemID=2667",
  "https://www.pyroweb.de/light-night-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3013",
  "https://www.pyroweb.de/lila-lena-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1095",
  "https://www.pyroweb.de/liliput-raketen-10er-set/":
    "JumpIn=1&WebID=2553&intItemID=2726",
  "https://www.pyroweb.de/lock-down-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3167",
  "https://www.pyroweb.de/lowrider-9-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1849",
  "https://www.pyroweb.de/lucky-you-7-teiliges-leuchtsortiment/":
    "JumpIn=1&WebID=2553&intItemID=3750",
  "https://www.pyroweb.de/luftpfeifer-12er/":
    "JumpIn=1&WebID=2553&intItemID=1364",
  "https://www.pyroweb.de/luftruessel-beautiful-horses-papier-8er/":
    "JumpIn=1&WebID=2553&intItemID=3378",
  "https://www.pyroweb.de/luftruessel-confetti-birthday-8er/":
    "JumpIn=1&WebID=2553&intItemID=3497",
  "https://www.pyroweb.de/luftruessel-fireman-sam-8er/":
    "JumpIn=1&WebID=2553&intItemID=3411",
  "https://www.pyroweb.de/luftruessel-paw-patrol-8er/":
    "JumpIn=1&WebID=2553&intItemID=3387",
  "https://www.pyroweb.de/luftruessel-pirat-8er/":
    "JumpIn=1&WebID=2553&intItemID=3430",
  "https://www.pyroweb.de/luftschlangen-bvb-dortmund-3er/":
    "JumpIn=1&WebID=2553&intItemID=3458",
  "https://www.pyroweb.de/luftschlangen-fc-bayern-muenchen-3er/":
    "JumpIn=1&WebID=2553&intItemID=3480",
  "https://www.pyroweb.de/luftschlangen-schulstart-papier-3er/":
    "JumpIn=1&WebID=2553&intItemID=3324",
  "https://www.pyroweb.de/luftschlangen-spray-blau/":
    "JumpIn=1&WebID=2553&intItemID=2950",
  "https://www.pyroweb.de/luftschlangen-spray-gelb/":
    "JumpIn=1&WebID=2553&intItemID=2948",
  "https://www.pyroweb.de/luftschlangen-spray-gruen/":
    "JumpIn=1&WebID=2553&intItemID=2949",
  "https://www.pyroweb.de/luftschlangen-spray-rot/":
    "JumpIn=1&WebID=2553&intItemID=2947",
  "https://www.pyroweb.de/luftschloss-60-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3189",
  "https://www.pyroweb.de/lustfeiern-12-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3185",
  "https://www.pyroweb.de/mach-2-20er-pack-fontaenen/":
    "JumpIn=1&WebID=2553&intItemID=3147",
  "https://www.pyroweb.de/machine-gun-crackers/":
    "JumpIn=1&WebID=2553&intItemID=3207",
  "https://www.pyroweb.de/mad-koi-276-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1930",
  "https://www.pyroweb.de/mafia-boy-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3269",
  "https://www.pyroweb.de/magellan-40-teiliges-familiensortiment/":
    "JumpIn=1&WebID=2553&intItemID=3199",
  "https://www.pyroweb.de/magic-lights-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2086",
  "https://www.pyroweb.de/magic-whip-zauberpeitsche/":
    "JumpIn=1&WebID=2553&intItemID=3929",
  "https://www.pyroweb.de/magic-whirl-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2098",
  "https://www.pyroweb.de/magma-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2101",
  "https://www.pyroweb.de/magmaniacs-3er-vulkan-set/":
    "JumpIn=1&WebID=2553&intItemID=2055",
  "https://www.pyroweb.de/magnesiumfackel-color-box/":
    "JumpIn=1&WebID=2553&intItemID=2951",
  "https://www.pyroweb.de/magnum-pi-288-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3135",
  "https://www.pyroweb.de/magnum-222-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2220",
  "https://www.pyroweb.de/magus-leuchtfeuerwerk-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2159",
  "https://www.pyroweb.de/mairo-gold-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3959",
  "https://www.pyroweb.de/majestic-carp-87-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1927",
  "https://www.pyroweb.de/malachit-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2193",
  "https://www.pyroweb.de/manhattan-187-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3232",
  "https://www.pyroweb.de/mars-attack-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2617",
  "https://www.pyroweb.de/masken-miraculous-8er/":
    "JumpIn=1&WebID=2553&intItemID=3339",
  "https://www.pyroweb.de/masken-paw-patrol-8er/":
    "JumpIn=1&WebID=2553&intItemID=3395",
  "https://www.pyroweb.de/massive-escape-76-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2933",
  "https://www.pyroweb.de/matrix-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3001",
  "https://www.pyroweb.de/maxi-wunderkerzen-10er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=398",
  "https://www.pyroweb.de/maxi-wunderkerzen-40cm/":
    "JumpIn=1&WebID=2553&intItemID=3198",
  "https://www.pyroweb.de/maximum-force-165-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2880",
  "https://www.pyroweb.de/mega-morning-glory/":
    "JumpIn=1&WebID=2553&intItemID=3804",
  "https://www.pyroweb.de/familiensortiment-mega-set/":
    "JumpIn=1&WebID=2553&intItemID=1405",
  "https://www.pyroweb.de/megamatte-mega-chain-knallkette-200-schuss/":
    "JumpIn=1&WebID=2553&intItemID=583",
  "https://www.pyroweb.de/mercury-fontaenen-batterie/":
    "JumpIn=1&WebID=2553&intItemID=593",
  "https://www.pyroweb.de/metamorph-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3131",
  "https://www.pyroweb.de/meteorite/": "JumpIn=1&WebID=2553&intItemID=1151",
  "https://www.pyroweb.de/meteoriten-kaliber-a/":
    "JumpIn=1&WebID=2553&intItemID=3295",
  "https://www.pyroweb.de/meteorschlag-10er/":
    "JumpIn=1&WebID=2553&intItemID=2683",
  "https://www.pyroweb.de/micro-rockets/": "JumpIn=1&WebID=2553&intItemID=2308",
  "https://www.pyroweb.de/midi-wunderkerzen-30cm/":
    "JumpIn=1&WebID=2553&intItemID=3197",
  "https://www.pyroweb.de/mikado-19-teiliges-raketen-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=927",
  "https://www.pyroweb.de/minerva-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3246",
  "https://www.pyroweb.de/mini-schultuete-mit-kreide/":
    "JumpIn=1&WebID=2553&intItemID=3326",
  "https://www.pyroweb.de/mix-two-jugendfeuerwerks-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=3206",
  "https://www.pyroweb.de/monster-frogs-12er-packung/":
    "JumpIn=1&WebID=2553&intItemID=2699",
  "https://www.pyroweb.de/monster-knicklicht-blau-25x350mm/":
    "JumpIn=1&WebID=2553&intItemID=313",
  "https://www.pyroweb.de/monster-knicklicht-gelb-25x350mm/":
    "JumpIn=1&WebID=2553&intItemID=314",
  "https://www.pyroweb.de/monster-knicklicht-gruen-25x350mm/":
    "JumpIn=1&WebID=2553&intItemID=311",
  "https://www.pyroweb.de/monster-knicklicht-orange-25x350mm/":
    "JumpIn=1&WebID=2553&intItemID=1047",
  "https://www.pyroweb.de/monster-knicklicht-pink-25x350mm/":
    "JumpIn=1&WebID=2553&intItemID=2532",
  "https://www.pyroweb.de/monster-knicklicht-rot-25x350mm/":
    "JumpIn=1&WebID=2553&intItemID=312",
  "https://www.pyroweb.de/monster-9-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3892",
  "https://www.pyroweb.de/moon-travellers/":
    "JumpIn=1&WebID=2553&intItemID=3137",
  "https://www.pyroweb.de/moonlight-shadow-29-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1898",
  "https://www.pyroweb.de/morning-glory/": "JumpIn=1&WebID=2553&intItemID=1350",
  "https://www.pyroweb.de/morning-glory-xl-version/":
    "JumpIn=1&WebID=2553&intItemID=3078",
  "https://www.pyroweb.de/moskitos/": "JumpIn=1&WebID=2553&intItemID=1369",
  "https://www.pyroweb.de/mount-everest/": "JumpIn=1&WebID=2553&intItemID=640",
  "https://www.pyroweb.de/mpm-ueberraschungs-paket/":
    "JumpIn=1&WebID=2553&intItemID=3894",
  "https://www.pyroweb.de/multi-flash-blinkfontaenen/":
    "JumpIn=1&WebID=2553&intItemID=1353",
  "https://www.pyroweb.de/multicolor-fan-crossette-42-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2988",
  "https://www.pyroweb.de/multicolor-polypen-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2647",
  "https://www.pyroweb.de/multicolour-coconut-49-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3129",
  "https://www.pyroweb.de/mundschutz-atemschutz-einwegmaske/":
    "JumpIn=1&WebID=2553&intItemID=2673",
  "https://www.pyroweb.de/mundschutz-atemschutz-feinstaubmaske-ffp2-kn95-2/":
    "JumpIn=1&WebID=2553&intItemID=2674",
  "https://www.pyroweb.de/mystique-v20-13-schuss-premium-batterie-kal-30mm/":
    "JumpIn=1&WebID=2553&intItemID=802",
  "https://www.pyroweb.de/mystischer-zirkel/":
    "JumpIn=1&WebID=2553&intItemID=486",
  "https://www.pyroweb.de/napoles-57-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3836",
  "https://www.pyroweb.de/naughty-screamers-10er-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=1545",
  "https://www.pyroweb.de/need-4-speed-224-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3858",
  "https://www.pyroweb.de/ster-neon/": "JumpIn=1&WebID=2553&intItemID=1345",
  "https://www.pyroweb.de/neon-clouds-10-feuertoepfe/":
    "JumpIn=1&WebID=2553&intItemID=3059",
  "https://www.pyroweb.de/neon-fishes-fountain/":
    "JumpIn=1&WebID=2553&intItemID=2046",
  "https://www.pyroweb.de/neon-knights-1-34-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2723",
  "https://www.pyroweb.de/neon-stars-wunderkerzen/":
    "JumpIn=1&WebID=2553&intItemID=3227",
  "https://www.pyroweb.de/neon-torchez/": "JumpIn=1&WebID=2553&intItemID=3205",
  "https://www.pyroweb.de/neon-wunderkerzen/":
    "JumpIn=1&WebID=2553&intItemID=2618",
  "https://www.pyroweb.de/new-generation-1-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=929",
  "https://www.pyroweb.de/new-generation-2-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=862",
  "https://www.pyroweb.de/new-generation-4-19-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1102",
  "https://www.pyroweb.de/new-generation-5-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=930",
  "https://www.pyroweb.de/new-generation-7-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=931",
  "https://www.pyroweb.de/nexus-15-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3272",
  "https://www.pyroweb.de/nico-bengalische-fackeln-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=3315",
  "https://www.pyroweb.de/nico-bengalische-riesenfackeln/":
    "JumpIn=1&WebID=2553&intItemID=1529",
  "https://www.pyroweb.de/nico-stepperbatterie-60-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1532",
  "https://www.pyroweb.de/nico-bengalfeuer-blau/":
    "JumpIn=1&WebID=2553&intItemID=1112",
  "https://www.pyroweb.de/nico-bengalfeuer-gelb/":
    "JumpIn=1&WebID=2553&intItemID=1115",
  "https://www.pyroweb.de/nico-bengalfeuer-grunduumln/":
    "JumpIn=1&WebID=2553&intItemID=1114",
  "https://www.pyroweb.de/nico-bengalfeuer-orange-2/":
    "JumpIn=1&WebID=2553&intItemID=2342",
  "https://www.pyroweb.de/nico-bengalfeuer-rot/":
    "JumpIn=1&WebID=2553&intItemID=1140",
  "https://www.pyroweb.de/nico-bengalfeuer-rot-blink/":
    "JumpIn=1&WebID=2553&intItemID=1113",
  "https://www.pyroweb.de/nico-bengalfeuer-weiss-strobo/":
    "JumpIn=1&WebID=2553&intItemID=1799",
  "https://www.pyroweb.de/nico-biene/": "JumpIn=1&WebID=2553&intItemID=1388",
  "https://www.pyroweb.de/nico-smoke-wire-pull-rauchgranate-120-sekunden-grau/":
    "JumpIn=1&WebID=2553&intItemID=3125",
  "https://www.pyroweb.de/nico-smoke-wire-pull-rauchgranate-80-sekunden-grau/":
    "JumpIn=1&WebID=2553&intItemID=3124",
  "https://www.pyroweb.de/nico-extreme-400-schuss/":
    "JumpIn=1&WebID=2553&intItemID=3243",
  "https://www.pyroweb.de/nico-jungle-fountains-5er-schtl/":
    "JumpIn=1&WebID=2553&intItemID=3270",
  "https://www.pyroweb.de/nico-jungle-mix/":
    "JumpIn=1&WebID=2553&intItemID=3273",
  "https://www.pyroweb.de/nico-party-power-4er-btl/":
    "JumpIn=1&WebID=2553&intItemID=3244",
  "https://www.pyroweb.de/nico-reibkopf-knaller/":
    "JumpIn=1&WebID=2553&intItemID=3240",
  "https://www.pyroweb.de/nico-silver-fountain/":
    "JumpIn=1&WebID=2553&intItemID=3020",
  "https://www.pyroweb.de/night-crawler-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2168",
  "https://www.pyroweb.de/night-crawlers-feuervoegel-set/":
    "JumpIn=1&WebID=2553&intItemID=3090",
  "https://www.pyroweb.de/night-friends-familien-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=1064",
  "https://www.pyroweb.de/nightliner-24-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2039",
  "https://www.pyroweb.de/nimbus-fontaenen-batterie-silent-line/":
    "JumpIn=1&WebID=2553&intItemID=2852",
  "https://www.pyroweb.de/nitro-boeller/": "JumpIn=1&WebID=2553&intItemID=2859",
  "https://www.pyroweb.de/nitro-crackers-20er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2851",
  "https://www.pyroweb.de/no-trespassing-34-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2596",
  "https://www.pyroweb.de/noblesse/": "JumpIn=1&WebID=2553&intItemID=3818",
  "https://www.pyroweb.de/nova-fontaene-no2/":
    "JumpIn=1&WebID=2553&intItemID=1022",
  "https://www.pyroweb.de/nova-fontaene-no3/":
    "JumpIn=1&WebID=2553&intItemID=1023",
  "https://www.pyroweb.de/nova-fontaene-no4/":
    "JumpIn=1&WebID=2553&intItemID=1024",
  "https://www.pyroweb.de/nox-6-schuss-multieffekt-batterie/":
    "JumpIn=1&WebID=2553&intItemID=951",
  "https://www.pyroweb.de/nydia-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2229",
  "https://www.pyroweb.de/octopus-60-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3801",
  "https://www.pyroweb.de/olymp-3er-raketen-set/":
    "JumpIn=1&WebID=2553&intItemID=1658",
  "https://www.pyroweb.de/olympic-ring-rockets-5er-set/":
    "JumpIn=1&WebID=2553&intItemID=2692",
  "https://www.pyroweb.de/one-loud-cloud-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3874",
  "https://www.pyroweb.de/opal-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2972",
  "https://www.pyroweb.de/optimus-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2462",
  "https://www.pyroweb.de/orange-crossette-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3141",
  "https://www.pyroweb.de/orc-4er-set-singleshots/":
    "JumpIn=1&WebID=2553&intItemID=3042",
  "https://www.pyroweb.de/orchid-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2973",
  "https://www.pyroweb.de/out-of-limits-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=956",
  "https://www.pyroweb.de/outlaws/": "JumpIn=1&WebID=2553&intItemID=3869",
  "https://www.pyroweb.de/overkill-13-schuss-feuerwerks-batterie/":
    "JumpIn=1&WebID=2553&intItemID=804",
  "https://www.pyroweb.de/paket-cracker-40-paeckchen-a-20-stck/":
    "JumpIn=1&WebID=2553&intItemID=103",
  "https://www.pyroweb.de/palmetum-31-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2015",
  "https://www.pyroweb.de/panda-6er-vulkan-set/":
    "JumpIn=1&WebID=2553&intItemID=2081",
  "https://www.pyroweb.de/panzer-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1564",
  "https://www.pyroweb.de/papp-teller-21-pink-celeb-8er/":
    "JumpIn=1&WebID=2553&intItemID=3721",
  "https://www.pyroweb.de/papp-teller-50-sparkling-8er/":
    "JumpIn=1&WebID=2553&intItemID=3558",
  "https://www.pyroweb.de/papp-teller-70-pink-celeb-8er/":
    "JumpIn=1&WebID=2553&intItemID=3703",
  "https://www.pyroweb.de/papp-teller-80-pink-celeb-8er/":
    "JumpIn=1&WebID=2553&intItemID=3709",
  "https://www.pyroweb.de/papp-teller-beautiful-horses-8er/":
    "JumpIn=1&WebID=2553&intItemID=3368",
  "https://www.pyroweb.de/papp-teller-bvb-dortmund-8er/":
    "JumpIn=1&WebID=2553&intItemID=3450",
  "https://www.pyroweb.de/papp-teller-confetti-birthday-8er/":
    "JumpIn=1&WebID=2553&intItemID=3488",
  "https://www.pyroweb.de/papp-teller-fc-bayern-muenchen-8er/":
    "JumpIn=1&WebID=2553&intItemID=3471",
  "https://www.pyroweb.de/papp-teller-happy-birthday-8er/":
    "JumpIn=1&WebID=2553&intItemID=3487",
  "https://www.pyroweb.de/papp-teller-kicker-party-8er/":
    "JumpIn=1&WebID=2553&intItemID=3437",
  "https://www.pyroweb.de/papp-teller-magical-unicorn-8er/":
    "JumpIn=1&WebID=2553&intItemID=3352",
  "https://www.pyroweb.de/papp-teller-miraculous-23cm/":
    "JumpIn=1&WebID=2553&intItemID=3333",
  "https://www.pyroweb.de/papp-teller-paw-patrol-pink-8er/":
    "JumpIn=1&WebID=2553&intItemID=3345",
  "https://www.pyroweb.de/papp-teller-pirat-8er/":
    "JumpIn=1&WebID=2553&intItemID=3421",
  "https://www.pyroweb.de/papp-teller-schulstart-8er/":
    "JumpIn=1&WebID=2553&intItemID=3320",
  "https://www.pyroweb.de/para-world/": "JumpIn=1&WebID=2553&intItemID=2857",
  "https://www.pyroweb.de/parabolic-195-schuss/":
    "JumpIn=1&WebID=2553&intItemID=935",
  "https://www.pyroweb.de/paradoxon-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2261",
  "https://www.pyroweb.de/party-bag/": "JumpIn=1&WebID=2553&intItemID=3897",
  "https://www.pyroweb.de/party-geschenke-set-pirat/":
    "JumpIn=1&WebID=2553&intItemID=3435",
  "https://www.pyroweb.de/party-huetchen-confetti-birthday-8er/":
    "JumpIn=1&WebID=2553&intItemID=3498",
  "https://www.pyroweb.de/party-huetchen-fireman-sam-8er/":
    "JumpIn=1&WebID=2553&intItemID=3408",
  "https://www.pyroweb.de/party-huetchen-pirat-8er/":
    "JumpIn=1&WebID=2553&intItemID=3433",
  "https://www.pyroweb.de/party-popper-4er/":
    "JumpIn=1&WebID=2553&intItemID=1332",
  "https://www.pyroweb.de/party-popper-4er-2/":
    "JumpIn=1&WebID=2553&intItemID=2488",
  "https://www.pyroweb.de/party-popper-4er-2-3/":
    "JumpIn=1&WebID=2553&intItemID=3099",
  "https://www.pyroweb.de/party-spass-paket/":
    "JumpIn=1&WebID=2553&intItemID=3827",
  "https://www.pyroweb.de/party-streamer-bunt/":
    "JumpIn=1&WebID=2553&intItemID=3305",
  "https://www.pyroweb.de/party-streamer-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3312",
  "https://www.pyroweb.de/party-12-schuss-luftschlangenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2385",
  "https://www.pyroweb.de/party-knaller-10er/":
    "JumpIn=1&WebID=2553&intItemID=1333",
  "https://www.pyroweb.de/party-knaller-9er/":
    "JumpIn=1&WebID=2553&intItemID=2479",
  "https://www.pyroweb.de/party-ueberraschung-fuer-kids/":
    "JumpIn=1&WebID=2553&intItemID=326",
  "https://www.pyroweb.de/partygeschenke-set-fireman-sam/":
    "JumpIn=1&WebID=2553&intItemID=3414",
  "https://www.pyroweb.de/partygeschenke-set-paw-patrol/":
    "JumpIn=1&WebID=2553&intItemID=3394",
  "https://www.pyroweb.de/partygeschenkeset-miraculous-24-teilig/":
    "JumpIn=1&WebID=2553&intItemID=3338",
  "https://www.pyroweb.de/partykette-18-gold-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3589",
  "https://www.pyroweb.de/partykette-18-pink-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3593",
  "https://www.pyroweb.de/partykette-30-gold-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3401",
  "https://www.pyroweb.de/partykette-30-pink-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3592",
  "https://www.pyroweb.de/partykette-40-gold-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3400",
  "https://www.pyroweb.de/partykette-40-pink-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3591",
  "https://www.pyroweb.de/partykette-50-gold-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3399",
  "https://www.pyroweb.de/partykette-50-pink-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3545",
  "https://www.pyroweb.de/partykette-60-gold-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3594",
  "https://www.pyroweb.de/partykette-60-pink-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3590",
  "https://www.pyroweb.de/partykette-beautiful-horses-140cm/":
    "JumpIn=1&WebID=2553&intItemID=3371",
  "https://www.pyroweb.de/partykette-fc-bayern-muenchen/":
    "JumpIn=1&WebID=2553&intItemID=3473",
  "https://www.pyroweb.de/partykette-fireman-sam/":
    "JumpIn=1&WebID=2553&intItemID=3407",
  "https://www.pyroweb.de/partykette-happy-70-birthday-gold-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3684",
  "https://www.pyroweb.de/partykette-happy-70-birthday-pink-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3701",
  "https://www.pyroweb.de/partykette-happy-80-birthday-pink-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3707",
  "https://www.pyroweb.de/partykette-happy-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3492",
  "https://www.pyroweb.de/partykette-happy-birthday-2/":
    "JumpIn=1&WebID=2553&intItemID=3502",
  "https://www.pyroweb.de/partykette-kicker-party/":
    "JumpIn=1&WebID=2553&intItemID=3440",
  "https://www.pyroweb.de/partykette-lets-party/":
    "JumpIn=1&WebID=2553&intItemID=3493",
  "https://www.pyroweb.de/partykette-magical-unicorn/":
    "JumpIn=1&WebID=2553&intItemID=3361",
  "https://www.pyroweb.de/partykette-paw-patrol-180cm/":
    "JumpIn=1&WebID=2553&intItemID=3382",
  "https://www.pyroweb.de/partykette-pirat/":
    "JumpIn=1&WebID=2553&intItemID=3424",
  "https://www.pyroweb.de/partykette-pirat-2/":
    "JumpIn=1&WebID=2553&intItemID=3431",
  "https://www.pyroweb.de/partykette-wimpel-21-gold-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3713",
  "https://www.pyroweb.de/partykette-wimpel-21-gold-folie-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3711",
  "https://www.pyroweb.de/partykette-wimpel-21-pink-folie-213cm/":
    "JumpIn=1&WebID=2553&intItemID=3718",
  "https://www.pyroweb.de/partykette-wimpel-21-pink-folie-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3716",
  "https://www.pyroweb.de/partykette-wimpel-70-gold-folie-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3682",
  "https://www.pyroweb.de/partykette-wimpel-70-pink-folie-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3699",
  "https://www.pyroweb.de/partykette-wimpel-80-gold-folie-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3683",
  "https://www.pyroweb.de/partykette-wimpel-80-pink-folie-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3705",
  "https://www.pyroweb.de/partykette-wimpel-bvb-dortmund/":
    "JumpIn=1&WebID=2553&intItemID=3460",
  "https://www.pyroweb.de/partykette-wimpel-fc-bayern-muenchen/":
    "JumpIn=1&WebID=2553&intItemID=3482",
  "https://www.pyroweb.de/partykette-wimpel-kicker-party/":
    "JumpIn=1&WebID=2553&intItemID=3445",
  "https://www.pyroweb.de/partyknaller-21er-pack/":
    "JumpIn=1&WebID=2553&intItemID=497",
  "https://www.pyroweb.de/partyrock-familypack/":
    "JumpIn=1&WebID=2553&intItemID=3753",
  "https://www.pyroweb.de/partytueten-beautiful-horses-papier-8er/":
    "JumpIn=1&WebID=2553&intItemID=3376",
  "https://www.pyroweb.de/partytueten-bvb-dortmund-8er/":
    "JumpIn=1&WebID=2553&intItemID=3456",
  "https://www.pyroweb.de/partytueten-confetti-birthday-8er/":
    "JumpIn=1&WebID=2553&intItemID=3499",
  "https://www.pyroweb.de/partytueten-fc-bayern-muenchen-8er/":
    "JumpIn=1&WebID=2553&intItemID=3477",
  "https://www.pyroweb.de/partytueten-fireman-sam-8er/":
    "JumpIn=1&WebID=2553&intItemID=3412",
  "https://www.pyroweb.de/partytueten-kicker-party-8er/":
    "JumpIn=1&WebID=2553&intItemID=3441",
  "https://www.pyroweb.de/partytueten-magical-unicorn-20er/":
    "JumpIn=1&WebID=2553&intItemID=3360",
  "https://www.pyroweb.de/partytueten-miraculous-8er/":
    "JumpIn=1&WebID=2553&intItemID=3337",
  "https://www.pyroweb.de/partytueten-paw-patrol-8er/":
    "JumpIn=1&WebID=2553&intItemID=3386",
  "https://www.pyroweb.de/partytueten-pirat-8er/":
    "JumpIn=1&WebID=2553&intItemID=3425",
  "https://www.pyroweb.de/pegasus-24-teiliges-raketen-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=938",
  "https://www.pyroweb.de/peony-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2341",
  "https://www.pyroweb.de/perfect-balance-50-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3935",
  "https://www.pyroweb.de/permon-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2577",
  "https://www.pyroweb.de/peter-der-grosse-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1659",
  "https://www.pyroweb.de/petrol-rockets/":
    "JumpIn=1&WebID=2553&intItemID=3754",
  "https://www.pyroweb.de/pfeifraketen-kal-b-mit-3-stufen-silbercrackern/":
    "JumpIn=1&WebID=2553&intItemID=2638",
  "https://www.pyroweb.de/pin-up-9-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1851",
  "https://www.pyroweb.de/pinata-fireman-sam/":
    "JumpIn=1&WebID=2553&intItemID=3418",
  "https://www.pyroweb.de/pinata-paw-patrol/":
    "JumpIn=1&WebID=2553&intItemID=3397",
  "https://www.pyroweb.de/pine-barrens-21-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2221",
  "https://www.pyroweb.de/pinetum-performance-fontaene/":
    "JumpIn=1&WebID=2553&intItemID=2013",
  "https://www.pyroweb.de/pink-lady-kunststoffauto/":
    "JumpIn=1&WebID=2553&intItemID=1870",
  "https://www.pyroweb.de/alligatorpiranha/":
    "JumpIn=1&WebID=2553&intItemID=513",
  "https://www.pyroweb.de/pirate-black-edition-50er/":
    "JumpIn=1&WebID=2553&intItemID=2684",
  "https://www.pyroweb.de/piroman-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2100",
  "https://www.pyroweb.de/pitbull-43-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1099",
  "https://www.pyroweb.de/plasma-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3918",
  "https://www.pyroweb.de/platinum-sensation-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3925",
  "https://www.pyroweb.de/pluton-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1106",
  "https://www.pyroweb.de/polo-schwarz-pyrocrew/":
    "JumpIn=1&WebID=2553&intItemID=218",
  "https://www.pyroweb.de/polyp-variations-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2648",
  "https://www.pyroweb.de/pop-up-20er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1118",
  "https://www.pyroweb.de/popcorn-becher-kicker-party-4er/":
    "JumpIn=1&WebID=2553&intItemID=3449",
  "https://www.pyroweb.de/power-balls-3er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=95",
  "https://www.pyroweb.de/power-bank-5er-batterie-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2074",
  "https://www.pyroweb.de/power-deluxe-250-schuss-batterie-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2729",
  "https://www.pyroweb.de/power-punch-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=914",
  "https://www.pyroweb.de/power-tube-8-schuss-kometenrohr/":
    "JumpIn=1&WebID=2553&intItemID=320",
  "https://www.pyroweb.de/power-knicklicht-blau-15x150mm/":
    "JumpIn=1&WebID=2553&intItemID=307",
  "https://www.pyroweb.de/power-knicklicht-gelb-15x150mm/":
    "JumpIn=1&WebID=2553&intItemID=308",
  "https://www.pyroweb.de/power-knicklicht-gruen-15x150mm/":
    "JumpIn=1&WebID=2553&intItemID=305",
  "https://www.pyroweb.de/power-knicklicht-pink-15x150mm/":
    "JumpIn=1&WebID=2553&intItemID=310",
  "https://www.pyroweb.de/power-knicklicht-rot-15x150mm/":
    "JumpIn=1&WebID=2553&intItemID=306",
  "https://www.pyroweb.de/power-knicklicht-weiss-15x150mm/":
    "JumpIn=1&WebID=2553&intItemID=381",
  "https://www.pyroweb.de/power-knicklicht-orange-15x150mm/":
    "JumpIn=1&WebID=2553&intItemID=309",
  "https://www.pyroweb.de/powerball-3er-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=1543",
  "https://www.pyroweb.de/powercrafter-27er-raketenset/":
    "JumpIn=1&WebID=2553&intItemID=1840",
  "https://www.pyroweb.de/blutkapseln-mundblut-5er-flasche/":
    "JumpIn=1&WebID=2553&intItemID=491",
  "https://www.pyroweb.de/premium-diamant-sonne/":
    "JumpIn=1&WebID=2553&intItemID=483",
  "https://www.pyroweb.de/premium-kometen-mix-5er-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2745",
  "https://www.pyroweb.de/premium-wunderkerzen-40-stueck/":
    "JumpIn=1&WebID=2553&intItemID=3196",
  "https://www.pyroweb.de/premium-raketen-bunt-5er/":
    "JumpIn=1&WebID=2553&intItemID=2771",
  "https://www.pyroweb.de/premium-raketen-gruen-citro-gf-5er/":
    "JumpIn=1&WebID=2553&intItemID=2772",
  "https://www.pyroweb.de/premium-raketen-silberweide-blau-5er/":
    "JumpIn=1&WebID=2553&intItemID=2779",
  "https://www.pyroweb.de/presszange-fuer-kabelverbinder/":
    "JumpIn=1&WebID=2553&intItemID=598",
  "https://www.pyroweb.de/prestige-escort-19-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=1978",
  "https://www.pyroweb.de/prezident-piro-144-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1550",
  "https://www.pyroweb.de/primavera-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2824",
  "https://www.pyroweb.de/prime-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1563",
  "https://www.pyroweb.de/prinzesschen-117-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3268",
  "https://www.pyroweb.de/professional-powerbox-1-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1596",
  "https://www.pyroweb.de/professional-powerbox-2-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2606",
  "https://www.pyroweb.de/professional-powerbox-3-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2607",
  "https://www.pyroweb.de/profi-pyrosystem-143/":
    "JumpIn=1&WebID=2553&intItemID=1130",
  "https://www.pyroweb.de/profi-torch-blue/":
    "JumpIn=1&WebID=2553&intItemID=3107",
  "https://www.pyroweb.de/profi-torch-gelb-bengalo/":
    "JumpIn=1&WebID=2553&intItemID=3108",
  "https://www.pyroweb.de/profi-torch-green/":
    "JumpIn=1&WebID=2553&intItemID=3111",
  "https://www.pyroweb.de/profi-torch-orange/":
    "JumpIn=1&WebID=2553&intItemID=3105",
  "https://www.pyroweb.de/profi-torch-purple/":
    "JumpIn=1&WebID=2553&intItemID=3110",
  "https://www.pyroweb.de/profi-torch-red/":
    "JumpIn=1&WebID=2553&intItemID=3106",
  "https://www.pyroweb.de/profi-torch-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3109",
  "https://www.pyroweb.de/project-x-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2996",
  "https://www.pyroweb.de/prop-joe-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2227",
  "https://www.pyroweb.de/prosit-neujahr-weco/":
    "JumpIn=1&WebID=2553&intItemID=1156",
  "https://www.pyroweb.de/proton-9-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1850",
  "https://www.pyroweb.de/proud-mary-138-schuss-compound-2/":
    "JumpIn=1&WebID=2553&intItemID=3930",
  "https://www.pyroweb.de/pryzm-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2751",
  "https://www.pyroweb.de/praeludium-13-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2117",
  "https://www.pyroweb.de/psp1-22-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1614",
  "https://www.pyroweb.de/psycho-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2598",
  "https://www.pyroweb.de/psychobilly-48-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2883",
  "https://www.pyroweb.de/pulp-fiction-56-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3738",
  "https://www.pyroweb.de/pure-crosette-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1517",
  "https://www.pyroweb.de/purge-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1856",
  "https://www.pyroweb.de/purple-crossette-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1861",
  "https://www.pyroweb.de/push-it-to-the-limit-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3908",
  "https://www.pyroweb.de/pusteblume-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2212",
  "https://www.pyroweb.de/pyro-art-knall-nachos-20er-beutel/":
    "JumpIn=1&WebID=2553&intItemID=2263",
  "https://www.pyroweb.de/pyro-busters-56-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2666",
  "https://www.pyroweb.de/pyro-complete-186-cbp-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2312",
  "https://www.pyroweb.de/pyro-cracker-320er-mega-pack/":
    "JumpIn=1&WebID=2553&intItemID=104",
  "https://www.pyroweb.de/pyro-master-73-schuss/":
    "JumpIn=1&WebID=2553&intItemID=984",
  "https://www.pyroweb.de/pyro-mix-for-kids-196-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2508",
  "https://www.pyroweb.de/pyro-peitsche/": "JumpIn=1&WebID=2553&intItemID=2493",
  "https://www.pyroweb.de/pyro-system-pro-3/":
    "JumpIn=1&WebID=2553&intItemID=765",
  "https://www.pyroweb.de/pyro-tank-xl-2er-set/":
    "JumpIn=1&WebID=2553&intItemID=2270",
  "https://www.pyroweb.de/pyro-wirbel-6er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=334",
  "https://www.pyroweb.de/pyro-zone-15-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1559",
  "https://www.pyroweb.de/pyro-zylinder-no-3/":
    "JumpIn=1&WebID=2553&intItemID=1127",
  "https://www.pyroweb.de/pyro-spektakel-level-3-132-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3271",
  "https://www.pyroweb.de/pyro-warmup-f1-2023/":
    "JumpIn=1&WebID=2553&intItemID=2001",
  "https://www.pyroweb.de/pyro-warmup-t1-2023/":
    "JumpIn=1&WebID=2553&intItemID=2002",
  "https://www.pyroweb.de/pyrock-18-schuss-feuerwerksbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2862",
  "https://www.pyroweb.de/pyrocracker-320-stueck/":
    "JumpIn=1&WebID=2553&intItemID=3749",
  "https://www.pyroweb.de/pyrodrohne/": "JumpIn=1&WebID=2553&intItemID=2310",
  "https://www.pyroweb.de/pyrofackel-rot-5-min/":
    "JumpIn=1&WebID=2553&intItemID=743",
  "https://www.pyroweb.de/pyrofackel-rot-3-min/":
    "JumpIn=1&WebID=2553&intItemID=742",
  "https://www.pyroweb.de/pyrofessional-eta-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2131",
  "https://www.pyroweb.de/pyrofessional-my-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2129",
  "https://www.pyroweb.de/pyrofessional-zeta-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2133",
  "https://www.pyroweb.de/pyronator-v20-13-schuss-premium-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1817",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-blau/":
    "JumpIn=1&WebID=2553&intItemID=2467",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-gelb/":
    "JumpIn=1&WebID=2553&intItemID=2469",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-gruen/":
    "JumpIn=1&WebID=2553&intItemID=2474",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-orange/":
    "JumpIn=1&WebID=2553&intItemID=2472",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-purpur/":
    "JumpIn=1&WebID=2553&intItemID=2470",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-rot/":
    "JumpIn=1&WebID=2553&intItemID=2468",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-schwarz/":
    "JumpIn=1&WebID=2553&intItemID=2473",
  "https://www.pyroweb.de/pyrorauch-g400-mit-kipphebelzuendung-weiss/":
    "JumpIn=1&WebID=2553&intItemID=2471",
  "https://www.pyroweb.de/pyrorauch-p80-blau-reisszuendung/":
    "JumpIn=1&WebID=2553&intItemID=1491",
  "https://www.pyroweb.de/pyrorauch-p80-gelb-reisszuendung/":
    "JumpIn=1&WebID=2553&intItemID=1492",
  "https://www.pyroweb.de/pyrorauch-p80-gruen-reisszuendung/":
    "JumpIn=1&WebID=2553&intItemID=1490",
  "https://www.pyroweb.de/pyrorauch-p80-lila-mit-reisszuendung/":
    "JumpIn=1&WebID=2553&intItemID=1493",
  "https://www.pyroweb.de/pyrorauch-p80-orange-mit-reisszuendung/":
    "JumpIn=1&WebID=2553&intItemID=1495",
  "https://www.pyroweb.de/pyrorauch-p80-rot-mit-reisszuendung/":
    "JumpIn=1&WebID=2553&intItemID=1489",
  "https://www.pyroweb.de/pyrorauch-p80-weiss-mit-reisszuendung/":
    "JumpIn=1&WebID=2553&intItemID=1494",
  "https://www.pyroweb.de/pyrorauch-s80-mit-schlagzuendung-blau/":
    "JumpIn=1&WebID=2553&intItemID=2446",
  "https://www.pyroweb.de/pyrorauch-s80-mit-schlagzuendung-gelb/":
    "JumpIn=1&WebID=2553&intItemID=2445",
  "https://www.pyroweb.de/pyrorauch-s80-mit-schlagzuendung-gruen/":
    "JumpIn=1&WebID=2553&intItemID=2447",
  "https://www.pyroweb.de/pyrorauch-s80-mit-schlagzuendung-purpur/":
    "JumpIn=1&WebID=2553&intItemID=2448",
  "https://www.pyroweb.de/pyrorauch-s80-mit-schlagzuendung-rot/":
    "JumpIn=1&WebID=2553&intItemID=2444",
  "https://www.pyroweb.de/pyrorauch-s80-mit-schlagzuendung-schwarz/":
    "JumpIn=1&WebID=2553&intItemID=2450",
  "https://www.pyroweb.de/pyrorauch-s80-mit-schlagzuendung-weiss/":
    "JumpIn=1&WebID=2553&intItemID=2451",
  "https://www.pyroweb.de/pyrorauch-xl600-gelb/":
    "JumpIn=1&WebID=2553&intItemID=2414",
  "https://www.pyroweb.de/pyrorauch-xl600-gruen/":
    "JumpIn=1&WebID=2553&intItemID=2412",
  "https://www.pyroweb.de/pyrorauch-xl600-orange/":
    "JumpIn=1&WebID=2553&intItemID=2416",
  "https://www.pyroweb.de/pyrorauch-xl600-purpur/":
    "JumpIn=1&WebID=2553&intItemID=2413",
  "https://www.pyroweb.de/pyrorauch-xl600-rot/":
    "JumpIn=1&WebID=2553&intItemID=2411",
  "https://www.pyroweb.de/pyrorauch-xl600-weiss/":
    "JumpIn=1&WebID=2553&intItemID=2417",
  "https://www.pyroweb.de/pyrorauch-xl800-blau-rauchpatrone-jumbo-rauch/":
    "JumpIn=1&WebID=2553&intItemID=1693",
  "https://www.pyroweb.de/pyrorauch-xl800-gelb-rauchpatrone-jumbo-rauch/":
    "JumpIn=1&WebID=2553&intItemID=1692",
  "https://www.pyroweb.de/pyrorauch-xl800-gruen-rauchpatrone-jumbo-rauch/":
    "JumpIn=1&WebID=2553&intItemID=1691",
  "https://www.pyroweb.de/pyrorauch-xl800-lila-rauchpatrone-jumbo-rauch/":
    "JumpIn=1&WebID=2553&intItemID=1690",
  "https://www.pyroweb.de/pyrorauch-xl800-orange-rauchpatrone-jumbo-rauch-2/":
    "JumpIn=1&WebID=2553&intItemID=971",
  "https://www.pyroweb.de/pyrorauch-xl800-rot-rauchpatrone-jumbo-rauch/":
    "JumpIn=1&WebID=2553&intItemID=1689",
  "https://www.pyroweb.de/pyrorauch-xl800-weiss-rauchpatrone-jumbo-rauch/":
    "JumpIn=1&WebID=2553&intItemID=1688",
  "https://www.pyroweb.de/pyroshow-1000-a-palermo/":
    "JumpIn=1&WebID=2553&intItemID=3832",
  "https://www.pyroweb.de/pyroshow-2000-e-infinitum/":
    "JumpIn=1&WebID=2553&intItemID=3056",
  "https://www.pyroweb.de/pyroshow-malibu-117-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3852",
  "https://www.pyroweb.de/pyroweb-aufkleberset/":
    "JumpIn=1&WebID=2553&intItemID=1638",
  "https://www.pyroweb.de/pyroweb-rauchfackel-pyrorauch-l55-blau/":
    "JumpIn=1&WebID=2553&intItemID=661",
  "https://www.pyroweb.de/pyroweb-rauchfackel-pyrorauch-l55-gelb/":
    "JumpIn=1&WebID=2553&intItemID=662",
  "https://www.pyroweb.de/pyroweb-rauchfackel-pyrorauch-l55-gruen/":
    "JumpIn=1&WebID=2553&intItemID=660",
  "https://www.pyroweb.de/pyroweb-rauchfackel-pyrorauch-l55-orange/":
    "JumpIn=1&WebID=2553&intItemID=667",
  "https://www.pyroweb.de/pyroweb-rauchfackel-pyrorauch-l55-purpur/":
    "JumpIn=1&WebID=2553&intItemID=668",
  "https://www.pyroweb.de/pyroweb-rauchfackel-pyrorauch-l55-rot/":
    "JumpIn=1&WebID=2553&intItemID=659",
  "https://www.pyroweb.de/pyrowebde-polo/": "JumpIn=1&WebID=2553&intItemID=217",
  "https://www.pyroweb.de/quickie-100-schnellfeuerkometen/":
    "JumpIn=1&WebID=2553&intItemID=954",
  "https://www.pyroweb.de/r-t-c-rotterdam-terror-corps/":
    "JumpIn=1&WebID=2553&intItemID=2983",
  "https://www.pyroweb.de/radiation-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2578",
  "https://www.pyroweb.de/radioactive-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2093",
  "https://www.pyroweb.de/ragbag-leuchtfeuerwerk-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2160",
  "https://www.pyroweb.de/raging-perch-123-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1928",
  "https://www.pyroweb.de/rainbow-colours-96-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1518",
  "https://www.pyroweb.de/rainbow-mountain-49-schuss/":
    "JumpIn=1&WebID=2553&intItemID=3899",
  "https://www.pyroweb.de/rainbow-wiper-42-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2651",
  "https://www.pyroweb.de/raketen-cocktail/":
    "JumpIn=1&WebID=2553&intItemID=3303",
  "https://www.pyroweb.de/raketenset-berlin-9er-beutel/":
    "JumpIn=1&WebID=2553&intItemID=1123",
  "https://www.pyroweb.de/rampage-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1964",
  "https://www.pyroweb.de/rapid-fire-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=350",
  "https://www.pyroweb.de/rapido-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2321",
  "https://www.pyroweb.de/ratz-fatz-70-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2040",
  "https://www.pyroweb.de/rauch-handfackel-tf23-gruen/":
    "JumpIn=1&WebID=2553&intItemID=3727",
  "https://www.pyroweb.de/rauch-handfackel-tf23-blau/":
    "JumpIn=1&WebID=2553&intItemID=3730",
  "https://www.pyroweb.de/rauch-handfackel-tf23-gelb/":
    "JumpIn=1&WebID=2553&intItemID=3728",
  "https://www.pyroweb.de/rauch-handfackel-tf23-lila/":
    "JumpIn=1&WebID=2553&intItemID=3729",
  "https://www.pyroweb.de/rauch-handfackel-tf23-rot/":
    "JumpIn=1&WebID=2553&intItemID=3726",
  "https://www.pyroweb.de/rauch-handfackel-tf23-weiss/":
    "JumpIn=1&WebID=2553&intItemID=3731",
  "https://www.pyroweb.de/rauchbombe-18mm-blau/":
    "JumpIn=1&WebID=2553&intItemID=3974",
  "https://www.pyroweb.de/rauchbombe-18mm-bunt/":
    "JumpIn=1&WebID=2553&intItemID=3797",
  "https://www.pyroweb.de/rauchbombe-18mm-rot/":
    "JumpIn=1&WebID=2553&intItemID=3975",
  "https://www.pyroweb.de/rauchbombe-38mm-gruen/":
    "JumpIn=1&WebID=2553&intItemID=3983",
  "https://www.pyroweb.de/rauchbombe-38mm-mixed/":
    "JumpIn=1&WebID=2553&intItemID=3984",
  "https://www.pyroweb.de/rauchbombe-38mm-purpur/":
    "JumpIn=1&WebID=2553&intItemID=3985",
  "https://www.pyroweb.de/rauchbaelle-vierfarbig-6er/":
    "JumpIn=1&WebID=2553&intItemID=2165",
  "https://www.pyroweb.de/rauchbaelle-6er-set/":
    "JumpIn=1&WebID=2553&intItemID=2264",
  "https://www.pyroweb.de/rauchfackel-pyrorauch-l55-schwarz/":
    "JumpIn=1&WebID=2553&intItemID=669",
  "https://www.pyroweb.de/rauchfackel-pyrorauch-l55-weiss/":
    "JumpIn=1&WebID=2553&intItemID=663",
  "https://www.pyroweb.de/rauchkometen-batterie-orange/":
    "JumpIn=1&WebID=2553&intItemID=755",
  "https://www.pyroweb.de/rauchkometen-batterie-rot/":
    "JumpIn=1&WebID=2553&intItemID=756",
  "https://www.pyroweb.de/rauchkometen-tageszauber-blau-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2118",
  "https://www.pyroweb.de/rauchkometen-tageszauber-bunt-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2119",
  "https://www.pyroweb.de/rauchkometen-tageszauber-gelb-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2121",
  "https://www.pyroweb.de/rauchkometen-tageszauber-gruen-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2122",
  "https://www.pyroweb.de/rauchkometen-tageszauber-orange-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2123",
  "https://www.pyroweb.de/rauchkometen-tageszauber-rot-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2120",
  "https://www.pyroweb.de/rauchkometenbatterie-rot-25-schuss-faecher/":
    "JumpIn=1&WebID=2553&intItemID=2685",
  "https://www.pyroweb.de/rauchpulver-weiss-1kg-beutel/":
    "JumpIn=1&WebID=2553&intItemID=157",
  "https://www.pyroweb.de/rebel-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3802",
  "https://www.pyroweb.de/red-admiral-18-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2084",
  "https://www.pyroweb.de/red-blinking-mine-to-big-silver-spinner-49-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3932",
  "https://www.pyroweb.de/red-bomba-riesen-knallerbsen/":
    "JumpIn=1&WebID=2553&intItemID=2984",
  "https://www.pyroweb.de/red-city-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3169",
  "https://www.pyroweb.de/red-crossette-16-schuss/":
    "JumpIn=1&WebID=2553&intItemID=3898",
  "https://www.pyroweb.de/red-crossette-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1859",
  "https://www.pyroweb.de/red-crossette-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1925",
  "https://www.pyroweb.de/red-dahlia-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3963",
  "https://www.pyroweb.de/red-dahlia-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1533",
  "https://www.pyroweb.de/red-dahlia-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2095",
  "https://www.pyroweb.de/red-fox-80-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3772",
  "https://www.pyroweb.de/red-glitter-mine-blue-star-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1534",
  "https://www.pyroweb.de/red-tears-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3970",
  "https://www.pyroweb.de/redgreen-and-coco-glitter-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1521",
  "https://www.pyroweb.de/reibkopfknaller-harzer-art/":
    "JumpIn=1&WebID=2553&intItemID=260",
  "https://www.pyroweb.de/relax-208-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2366",
  "https://www.pyroweb.de/revolution-2-160-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2195",
  "https://www.pyroweb.de/rhino-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1642",
  "https://www.pyroweb.de/riesen-flimmervulkan-2er/":
    "JumpIn=1&WebID=2553&intItemID=2854",
  "https://www.pyroweb.de/riesen-zylinderfontaene-gold/":
    "JumpIn=1&WebID=2553&intItemID=3905",
  "https://www.pyroweb.de/riesen-china-boeller-d-40er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1046",
  "https://www.pyroweb.de/riesen-glueckspilz/":
    "JumpIn=1&WebID=2553&intItemID=1154",
  "https://www.pyroweb.de/riesen-kanonenschlaege-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2732",
  "https://www.pyroweb.de/riesen-rauchbaelle/":
    "JumpIn=1&WebID=2553&intItemID=3058",
  "https://www.pyroweb.de/ring-rockets-7er-raketenset/":
    "JumpIn=1&WebID=2553&intItemID=2078",
  "https://www.pyroweb.de/ring-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1558",
  "https://www.pyroweb.de/robocalypse-152-schuss-verbundfeuerwerk-2/":
    "JumpIn=1&WebID=2553&intItemID=2110",
  "https://www.pyroweb.de/rock-star-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=773",
  "https://www.pyroweb.de/rocket-mix-12er-raketen/":
    "JumpIn=1&WebID=2553&intItemID=2041",
  "https://www.pyroweb.de/rocket-party-12er-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=2128",
  "https://www.pyroweb.de/rocket-strike-3er/":
    "JumpIn=1&WebID=2553&intItemID=1377",
  "https://www.pyroweb.de/rokoko-fontaene-a/":
    "JumpIn=1&WebID=2553&intItemID=3161",
  "https://www.pyroweb.de/rokoko-fontaene-b/":
    "JumpIn=1&WebID=2553&intItemID=3160",
  "https://www.pyroweb.de/rollercoaster-206-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1471",
  "https://www.pyroweb.de/roter-baron-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2823",
  "https://www.pyroweb.de/roter-korsar/": "JumpIn=1&WebID=2553&intItemID=1111",
  "https://www.pyroweb.de/rottweiler-130-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2653",
  "https://www.pyroweb.de/roughneck-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3747",
  "https://www.pyroweb.de/round-cake-12-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2102",
  "https://www.pyroweb.de/royalfackel-blau/":
    "JumpIn=1&WebID=2553&intItemID=746",
  "https://www.pyroweb.de/royalfackel-gelb/":
    "JumpIn=1&WebID=2553&intItemID=747",
  "https://www.pyroweb.de/royalfackel-gruen/":
    "JumpIn=1&WebID=2553&intItemID=745",
  "https://www.pyroweb.de/royalfackel-rot/":
    "JumpIn=1&WebID=2553&intItemID=817",
  "https://www.pyroweb.de/royalfackel-silber/":
    "JumpIn=1&WebID=2553&intItemID=748",
  "https://www.pyroweb.de/ruck-zuck-71-teiliges-jugendsortiment/":
    "JumpIn=1&WebID=2553&intItemID=2956",
  "https://www.pyroweb.de/roemische-lichter-12-schuss-10er-pack/":
    "JumpIn=1&WebID=2553&intItemID=119",
  "https://www.pyroweb.de/roemische-lichter-3er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=118",
  "https://www.pyroweb.de/roemisches-licht-blau/":
    "JumpIn=1&WebID=2553&intItemID=1390",
  "https://www.pyroweb.de/roemisches-licht-gruen/":
    "JumpIn=1&WebID=2553&intItemID=1391",
  "https://www.pyroweb.de/roemisches-licht-10-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2611",
  "https://www.pyroweb.de/roemisches-licht-20-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2612",
  "https://www.pyroweb.de/roemisches-licht-20-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2766",
  "https://www.pyroweb.de/roemisches-licht-20-schuss-2/":
    "JumpIn=1&WebID=2553&intItemID=3888",
  "https://www.pyroweb.de/roemisches-licht-xl-15-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2326",
  "https://www.pyroweb.de/roemisches-licht-xl-8-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2325",
  "https://www.pyroweb.de/s-o-s-fontaenen-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1445",
  "https://www.pyroweb.de/samurai-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1473",
  "https://www.pyroweb.de/santa-domingo-200-schuss-compound/":
    "JumpIn=1&WebID=2553&intItemID=3927",
  "https://www.pyroweb.de/santiago-masters-100-schuss-schnellfeuerbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3944",
  "https://www.pyroweb.de/satansknall-50er-packung-knallerbsen/":
    "JumpIn=1&WebID=2553&intItemID=333",
  "https://www.pyroweb.de/saturn-rockets-11er-raketen-set/":
    "JumpIn=1&WebID=2553&intItemID=3138",
  "https://www.pyroweb.de/saturn-raketen-6er/":
    "JumpIn=1&WebID=2553&intItemID=2826",
  "https://www.pyroweb.de/say-watt-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3740",
  "https://www.pyroweb.de/scaena-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1888",
  "https://www.pyroweb.de/scala-100a-100-schuss-verbundfeuerwerk-13g/":
    "JumpIn=1&WebID=2553&intItemID=2930",
  "https://www.pyroweb.de/scenario-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1963",
  "https://www.pyroweb.de/scharfe-schnitte-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3965",
  "https://www.pyroweb.de/schlossgeist-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1100",
  "https://www.pyroweb.de/schluesselband-keyholder-pyrocrew/":
    "JumpIn=1&WebID=2553&intItemID=213",
  "https://www.pyroweb.de/schluesselband-keyholder-pyrowebde/":
    "JumpIn=1&WebID=2553&intItemID=212",
  "https://www.pyroweb.de/schnaps-becher-extra-leuchtkraft-50-ml-blau/":
    "JumpIn=1&WebID=2553&intItemID=410",
  "https://www.pyroweb.de/schnaps-becher-extra-leuchtkraft-50-ml-gelb/":
    "JumpIn=1&WebID=2553&intItemID=412",
  "https://www.pyroweb.de/schnaps-becher-extra-leuchtkraft-50-ml-gruen/":
    "JumpIn=1&WebID=2553&intItemID=413",
  "https://www.pyroweb.de/schnaps-becher-extra-leuchtkraft-50-ml-rot/":
    "JumpIn=1&WebID=2553&intItemID=411",
  "https://www.pyroweb.de/schwarze-witwe-10-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1359",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-1-2/":
    "JumpIn=1&WebID=2553&intItemID=726",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-10/":
    "JumpIn=1&WebID=2553&intItemID=1913",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-11/":
    "JumpIn=1&WebID=2553&intItemID=3987",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-12/":
    "JumpIn=1&WebID=2553&intItemID=3988",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-2/":
    "JumpIn=1&WebID=2553&intItemID=811",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-3/":
    "JumpIn=1&WebID=2553&intItemID=727",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-4/":
    "JumpIn=1&WebID=2553&intItemID=1907",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-5/":
    "JumpIn=1&WebID=2553&intItemID=1908",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-7/":
    "JumpIn=1&WebID=2553&intItemID=1910",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-8/":
    "JumpIn=1&WebID=2553&intItemID=1911",
  "https://www.pyroweb.de/schweizer-super-vulkan-no-9/":
    "JumpIn=1&WebID=2553&intItemID=1912",
  "https://www.pyroweb.de/scream-8-schuss-roemisches-licht/":
    "JumpIn=1&WebID=2553&intItemID=3982",
  "https://www.pyroweb.de/scream-rocket-2-3-stage-6er-pack-13g/":
    "JumpIn=1&WebID=2553&intItemID=2929",
  "https://www.pyroweb.de/scream-rocket-2-6er-pack-13g/":
    "JumpIn=1&WebID=2553&intItemID=2928",
  "https://www.pyroweb.de/scream-rocket-mini/":
    "JumpIn=1&WebID=2553&intItemID=3981",
  "https://www.pyroweb.de/scream-whistling-batterie-100-schuss/":
    "JumpIn=1&WebID=2553&intItemID=3977",
  "https://www.pyroweb.de/screaming-spirits-66-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3864",
  "https://www.pyroweb.de/scuba-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3736",
  "https://www.pyroweb.de/selfie-party-box/":
    "JumpIn=1&WebID=2553&intItemID=2358",
  "https://www.pyroweb.de/servietten-18-goldsilber-16er/":
    "JumpIn=1&WebID=2553&intItemID=3581",
  "https://www.pyroweb.de/servietten-18-happy-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3505",
  "https://www.pyroweb.de/servietten-18-pink-16er/":
    "JumpIn=1&WebID=2553&intItemID=3584",
  "https://www.pyroweb.de/servietten-21-sparkling-celeb-pink-16er/":
    "JumpIn=1&WebID=2553&intItemID=3720",
  "https://www.pyroweb.de/servietten-21-sparkling-celeb-16er/":
    "JumpIn=1&WebID=2553&intItemID=3714",
  "https://www.pyroweb.de/servietten-30-goldsilber-16er/":
    "JumpIn=1&WebID=2553&intItemID=3580",
  "https://www.pyroweb.de/servietten-30-happy-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3513",
  "https://www.pyroweb.de/servietten-30-pink-16er/":
    "JumpIn=1&WebID=2553&intItemID=3585",
  "https://www.pyroweb.de/servietten-40-goldsilber-16er/":
    "JumpIn=1&WebID=2553&intItemID=3579",
  "https://www.pyroweb.de/servietten-40-happy-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3517",
  "https://www.pyroweb.de/servietten-40-pink-16er/":
    "JumpIn=1&WebID=2553&intItemID=3586",
  "https://www.pyroweb.de/servietten-50-goldsilber-16er/":
    "JumpIn=1&WebID=2553&intItemID=3582",
  "https://www.pyroweb.de/servietten-50-pink-16er/":
    "JumpIn=1&WebID=2553&intItemID=3587",
  "https://www.pyroweb.de/servietten-60-happy-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3527",
  "https://www.pyroweb.de/servietten-60-pink-16er/":
    "JumpIn=1&WebID=2553&intItemID=3588",
  "https://www.pyroweb.de/servietten-70-happy-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3533",
  "https://www.pyroweb.de/servietten-70-sparkling-celeb-16er/":
    "JumpIn=1&WebID=2553&intItemID=3687",
  "https://www.pyroweb.de/servietten-80-happy-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3541",
  "https://www.pyroweb.de/servietten-80-sparkling-celeb-16er/":
    "JumpIn=1&WebID=2553&intItemID=3688",
  "https://www.pyroweb.de/servietten-beautiful-horses-20er/":
    "JumpIn=1&WebID=2553&intItemID=3370",
  "https://www.pyroweb.de/servietten-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3491",
  "https://www.pyroweb.de/servietten-bvb-dortmund-20er/":
    "JumpIn=1&WebID=2553&intItemID=3454",
  "https://www.pyroweb.de/servietten-confetti-birthday-20er/":
    "JumpIn=1&WebID=2553&intItemID=3490",
  "https://www.pyroweb.de/servietten-fc-bayern-muenchen-20er/":
    "JumpIn=1&WebID=2553&intItemID=3474",
  "https://www.pyroweb.de/servietten-fireman-sam-16er/":
    "JumpIn=1&WebID=2553&intItemID=3405",
  "https://www.pyroweb.de/servietten-kicker-party-20er/":
    "JumpIn=1&WebID=2553&intItemID=3439",
  "https://www.pyroweb.de/servietten-magical-unicorn-16er/":
    "JumpIn=1&WebID=2553&intItemID=3354",
  "https://www.pyroweb.de/servietten-miraculous-33cm-20er/":
    "JumpIn=1&WebID=2553&intItemID=3334",
  "https://www.pyroweb.de/servietten-paw-patrol-pink-20er/":
    "JumpIn=1&WebID=2553&intItemID=3347",
  "https://www.pyroweb.de/servietten-paw-patrol-16er/":
    "JumpIn=1&WebID=2553&intItemID=3384",
  "https://www.pyroweb.de/servietten-pirat-20er/":
    "JumpIn=1&WebID=2553&intItemID=3423",
  "https://www.pyroweb.de/sethos-21er-raketen-set/":
    "JumpIn=1&WebID=2553&intItemID=1900",
  "https://www.pyroweb.de/shatterdome-55-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2158",
  "https://www.pyroweb.de/shellrockets-cal-34-mm/":
    "JumpIn=1&WebID=2553&intItemID=2717",
  "https://www.pyroweb.de/shellrockets-cal-3438-mm/":
    "JumpIn=1&WebID=2553&intItemID=2719",
  "https://www.pyroweb.de/shine-roemische-lichter/":
    "JumpIn=1&WebID=2553&intItemID=2877",
  "https://www.pyroweb.de/shock-64-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1567",
  "https://www.pyroweb.de/shoot-out-85-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=983",
  "https://www.pyroweb.de/shot30-4er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1975",
  "https://www.pyroweb.de/sicherheits-anstecklunte/":
    "JumpIn=1&WebID=2553&intItemID=1544",
  "https://www.pyroweb.de/sicherheits-anzuendpaket/":
    "JumpIn=1&WebID=2553&intItemID=2320",
  "https://www.pyroweb.de/sidewinder-40-schuss-compound/":
    "JumpIn=1&WebID=2553&intItemID=2050",
  "https://www.pyroweb.de/silber-meteoriten-kaliber-a/":
    "JumpIn=1&WebID=2553&intItemID=3792",
  "https://www.pyroweb.de/silber-titan-fontaene-quax/":
    "JumpIn=1&WebID=2553&intItemID=3816",
  "https://www.pyroweb.de/silber-fontaene-mit-basis-blau-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1729",
  "https://www.pyroweb.de/silber-fontaene-mit-basis-gruen-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1728",
  "https://www.pyroweb.de/silber-fontaene-mit-basis-lemon/":
    "JumpIn=1&WebID=2553&intItemID=3822",
  "https://www.pyroweb.de/silber-fontaene-mit-basis-orange/":
    "JumpIn=1&WebID=2553&intItemID=3823",
  "https://www.pyroweb.de/silber-fontaene-mit-basis-purple/":
    "JumpIn=1&WebID=2553&intItemID=3821",
  "https://www.pyroweb.de/silber-fontaene-mit-basis-rot-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1730",
  "https://www.pyroweb.de/silber-fontaene-mit-basis-tuerkis/":
    "JumpIn=1&WebID=2553&intItemID=3820",
  "https://www.pyroweb.de/silber-knallteufel-50er/":
    "JumpIn=1&WebID=2553&intItemID=1383",
  "https://www.pyroweb.de/silber-titanfontaene-6er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1731",
  "https://www.pyroweb.de/silbercracker-fontaene-5er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2496",
  "https://www.pyroweb.de/silberfontaene/":
    "JumpIn=1&WebID=2553&intItemID=2615",
  "https://www.pyroweb.de/silberfaecher-3er-vulkanverbund/":
    "JumpIn=1&WebID=2553&intItemID=3882",
  "https://www.pyroweb.de/silbervulkan-mit-gruen-blinker/":
    "JumpIn=1&WebID=2553&intItemID=2484",
  "https://www.pyroweb.de/silver-blinking-tail-to-silver-butterfly-with-blue-stars-42-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3922",
  "https://www.pyroweb.de/silver-flower-wave-tail-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1512",
  "https://www.pyroweb.de/silver-glitter-mine-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1948",
  "https://www.pyroweb.de/silver-pirate-knaller-50er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=1528",
  "https://www.pyroweb.de/silver-spinner-mine-36-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=1947",
  "https://www.pyroweb.de/silver-strobe-30-sec-5er-pack-2/":
    "JumpIn=1&WebID=2553&intItemID=3941",
  "https://www.pyroweb.de/silver-volcano/":
    "JumpIn=1&WebID=2553&intItemID=3770",
  "https://www.pyroweb.de/silvester-party-5er-raketen-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=875",
  "https://www.pyroweb.de/single-shot-europla-blue/":
    "JumpIn=1&WebID=2553&intItemID=3780",
  "https://www.pyroweb.de/single-shot-europla-green/":
    "JumpIn=1&WebID=2553&intItemID=3779",
  "https://www.pyroweb.de/single-shot-europla-lime/":
    "JumpIn=1&WebID=2553&intItemID=3783",
  "https://www.pyroweb.de/single-shot-europla-orange/":
    "JumpIn=1&WebID=2553&intItemID=3784",
  "https://www.pyroweb.de/single-shot-europla-purple/":
    "JumpIn=1&WebID=2553&intItemID=3781",
  "https://www.pyroweb.de/single-shot-europla-red/":
    "JumpIn=1&WebID=2553&intItemID=3778",
  "https://www.pyroweb.de/single-shot-europla-yellow/":
    "JumpIn=1&WebID=2553&intItemID=3782",
  "https://www.pyroweb.de/single-shot-3er-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=1447",
  "https://www.pyroweb.de/sinnendurst-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3186",
  "https://www.pyroweb.de/sirius-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=945",
  "https://www.pyroweb.de/sixpack-85-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2681",
  "https://www.pyroweb.de/skream-200-schuss-heulerbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3751",
  "https://www.pyroweb.de/skull-heaven-60-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2599",
  "https://www.pyroweb.de/sky-blue-crossette-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3140",
  "https://www.pyroweb.de/sky-bombs-5er-single-shots/":
    "JumpIn=1&WebID=2553&intItemID=2153",
  "https://www.pyroweb.de/sky-high-a-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3287",
  "https://www.pyroweb.de/sky-high-b-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3288",
  "https://www.pyroweb.de/sky-high-c-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3289",
  "https://www.pyroweb.de/sky-high-300-schuss-komplettfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2399",
  "https://www.pyroweb.de/sky-show-100-schuss-verbundbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2388",
  "https://www.pyroweb.de/sky-shuttle-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1712",
  "https://www.pyroweb.de/sky-tripper-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2881",
  "https://www.pyroweb.de/slender-man-51-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2865",
  "https://www.pyroweb.de/slotmachine-30-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1440",
  "https://www.pyroweb.de/smaragd-70cm-wunderkerzen/":
    "JumpIn=1&WebID=2553&intItemID=3752",
  "https://www.pyroweb.de/smiley-maxi-pack/":
    "JumpIn=1&WebID=2553&intItemID=2855",
  "https://www.pyroweb.de/smoke-arancio-single-shot/":
    "JumpIn=1&WebID=2553&intItemID=3847",
  "https://www.pyroweb.de/smoke-bianco-single-shot/":
    "JumpIn=1&WebID=2553&intItemID=3849",
  "https://www.pyroweb.de/smoke-bird-4er/":
    "JumpIn=1&WebID=2553&intItemID=2378",
  "https://www.pyroweb.de/smoke-fucsia-single-shot/":
    "JumpIn=1&WebID=2553&intItemID=3846",
  "https://www.pyroweb.de/smoke-giallo-single-shot/":
    "JumpIn=1&WebID=2553&intItemID=3848",
  "https://www.pyroweb.de/smoke-mit-reisszuendung-grau/":
    "JumpIn=1&WebID=2553&intItemID=3121",
  "https://www.pyroweb.de/smoke-tubes-6er-beutel/":
    "JumpIn=1&WebID=2553&intItemID=2267",
  "https://www.pyroweb.de/smoke-verde-single-shot/":
    "JumpIn=1&WebID=2553&intItemID=3850",
  "https://www.pyroweb.de/sneaky-pike-224-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1929",
  "https://www.pyroweb.de/softshell-weste-pyroweb/":
    "JumpIn=1&WebID=2553&intItemID=2233",
  "https://www.pyroweb.de/solaris-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2196",
  "https://www.pyroweb.de/sommerglueck-8-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3187",
  "https://www.pyroweb.de/sonnencracker-fontaenen-5er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2626",
  "https://www.pyroweb.de/sonnenvoegel/": "JumpIn=1&WebID=2553&intItemID=2860",
  "https://www.pyroweb.de/space-balls-knatterbaelle-25er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=3044",
  "https://www.pyroweb.de/space-box/": "JumpIn=1&WebID=2553&intItemID=1325",
  "https://www.pyroweb.de/space-cadets/": "JumpIn=1&WebID=2553&intItemID=3069",
  "https://www.pyroweb.de/-2/": "JumpIn=1&WebID=2553&intItemID=1402",
  "https://www.pyroweb.de/space-trip-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2094",
  "https://www.pyroweb.de/space-x/": "JumpIn=1&WebID=2553&intItemID=3204",
  "https://www.pyroweb.de/special-gold-100-schuss-verbundfeuerwerk-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=3834",
  "https://www.pyroweb.de/spectacular-75-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3130",
  "https://www.pyroweb.de/spectakularis-no-3-198-schuss-megaverbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3299",
  "https://www.pyroweb.de/speed-triple-87-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1339",
  "https://www.pyroweb.de/speedrunner-8er/":
    "JumpIn=1&WebID=2553&intItemID=2689",
  "https://www.pyroweb.de/speedtrap-224-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3035",
  "https://www.pyroweb.de/speedy/": "JumpIn=1&WebID=2553&intItemID=1387",
  "https://www.pyroweb.de/spider-kings-10er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2842",
  "https://www.pyroweb.de/spin-doctor-3er/":
    "JumpIn=1&WebID=2553&intItemID=2659",
  "https://www.pyroweb.de/spinner-pack/": "JumpIn=1&WebID=2553&intItemID=1372",
  "https://www.pyroweb.de/spinning-blinker/":
    "JumpIn=1&WebID=2553&intItemID=1343",
  "https://www.pyroweb.de/spinning-dahlia-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2075",
  "https://www.pyroweb.de/spiralkerzen-blau-mit-halter-24er/":
    "JumpIn=1&WebID=2553&intItemID=3723",
  "https://www.pyroweb.de/spiralkerzen-bunt-magisch-10er/":
    "JumpIn=1&WebID=2553&intItemID=3605",
  "https://www.pyroweb.de/spiralkerzen-bunt-mit-stern-8er/":
    "JumpIn=1&WebID=2553&intItemID=3604",
  "https://www.pyroweb.de/spiralkerzen-bunt-12er/":
    "JumpIn=1&WebID=2553&intItemID=3602",
  "https://www.pyroweb.de/spiralkerzen-glitzer-blau-10er/":
    "JumpIn=1&WebID=2553&intItemID=3597",
  "https://www.pyroweb.de/spiralkerzen-glitzer-bunt-10er/":
    "JumpIn=1&WebID=2553&intItemID=3596",
  "https://www.pyroweb.de/spiralkerzen-glitzer-pink-10er/":
    "JumpIn=1&WebID=2553&intItemID=3599",
  "https://www.pyroweb.de/spiralkerzen-glitzer-weiss-10er/":
    "JumpIn=1&WebID=2553&intItemID=3598",
  "https://www.pyroweb.de/spiralkerzen-gold-10er/":
    "JumpIn=1&WebID=2553&intItemID=3601",
  "https://www.pyroweb.de/spiralkerzen-pink-mit-halter-24er/":
    "JumpIn=1&WebID=2553&intItemID=3724",
  "https://www.pyroweb.de/spiralkerzen-silber-mit-stern-8er/":
    "JumpIn=1&WebID=2553&intItemID=3603",
  "https://www.pyroweb.de/spiralkerzen-silber-10er/":
    "JumpIn=1&WebID=2553&intItemID=3600",
  "https://www.pyroweb.de/spirit-sun-effekt-sonne/":
    "JumpIn=1&WebID=2553&intItemID=1525",
  "https://www.pyroweb.de/spit-it-out/": "JumpIn=1&WebID=2553&intItemID=3910",
  "https://www.pyroweb.de/spooky-flames-jugend-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2856",
  "https://www.pyroweb.de/spring-butterfly-12er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2114",
  "https://www.pyroweb.de/springinsfeld-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3188",
  "https://www.pyroweb.de/springteufel-1/":
    "JumpIn=1&WebID=2553&intItemID=3007",
  "https://www.pyroweb.de/spuk-fontaenen-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1588",
  "https://www.pyroweb.de/st-helena-no2/": "JumpIn=1&WebID=2553&intItemID=1012",
  "https://www.pyroweb.de/st-helena-no-3/":
    "JumpIn=1&WebID=2553&intItemID=1032",
  "https://www.pyroweb.de/stachelrose-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2825",
  "https://www.pyroweb.de/star-duster-10er-raketenset/":
    "JumpIn=1&WebID=2553&intItemID=2392",
  "https://www.pyroweb.de/star-light-20er-raketensortiment/":
    "JumpIn=1&WebID=2553&intItemID=1918",
  "https://www.pyroweb.de/star-tail-7er-raketen-pack/":
    "JumpIn=1&WebID=2553&intItemID=3971",
  "https://www.pyroweb.de/starbase-10-profi-raketenstaender-mit-automatischer-zuendfolge/":
    "JumpIn=1&WebID=2553&intItemID=2639",
  "https://www.pyroweb.de/stargate-leuchtsortiment/":
    "JumpIn=1&WebID=2553&intItemID=2354",
  "https://www.pyroweb.de/stargazing-113-teilig/":
    "JumpIn=1&WebID=2553&intItemID=2333",
  "https://www.pyroweb.de/starklicht-bengalfackel-gelb-bengalo/":
    "JumpIn=1&WebID=2553&intItemID=933",
  "https://www.pyroweb.de/starklicht-bengalfackel-gruen-bengalo/":
    "JumpIn=1&WebID=2553&intItemID=932",
  "https://www.pyroweb.de/starklicht-bengalfackel-purpur-bengalo/":
    "JumpIn=1&WebID=2553&intItemID=999",
  "https://www.pyroweb.de/starklicht-bengalfackel-rot-bengalo/":
    "JumpIn=1&WebID=2553&intItemID=749",
  "https://www.pyroweb.de/starklicht-bengalfackel-weiss-bengalo/":
    "JumpIn=1&WebID=2553&intItemID=934",
  "https://www.pyroweb.de/starlight-bouquet-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2099",
  "https://www.pyroweb.de/startrade-gold-schlag-bombenrohr/":
    "JumpIn=1&WebID=2553&intItemID=2907",
  "https://www.pyroweb.de/startrade-kugelrakete-brocade-crown-to-red-f3/":
    "JumpIn=1&WebID=2553&intItemID=2905",
  "https://www.pyroweb.de/startrade-kugelrakete-brocade-crown-with-white-strobe-pistil-f3/":
    "JumpIn=1&WebID=2553&intItemID=2906",
  "https://www.pyroweb.de/startrade-kugelrakete-red-heart/":
    "JumpIn=1&WebID=2553&intItemID=2902",
  "https://www.pyroweb.de/steampunk-41-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2072",
  "https://www.pyroweb.de/stellaris-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3745",
  "https://www.pyroweb.de/stempel-set-abc/":
    "JumpIn=1&WebID=2553&intItemID=3327",
  "https://www.pyroweb.de/ster-30cm-wunderkerzen/":
    "JumpIn=1&WebID=2553&intItemID=3931",
  "https://www.pyroweb.de/sternen-kracher-power-set/":
    "JumpIn=1&WebID=2553&intItemID=1400",
  "https://www.pyroweb.de/sternenmeer-72-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3063",
  "https://www.pyroweb.de/sternstunde-80-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3300",
  "https://www.pyroweb.de/stinging-catfish-57-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1926",
  "https://www.pyroweb.de/stoffbeutel-caramel-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1295",
  "https://www.pyroweb.de/stoffbeutel-caramel-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1294",
  "https://www.pyroweb.de/stoffbeutel-caramel-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1293",
  "https://www.pyroweb.de/stoffbeutel-caramel-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1297",
  "https://www.pyroweb.de/stoffbeutel-caramel-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1296",
  "https://www.pyroweb.de/stoffbeutel-chocolate-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1290",
  "https://www.pyroweb.de/stoffbeutel-chocolate-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1289",
  "https://www.pyroweb.de/stoffbeutel-chocolate-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1288",
  "https://www.pyroweb.de/stoffbeutel-chocolate-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1291",
  "https://www.pyroweb.de/stoffbeutel-chocolate-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1292",
  "https://www.pyroweb.de/stoffbeutel-graphit-grey-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1285",
  "https://www.pyroweb.de/stoffbeutel-graphit-grey-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1284",
  "https://www.pyroweb.de/stoffbeutel-graphit-grey-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1283",
  "https://www.pyroweb.de/stoffbeutel-graphit-grey-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1287",
  "https://www.pyroweb.de/stoffbeutel-graphit-grey-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1286",
  "https://www.pyroweb.de/stoffbeutel-lime-green-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1270",
  "https://www.pyroweb.de/stoffbeutel-lime-green-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1271",
  "https://www.pyroweb.de/stoffbeutel-lime-green-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1269",
  "https://www.pyroweb.de/stoffbeutel-lime-green-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1268",
  "https://www.pyroweb.de/stoffbeutel-lime-green-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1272",
  "https://www.pyroweb.de/stoffbeutel-olive-green-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1280",
  "https://www.pyroweb.de/stoffbeutel-olive-green-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1282",
  "https://www.pyroweb.de/stoffbeutel-olive-green-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1279",
  "https://www.pyroweb.de/stoffbeutel-olive-green-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1278",
  "https://www.pyroweb.de/stoffbeutel-olive-green-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1281",
  "https://www.pyroweb.de/stoffbeutel-purple-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1263",
  "https://www.pyroweb.de/stoffbeutel-purple-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1265",
  "https://www.pyroweb.de/stoffbeutel-purple-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1266",
  "https://www.pyroweb.de/stoffbeutel-purple-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1264",
  "https://www.pyroweb.de/stoffbeutel-purple-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1267",
  "https://www.pyroweb.de/stoffbeutel-schwarz-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1260",
  "https://www.pyroweb.de/stoffbeutel-schwarz-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1258",
  "https://www.pyroweb.de/stoffbeutel-schwarz-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1261",
  "https://www.pyroweb.de/stoffbeutel-schwarz-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1262",
  "https://www.pyroweb.de/stoffbeutel-schwarz-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1259",
  "https://www.pyroweb.de/stoffbeutel-surf-blue-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1276",
  "https://www.pyroweb.de/stoffbeutel-surf-blue-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1274",
  "https://www.pyroweb.de/stoffbeutel-surf-blue-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1273",
  "https://www.pyroweb.de/stoffbeutel-surf-blue-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1277",
  "https://www.pyroweb.de/stoffbeutel-surf-blue-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1275",
  "https://www.pyroweb.de/stoffrucksack-bottle-green-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1313",
  "https://www.pyroweb.de/stoffrucksack-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1298",
  "https://www.pyroweb.de/stoffrucksack-bottle-green-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1303",
  "https://www.pyroweb.de/stoffrucksack-bottle-green-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1308",
  "https://www.pyroweb.de/stoffrucksack-bottle-green-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1318",
  "https://www.pyroweb.de/stoffrucksack-graphit-grey-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1314",
  "https://www.pyroweb.de/stoffrucksack-graphit-grey-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1299",
  "https://www.pyroweb.de/stoffrucksack-graphit-grey-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1304",
  "https://www.pyroweb.de/stoffrucksack-graphit-grey-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1309",
  "https://www.pyroweb.de/stoffrucksack-graphit-grey-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1319",
  "https://www.pyroweb.de/stoffrucksack-light-grey-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1315",
  "https://www.pyroweb.de/stoffrucksack-light-grey-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1300",
  "https://www.pyroweb.de/stoffrucksack-light-grey-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1305",
  "https://www.pyroweb.de/stoffrucksack-light-grey-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1310",
  "https://www.pyroweb.de/stoffrucksack-light-grey-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1320",
  "https://www.pyroweb.de/stoffrucksack-navy-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1316",
  "https://www.pyroweb.de/stoffrucksack-navy-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1301",
  "https://www.pyroweb.de/stoffrucksack-navy-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1306",
  "https://www.pyroweb.de/stoffrucksack-navy-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1311",
  "https://www.pyroweb.de/stoffrucksack-navy-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1321",
  "https://www.pyroweb.de/stoffrucksack-schwarz-boellerbeutel/":
    "JumpIn=1&WebID=2553&intItemID=1317",
  "https://www.pyroweb.de/stoffrucksack-schwarz-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1302",
  "https://www.pyroweb.de/stoffrucksack-schwarz-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1307",
  "https://www.pyroweb.de/stoffrucksack-schwarz-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1312",
  "https://www.pyroweb.de/stoffrucksack-schwarz-wundertuete/":
    "JumpIn=1&WebID=2553&intItemID=1322",
  "https://www.pyroweb.de/storm-4er/": "JumpIn=1&WebID=2553&intItemID=2360",
  "https://www.pyroweb.de/strammer-max-50-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2215",
  "https://www.pyroweb.de/streetmuscle-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3739",
  "https://www.pyroweb.de/strobe-flash-3er/":
    "JumpIn=1&WebID=2553&intItemID=2660",
  "https://www.pyroweb.de/strobe-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1877",
  "https://www.pyroweb.de/strobo-fontaene-weiss-3er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2186",
  "https://www.pyroweb.de/strobocop/": "JumpIn=1&WebID=2553&intItemID=3074",
  "https://www.pyroweb.de/strobos-weiss-3er-pack/":
    "JumpIn=1&WebID=2553&intItemID=3829",
  "https://www.pyroweb.de/stroboscope-60-sec-6er-pack-2/":
    "JumpIn=1&WebID=2553&intItemID=3940",
  "https://www.pyroweb.de/stroboskop-mix-2/":
    "JumpIn=1&WebID=2553&intItemID=3165",
  "https://www.pyroweb.de/stromboli-fontaenen-und-vulkanset/":
    "JumpIn=1&WebID=2553&intItemID=2884",
  "https://www.pyroweb.de/sturdy-2-the-max-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3838",
  "https://www.pyroweb.de/subway-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3171",
  "https://www.pyroweb.de/sugar-daddy-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3839",
  "https://www.pyroweb.de/summer-fountains/":
    "JumpIn=1&WebID=2553&intItemID=1380",
  "https://www.pyroweb.de/super-boilers-3er/":
    "JumpIn=1&WebID=2553&intItemID=2830",
  "https://www.pyroweb.de/super-fun-pack/": "JumpIn=1&WebID=2553&intItemID=854",
  "https://www.pyroweb.de/super-gemini-doppelschlag-raketen/":
    "JumpIn=1&WebID=2553&intItemID=2619",
  "https://www.pyroweb.de/super-tornados-5er-pack-bodenwirbel/":
    "JumpIn=1&WebID=2553&intItemID=2841",
  "https://www.pyroweb.de/super-whistling-comets-300-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2082",
  "https://www.pyroweb.de/super-aetna/": "JumpIn=1&WebID=2553&intItemID=115",
  "https://www.pyroweb.de/super-10er/": "JumpIn=1&WebID=2553&intItemID=2957",
  "https://www.pyroweb.de/super-boeller-i/":
    "JumpIn=1&WebID=2553&intItemID=1649",
  "https://www.pyroweb.de/super-boeller-ii/":
    "JumpIn=1&WebID=2553&intItemID=1650",
  "https://www.pyroweb.de/supermaniac-133-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2709",
  "https://www.pyroweb.de/sweet-strawberry-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2140",
  "https://www.pyroweb.de/sydney-sunrise-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3142",
  "https://www.pyroweb.de/sylvester-mainframe-138-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2989",
  "https://www.pyroweb.de/symphonie-15-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3237",
  "https://www.pyroweb.de/symphony-113-schuss-cbp-serie/":
    "JumpIn=1&WebID=2553&intItemID=1126",
  "https://www.pyroweb.de/suesses-gift-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2018",
  "https://www.pyroweb.de/t-shirt-mit-pyrowebde-flame/":
    "JumpIn=1&WebID=2553&intItemID=215",
  "https://www.pyroweb.de/t-shirt-mit-pyrowebde-logo/":
    "JumpIn=1&WebID=2553&intItemID=214",
  "https://www.pyroweb.de/t-shirt-navy-bam-pyrotechnik-ja-bitte/":
    "JumpIn=1&WebID=2553&intItemID=1199",
  "https://www.pyroweb.de/t-shirt-navy-booom-und-wieder-unbeliebt-gemacht/":
    "JumpIn=1&WebID=2553&intItemID=1208",
  "https://www.pyroweb.de/t-shirt-navy-eat-sleep-pyro-repeat/":
    "JumpIn=1&WebID=2553&intItemID=1164",
  "https://www.pyroweb.de/t-shirt-navy-every-day-is-a-pyro-day/":
    "JumpIn=1&WebID=2553&intItemID=1166",
  "https://www.pyroweb.de/t-shirt-navy-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1169",
  "https://www.pyroweb.de/t-shirt-navy-konfetti-und-randale/":
    "JumpIn=1&WebID=2553&intItemID=1172",
  "https://www.pyroweb.de/t-shirt-navy-nicht-lange-fackeln/":
    "JumpIn=1&WebID=2553&intItemID=1175",
  "https://www.pyroweb.de/t-shirt-navy-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1205",
  "https://www.pyroweb.de/t-shirt-navy-no-pyro-no-party/":
    "JumpIn=1&WebID=2553&intItemID=1178",
  "https://www.pyroweb.de/t-shirt-navy-pyro-addict/":
    "JumpIn=1&WebID=2553&intItemID=1181",
  "https://www.pyroweb.de/t-shirt-navy-pyro-addiction/":
    "JumpIn=1&WebID=2553&intItemID=1184",
  "https://www.pyroweb.de/t-shirt-navy-pyro-is-not-a-crime/":
    "JumpIn=1&WebID=2553&intItemID=1190",
  "https://www.pyroweb.de/t-shirt-navy-pyro-statt-buero/":
    "JumpIn=1&WebID=2553&intItemID=1196",
  "https://www.pyroweb.de/t-shirt-navy-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1217",
  "https://www.pyroweb.de/t-shirt-navy-pyro-wuerfel/":
    "JumpIn=1&WebID=2553&intItemID=1216",
  "https://www.pyroweb.de/t-shirt-navy-pyroholic/":
    "JumpIn=1&WebID=2553&intItemID=1187",
  "https://www.pyroweb.de/t-shirt-navy-pyrotechnik-ist-kein-verbrechen/":
    "JumpIn=1&WebID=2553&intItemID=1202",
  "https://www.pyroweb.de/t-shirt-navy-wir-gluehen-nicht-vor-wir-fackeln-ab/":
    "JumpIn=1&WebID=2553&intItemID=1211",
  "https://www.pyroweb.de/t-shirt-pyrocrew/":
    "JumpIn=1&WebID=2553&intItemID=216",
  "https://www.pyroweb.de/t-shirt-schwarz-bam-pyrotechnik-ja-bitte/":
    "JumpIn=1&WebID=2553&intItemID=1200",
  "https://www.pyroweb.de/t-shirt-schwarz-booom-und-wieder-unbeliebt-gemacht/":
    "JumpIn=1&WebID=2553&intItemID=1209",
  "https://www.pyroweb.de/eat-sleep-pyro-repeat/":
    "JumpIn=1&WebID=2553&intItemID=1163",
  "https://www.pyroweb.de/t-shirt-schwarz-every-day-is-a-pyro-day/":
    "JumpIn=1&WebID=2553&intItemID=1167",
  "https://www.pyroweb.de/t-shirt-schwarz-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1170",
  "https://www.pyroweb.de/t-shirt-schwarz-konfetti-und-randale/":
    "JumpIn=1&WebID=2553&intItemID=1173",
  "https://www.pyroweb.de/t-shirt-schwarz-nicht-lange-fackeln/":
    "JumpIn=1&WebID=2553&intItemID=1176",
  "https://www.pyroweb.de/t-shirt-schwarz-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1206",
  "https://www.pyroweb.de/t-shirt-schwarz-no-pyro-no-party/":
    "JumpIn=1&WebID=2553&intItemID=1179",
  "https://www.pyroweb.de/t-shirt-schwarz-pyro-addict/":
    "JumpIn=1&WebID=2553&intItemID=1182",
  "https://www.pyroweb.de/t-shirt-schwarz-pyro-addiction/":
    "JumpIn=1&WebID=2553&intItemID=1185",
  "https://www.pyroweb.de/t-shirt-schwarz-pyro-is-not-a-crime/":
    "JumpIn=1&WebID=2553&intItemID=1191",
  "https://www.pyroweb.de/t-shirt-schwarz-pyrolution-2/":
    "JumpIn=1&WebID=2553&intItemID=1197",
  "https://www.pyroweb.de/t-shirt-schwarz-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1218",
  "https://www.pyroweb.de/t-shirt-schwarz-pyro-wuerfel/":
    "JumpIn=1&WebID=2553&intItemID=1214",
  "https://www.pyroweb.de/t-shirt-schwarz-pyroholic/":
    "JumpIn=1&WebID=2553&intItemID=1188",
  "https://www.pyroweb.de/t-shirt-schwarz-pyrotechnik-ist-kein-verbrechen/":
    "JumpIn=1&WebID=2553&intItemID=1203",
  "https://www.pyroweb.de/t-shirt-schwarz-wir-gluehen-nicht-vor-wir-fackeln-ab/":
    "JumpIn=1&WebID=2553&intItemID=1212",
  "https://www.pyroweb.de/t-shirt-weiss-every-day-is-a-pyro-day/":
    "JumpIn=1&WebID=2553&intItemID=1168",
  "https://www.pyroweb.de/t-shirt-weiss-bam-pyrotechnik-ja-bitte/":
    "JumpIn=1&WebID=2553&intItemID=1201",
  "https://www.pyroweb.de/t-shirt-weiss-booom-und-wieder-unbeliebt-gemacht/":
    "JumpIn=1&WebID=2553&intItemID=1210",
  "https://www.pyroweb.de/t-shirt-weiss-eat-sleep-pyro-repeat/":
    "JumpIn=1&WebID=2553&intItemID=1165",
  "https://www.pyroweb.de/t-shirt-weiss-knalltuete/":
    "JumpIn=1&WebID=2553&intItemID=1171",
  "https://www.pyroweb.de/t-shirt-weiss-konfetti-und-randale/":
    "JumpIn=1&WebID=2553&intItemID=1174",
  "https://www.pyroweb.de/t-shirt-weiss-nicht-lange-fackeln/":
    "JumpIn=1&WebID=2553&intItemID=1177",
  "https://www.pyroweb.de/t-shirt-weiss-no-one-likes-us-we-dont-care/":
    "JumpIn=1&WebID=2553&intItemID=1207",
  "https://www.pyroweb.de/t-shirt-weiss-no-pyro-no-party/":
    "JumpIn=1&WebID=2553&intItemID=1180",
  "https://www.pyroweb.de/t-shirt-weiss-pyro-addict/":
    "JumpIn=1&WebID=2553&intItemID=1183",
  "https://www.pyroweb.de/t-shirt-weiss-pyro-addiction/":
    "JumpIn=1&WebID=2553&intItemID=1186",
  "https://www.pyroweb.de/t-shirt-weiss-pyro-is-not-a-crime/":
    "JumpIn=1&WebID=2553&intItemID=1192",
  "https://www.pyroweb.de/t-shirt-weiss-pyro-statt-buero/":
    "JumpIn=1&WebID=2553&intItemID=1198",
  "https://www.pyroweb.de/t-shirt-weiss-pyro-icons/":
    "JumpIn=1&WebID=2553&intItemID=1219",
  "https://www.pyroweb.de/t-shirt-weiss-pyro-wuerfel/":
    "JumpIn=1&WebID=2553&intItemID=1215",
  "https://www.pyroweb.de/t-shirt-weiss-pyroholic/":
    "JumpIn=1&WebID=2553&intItemID=1189",
  "https://www.pyroweb.de/t-shirt-weiss-pyrolution/":
    "JumpIn=1&WebID=2553&intItemID=1195",
  "https://www.pyroweb.de/t-shirt-weiss-pyrotechnik-ist-kein-verbrechen/":
    "JumpIn=1&WebID=2553&intItemID=1204",
  "https://www.pyroweb.de/t-shirt-weiss-wir-gluehen-nicht-vor-wir-fackeln-ab/":
    "JumpIn=1&WebID=2553&intItemID=1213",
  "https://www.pyroweb.de/tailgate-fan-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2991",
  "https://www.pyroweb.de/takezo-kensei-100-schuss-verbundbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2389",
  "https://www.pyroweb.de/talon-igniter-05m/":
    "JumpIn=1&WebID=2553&intItemID=2964",
  "https://www.pyroweb.de/talon-igniter-1m/":
    "JumpIn=1&WebID=2553&intItemID=2965",
  "https://www.pyroweb.de/talon-igniter-2m/":
    "JumpIn=1&WebID=2553&intItemID=2966",
  "https://www.pyroweb.de/talon-igniter-3m/":
    "JumpIn=1&WebID=2553&intItemID=2967",
  "https://www.pyroweb.de/talon-igniter-4m/":
    "JumpIn=1&WebID=2553&intItemID=2968",
  "https://www.pyroweb.de/talon-igniter-5m/":
    "JumpIn=1&WebID=2553&intItemID=2969",
  "https://www.pyroweb.de/tanz-der-vampire-12-schuss/":
    "JumpIn=1&WebID=2553&intItemID=373",
  "https://www.pyroweb.de/zuendband-tapematch-15m/":
    "JumpIn=1&WebID=2553&intItemID=441",
  "https://www.pyroweb.de/tausendsassa-fontaenen-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2163",
  "https://www.pyroweb.de/tazmania/": "JumpIn=1&WebID=2553&intItemID=2581",
  "https://www.pyroweb.de/tears-of-fire-49-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2863",
  "https://www.pyroweb.de/temptation-willow-100-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3926",
  "https://www.pyroweb.de/terabyte-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3743",
  "https://www.pyroweb.de/teufels-turm/": "JumpIn=1&WebID=2553&intItemID=2891",
  "https://www.pyroweb.de/teufelskugeln-3er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2688",
  "https://www.pyroweb.de/tgvolt-150-schuss-verbund/":
    "JumpIn=1&WebID=2553&intItemID=3960",
  "https://www.pyroweb.de/thalia-25-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2829",
  "https://www.pyroweb.de/the-face-3-raketenset/":
    "JumpIn=1&WebID=2553&intItemID=1416",
  "https://www.pyroweb.de/the-flying-dutchman-70-schuss-verbundbatterie-im-faecher/":
    "JumpIn=1&WebID=2553&intItemID=2838",
  "https://www.pyroweb.de/the-garden-gnom/":
    "JumpIn=1&WebID=2553&intItemID=3865",
  "https://www.pyroweb.de/the-king/": "JumpIn=1&WebID=2553&intItemID=1110",
  "https://www.pyroweb.de/the-maxx-50-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2390",
  "https://www.pyroweb.de/the-maze-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2225",
  "https://www.pyroweb.de/the-motherload-1-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3157",
  "https://www.pyroweb.de/the-motherload-2-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3156",
  "https://www.pyroweb.de/the-motherload-3-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3155",
  "https://www.pyroweb.de/the-motherload-4-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3154",
  "https://www.pyroweb.de/the-motherload-5-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3153",
  "https://www.pyroweb.de/the-one-64-schuss-compound/":
    "JumpIn=1&WebID=2553&intItemID=2049",
  "https://www.pyroweb.de/thunder-blitz-3er-single-shot/":
    "JumpIn=1&WebID=2553&intItemID=2600",
  "https://www.pyroweb.de/thunder-clouds/":
    "JumpIn=1&WebID=2553&intItemID=3203",
  "https://www.pyroweb.de/thunder-cracker/":
    "JumpIn=1&WebID=2553&intItemID=3096",
  "https://www.pyroweb.de/thunder-cube-3er/":
    "JumpIn=1&WebID=2553&intItemID=3088",
  "https://www.pyroweb.de/thunder-lords-40er-singelshots/":
    "JumpIn=1&WebID=2553&intItemID=3041",
  "https://www.pyroweb.de/thunder-minez-40er-set-singelshots/":
    "JumpIn=1&WebID=2553&intItemID=3040",
  "https://www.pyroweb.de/thunder-tricky-megapack/":
    "JumpIn=1&WebID=2553&intItemID=3097",
  "https://www.pyroweb.de/thunderbirds-75-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2387",
  "https://www.pyroweb.de/thunderking-crackling-mine-4er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=3046",
  "https://www.pyroweb.de/thunderking-crackling-mortar/":
    "JumpIn=1&WebID=2553&intItemID=3948",
  "https://www.pyroweb.de/tiger-bomb-6er/":
    "JumpIn=1&WebID=2553&intItemID=3291",
  "https://www.pyroweb.de/time-rain-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1527",
  "https://www.pyroweb.de/tischbombe-girls-party/":
    "JumpIn=1&WebID=2553&intItemID=2142",
  "https://www.pyroweb.de/tischbombe-kids-party/":
    "JumpIn=1&WebID=2553&intItemID=2141",
  "https://www.pyroweb.de/tischbombe-party/":
    "JumpIn=1&WebID=2553&intItemID=2145",
  "https://www.pyroweb.de/tischbombe-party-time-gold/":
    "JumpIn=1&WebID=2553&intItemID=2147",
  "https://www.pyroweb.de/tischbombe-party-time-silber/":
    "JumpIn=1&WebID=2553&intItemID=2146",
  "https://www.pyroweb.de/tischbombe-piraten-party/":
    "JumpIn=1&WebID=2553&intItemID=2144",
  "https://www.pyroweb.de/tischdecke-beautiful-horses-120x180cm/":
    "JumpIn=1&WebID=2553&intItemID=3379",
  "https://www.pyroweb.de/tischdecke-bvb-dortmund/":
    "JumpIn=1&WebID=2553&intItemID=3457",
  "https://www.pyroweb.de/tischdecke-confetti-birthday/":
    "JumpIn=1&WebID=2553&intItemID=3494",
  "https://www.pyroweb.de/tischdecke-fc-bayern-muenchen/":
    "JumpIn=1&WebID=2553&intItemID=3478",
  "https://www.pyroweb.de/tischdecke-fireman-sam/":
    "JumpIn=1&WebID=2553&intItemID=3409",
  "https://www.pyroweb.de/tischdecke-kicker-party/":
    "JumpIn=1&WebID=2553&intItemID=3447",
  "https://www.pyroweb.de/tischdecke-magical-unicorn-137-x-243-cm/":
    "JumpIn=1&WebID=2553&intItemID=3356",
  "https://www.pyroweb.de/tischdecke-miraculous-120x180cm/":
    "JumpIn=1&WebID=2553&intItemID=3341",
  "https://www.pyroweb.de/tischdecke-paw-patrol-120x180cm/":
    "JumpIn=1&WebID=2553&intItemID=3388",
  "https://www.pyroweb.de/tischdecke-pirat/":
    "JumpIn=1&WebID=2553&intItemID=3428",
  "https://www.pyroweb.de/tischdeko-sparkling-celeb-21-gold-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3712",
  "https://www.pyroweb.de/tischdeko-sparkling-celeb-21-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3717",
  "https://www.pyroweb.de/tischdeko-sparkling-celeb-70-gold-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3680",
  "https://www.pyroweb.de/tischdeko-sparkling-celeb-70-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3700",
  "https://www.pyroweb.de/tischdeko-sparkling-celeb-80-gold-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3681",
  "https://www.pyroweb.de/tischdeko-sparkling-celeb-80-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3706",
  "https://www.pyroweb.de/tischdekoration-sparkling-celebration-18-gold-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3526",
  "https://www.pyroweb.de/tischdekoration-sparkling-celebration-18-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3530",
  "https://www.pyroweb.de/tischdekoration-sparkling-celebration-30-gold-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3479",
  "https://www.pyroweb.de/tischdekoration-sparkling-celebration-30-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3534",
  "https://www.pyroweb.de/tischdekoration-sparkling-celebration-40-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3536",
  "https://www.pyroweb.de/tischdekoration-sparkling-celebration-50-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3539",
  "https://www.pyroweb.de/tischdekoration-sparkling-celebration-60-pink-46cm/":
    "JumpIn=1&WebID=2553&intItemID=3542",
  "https://www.pyroweb.de/tischfeuerwerk-5-vor-12-2er-beutel/":
    "JumpIn=1&WebID=2553&intItemID=331",
  "https://www.pyroweb.de/tischfeuerwerk-bombenspass-kinder-4er-schtl/":
    "JumpIn=1&WebID=2553&intItemID=551",
  "https://www.pyroweb.de/tischfeuerwerk-crazy-party/":
    "JumpIn=1&WebID=2553&intItemID=1087",
  "https://www.pyroweb.de/tischfeuerwerk-gluecksbringer/":
    "JumpIn=1&WebID=2553&intItemID=1015",
  "https://www.pyroweb.de/tischfeuerwerk-happy-new-year-2er-beutel/":
    "JumpIn=1&WebID=2553&intItemID=963",
  "https://www.pyroweb.de/tischfeuerwerk-mega-party/":
    "JumpIn=1&WebID=2553&intItemID=330",
  "https://www.pyroweb.de/tischfeuerwerk-party-bomben-4er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=537",
  "https://www.pyroweb.de/tischfeuerwerk-prosit-neujahr-2er-blister/":
    "JumpIn=1&WebID=2553&intItemID=332",
  "https://www.pyroweb.de/tischfeuerwerk-10er-pack/":
    "JumpIn=1&WebID=2553&intItemID=2498",
  "https://www.pyroweb.de/tischfeuerwerk-diverse-fuellung/":
    "JumpIn=1&WebID=2553&intItemID=1076",
  "https://www.pyroweb.de/titan-138-138-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2706",
  "https://www.pyroweb.de/titan-vulkan-2er-set/":
    "JumpIn=1&WebID=2553&intItemID=2076",
  "https://www.pyroweb.de/titanball-gross-3er/":
    "JumpIn=1&WebID=2553&intItemID=2635",
  "https://www.pyroweb.de/titanball-mittel/":
    "JumpIn=1&WebID=2553&intItemID=2686",
  "https://www.pyroweb.de/titanbengalo-gelb-2/":
    "JumpIn=1&WebID=2553&intItemID=2435",
  "https://www.pyroweb.de/titanbengalo-gruen-2/":
    "JumpIn=1&WebID=2553&intItemID=2434",
  "https://www.pyroweb.de/titanbengalo-purpur-2/":
    "JumpIn=1&WebID=2553&intItemID=2437",
  "https://www.pyroweb.de/titanbengalo-rot-2/":
    "JumpIn=1&WebID=2553&intItemID=2433",
  "https://www.pyroweb.de/titanbengalo-weiss-2/":
    "JumpIn=1&WebID=2553&intItemID=2436",
  "https://www.pyroweb.de/titanica-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1094",
  "https://www.pyroweb.de/titanium-blue-49-schuss-faecherbatterie-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=2888",
  "https://www.pyroweb.de/titanium-salut-scream-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3768",
  "https://www.pyroweb.de/titanium-thunder-fountain-4er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2993",
  "https://www.pyroweb.de/tokyokido-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2707",
  "https://www.pyroweb.de/tomahawk-rocket/":
    "JumpIn=1&WebID=2553&intItemID=2582",
  "https://www.pyroweb.de/tommy-thunders/":
    "JumpIn=1&WebID=2553&intItemID=3903",
  "https://www.pyroweb.de/top-gun-21-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2200",
  "https://www.pyroweb.de/top-secret-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2528",
  "https://www.pyroweb.de/toupie-crackling-flowers/":
    "JumpIn=1&WebID=2553&intItemID=2111",
  "https://www.pyroweb.de/toxic-rain-willows-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2202",
  "https://www.pyroweb.de/toxic-typhoon-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2711",
  "https://www.pyroweb.de/transformer-289-schuss-batterie-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=964",
  "https://www.pyroweb.de/transonic-198-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3748",
  "https://www.pyroweb.de/traumsterne-3er-pack/":
    "JumpIn=1&WebID=2553&intItemID=957",
  "https://www.pyroweb.de/trektouwtjes-knallziehschnur/":
    "JumpIn=1&WebID=2553&intItemID=2981",
  "https://www.pyroweb.de/trektouwtjes-knallziehschnur-3x24-stueck/":
    "JumpIn=1&WebID=2553&intItemID=2982",
  "https://www.pyroweb.de/trickshot-singelshots/":
    "JumpIn=1&WebID=2553&intItemID=3756",
  "https://www.pyroweb.de/trinkhalme-fireman-sam-8er/":
    "JumpIn=1&WebID=2553&intItemID=3413",
  "https://www.pyroweb.de/trinkhalme-kicker-party-12er/":
    "JumpIn=1&WebID=2553&intItemID=3443",
  "https://www.pyroweb.de/trinkhalme-magical-unicorn-8er/":
    "JumpIn=1&WebID=2553&intItemID=3355",
  "https://www.pyroweb.de/trinkhalme-paw-patrol-8er/":
    "JumpIn=1&WebID=2553&intItemID=3391",
  "https://www.pyroweb.de/trinkhalme-pirat-8er/":
    "JumpIn=1&WebID=2553&intItemID=3427",
  "https://www.pyroweb.de/trinkspiel-bier-pong-22-cups-4-balls/":
    "JumpIn=1&WebID=2553&intItemID=3657",
  "https://www.pyroweb.de/trinkspiel-bier-pong-24-cups-24-balls/":
    "JumpIn=1&WebID=2553&intItemID=3658",
  "https://www.pyroweb.de/trinkspiel-drinking-roulette/":
    "JumpIn=1&WebID=2553&intItemID=3663",
  "https://www.pyroweb.de/trinkspiel-prosecco-pong-12-cups-3-balls/":
    "JumpIn=1&WebID=2553&intItemID=3659",
  "https://www.pyroweb.de/trinkspiel-shot-dispenser/":
    "JumpIn=1&WebID=2553&intItemID=3664",
  "https://www.pyroweb.de/trinkspiel-shot-pong-12-cups-3-balls/":
    "JumpIn=1&WebID=2553&intItemID=3660",
  "https://www.pyroweb.de/trinkspiel-shot-spinner/":
    "JumpIn=1&WebID=2553&intItemID=3662",
  "https://www.pyroweb.de/trinkspiel-worth-a-shot/":
    "JumpIn=1&WebID=2553&intItemID=3661",
  "https://www.pyroweb.de/triple-double-75-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3936",
  "https://www.pyroweb.de/triple-trouble-108-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3863",
  "https://www.pyroweb.de/triumph-51-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2866",
  "https://www.pyroweb.de/triumph-9-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2601",
  "https://www.pyroweb.de/troja-2x-100-schuss-heulerbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2739",
  "https://www.pyroweb.de/trommelfeuer-1000er-knallkette/":
    "JumpIn=1&WebID=2553&intItemID=770",
  "https://www.pyroweb.de/tropica-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2720",
  "https://www.pyroweb.de/tropical-thunder-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2760",
  "https://www.pyroweb.de/trouble-maker-112-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2363",
  "https://www.pyroweb.de/true-color-starz/":
    "JumpIn=1&WebID=2553&intItemID=2994",
  "https://www.pyroweb.de/tsunami-of-gold-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=2713",
  "https://www.pyroweb.de/twirl-fire/": "JumpIn=1&WebID=2553&intItemID=3803",
  "https://www.pyroweb.de/ufo-bodenwirbel/":
    "JumpIn=1&WebID=2553&intItemID=979",
  "https://www.pyroweb.de/ultimate-chry/": "JumpIn=1&WebID=2553&intItemID=3928",
  "https://www.pyroweb.de/ultimate-city-trip-amsterdam-36-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3278",
  "https://www.pyroweb.de/ultimate-city-trip-berlin-36-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3277",
  "https://www.pyroweb.de/ultimate-city-trip-london-36-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3276",
  "https://www.pyroweb.de/ultimate-city-trip-moscow-36-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3282",
  "https://www.pyroweb.de/ultimate-city-trip-paris-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3275",
  "https://www.pyroweb.de/ultimate-city-trip-rome-36-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3274",
  "https://www.pyroweb.de/ultimate-design-80-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3937",
  "https://www.pyroweb.de/ultralux-gelb/": "JumpIn=1&WebID=2553&intItemID=1027",
  "https://www.pyroweb.de/ultramarin-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3828",
  "https://www.pyroweb.de/universalblechschere/":
    "JumpIn=1&WebID=2553&intItemID=2605",
  "https://www.pyroweb.de/unplugged-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1847",
  "https://www.pyroweb.de/untamed-warrior-24-schuss-batterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=2925",
  "https://www.pyroweb.de/utopia-35-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2157",
  "https://www.pyroweb.de/valencia-community-pass-fallas-2020/":
    "JumpIn=1&WebID=2553&intItemID=2627",
  "https://www.pyroweb.de/valencia-vip-package-1-fallas-2020/":
    "JumpIn=1&WebID=2553&intItemID=2628",
  "https://www.pyroweb.de/valencia-vip-package-2-fallas-2020/":
    "JumpIn=1&WebID=2553&intItemID=2629",
  "https://www.pyroweb.de/vaporizer-10er-pack-premium-boeller/":
    "JumpIn=1&WebID=2553&intItemID=2761",
  "https://www.pyroweb.de/varius-performance-fontaene-i/":
    "JumpIn=1&WebID=2553&intItemID=2014",
  "https://www.pyroweb.de/venom-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3973",
  "https://www.pyroweb.de/verwandlung-fontaene-brokat-silber-rot/":
    "JumpIn=1&WebID=2553&intItemID=3819",
  "https://www.pyroweb.de/verzoegerer-10s/":
    "JumpIn=1&WebID=2553&intItemID=3807",
  "https://www.pyroweb.de/verzoegerer-25s/":
    "JumpIn=1&WebID=2553&intItemID=3808",
  "https://www.pyroweb.de/verzoegerer-35s-2-3/":
    "JumpIn=1&WebID=2553&intItemID=3809",
  "https://www.pyroweb.de/verzoegerer-40s/":
    "JumpIn=1&WebID=2553&intItemID=3810",
  "https://www.pyroweb.de/verzoegerer-50s/":
    "JumpIn=1&WebID=2553&intItemID=3811",
  "https://www.pyroweb.de/vesuvius-fountain/":
    "JumpIn=1&WebID=2553&intItemID=3098",
  "https://www.pyroweb.de/vintage-54-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=2330",
  "https://www.pyroweb.de/vipers-36-stueck/":
    "JumpIn=1&WebID=2553&intItemID=1554",
  "https://www.pyroweb.de/visco-30-anzuendlitze-gruen-30-sm-10m-rolle/":
    "JumpIn=1&WebID=2553&intItemID=816",
  "https://www.pyroweb.de/visco-60-anzuendlitze-gruen-60-sm-10m-rolle/":
    "JumpIn=1&WebID=2553&intItemID=948",
  "https://www.pyroweb.de/viva-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2092",
  "https://www.pyroweb.de/vivaldi-100-schuss-komplettfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3175",
  "https://www.pyroweb.de/vogelschreck-doppelschlag-6er-bombenrohre/":
    "JumpIn=1&WebID=2553&intItemID=2487",
  "https://www.pyroweb.de/vogelschreck-batterie-6er-set/":
    "JumpIn=1&WebID=2553&intItemID=959",
  "https://www.pyroweb.de/volcanic-lightning-goldvulkan/":
    "JumpIn=1&WebID=2553&intItemID=3309",
  "https://www.pyroweb.de/vulkan-gold/": "JumpIn=1&WebID=2553&intItemID=2381",
  "https://www.pyroweb.de/vulkan-silber/": "JumpIn=1&WebID=2553&intItemID=2380",
  "https://www.pyroweb.de/vulkan-2er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=335",
  "https://www.pyroweb.de/vulkan-set-4er/":
    "JumpIn=1&WebID=2553&intItemID=2517",
  "https://www.pyroweb.de/vuurspuwers-box-fontaenen-und-vulkanset-15-teilig-2/":
    "JumpIn=1&WebID=2553&intItemID=3219",
  "https://www.pyroweb.de/w-triple-layer-108-schuss-verbundfeuerwerk-im-kaefig/":
    "JumpIn=1&WebID=2553&intItemID=2892",
  "https://www.pyroweb.de/wachsfackel-45cm-45-minuten-brenndauer/":
    "JumpIn=1&WebID=2553&intItemID=2970",
  "https://www.pyroweb.de/wachsfackel-60-min-1-stueck/":
    "JumpIn=1&WebID=2553&intItemID=402",
  "https://www.pyroweb.de/wachsfackel-blau-barock-2er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1008",
  "https://www.pyroweb.de/wachsfackel-gruen-barock-2er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1007",
  "https://www.pyroweb.de/wachsfackel-rot-barock-2er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1005",
  "https://www.pyroweb.de/wachsfackel-silber-barock-2er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1006",
  "https://www.pyroweb.de/warrior-8-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3805",
  "https://www.pyroweb.de/wasabi-40-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3173",
  "https://www.pyroweb.de/water-world-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2728",
  "https://www.pyroweb.de/wckd-six-6er-batterie-sortiment/":
    "JumpIn=1&WebID=2553&intItemID=2840",
  "https://www.pyroweb.de/wckd-warlord-30-schuss-battierie/":
    "JumpIn=1&WebID=2553&intItemID=2834",
  "https://www.pyroweb.de/wckd-wonder-1-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3152",
  "https://www.pyroweb.de/wckd-wonder-3-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3150",
  "https://www.pyroweb.de/wckd-wonder-4-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3149",
  "https://www.pyroweb.de/wckd-wonder-5-10-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3148",
  "https://www.pyroweb.de/we-unite-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1961",
  "https://www.pyroweb.de/weco-color-sprueher-10er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1767",
  "https://www.pyroweb.de/weco-double-thunder-10er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1764",
  "https://www.pyroweb.de/weco-kanonenschlag-gross/":
    "JumpIn=1&WebID=2553&intItemID=2861",
  "https://www.pyroweb.de/weco-kubische-kanonenschlaege-15er-pack/":
    "JumpIn=1&WebID=2553&intItemID=3050",
  "https://www.pyroweb.de/weissblinker-rot-z-1-49sh/":
    "JumpIn=1&WebID=2553&intItemID=2620",
  "https://www.pyroweb.de/whistle-blower-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2694",
  "https://www.pyroweb.de/white-strobe-13-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3969",
  "https://www.pyroweb.de/wicked-whistle-60-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2754",
  "https://www.pyroweb.de/widows-kiss-36-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3742",
  "https://www.pyroweb.de/wild-cowboy-sam-20-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3896",
  "https://www.pyroweb.de/wilde-hummel/": "JumpIn=1&WebID=2553&intItemID=1385",
  "https://www.pyroweb.de/wimpelkette-18-gold-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3468",
  "https://www.pyroweb.de/wimpelkette-18-pink-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3463",
  "https://www.pyroweb.de/wimpelkette-30-gold-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3467",
  "https://www.pyroweb.de/wimpelkette-30-pink-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3406",
  "https://www.pyroweb.de/wimpelkette-40-gold-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3466",
  "https://www.pyroweb.de/wimpelkette-40-pink-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3404",
  "https://www.pyroweb.de/wimpelkette-50-gold-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3465",
  "https://www.pyroweb.de/wimpelkette-50-pink-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3402",
  "https://www.pyroweb.de/wimpelkette-60-gold-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3464",
  "https://www.pyroweb.de/wimpelkette-60-pink-396cm/":
    "JumpIn=1&WebID=2553&intItemID=3475",
  "https://www.pyroweb.de/wimpelkette-a-z-schulstart/":
    "JumpIn=1&WebID=2553&intItemID=3321",
  "https://www.pyroweb.de/winterfeuer-50-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3190",
  "https://www.pyroweb.de/wiper/": "JumpIn=1&WebID=2553&intItemID=362",
  "https://www.pyroweb.de/wolf-pride-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2038",
  "https://www.pyroweb.de/wolfdog-130-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=3993",
  "https://www.pyroweb.de/wolke-7-25-schuss/":
    "JumpIn=1&WebID=2553&intItemID=2116",
  "https://www.pyroweb.de/wolkenglitzer-30-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2155",
  "https://www.pyroweb.de/wonder-cones-vulkane/":
    "JumpIn=1&WebID=2553&intItemID=2328",
  "https://www.pyroweb.de/wondertol/": "JumpIn=1&WebID=2553&intItemID=2058",
  "https://www.pyroweb.de/wreckling-balls/":
    "JumpIn=1&WebID=2553&intItemID=3202",
  "https://www.pyroweb.de/wreckling-shot-4er/":
    "JumpIn=1&WebID=2553&intItemID=2998",
  "https://www.pyroweb.de/wrona-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2087",
  "https://www.pyroweb.de/wunderkerze-18cm/":
    "JumpIn=1&WebID=2553&intItemID=2382",
  "https://www.pyroweb.de/wunderkerze-carat-17cm/":
    "JumpIn=1&WebID=2553&intItemID=3854",
  "https://www.pyroweb.de/wunderkerze-herz-2/":
    "JumpIn=1&WebID=2553&intItemID=1456",
  "https://www.pyroweb.de/wunderkerze-herz-love-sparks/":
    "JumpIn=1&WebID=2553&intItemID=1346",
  "https://www.pyroweb.de/wunderkerze-kleeblatt/":
    "JumpIn=1&WebID=2553&intItemID=1455",
  "https://www.pyroweb.de/wunderkerzen-16cm-10er-btl-2/":
    "JumpIn=1&WebID=2553&intItemID=396",
  "https://www.pyroweb.de/wunderwuzzi-fontaenen-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1587",
  "https://www.pyroweb.de/xl-leuchtstick-blau-5x210mm-100erpack/":
    "JumpIn=1&WebID=2553&intItemID=296",
  "https://www.pyroweb.de/xl-leuchtstick-gelb-5x210mm-50er-pack/":
    "JumpIn=1&WebID=2553&intItemID=297",
  "https://www.pyroweb.de/xl-leuchtstick-gruen-5x210mm-100erpack/":
    "JumpIn=1&WebID=2553&intItemID=294",
  "https://www.pyroweb.de/xl-leuchtstick-pink-5x200mm-50er-pack/":
    "JumpIn=1&WebID=2553&intItemID=895",
  "https://www.pyroweb.de/xl-leuchtstick-rot-blau-5x210mm-50er-pack/":
    "JumpIn=1&WebID=2553&intItemID=325",
  "https://www.pyroweb.de/xl-leuchtstick-rot-5x210mm-100erpack/":
    "JumpIn=1&WebID=2553&intItemID=295",
  "https://www.pyroweb.de/xl-leuchtstick-6-farb-mix-50er-pack/":
    "JumpIn=1&WebID=2553&intItemID=896",
  "https://www.pyroweb.de/xl-leuchtstick-6-farb-mix-100er-packung/":
    "JumpIn=1&WebID=2553&intItemID=2247",
  "https://www.pyroweb.de/xp3-sp-boeller/":
    "JumpIn=1&WebID=2553&intItemID=1636",
  "https://www.pyroweb.de/xplode-donnerschlag-3er-pack-kubische-kanonenschlaege/":
    "JumpIn=1&WebID=2553&intItemID=2748",
  "https://www.pyroweb.de/xplode-rap-9-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2255",
  "https://www.pyroweb.de/xplode-triple-deck-25-schuss-batterie-2/":
    "JumpIn=1&WebID=2553&intItemID=3279",
  "https://www.pyroweb.de/luftheuler-xplode-am-stiel-24er-pack/":
    "JumpIn=1&WebID=2553&intItemID=1451",
  "https://www.pyroweb.de/yes-5-schuss-fontaenen-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1443",
  "https://www.pyroweb.de/yggdrasil-z-20-schuss-faecherbatterie-13g/":
    "JumpIn=1&WebID=2553&intItemID=2900",
  "https://www.pyroweb.de/z-generation-40-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2871",
  "https://www.pyroweb.de/zauberkessel-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2869",
  "https://www.pyroweb.de/zena-3-stepper/":
    "JumpIn=1&WebID=2553&intItemID=2764",
  "https://www.pyroweb.de/zena-brocade-blue-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1923",
  "https://www.pyroweb.de/zena-cakebox-144-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1736",
  "https://www.pyroweb.de/zena-colour-box-100-schuss-verbundfeuerwerk/":
    "JumpIn=1&WebID=2553&intItemID=1737",
  "https://www.pyroweb.de/zena-knetterton-xl/":
    "JumpIn=1&WebID=2553&intItemID=2763",
  "https://www.pyroweb.de/zensation-spinner-to-titanium-thunder-10-schuss-singelrow/":
    "JumpIn=1&WebID=2553&intItemID=3945",
  "https://www.pyroweb.de/zephir-fontaenenbatterie/":
    "JumpIn=1&WebID=2553&intItemID=2351",
  "https://www.pyroweb.de/zero-19-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=1436",
  "https://www.pyroweb.de/zevolun-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=2583",
  "https://www.pyroweb.de/zink-schuettrakete-z2-10er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2742",
  "https://www.pyroweb.de/zink-zerlegerraketen-z1-10er-schachtel/":
    "JumpIn=1&WebID=2553&intItemID=2741",
  "https://www.pyroweb.de/zink-raketen-blinkerset-farbig/":
    "JumpIn=1&WebID=2553&intItemID=2005",
  "https://www.pyroweb.de/zink-raketen-gelbflimmer-und-blau/":
    "JumpIn=1&WebID=2553&intItemID=2006",
  "https://www.pyroweb.de/zink-raketen-silberweide-mit-rotblinkern/":
    "JumpIn=1&WebID=2553&intItemID=2004",
  "https://www.pyroweb.de/zink-raketen-spezialeffekte/":
    "JumpIn=1&WebID=2553&intItemID=2003",
  "https://www.pyroweb.de/zink-sternrakete-premium-silberweidebrokat/":
    "JumpIn=1&WebID=2553&intItemID=1105",
  "https://www.pyroweb.de/zink-sternrakete-premium-sortiert/":
    "JumpIn=1&WebID=2553&intItemID=1104",
  "https://www.pyroweb.de/zinngiessen/": "JumpIn=1&WebID=2553&intItemID=2357",
  "https://www.pyroweb.de/zombie-attack/": "JumpIn=1&WebID=2553&intItemID=3180",
  "https://www.pyroweb.de/zorro-26-schuss-faecherbatterie/":
    "JumpIn=1&WebID=2553&intItemID=3737",
  "https://www.pyroweb.de/zunderboxx-16-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3127",
  "https://www.pyroweb.de/zyklop-36-schuss/":
    "JumpIn=1&WebID=2553&intItemID=1078",
  "https://www.pyroweb.de/zylindrischer-kanonenschlag-in-traditioneller-fertigung/":
    "JumpIn=1&WebID=2553&intItemID=1809",
  "https://www.pyroweb.de/zylon-25-schuss-batterie/":
    "JumpIn=1&WebID=2553&intItemID=3025",
  "https://www.pyroweb.de/zuendanlage/": "JumpIn=1&WebID=2553&intItemID=2959",
  "https://www.pyroweb.de/zuendanlage-remote-firing-system/":
    "JumpIn=1&WebID=2553&intItemID=2958",
  "https://www.pyroweb.de/zuendanlage-db06r/":
    "JumpIn=1&WebID=2553&intItemID=2962",
  "https://www.pyroweb.de/zuendanlage-dbr06-x412/":
    "JumpIn=1&WebID=2553&intItemID=2961",
  "https://www.pyroweb.de/zuendleitung-500-m-2-adrig/":
    "JumpIn=1&WebID=2553&intItemID=575",
};
