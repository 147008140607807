import React, { useContext, useState, useEffect } from "react";
import { Link} from "react-router-dom";
import { UserContext } from "../../../context";
import "./styles/middleHeader.css";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import MobileSidebar from "../sidebars/mobileSidebar";
import { toast } from "react-toastify";


function MiddleHeader({ wishListCountFromParents }) {

  const handleRedirect = () => {
    if (!state?.token) {
      toast.info("Bitte melde dich an oder erstelle einen kostenlosen Account, um den virtuellen Warenkorb nutzen zu können!");
    }
  };
  const [state, setState] = useContext(UserContext);



  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  return (
    <div className="header-middle my-middle-header">
      <div className="container">
        <div className="header-left mr-md-4">
          {!showMobileSidebar && (
            <a
              className="my-hamburger  w-icon-hamburger"
              onClick={() => setShowMobileSidebar(true)}
            ></a>
          )}
          {showMobileSidebar && (
            <MobileSidebar
              showMobileSidebar={showMobileSidebar}
              setShowMobileSidebar={setShowMobileSidebar}
            />
          )}
          {!showMobileSidebar && (
            <a href="/" className="logo ml-lg-0">
              <img
                src="assets/images/demos/demo14/logo-main.png"
                alt="logo"
                width={168}
                height={64}
              />
            </a>
          )}
          <nav className="main-nav">
            <ul className="menu active-underline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <a href="/shop">Preisvergleich</a>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/about">Über Uns</Link>
              </li>
              {/*<li>
                <a href="#">Blog</a>
                <ul>
                  <li>
                    <a href="#">blog page</a>
                  </li>
                  <li>
                    <a href="#">single post</a>
                  </li>
                </ul>
  </li>*/}
              {/*<li>
                <a href="#">Über uns</a>
</li>*/}
              <li>
                <Link to="/contact">Kontakt</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header-right ml-4">
          <div className="account align-items-center d-sm-show">
            {state && state.user && state.user.first_name ? (
              <>
                <Link
                  className="login inline-type d-flex ls-normal"
                  to={"/account"}
                >
                  <i className="w-icon-account d-flex align-items-center justify-content-center br-50" />
                  <span className="d-flex flex-column justify-content-center ml-3 d-xl-show">
                    Willkommen, {state.user.first_name}!
                    <b className="d-block font-weight-bold ls-25">Account</b>
                  </span>
                </Link>
              </>
            ) : (
              <Link
                className="login inline-type d-flex ls-normal"
                to={"/login"}
              >
                <i className="w-icon-account d-flex align-items-center justify-content-center br-50" />
                <span className="d-flex flex-column justify-content-center ml-3 d-xl-show">
                  Anmelden
                  <b className="d-block font-weight-bold ls-25">Account</b>
                </span>
              </Link>
            )}
          </div>

          <span className="divider mr-6 d-xl-show" />

          {!showMobileSidebar && (
            <div className="dropdown cart-dropdown cart-offcanvas mr-0 mr-lg-2">
              <div className="cart-overlay" />
              {state?.token != "" && state?.token ? (
                <Link to="/virtualcartlist" className="label-down link">
                  <div className="text-center"><i className="w-icon-cart">
                    {/*<span className="cart-count">{wishListCount}</span>*/}
                  </i>
                  <span className="cart-label">virtueller <br></br> Warenkorb</span></div>
                </Link>
              ) : (
                <Link to="/login" className="label-down link" onClick={handleRedirect}>
                  <div className="text-center"><i className="w-icon-cart">
                    {/*<span className="cart-count">{wishListCount}</span>*/}
                  </i>
                  <span className="cart-label">virtueller <br></br> Warenkorb</span></div>
                </Link>
              )}

              {/* End of Dropdown Box */}
            </div>
          )}
          {/*state && state.user && state.user.first_name && (
            <span
              className="d-flex flex-column justify-content-center ml-3 d-xl-show logout_link"
              onClick={logout}
            >
              <b className="d-block font-weight-bold ls-25">
                <h4>Logout</h4>
              </b>
            </span>
          )*/}
        </div>
      </div>
    </div>
  );
}

export default MiddleHeader;
