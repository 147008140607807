import React, { useEffect } from "react";
import Footer from "./ui/footer/footer";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Searchfield from "./ui/searchfield/Searchfield";

function Gdpr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const removeConsent = () => {
    document.cookie = "cookie_consent_user_accepted=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };
  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <div className="container">
        <h2
          className="title tab-pane-title font-weight-bold mb-2"
          style={{ fontSize: "3rem", marginTop: "2rem" }}
        >
          Cookie-Einstellungen
        </h2>
        <p className="mb-4">
          Cookies sind kleine Textdateien, die von Webseiten (wie BestPreisPyro)
          verwendet werden, um die Benutzererfahrung effizienter zu gestalten
          bzw. teilweise erst zu ermöglichen. Laut Gesetz können wir Cookies auf
          deinem Gerät speichern, wenn diese für den Betrieb dieser Seite
          unbedingt notwendig sind. Für alle anderen Cookie-Typen, um z.B. das
          Einkaufserlebnis noch besser zu machen, brauchen wir deine Erlaubnis.
          Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies
          werden von Drittparteien platziert, die auf unseren Seiten erscheinen.
          Wir verwenden Cookies neben der grundlegenden Funktion der Website, um
          Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien
          anbieten zu können und die Zugriffe auf unsere Website zu analysieren.
          Außerdem geben wir Informationen zur Verwendung unserer Website an
          unsere Partner für soziale Medien, Werbung und Analysen weiter. Du
          kannst die Einwilligung jederzeit von der Cookie-Erklärung auf unserer
          Website ändern oder widerrufen. Erfahre in unserer
          Datenschutzrichtlinie mehr darüber, wer wir sind, wie du uns
          kontaktieren kannst und wie wir personenbezogene Daten verarbeiten.
          Deine Einwilligung trifft auf die folgende Domain zu: https://www.bestpreispyro.de/
        </p>
        <a href="/" className="text-[#F4442C] mb-4 block" onClick={removeConsent}>Cookie-Einstellungen widerrufen</a>

      </div>
      <Footer />
    </div>
  );
}

export default Gdpr;
