import React, { useEffect } from "react";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import MiddleHeader from "./ui/headers/middleHeader";
import Searchbar from "./includes/Searchbar";
import Footer from "./ui/footer/footer";
import { Link } from "react-router-dom";
import MainHeader from "./ui/headers/header-assets/main-header.png";
import Searchfield from "./ui/searchfield/Searchfield";
import banner from "../images/virtueller_warenkorb_banner.png";

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-wrapper">
      <header className="header">
        <TopNavbar />
        <MiddleHeader />
        <Searchbar />
        <Searchfield />
      </header>

      <main className="main">
        <div className="page-header">
          <div className="container" style={{ marginBottom: "1rem" }}>
            <h1
              className="page-title mb-0 text-5xl font-bold"
              style={{ marginTop: "1rem" }}
            >
              BPPYRO Blog
            </h1>
          </div>
        </div>

        <div className="page-content mb-10 pb-2">
          <div className="container">
            <div className="row gutter-lg">
              <div className="main-content">
                <div className="row cols-sm-2">
                <article className="post post-grid-type overlay-zoom mb-6 fashion">
                    <figure className="post-media br-sm">
                      <Link to={"/blog/article2"}>
                        <a>
                          <img
                            src={banner}
                            width="600"
                            height="420"
                            alt="blog"
                          />
                        </a>
                      </Link>
                    </figure>
                    <div className="post-details">
                      <h4 className="post-title">
                        <Link to={"/blog/article2"}>
                          Willkommen zurück zur heißen Phase 2024! Was ist neu?
                        </Link>
                      </h4>
                      <div className="post-content">
                        <Link to={"/blog/article2"}>
                          <p style={{ color: "#999" }}>
                            Das ganze Jahr über haben wir im BPP-Team intensiv
                            an der nagelneuen virtuellen Warenkorb Funktion
                            getüftelt! Uns war es ein persönliches Anliegen, den
                            Preisvergleich nicht nur auf das einzelne Produkte
                            zu reduzieren, wir wollten einen so umfangreichen
                            und fairen Vergleich wie möglich schaffen. Alles
                            unter der Prämisse, dass mit dieser Funktion ein
                            Mehrwert geschaffen wir, für alle Nutzer, aber auch
                            für alle Händler und Shops, die hierbei mitmachen
                            möchten....
                          </p>
                        </Link>{" "}
                        <Link to={"/blog/article2"}>
                          <a className="btn btn-link btn-primary">
                            (mehr erfahren)
                          </a>
                        </Link>
                      </div>
                      <div className="post-meta">
                        von <a className="post-author">Stefan Schiller</a>-{" "}
                        <a className="post-date">09.10.2024</a>
                      </div>
                    </div>
                  </article>
                  <article className="post post-grid-type overlay-zoom mb-6 fashion">
                    <figure className="post-media br-sm">
                      <Link to={"/blog/article1"}>
                        <a>
                          <img
                            src={MainHeader}
                            width="600"
                            height="420"
                            alt="blog"
                          />
                        </a>
                      </Link>
                    </figure>
                    <div className="post-details">
                      <h4 className="post-title">
                        <Link to={"/blog/article1"}>
                          Die Geschichte, Mission und Personen hinter Best Preis
                          Pyro
                        </Link>
                      </h4>
                      <div className="post-content">
                        <Link to={"/blog/article1"}>
                          <p style={{ color: "#999" }}>
                            Mein Name ist Stefan Schiller, vielen auch bekannt
                            unter dem Pseudonym PyroExtrem. Seit 2011 auf
                            Youtube und seit geraumer Zeit auf weiteren Social
                            Media Plattformen vertreten wie beispielsweise
                            Instagram, Tiktok oder Facebook. Feuerwerk ist und
                            bleibt meine große Leidenschaft seit Kindheitstagen.
                            Die Faszination bunter Sterne, eines druckvollen
                            Knalls...
                          </p>
                        </Link>{" "}
                        <Link to={"/blog/article1"}>
                          <a className="btn btn-link btn-primary">
                            (mehr erfahren)
                          </a>
                        </Link>
                      </div>
                      <div className="post-meta">
                        von <a className="post-author">Stefan Schiller</a>-{" "}
                        <a className="post-date">03.01.2024</a>
                      </div>
                    </div>
                  </article>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Blog;
