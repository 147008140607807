import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import ListView from "./ui/shop-views/ListView";
import GridView from "./ui/shop-views/GridView";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Footer from "./ui/footer/footer";
import Searchbar from "./includes/Searchbar";
import MiddleHeader from "./ui/headers/middleHeader";
import { UserContext } from "../context";
import { toast } from "react-toastify";
import NewsletterPopup from "./Popup/NewsletterPopup";
import ShopSkeleton from "./ui/skeletons/shopSkeleton";
import CustomShopSidebar from "./ui/sidebars/customShopSidebar";
import {
  allCategoriesData,
  allBrandsData,
  paramsToObject,
  shopBaseUrl,
  isNotUndefined,
} from "../utils/data";
import ShopPageSwitcher from "./ui/shop-ui/shopPageSwitcher";

function NewShop() {
  const evaluateListSearchParam = (paramName, arrayTemplate) => {
    let param = searchParams.get(paramName);
    if (param !== "undefined") {
      try {
        const paramArray = param.split(",");

        paramArray.forEach((param) => {
          arrayTemplate.forEach((item) => {
            if (item.name === param) {
              item.value = true;
            }
          });
        });

        return arrayTemplate;
      } catch (error) {}
    } else {
      return false;
    }
  };

  const checkForFiltersSelected = (url) => {
    let params = new URLSearchParams(url);
    let filterParams = [
      "selectedcategories",
      "selectedbrands",
      "minprice",
      "maxprice",
      "mincaliber",
      "maxcaliber",
    ];
    let filtersSelected = false;
    filterParams.forEach((param) => {
      if (params.get(param) !== "undefined" && params.get(param) != null) {
        filtersSelected = true;
      }
    });

    return filtersSelected;
  };

  const evaluateRangeSearchParam = (paramNameMin, paramNameMax) => {
    let minParam = searchParams.get(paramNameMin);
    let maxParam = searchParams.get(paramNameMax);

    minParam =
      minParam === "undefined" || minParam === null
        ? undefined
        : parseInt(minParam);
    maxParam =
      maxParam === "undefined" || maxParam === null
        ? undefined
        : parseInt(maxParam);

    return { min: minParam, max: maxParam };
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [allCategories, setAllCategories] = useState(
    evaluateListSearchParam("selectedcategories", allCategoriesData) ||
      allCategoriesData
  );
  const [allBrands, setAllBrands] = useState(
    evaluateListSearchParam("selectedbrands", allBrandsData) || allBrandsData
  );
  const [caliberSelection, setCaliberSelection] = useState(
    evaluateRangeSearchParam("mincaliber", "maxcaliber")
  );
  const [priceSelection, setPriceSelection] = useState(
    evaluateRangeSearchParam("minprice", "maxprice")
  );

  const [pgNumber, setPgNumber] = useState(
    parseInt(searchParams.get("pgnumber")) || 1
  );

  const [mySearchQuery, setMySearchQuery] = useState(
    searchParams.get("pgnumber") !== undefined &&
      searchParams.get("pgnumber") !== null &&
      searchParams.get("pgnumber") !== "undefined"
      ? searchParams.get("searchquery")
      : undefined
  );

  const [pgSize, setPgSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState(false);
  const [resultProducts, setResultProducts] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    currentProductsCount: 20,
    pageSize: 20,
    pagesLeft: undefined,
    totalCount: undefined,
  });
  const [queryParams, setQueryParams] = useState({
    pgNumber: pgNumber,
    size: pgSize,
    categories: undefined,
    fromPriceQuery: undefined,
    toPriceQuery: undefined,
    fromCaliberQuery: undefined,
    toCaliberQuery: undefined,
    brands: undefined,
  });

  //evaluators
  const evaluateCheckboxes = (checkBoxArray) => {
    let tmp = checkBoxArray
      .filter((item) => item.value === true)
      .map((item) => item.name)
      .join(",");

    if (tmp !== "") {
      return tmp;
    } else {
      return undefined;
    }
  };

  //handlers
  const handleCategorySelect = (index) => {
    let paramObject = paramsToObject(searchParams);

    let currUrl = location.pathname;
    currUrl = currUrl.replace(allCategories[index].name, "");
    navigate(currUrl);
    let tmp = [...allCategories];
    tmp[index].value = !tmp[index].value;
    setAllCategories([...tmp]);
  };

  const handleBrandSelect = (index) => {
    let currUrl = location.pathname;
    currUrl = currUrl.replace(allBrands[index].name, "");
    navigate(currUrl);
    let tmp = [...allBrands];
    tmp[index].value = !tmp[index].value;
    setAllBrands([...tmp]);
  };

  const handleNextPageClick = () => {
    setPgNumber(pgNumber + 1);
  };

  const handlePrevPageClick = () => {
    if (pgNumber > 0) setPgNumber(pgNumber - 1);
  };

  const navigate = useNavigate();
  const resetResults = () => {
    navigate(shopBaseUrl);
    window.location.reload();
  };

  //wishlist functions
  const addToWishList = async (product_id, state) => {
    console.log(state);
    if (state && state.user && state.user.id) {
      setIsLoading(true);
      try {
        const { data } = await axios.post(`/wishlist`, {
          user_id: state.user.id,
          product_id: product_id,
        });

        if (data.message === "error") {
          toast.error(data.description);
        } else if (data.message === "success") {
          //toast.success(data.description);
          toast.success("Produkt wurde deiner Wishlist hinzugefügt");
          fetchProducts();
        }
      } catch (error) {
        console.log("An error occured ===> ", error);
      }
    } else {
      console.log("triggered");
      toast.error(
        `Logge dich ein, um einen Artikel deiner Wunschliste hinzuzufügen!`
      );
    }
  };

  const removeFromWishList = async (product_id, state) => {
    if (state && state.user && state.user.id) {
      setIsLoading(true);
      try {
        const { data } = await axios.delete(
          `/wishlist?user_id=${state.user.id}&product_id=${product_id}`
        );

        if (data.message === "error") {
          toast.error(data.description);
        } else if (data.message === "success") {
          //toast.success(data.description);
          toast.success("Produkt wurde von deiner Wishlist entfernt");
          fetchProducts();
        }
      } catch (error) {
        console.log("An error occured ===> ", error);
      }
    } else {
      toast.error(
        `Logge dich ein, um einen Artikel deiner Wunschliste hinzuzufügen!`
      );
    }
  };

  //fetching and use effect for fetching
  const fetchProducts = async () => {
    let tmp = {
      ...queryParams,
      pgNumber: pgNumber,
      size: pgSize,
      categories: evaluateCheckboxes(allCategories),
      fromPriceQuery: priceSelection.min,
      toPriceQuery: priceSelection.max,
      fromCaliberQuery: caliberSelection.min,
      toCaliberQuery: caliberSelection.max,
      brands: evaluateCheckboxes(allBrands),
      searchQuery: isNotUndefined(mySearchQuery) ? mySearchQuery : undefined,
    };

    setQueryParams({ ...tmp });
    setIsLoading(true);

    let newUrl = `/newshop?selectedbrands=${evaluateCheckboxes(
      allBrands
    )}&selectedcategories=${evaluateCheckboxes(allCategories)}&mincaliber=${
      caliberSelection.min
    }&maxcaliber=${caliberSelection.max}&minprice=${
      priceSelection.min
    }&maxprice=${priceSelection.max}&pgnumber=${pgNumber}&pgsize=${
      paginationInfo.pageSize
    }&searchquery=${tmp.searchQuery}`;
    navigate(newUrl);

    try {
      const { data } = await axios.get(`/getProductByMultipleCategory`, {
        params: tmp,
      });

      setResultProducts(data.data.products);
      setPaginationInfo({ ...data.data.pagination });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    //whenever a filter is changed new products are fetched
    !isLoading && fetchProducts();
  }, [
    pgNumber,
    pgSize,
    allCategories,
    priceSelection,
    caliberSelection,
    allBrands,
  ]);

  return (
    <>
      <div>
        <div className="page-wrapper">
          <NewsletterPopup />
          <h1 className="d-none">Shop page right sidebar</h1>
          {/* Start of Header */}
          <header className="header">
            <TopNavbar />
            <MiddleHeader />
            <Searchbar shouldReload={true} />
          </header>
          {/* End of Header */}
          {/* Start of Main */}
          <main className="main">
            {/* Start of Page Content */}
            <div className="page-content">
              <div className="container">
                {/* Start of Shop Banner */}
                <div
                  className="shop-default-banner banner d-flex align-items-center mb-5 br-xs"
                  style={{
                    marginTop: "32px",
                    backgroundImage:
                      "url(assets/images/shop/shop-background.png)",
                    backgroundColor: "#F4442C",
                  }}
                >
                  <div className="banner-content">
                    <h3 className="banner-title text-white text-uppercase font-weight-bolder ls-normal">
                      Deine Suchergebnisse
                    </h3>
                    <h4 className="banner-subtitle font-weight-bold">
                      Verfeinere die Suche durch das Verwenden der zahlreichen
                      Filter
                    </h4>
                  </div>
                </div>

                <div className="shop-content row gutter-lg mb-10">
                  {/* Start of Shop Main Content */}
                  <div className="main-content">
                    <nav className="toolbox sticky-toolbox sticky-content fix-top">
                      <div className="toolbox-left">
                        <ShopPageSwitcher
                          handleNextPageClick={handleNextPageClick}
                          handlePrevPageClick={handlePrevPageClick}
                          prodcutResultNumber={resultProducts.length}
                          paginationInfo={paginationInfo}
                          showTotalResults={false}
                        />
                      </div>
                      <div className="toolbox-right">
                        <select
                          name="count"
                          class="form-control"
                          onChange={(e) => setPgSize(e.target.value)}
                        >
                          <option value="20" selected="selected">
                            Zeige 20
                          </option>
                          <option value="50">Zeige 50</option>
                          <option value="100">Zeige 100</option>
                        </select>
                      </div>
                    </nav>

                    {isLoading ? (
                      <div className="product-wrapper row cols-md-4 cols-sm-1 cols-1">
                        <ShopSkeleton />
                      </div>
                    ) : (
                      <>
                        <div id="listview">
                          <ListView
                            products={resultProducts}
                            addToWishList={addToWishList}
                            removeFromWishList={removeFromWishList}
                          />
                        </div>
                        <div id="gridview">
                          <GridView products={resultProducts} />
                        </div>
                      </>
                    )}
                    <ShopPageSwitcher
                      handleNextPageClick={handleNextPageClick}
                      handlePrevPageClick={handlePrevPageClick}
                      prodcutResultNumber={resultProducts.length}
                      paginationInfo={paginationInfo}
                      showTotalResults={true}
                    />
                  </div>
                  {/* End of Shop Main Content */}
                  {/* Start of Sidebar, Shop Sidebar */}

                  <CustomShopSidebar
                    allCategories={allCategories}
                    handleCategorySelect={handleCategorySelect}
                    allBrands={allBrands}
                    handleBrandSelect={handleBrandSelect}
                    caliberSelection={caliberSelection}
                    setCaliberSelection={setCaliberSelection}
                    priceSelection={priceSelection}
                    setPriceSelection={setPriceSelection}
                    resetResults={resetResults}
                  />

                  {/* End of Shop Sidebar */}
                </div>
                {/* End of Shop Content */}
              </div>
            </div>
            {/* End of Page Content */}
          </main>
          {/* End of Main */}
          <Footer />
          {/* End of Footer */}
        </div>
      </div>
    </>
  );
}

export default NewShop;
