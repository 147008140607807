import React, {useState, useEffect} from "react";

function ProductImage(props) {
  const [imageDimensions, setImageDimensions] = useState({});
  const {productName} = props;

  const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = (err) => {
      console.log("img error");
      console.error(err);
    };
  };

  useEffect(() => {
    loadImage(setImageDimensions, props.imageUrl);
    console.log(imageDimensions);
  }, []);
  return (
    <div style={{display: "flex", justifyContent: "flex-end"}}>
      <img
        alt={`${productName} kaufen`}
        src={props.imageUrl}
        width={imageDimensions.width && imageDimensions.width}
        height={imageDimensions.height && imageDimensions.height}
      />
    </div>
  );
}

export default ProductImage;
