import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import ListView from "./ui/shop-views/ListView";
import GridView from "./ui/shop-views/GridView";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavbar from "./ui/countdown/topNavbar/topNavbar";
import Footer from "./ui/footer/footer";
import Searchbar from "./includes/Searchbar";
import MiddleHeader from "./ui/headers/middleHeader";
import ShopSkeleton from "./ui/skeletons/shopSkeleton";
import CustomShopSidebar from "./ui/sidebars/customShopSidebar";
import {
  allCategoriesData,
  allBrandsData,
  paramsToObject,
  shopBaseUrl,
  isNotUndefined,
} from "../utils/data";
import ShopPageSwitcher from "./ui/shop-ui/shopPageSwitcher";
import Searchfield from "./ui/searchfield/Searchfield";
import PatreonPopup from "./Popup/patreonPopup";

function Shop() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const evaluateListSearchParam = (paramName, arrayTemplate) => {
    let param = searchParams.get(paramName);
    if (param !== "undefined") {
      try {
        const paramArray = param.split(",");

        paramArray.forEach((param) => {
          arrayTemplate.forEach((item) => {
            if (item.name === param) {
              item.value = true;
            }
          });
        });

        return arrayTemplate;
      } catch (error) {}
    } else {
      return false;
    }
  };

  const evaluateShopParam = () => {
    let param = searchParams.get("selectedshops");

    if (isNotUndefined(param)) {
      return param.toString();
    } else {
      return false;
    }
  };

  const evaluateParams = (parameterName) => {
    let param = searchParams.get(parameterName);

    if (isNotUndefined(param)) {
      return param.toString();
    } else {
      return false;
    }
  };

  const evaluateRangeSearchParam = (paramNameMin, paramNameMax) => {
    let minParam = searchParams.get(paramNameMin);
    let maxParam = searchParams.get(paramNameMax);

    minParam =
      minParam === "undefined" || minParam === null
        ? undefined
        : parseInt(minParam);
    maxParam =
      maxParam === "undefined" || maxParam === null
        ? undefined
        : parseInt(maxParam);

    return { min: minParam, max: maxParam };
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // filter states will either be inialised with a default value or with a value from the url. Later is the case when a detail page was opened and the user returns
  //start filter variables state init
  const [allCategories, setAllCategories] = useState(
    evaluateListSearchParam("selectedcategories", allCategoriesData) ||
      allCategoriesData
  );
  const [allBrands, setAllBrands] = useState(
    evaluateListSearchParam("selectedbrands", allBrandsData) || allBrandsData
  );
  const [caliberSelection, setCaliberSelection] = useState(
    evaluateRangeSearchParam("mincaliber", "maxcaliber")
  );
  const [priceSelection, setPriceSelection] = useState(
    evaluateRangeSearchParam("minprice", "maxprice")
  );

  const [selectedShops, setSelectedShops] = useState(
    evaluateShopParam() || undefined
  );

  const [selectedFireworksCategories, setSelectedFireworksCategories] =
    useState(evaluateParams("selectedfireworkscategories") || undefined);

  const [pgNumber, setPgNumber] = useState(
    parseInt(searchParams.get("pgnumber")) || 1
  );

  const [sortingOrder, setSortingOrder] = useState(
    searchParams.get("sortingorder") || "asc"
  );

  const [isDiscounted, setIsDiscounted] = useState(
    searchParams.get("isdiscounted") === "true" || false
  );

  const [isAvailable, setIsAvailable] = useState(
    searchParams.get("isavailable") === "true" || false
  );

  const [onlyAllYear, setOnlyAllYear] = useState(
    searchParams.get("onlyallyear") === "true" || false
  );

  const [onlyVirtualCart, setOnlyVirtualCart] = useState(
    searchParams.get("onlyvirtualcart") === "true" || false
  );

  const [isDefault, setIsDefault] = useState(false);

  const [mySearchQuery, setMySearchQuery] = useState(
    searchParams.get("pgnumber") !== undefined &&
      searchParams.get("pgnumber") !== null &&
      searchParams.get("pgnumber") !== "undefined"
      ? searchParams.get("searchquery")
      : undefined
  );
  //end filter variables state init
  const [showSidebar, setShowSidebar] = useState(false);
  const [pgSize, setPgSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [resultProducts, setResultProducts] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    currentProductsCount: 20,
    pageSize: 20,
    pagesLeft: undefined,
    totalCount: undefined,
  });

  //maybe this could be removed needs a check
  const [queryParams, setQueryParams] = useState({
    pgNumber: pgNumber,
    size: pgSize,
    categories: undefined,
    fromPriceQuery: undefined,
    toPriceQuery: undefined,
    fromCaliberQuery: undefined,
    toCaliberQuery: undefined,
    brands: undefined,
    sortingOrder: sortingOrder,
    isDiscounted: isDiscounted,
    isAvailable: isAvailable,
    onlyAllYear: onlyAllYear,
    onlyVirtualCart: onlyVirtualCart,
  });

  //evaluators
  const evaluateCheckboxes = (checkBoxArray) => {
    let tmp = checkBoxArray
      .filter((item) => item.value === true)
      .map((item) => item.name)
      .join(",");

    if (tmp !== "") {
      return tmp;
    } else {
      return undefined;
    }
  };

  //filter handlers
  const handleCategorySelect = (index) => {
    setPgNumber(1);
    let paramObject = paramsToObject(searchParams);
    let currUrl = location.pathname;
    currUrl = currUrl.replace(allCategories[index].name, "");
    navigate(currUrl);
    let tmp = [...allCategories];
    tmp[index].value = !tmp[index].value;
    setAllCategories([...tmp]);
  };

  const handleBrandSelect = (index) => {
    setPgNumber(1);
    let currUrl = location.pathname;
    currUrl = currUrl.replace(allBrands[index].name, "");
    navigate(currUrl);
    let tmp = [...allBrands];
    tmp[index].value = !tmp[index].value;
    setAllBrands([...tmp]);
  };

  const handlePriceSelection = (priceSelection) => {
    setPgNumber(1);
    setPriceSelection({ ...priceSelection });
  };

  const handleCaliberSelection = (caliberSelection) => {
    setPgNumber(1);
    setCaliberSelection({ ...caliberSelection });
  };

  const handleShopSelection = (selectedShops) => {
    setPgNumber(1);
    setSelectedShops(selectedShops);
  };

  const handleNextPageClick = (jumpTop) => {
    paginationInfo.pagesLeft > 0 && setPgNumber(pgNumber + 1);
    jumpTop && window.scrollTo(0, 0);
  };

  const handlePrevPageClick = (jumpTop) => {
    if (pgNumber > 1) setPgNumber(pgNumber - 1);
    jumpTop && window.scrollTo(0, 0);
  };

  const navigate = useNavigate();
  const resetResults = () => {
    navigate(shopBaseUrl);
    window.location.reload();
  };

  //fetching and use effect for fetching
  const fetchProducts = async () => {
    //prepare all values of the filters in the tmp object to be send to the api
    let tmp = {
      ...queryParams,
      pgNumber: pgNumber,
      size: pgSize,
      categories: evaluateCheckboxes(allCategories),
      fromPriceQuery: priceSelection.min,
      toPriceQuery: priceSelection.max,
      fromCaliberQuery: caliberSelection.min,
      toCaliberQuery: caliberSelection.max,
      sortingOrder: sortingOrder,
      brands: evaluateCheckboxes(allBrands),
      searchQuery: isNotUndefined(mySearchQuery) ? mySearchQuery : undefined,
      isDiscounted: isDiscounted,
      isAvailable: isAvailable,
      onlyAllYear: onlyAllYear,
      onlyVirtualCart: onlyVirtualCart,
      selectedFireworksCategories:
        isNotUndefined(selectedFireworksCategories) &&
        selectedFireworksCategories !== ""
          ? selectedFireworksCategories?.toString()
          : undefined,
      selectedShops:
        isNotUndefined(selectedShops) && selectedShops !== ""
          ? selectedShops?.toString()
          : undefined,
    };
    setQueryParams({ ...tmp });
    setIsLoading(true);

    //save filter values in the url to restore filter state if needed
    let newUrl = `/shop?selectedbrands=${evaluateCheckboxes(
      allBrands
    )}&selectedcategories=${evaluateCheckboxes(allCategories)}&mincaliber=${
      caliberSelection.min
    }&maxcaliber=${caliberSelection.max}&minprice=${
      priceSelection.min
    }&maxprice=${priceSelection.max}&pgnumber=${pgNumber}&pgsize=${
      paginationInfo.pageSize
    }&searchquery=${tmp.searchQuery}&selectedshops=${
      tmp.selectedShops
    }&sortingorder=${tmp.sortingOrder}&isdiscounted=${
      tmp.isDiscounted
    }&isavailable=${tmp.isAvailable}&onlyallyear=${
      tmp.onlyAllYear
    }&selectedfireworkscategories=${
      tmp.selectedFireworksCategories
    }&onlyvirtualcart=${tmp.onlyVirtualCart}`;
    navigate(newUrl);

    try {
      const { data } = await axios.get(`/getProductByMultipleCategory`, {
        params: tmp,
      });
      //check for the default case this will effect if certain components will be rendered
      if (data.data !== "none") {
        console.log(data.data.products);
        setResultProducts(data.data.products);
        setPaginationInfo({ ...data.data.pagination });
        setIsDefault(false);
      } else {
        setIsDefault(true);
        setResultProducts([]);
        setPaginationInfo({
          currentPage: 1,
          currentProductsCount: 20,
          pageSize: 20,
          pagesLeft: undefined,
          totalCount: undefined,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    //whenever a filter is changed new products are fetched

    fetchProducts();
  }, [
    pgNumber,
    pgSize,
    allCategories,
    priceSelection,
    caliberSelection,
    allBrands,
    selectedShops,
    sortingOrder,
    isDiscounted,
    isAvailable,
    onlyAllYear,
    onlyVirtualCart,
    selectedFireworksCategories,
  ]);

  return (
    <>
      <div>
        <div className="page-wrapper">
          <PatreonPopup />
          <h1 className="d-none">Shop page right sidebar</h1>
          {/* Start of Header */}
          <header className="header">
            <TopNavbar />
            <MiddleHeader />
            <Searchbar shouldReload={true} />
            <Searchfield
              shouldReload={true}
              showFilterIcon={true}
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
          </header>
          {/* End of Header */}
          {/* Start of Main */}
          <main className="main">
            {/* Start of Page Content */}
            <div className="page-content">
              <div className="container">
                {/* Start of Shop Banner */}
                <div
                  id="shopbanner"
                  className="shop-default-banner banner d-flex align-items-center mb-5 br-xs"
                  style={{
                    marginTop: "32px",
                    backgroundImage:
                      "url(assets/images/shop/bpp-shop-banner.svg)",
                    backgroundColor: "#F4442C",
                  }}
                >
                  <div className="banner-content">
                    <h3 className="page-title mb-0 text-5xl font-bold text-white text-uppercase font-weight-bolder pt-8 pb-8 pr-8">
                      Deine Suchergebnisse
                    </h3>
                    <h4 className="banner-subtitle font-weight-bold">
                      Verfeinere die Suche durch das Verwenden der zahlreichen
                      Filter
                    </h4>
                  </div>
                </div>

                <div className="shop-content row gutter-lg mb-10">
                  {/* Start of Shop Main Content */}
                  <div className="main-content">
                    <nav className="toolbox fix-top">
                      <div className="toolbox-left">
                        {!isDefault && (
                          <ShopPageSwitcher
                            handleNextPageClick={handleNextPageClick}
                            handlePrevPageClick={handlePrevPageClick}
                            prodcutResultNumber={resultProducts.length}
                            paginationInfo={paginationInfo}
                            showTotalResults={false}
                          />
                        )}
                      </div>
                      <div className="toolbox-right">
                        {!isDefault && (
                          <>
                            <select
                              name="count"
                              class="form-control"
                              onChange={(e) => setPgSize(e.target.value)}
                            >
                              <option value="20" selected="selected">
                                Zeige 20
                              </option>
                              <option value="50">Zeige 50</option>
                              <option value="100">Zeige 100</option>
                            </select>
                            <select
                              name="count"
                              class="form-control"
                              value={sortingOrder}
                              onChange={(e) => setSortingOrder(e.target.value)}
                            >
                              <option value="asc">Preise aufsteigend</option>
                              <option value="dsc">Preise absteigend</option>
                            </select>
                          </>
                        )}
                      </div>
                    </nav>

                    {isLoading ? (
                      <div className="product-wrapper row cols-md-4 cols-sm-1 cols-1">
                        <ShopSkeleton />
                      </div>
                    ) : (
                      <>
                        {isDefault && (
                          <div
                            style={{
                              display: "inline-block",
                              color: "white",
                              fontFamily: "Poppins",
                              backgroundColor: "#183544",
                              padding: "3rem",
                              borderRadius: "4px",
                              marginTop: "2rem",
                            }}
                          >
                            Du hast noch keine Filter ausgewählt. Benutze die
                            Filterleiste oder das Filtericon auf Mobilgeräten.
                          </div>
                        )}

                        <div id="listview">
                          <ListView products={resultProducts} />
                        </div>
                        <div id="gridview">
                          <GridView products={resultProducts} />
                        </div>
                      </>
                    )}
                    {!isDefault && (
                      <ShopPageSwitcher
                        handleNextPageClick={handleNextPageClick}
                        handlePrevPageClick={handlePrevPageClick}
                        prodcutResultNumber={resultProducts.length}
                        paginationInfo={paginationInfo}
                        showTotalResults={true}
                        jumpTop={true}
                      />
                    )}
                  </div>
                  {/* End of Shop Main Content */}
                  {/* Start of Sidebar, Shop Sidebar */}

                  <CustomShopSidebar
                    allCategories={allCategories}
                    handleCategorySelect={handleCategorySelect}
                    allBrands={allBrands}
                    handleBrandSelect={handleBrandSelect}
                    caliberSelection={caliberSelection}
                    setCaliberSelection={handleCaliberSelection}
                    priceSelection={priceSelection}
                    setPriceSelection={handlePriceSelection}
                    resetResults={resetResults}
                    showSidebar={showSidebar}
                    setShowSidebar={setShowSidebar}
                    selectedShops={selectedShops}
                    setSelectedShops={handleShopSelection}
                    isDiscounted={isDiscounted}
                    setIsDiscounted={setIsDiscounted}
                    isAvailable={isAvailable}
                    setIsAvailable={setIsAvailable}
                    onlyAllYear={onlyAllYear}
                    setOnlyAllYear={setOnlyAllYear}
                    onlyVirtualCart={onlyVirtualCart}
                    setOnlyVirtualCart={setOnlyVirtualCart}
                    selectedFireworksCategories={selectedFireworksCategories}
                    setSelectedFireworksCategories={
                      setSelectedFireworksCategories
                    }
                  />

                  {/* End of Shop Sidebar */}
                </div>
                {/* End of Shop Content */}
              </div>
            </div>
            {/* End of Page Content */}
          </main>
          {/* End of Main */}
          <Footer />
          {/* End of Footer */}
        </div>
      </div>
    </>
  );
}

export default Shop;
