import React from 'react'
import Skeleton from "react-loading-skeleton";

function ShopSkeleton() {
  return (
    <>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                          <div className="product-wrap">
                            <div className="product text-center">
                              <figure className="product-media">
                                <a href="#">
                                  <Skeleton height={338} />
                                </a>
                              </figure>
                              <div className="product-details">
                                <div className="product-cat">
                                  <a href="#">
                                    <Skeleton />
                                  </a>
                                </div>
                                <h3 className="product-name">
                                  <Skeleton />
                                </h3>
                                <Skeleton count={2} />
                              </div>
                            </div>
                          </div>
                        </>
  )
}

export default ShopSkeleton;