import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import getMarkers from "../../../utils/getMarkers";
import GeoCoder from "./geoCoder";

function PickUpLocationFinder() {
  const position = [50.9766372, 10.31353464552388];
  let markers = getMarkers();

  return (
    <div
      style={{
        width: "100vw",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        marginBottom: "50px",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <MapContainer center={position} zoom={6} scrollWheelZoom={false}>
        <TileLayer
        
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoCoder />
        {markers.map((marker, index) => {
          return (
            <Marker
              key={index}
              position={marker?.coordinates}
              icon={marker.icon}
            >
              <Popup>
                <a
                  style={{ fontFamily: "Poppins", color: "#183544" }}
                  href={"//" + marker.shopLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${marker.shopName} - ${marker.mainLocation}`}
                </a>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
}
export default PickUpLocationFinder;
