import React from "react";
import "./stylesheets/ConsentPopup.css";
import { useState, useEffect, useContext } from "react";

function ConsentPopup({ subscribe }) {
  const [isVisible, setIsVisible] = useState(true);

  const handleSubscribeClick = () => {
    subscribe();
    setIsVisible(false);
  };

  return (
    <div
      className="consentpopup"
      style={{ display: isVisible ? "flex" : "none", fontFamily: "Poppins", zIndex: "1000000000" }}
    >
      <h4>🔔 Push Benachrichtigungen erlauben!🔔</h4>
      <p>
      Du willst unser Preisalarm Feature bzw. die Wishlist Benachrichtigungen nicht nur per Mail, 
      sondern auch per Browser Push Nachricht erhalten? Dann stimme den Push Benachrichtigungen hier zu. 
      </p>
      <button
        style={{ fontFamily: "Poppins", borderRadius: "4px"}}
        className="consentpopup__button__accept"
        onClick={handleSubscribeClick}
      >
        Ich will Push Benachrichtigungen
      </button>
      <br />
      <button
        style={{ fontFamily: "Poppins",  borderRadius: "4px" }}
        className="consentpopup__button__reject"
        onClick={() => setIsVisible(false)}
      >
        Abbrechen
      </button>
    </div>
  );
}

export default ConsentPopup;
