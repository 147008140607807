import L from 'leaflet';

const mainLocationIcon = new L.Icon({
    iconUrl: require('../../images/mainLocationIcon.png'),
    iconRetinaUrl: require('../../images/mainLocationIcon.png'),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(16.5, 22.4),
    className: 'leaflet-div-icon'
});

const storeIcon = new L.Icon({
    iconUrl: require('../../images/storeIcon.png'),
    iconRetinaUrl: require('../../images/storeIcon.png'),
 
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(24, 32),
    className: 'leaflet-div-icon'
});


const sideLocationIcon = new L.Icon({
    iconUrl: require('../../images/sideLocation.png'),
    iconRetinaUrl: require('../../images/sideLocation.png'),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(16.5, 22.4),
    className: 'leaflet-div-icon'
});

export { mainLocationIcon, sideLocationIcon,storeIcon };